import { useMemo, useState } from 'react';

const firstTabIndex = 0;

export default function useTabs(menuOptions) {
  const initialIndex = menuOptions.findIndex(option => option.active);
  const [activeTab, setActiveTab] = useState(initialIndex !== -1 ? initialIndex : firstTabIndex);

  const handleTabChange = (_, { activeIndex }) => {
    setActiveTab(activeIndex);
  };

  const panes = useMemo(() => menuOptions.map(option => ({
    menuItem: option.menuItem,
    render: () => <>{option.component}</>
  })), [menuOptions]);

  const tabMenuProps = useMemo(() => ({
    widths: menuOptions.length,
    size: 'small',
    compact: true,
    attached: false,
    pointing: false,
    secondary: false,
    borderless: false,
  }), [menuOptions.length]);

  return { activeTab, handleTabChange, panes, tabMenuProps };
};