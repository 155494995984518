import { getCalloutStyle, getCompareStyle } from "../components/Common/TableCellUtils"

// function used to parse cell values of top/bottom DeptDrilldown cells
export const topBottomFormat = ({ metric, value, table }) => {
    // dollarValue is used to parse 0 into $0.00, following current pattern in tables
    const dollarValue = value === 0 ? '$' + value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : value
    if (metric === 'SLS_AMT') {
        return {
            dollarValue,
            decimals: 2,
            customStyle: getCalloutStyle(value)
        }
    }
    if (metric === 'SLS_COMP_NOM') {
        return {
            dollarValue,
            decimals: 2,
            compareStyle: true,
            customStyle: table === 'bottom' ? getCompareStyle(value) : undefined
        }
    }
    if (metric === 'SLS_COMP_PCT') {
        return {
            pctValue: value,
            compareStyle: true,
            decimals: 2
        }
    }
    if (metric === 'UNT_SLS') {
        return {
            //toLocaleString to parse number from 12345.67 to 12,345.67
            numValue: value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }),
            customStyle: getCalloutStyle(value)
        }
    }
    if (metric === 'UNT_COMP_NOM') {
        return {
            //toLocaleString to parse number from 12345.67 to 12,345.67
            numValue: value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }),
            compareStyle: true
        }
    }
    if (metric === 'UNT_COMP_PCT') {
        return {
            pctValue: value,
            compareStyle: true,
            decimals: 2
        }
    }
}   