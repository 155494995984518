import React from 'react'
import {
  Tab,
  Grid,
} from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
import { DeptsByDstTab } from '../ProdHierDrilldown/DeptsByDstTab'
import { ClassesByDstTab } from '../ProdHierDrilldown/ClassesByDstTab'
import { SubclassesByDstTab } from '../ProdHierDrilldown/SubclassesByDstTab'
import { Top40Btm40Skus } from '../ProdHierDrilldown/Top40Btm40Skus'

export const DstSlsTab = withRouter(({ dataSlsDeptByDst, dataSlsClsByDst, dataSlsSclsByDst, Top40Btm40Query, timeframe, location, dstNm }) => {

  const search = new URLSearchParams(location.search)
  const dstNbr = search.has('dstNbr') ? search.get('dstNbr') : 0

  const deptsByDst = <DeptsByDstTab
    dstNbr={dstNbr}
    data={dataSlsDeptByDst}
    timeframe={timeframe}
    dstNm={dstNm}
  />

  const classesByDst = <ClassesByDstTab
    dstNbr={dstNbr}
    data={dataSlsClsByDst}
    timeframe={timeframe}
    dstNm={dstNm}
  />
  const subclassesByDst = <SubclassesByDstTab
    dstNbr={dstNbr}
    data={dataSlsSclsByDst}
    timeframe={timeframe}
    dstNm={dstNm}
  />
  const skusByDst = <Top40Btm40Skus
  dstNbr={dstNbr}
  Top40Btm40Query={Top40Btm40Query}
  timeframe={timeframe}
  dstNm={dstNm}
/>

  const tabs = [
    {
      menuItem: 'Dept',
      render: () => deptsByDst
    },

    {
      menuItem: 'Classes',
      render: () => classesByDst
    },

    {
      menuItem: 'Subclasses',
      render: () => subclassesByDst
    },
    {
      menuItem: 'Skus',
      render: () => skusByDst
    }
  ]

  return (
    <Tab.Pane>
      <Grid>
        <Grid.Column>
          <Grid.Row>
            <Tab menu={{ attached: false, pointing: false, secondary: false, borderless: false, compact: true, size: 'small', widths: 4 }} panes={tabs} />
          </Grid.Row>
        </Grid.Column>
      </Grid>
    </Tab.Pane>
  )
}
)