import { SalesTab } from './SalesTab'
import { Loader, Tab } from 'semantic-ui-react'
import { ComparisonByDeparmentTab } from './ComparisonByDeparmentTab'
import { InventoryTab } from './InventoryTab'
import { useRegionOverviewContext } from '../../../hooks/useRegionOverviewContext'

export const RegionOverviewTabs = () => {
  const { isError, isRegionOverviewTabsLoading } = useRegionOverviewContext()

  if (isError) return <h1>Something went wrong</h1>
  if (isRegionOverviewTabsLoading) return <Loader active>Loading</Loader>

  const tabs = [
    {
      menuItem: 'Sales',
      render: () => <SalesTab />
    },
    {
      menuItem: 'Comp',
      render: () => <ComparisonByDeparmentTab />,
    },
    {
      menuItem: 'Inventory',
      render: () => <InventoryTab />,
    },
  ]

  return <Tab panes={tabs} />
}
