import { createContext } from 'react';

import { useQuery } from 'react-query';
import { useQueryParams } from '../hooks/useQueryParams';

import { getData } from '../service/DataService';

export const RegionOverviewContext = createContext();

const RegionOverviewContextProvider = ({ children, fpToggle, ...props }) => {
  const { rgnNbr } = useQueryParams();
  const {
    data: salesDeptData = [],
    isError: isErrorSalesDept,
    isLoading: isLoadingSalesDept
  } = useQuery([`query/deptSales/region`, { fpToggle, locNbr: rgnNbr }], getData);
  const {
    data: deptInvData = [],
    isError: isErrorDeptInv,
    isLoading: isLoadingDepInv
  } = useQuery([`query/invDept/region`, { fpToggle, locNbr: rgnNbr }], getData);
  const {
    data: slsSclsByRgnData = [],
    isError: isErrorslsSclsByRgnData,
    isLoading: isLoadingslsSclsByRgnData,
  } = useQuery(['querySlsSclsByRgn', { locNbr: rgnNbr, fpToggle }], getData)
  const {
    data : slsClsByRgnData = [],
    isError: isErrorSlsClsByRgn,
    isLoading: isLoadingSlsClsByRgn
  } = useQuery(['querySlsClsByRgn', { locNbr: rgnNbr, fpToggle }], getData)

  const value = {
    ...props,
    deptInvData,
    isError: isErrorSalesDept || isErrorDeptInv || isErrorslsSclsByRgnData || isErrorSlsClsByRgn, // TODO: Add more isError once we add the next queries/tabs
    isRegionOverviewTabsLoading: isLoadingSalesDept || isLoadingDepInv || isLoadingslsSclsByRgnData || isLoadingSlsClsByRgn, // TODO: Add more loadings once we add the next queries/tabs
    rgnNbr,
    salesDeptData,
    slsSclsByRgnData,
    fpToggle,
    slsClsByRgnData

  };

  return <RegionOverviewContext.Provider value={value}>{children}</RegionOverviewContext.Provider>;
};

export default RegionOverviewContextProvider;
