import React, { useState, useEffect } from 'react'
import { withRouter, Link } from 'react-router-dom'
import {
  Table,
  Header,
  Loader,
  Menu,
  Grid,
  Pagination,
} from 'semantic-ui-react'

import { useQuery } from 'react-query'
import {
  getData
} from '../../service/DataService'
import { getDollarString, getPercentString } from '../Common/TableCellUtils'

export const DailyView = withRouter(({ fpToggle, level, location }) => {

  const search = new URLSearchParams(location.search)
  const strNbr = search.has('strNbr') ? search.get('strNbr') : 0
  const dstNbr = search.has('dstNbr') ? search.get('dstNbr') : 0

  const [sortColumn, setSortColumn] = useState('DAY_CODE')
  const [sortDirection, setSortDirection] = useState('ascending')

  const [wtdOverviewSortColumn, setWtdOverviewSortColumn] = useState('DEPT_NBR')
  const [wtdOverviewSortDirection, setWtdOverviewSortDirection] = useState('ascending')

  const [slsHistSortColumn, setSlsHistSortColumn] = useState('PERIOD_END_DT')
  const [slsHistSortDirection, setSlsHistSortDirection] = useState('ascending')
  const [pageNum, setPageNum] = useState(1)
  const rowsPerPage = 20

  const [wtdOrScorecard, setWtdOrScorecard] = useState("WTD Sales")
  const [metric, setMetric] = useState("Sales Plan")
  const [timeframe, setTimeframe] = useState("FISCAL DAY")
  const [fiscalYear, setFiscalYear] = useState("2022")
  const [dateFormat, setDateFormat] = useState("PERIOD_END_DT")

  const wtdOverviewQuery = level === 'store' ? useQuery(['queryWtdDeptOverviewByStr', { strNbr, fpToggle }], getData) :
                        level === 'district' ? useQuery(['queryWtdDeptOverviewByDst', { dstNbr, fpToggle }], getData) :
                              null

  const wtdQuery = level === 'store' ? useQuery(['queryWtdSlsByStr', { strNbr, fpToggle }], getData) :
                  level === 'district' ? useQuery(['queryWtdSlsByDst', { dstNbr, fpToggle }], getData) :
                        null

  const dailyQuery = level === 'store' ? useQuery(['queryDailySlsByStr', { strNbr, fpToggle }], getData) :
                    level === 'district' ? useQuery(['queryDailySlsByDst', { dstNbr, fpToggle }], getData) :
                          null

  const slsHistByStrQuery = level === 'store' ? useQuery(['querySlsHistByStr', { strNbr, fpToggle }], getData) :
                          level === 'district' ? useQuery(['querySlsHistByDst', { dstNbr, fpToggle }], getData) :
                                null

  useEffect(() => {
    setPageNum(1)
  }, [slsHistSortColumn, slsHistSortDirection, rowsPerPage, metric, timeframe, fiscalYear])


  if (wtdOverviewQuery.isLoading || wtdQuery.isLoading || dailyQuery.isLoading || slsHistByStrQuery.isLoading) {
    return <Loader active>Loading...</Loader>
  }
  if (!wtdOverviewQuery.data[0] || !wtdQuery.data[0] || !dailyQuery.data[0]) {
    return (<Header textAlign='center'>Invalid Store Number</Header>)
  }

  const wtdData = wtdQuery.data[0]

  let dailyDataSort = dailyQuery.data.sort((left, right) => {
    let result = left[sortColumn] - right[sortColumn]
    if (sortColumn === 'DAY_CODE') {
      result = left.DAY_CODE > right.DAY_CODE ? 1 : -1
    }
    if (sortDirection === 'descending') {
      result *= -1
    }
    return result
  })

  let wtdOverviewDataSort = wtdOverviewQuery.data.sort((left, right) => {
    let result = left[wtdOverviewSortColumn] - right[wtdOverviewSortColumn]
    if (wtdOverviewSortColumn === 'DEPT_NBR') {
      result = left.DEPT_NBR > right.DEPT_NBR ? 1 : -1
    }
    if (wtdOverviewSortDirection === 'descending') {
      result *= -1
    }
    return result
  })

  let slsHistData = slsHistByStrQuery.data
  let fiscalYears = []
  slsHistData.map((row) => {
    return fiscalYears.includes(row['FSCL_YR']) ? fiscalYears : fiscalYears.push(row['FSCL_YR'])
  })
  let fiscalYearsSorted = fiscalYears.reverse()

  let slsHistDataFiltered = slsHistData.filter((row) => {
    return row
      && row['AGG_TYPE'] === timeframe
      && (fiscalYear === 'All' || row['FSCL_YR'].toString() === fiscalYear)
  })

  let slsHistDataSorted = slsHistDataFiltered.sort((left, right) => {
    let result = left[slsHistSortColumn] - right[slsHistSortColumn]
    if (slsHistSortColumn === 'PERIOD_END_DT') {
      result = left.PERIOD_END_DT > right.PERIOD_END_DT ? 1 : -1
    }
    if (slsHistSortColumn === 'PERIOD_DESC') {
      result = left.PERIOD_DESC > right.PERIOD_DESC ? 1 : -1
    }
    if (slsHistSortColumn === 'DAY_DESC') {
      result = left.DAY_DESC > right.DAY_DESC ? 1 : -1
    }
    if (slsHistSortDirection === 'descending') {
      result *= -1
    }
    return result
  })

  let dataPagination = slsHistDataSorted.slice(rowsPerPage * (pageNum - 1), parseInt(Math.min(rowsPerPage * pageNum, slsHistDataSorted.length)))


  //  let today = new Date()
  // let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()

  return (
    <>
      <Menu compact size='small'>
        <Menu.Item
          name='WTD Sales'
          active={wtdOrScorecard === 'WTD Sales'}
          content='WTD Sales'
          onClick={() => {
            setWtdOrScorecard('WTD Sales')
          }}
        />
        <Menu.Item
          name='Sales Scorecard'
          active={wtdOrScorecard === 'Sales Scorecard'}
          content='Sales Scorecard'
          onClick={() => {
            setWtdOrScorecard('Sales Scorecard')
          }}
        />
      </Menu>
      {wtdOrScorecard === 'WTD Sales' && <>
        <Header textAlign='center'>WTD Sales - {level[0].toUpperCase() + level.substring(1)} #{level === "store" ? strNbr : level === "district" ? dstNbr : "n/a"}</Header>
        <Table textAlign='center' celled unstackable size='small' style={{ fontSize: 10 }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>As Of</Table.HeaderCell>
              <Table.HeaderCell>WTD Sales<br />$ (K)</Table.HeaderCell>
              <Table.HeaderCell>$ vPlan<br />WTD (K)</Table.HeaderCell>
              <Table.HeaderCell>% to<br />WTD Plan</Table.HeaderCell>
              <Table.HeaderCell>WTD<br />Comp %</Table.HeaderCell>
              <Table.HeaderCell>WTD Planned<br />Comp %</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>{wtdData['ABBR_DAY_NM']}</Table.Cell>
              <Table.Cell style={{ color: wtdData['WTD_SLS_AMT'] < 0 ? 'red' : 'black' }}>
                {wtdData['WTD_SLS_AMT'] != null ?
                  wtdData['WTD_SLS_AMT'] < 1000 ?
                    wtdData['WTD_SLS_AMT'] < 0 ?
                      wtdData['WTD_SLS_AMT'] <= -1000 ?
                        '($' + (wtdData['WTD_SLS_AMT'] / -1000).toLocaleString(undefined, { maximumFractionDigits: 1 }) + 'K)' :
                        '($' + (-1 * wtdData['WTD_SLS_AMT']).toLocaleString(undefined, { maximumFractionDigits: 0 }) + ')' :
                      '$' + wtdData['WTD_SLS_AMT'].toLocaleString(undefined, { maximumFractionDigits: 0 }) :
                    '$' + (wtdData['WTD_SLS_AMT'] / 1000).toLocaleString(undefined, { maximumFractionDigits: 1 }) + 'K' : '-'}
              </Table.Cell>
              <Table.Cell style={{ color: wtdData['DOL_TO_WTD_PLN'] < 0 ? 'red' : 'black' }}>
                {wtdData['DOL_TO_WTD_PLN'] != null ?
                  wtdData['DOL_TO_WTD_PLN'] < 1000 ?
                    wtdData['DOL_TO_WTD_PLN'] < 0 ?
                      wtdData['DOL_TO_WTD_PLN'] <= -1000 ?
                        '($' + (wtdData['DOL_TO_WTD_PLN'] / -1000).toLocaleString(undefined, { maximumFractionDigits: 1 }) + 'K)' :
                        '($' + (-1 * wtdData['DOL_TO_WTD_PLN']).toLocaleString('en-US', { maximumFractionDigits: 0 }) + ')' :
                      '$' + wtdData['DOL_TO_WTD_PLN'].toLocaleString(undefined, { maximumFractionDigits: 0 }) :
                    '$' + (wtdData['DOL_TO_WTD_PLN'] / 1000).toLocaleString(undefined, { maximumFractionDigits: 1 }) + 'K' :
                  '-'}
              </Table.Cell>
              <Table.Cell style={{ color: wtdData['PCT_TO_WTD_PLN'] < 0 ? 'red' : 'black' }}>
                {wtdData['PCT_TO_WTD_PLN'] != null ?
                  wtdData['PCT_TO_WTD_PLN'] < 0 ?
                    '(' + (-100 * wtdData['PCT_TO_WTD_PLN']).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + '%)' :
                    (100 * wtdData['PCT_TO_WTD_PLN']).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + '%' :
                  '-'}
              </Table.Cell>
              <Table.Cell style={{ color: wtdData['WTD_COMP_PCT'] < 0 ? 'red' : 'black' }}>
                {wtdData['WTD_COMP_PCT'] != null ?
                  wtdData['WTD_COMP_PCT'] < 0 ?
                    '(' + (-100 * wtdData['WTD_COMP_PCT']).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + '%)' :
                    (100 * wtdData['WTD_COMP_PCT']).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + '%' :
                  '-'}
              </Table.Cell>
              <Table.Cell style={{ color: wtdData['WTD_PLANNED_COMP_PCT'] < 0 ? 'red' : 'black' }}>
                {wtdData['WTD_PLANNED_COMP_PCT'] != null ?
                  wtdData['WTD_PLANNED_COMP_PCT'] < 0 ?
                    '(' + (-100 * wtdData['WTD_PLANNED_COMP_PCT']).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + '%)' :
                    (100 * wtdData['WTD_PLANNED_COMP_PCT']).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + '%' :
                  '-'}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        <Header textAlign='center'>Daily Sales View</Header>
        <Table textAlign='center' celled sortable unstackable size='small' style={{ fontSize: 10 }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                sorted={sortColumn === 'DAY_CODE' ? sortDirection : null}
                onClick={() => {
                  if (sortColumn !== 'DAY_CODE') {
                    setSortColumn('DAY_CODE')
                    setSortDirection('ascending')
                  } else {
                    sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                  }
                }}>Day</Table.HeaderCell>
              <Table.HeaderCell>Daily<br />Sales $ (K)</Table.HeaderCell>
              <Table.HeaderCell>$ vPlan (K)</Table.HeaderCell>
              <Table.HeaderCell>% to<br />Daily Plan</Table.HeaderCell>
              <Table.HeaderCell>Comp %</Table.HeaderCell>
              <Table.HeaderCell>Planned<br />Comp %</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {dailyDataSort.map((data) => {
              return (
                <Table.Row>
                  <Table.Cell>
                    {data['ABBR_DAY_NM']}
                  </Table.Cell>
                  <Table.Cell>
                    {data['TY_SLS_AMT'] != null ?
                      data['TY_SLS_AMT'] < 1000 ?
                        data['TY_SLS_AMT'] < 0 ?
                          data['TY_SLS_AMT'] <= -1000 ?
                            '($' + (data['TY_SLS_AMT'] / -1000).toLocaleString(undefined, { maximumFractionDigits: 1 }) + 'K)' :
                            '($' + (-1 * data['TY_SLS_AMT']).toLocaleString(undefined, { maximumFractionDigits: 0 }) + ')' :
                          '$' + data['TY_SLS_AMT'].toLocaleString(undefined, { maximumFractionDigits: 0 }) :
                        '$' + (data['TY_SLS_AMT'] / 1000).toLocaleString(undefined, { maximumFractionDigits: 1 }) + 'K' : '-'}
                  </Table.Cell>
                  <Table.Cell style={{ color: data['DOL_TO_PLN'] < 0 ? 'red' : 'black' }}>
                    {data['DOL_TO_PLN'] != null ?
                      data['DOL_TO_PLN'] < 1000 ?
                        data['DOL_TO_PLN'] < 0 ?
                          data['DOL_TO_PLN'] <= -1000 ?
                            '($' + (data['DOL_TO_PLN'] / -1000).toLocaleString(undefined, { maximumFractionDigits: 1 }) + 'K)' :
                            '($' + (-1 * data['DOL_TO_PLN']).toLocaleString('en-US', { maximumFractionDigits: 0 }) + ')' :
                          '$' + data['DOL_TO_PLN'].toLocaleString(undefined, { maximumFractionDigits: 0 }) :
                        '$' + (data['DOL_TO_PLN'] / 1000).toLocaleString(undefined, { maximumFractionDigits: 1 }) + 'K' :
                      '-'}
                  </Table.Cell>
                  <Table.Cell style={{ color: data['PCT_TO_PLN'] < 0 ? 'red' : 'black' }}>
                    {data['PCT_TO_PLN'] != null ?
                      data['PCT_TO_PLN'] < 0 ?
                        '(' + (-100 * data['PCT_TO_PLN']).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + '%)' :
                        (100 * data['PCT_TO_PLN']).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + '%' :
                      '-'}
                  </Table.Cell>
                  <Table.Cell style={{ color: data['COMP_PCT'] < 0 ? 'red' : 'black' }}>
                    {data['COMP_PCT'] != null ?
                      data['COMP_PCT'] >= 0 ?
                        (100 * data['COMP_PCT']).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + '%' :
                        '(' + (-100 * data['COMP_PCT']).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + '%)' :
                      '-'}
                  </Table.Cell>
                  <Table.Cell style={{ color: data['PLANNED_COMP_PCT'] < 0 ? 'red' : 'black' }}>
                    {data['PLANNED_COMP_PCT'] != null ?
                      data['PLANNED_COMP_PCT'] >= 0 ?
                        (100 * data['PLANNED_COMP_PCT']).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + '%' :
                        '(' + (-100 * data['PLANNED_COMP_PCT']).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + '%)' :
                      '-'}
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>

        <Header textAlign='center'>WTD Sales By Department</Header>
        <Table textAlign='center' celled sortable unstackable size='small' style={{ fontSize: 10 }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                sorted={wtdOverviewSortColumn === 'DEPT_NBR' ? wtdOverviewSortDirection : null}
                onClick={() => {
                  if (wtdOverviewSortColumn !== 'DEPT_NBR') {
                    setWtdOverviewSortColumn('DEPT_NBR')
                    setWtdOverviewSortDirection('ascending')
                  } else {
                    wtdOverviewSortDirection === 'ascending' ? setWtdOverviewSortDirection('descending') : setWtdOverviewSortDirection('ascending')
                  }
                }}>Dept</Table.HeaderCell>
              <Table.HeaderCell>WTD Sales<br />$ (K)</Table.HeaderCell>
              <Table.HeaderCell>$ vPlan<br />WTD (K)</Table.HeaderCell>
              <Table.HeaderCell>% to<br />WTD Plan</Table.HeaderCell>
              <Table.HeaderCell>WTD<br />Comp %</Table.HeaderCell>
              <Table.HeaderCell>WTD Planned<br />Comp %</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {wtdOverviewDataSort.map((data) => {
              return (
                <Table.Row>
                  <Table.Cell>
                    <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/${level}/daily/dept?${level === 'store' ? 'strNbr' : level === 'district' ? 'dstNbr' : 'strNbr'}=${level === 'store' ? strNbr : level === 'district' ? dstNbr : strNbr}&deptNbr=${data['DEPT_NM']}`}>
                      {data['DEPT_NM']}
                    </Link>
                  </Table.Cell>
                  <Table.Cell style={{ color: data['WTD_SLS_AMT'] < 0 ? 'red' : 'black' }}>
                    {data['WTD_SLS_AMT'] != null ?
                      data['WTD_SLS_AMT'] < 1000 ?
                        data['WTD_SLS_AMT'] < 0 ?
                          data['WTD_SLS_AMT'] <= -1000 ?
                            '($' + (data['WTD_SLS_AMT'] / -1000).toLocaleString(undefined, { maximumFractionDigits: 1 }) + 'K)' :
                            '($' + (-1 * data['WTD_SLS_AMT']).toLocaleString(undefined, { maximumFractionDigits: 0 }) + ')' :
                          '$' + data['WTD_SLS_AMT'].toLocaleString(undefined, { maximumFractionDigits: 0 }) :
                        '$' + (data['WTD_SLS_AMT'] / 1000).toLocaleString(undefined, { maximumFractionDigits: 1 }) + 'K' : '-'}
                  </Table.Cell>
                  <Table.Cell style={{ color: data['DOL_TO_WTD_PLN'] < 0 ? 'red' : 'black' }}>
                    {data['DOL_TO_WTD_PLN'] != null ?
                      data['DOL_TO_WTD_PLN'] < 1000 ?
                        data['DOL_TO_WTD_PLN'] < 0 ?
                          data['DOL_TO_WTD_PLN'] <= -1000 ?
                            '($' + (data['DOL_TO_WTD_PLN'] / -1000).toLocaleString(undefined, { maximumFractionDigits: 1 }) + 'K)' :
                            '($' + (-1 * data['DOL_TO_WTD_PLN']).toLocaleString('en-US', { maximumFractionDigits: 0 }) + ')' :
                          '$' + data['DOL_TO_WTD_PLN'].toLocaleString(undefined, { maximumFractionDigits: 0 }) :
                        '$' + (data['DOL_TO_WTD_PLN'] / 1000).toLocaleString(undefined, { maximumFractionDigits: 1 }) + 'K' :
                      '-'}
                  </Table.Cell>
                  <Table.Cell style={{ color: data['PCT_TO_WTD_PLN'] < 0 ? 'red' : 'black' }}>
                    {data['PCT_TO_WTD_PLN'] != null ?
                      data['PCT_TO_WTD_PLN'] < 0 ?
                        '(' + (-100 * data['PCT_TO_WTD_PLN']).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + '%)' :
                        (100 * data['PCT_TO_WTD_PLN']).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + '%' :
                      '-'}
                  </Table.Cell>
                  <Table.Cell style={{ color: data['WTD_COMP_PCT'] < 0 ? 'red' : 'black' }}>
                    {data['WTD_COMP_PCT'] != null ?
                      data['WTD_COMP_PCT'] < 0 ?
                        '(' + (-100 * data['WTD_COMP_PCT']).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + '%)' :
                        (100 * data['WTD_COMP_PCT']).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + '%' :
                      '-'}
                  </Table.Cell>
                  <Table.Cell style={{ color: data['WTD_PLANNED_COMP_PCT'] < 0 ? 'red' : 'black' }}>
                    {data['WTD_PLANNED_COMP_PCT'] != null ?
                      data['WTD_PLANNED_COMP_PCT'] < 0 ?
                        '(' + (-100 * data['WTD_PLANNED_COMP_PCT']).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + '%)' :
                        (100 * data['WTD_PLANNED_COMP_PCT']).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + '%' :
                      '-'}
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      </>}
      {wtdOrScorecard === 'Sales Scorecard' && <>
        <Grid>
          <Grid.Column width={8} textAlign='left'>
            <Grid.Row>&nbsp;</Grid.Row>
            Metric:
            <div>
              <select
                name="metric"
                onChange={e => {
                  setMetric(e.target.value)
                }}
                value={metric}
                style={{ padding: 10 }}
              >
                <option value="Sales Plan" selected>Sales Plan</option>
                <option value="Sales Comp">Sales Comp</option>
                <option value="Labor">Labor</option>
                <option value="Markdowns">Markdowns</option>
              </select>
            </div>
            <Grid.Row>&nbsp;</Grid.Row>
            Timeframe:
            <div>
              <select
                name="timeframe"
                onChange={e => {
                  setTimeframe(e.target.value)
                }}
                value={timeframe}
                style={{ padding: 10 }}
              >
                <option value="FISCAL DAY" selected>Day</option>
                <option value="FISCAL WEEK">Week</option>
                <option value="FISCAL MONTH">Month</option>
                <option value="FISCAL QUARTER">Quarter</option>
              </select>
            </div>
            <Grid.Row>&nbsp;</Grid.Row>
          </Grid.Column>
          <Grid.Column width={8} textAlign='right'>
            <Grid.Row>&nbsp;</Grid.Row>
            Fiscal Year:
            <div>
              <select
                name="fiscalYear"
                onChange={e => {
                  setFiscalYear(e.target.value)
                }}
                value={fiscalYear}
                style={{ padding: 10 }}
              >
                <option value="All">All</option>
                {fiscalYearsSorted.map((row) => {
                  return <option value={row}>{row}</option>
                })}
              </select>
            </div>
            <Grid.Row>&nbsp;</Grid.Row>
            Date Format:
            <div>
              <select
                name="dateFormat"
                onChange={e => {
                  setDateFormat(e.target.value)
                }}
                value={dateFormat}
                style={{ padding: 10 }}
              >
                <option selected value="PERIOD_END_DT">Date</option>
                <option value="PERIOD_DESC">Fiscal Period</option>
              </select>
            </div>
            <Grid.Row>&nbsp;</Grid.Row>
          </Grid.Column>
        </Grid>
        <Grid>
        {slsHistDataSorted.length > rowsPerPage && <Grid.Row>
          <Grid.Column width={16} textAlign='center'>
            <Pagination
              size='mini'
              defaultActivePage={1}
              totalPages={Math.floor((slsHistDataSorted.length - 1) / rowsPerPage) + 1}
              activePage={pageNum}
              boundaryRange={0}
              siblingRange={1}
              onPageChange={(e, { activePage }) => {
                setPageNum(activePage)
              }}
            />
          </Grid.Column>
        </Grid.Row>}
        </Grid>
        <Header textAlign='center'>Full Year Scorecard - {level[0].toUpperCase() + level.substring(1)} #{level === "store" ? strNbr : level === "district" ? dstNbr : "n/a"}</Header>
        <Table textAlign='center' unstackable celled sortable size='small'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell 
              sorted={slsHistSortColumn === dateFormat ? slsHistSortDirection : null}
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (slsHistSortColumn !== dateFormat) {
                  setSlsHistSortColumn(dateFormat)
                  setSlsHistSortDirection('ascending')
                } else {
                  slsHistSortDirection === 'ascending' ? setSlsHistSortDirection('descending') : setSlsHistSortDirection('ascending')
                }
              }}
              >Time Period</Table.HeaderCell>
              {timeframe === "FISCAL DAY" && <Table.HeaderCell
                style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                onClick={() => {
                  if (slsHistSortColumn !== dateFormat) {
                    setSlsHistSortColumn(dateFormat)
                    setSlsHistSortDirection('ascending')
                  } else {
                    slsHistSortDirection === 'ascending' ? setSlsHistSortDirection('descending') : setSlsHistSortDirection('ascending')
                  }
                }}
              >Day Name</Table.HeaderCell>}
              {(metric === "Sales Plan" || metric === "Sales Comp") && <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Sales $</Table.HeaderCell>}
              {metric === "Labor" && <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Labor Hours</Table.HeaderCell>}
              {metric === "Markdowns" && <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Mkdn %</Table.HeaderCell>}
              {metric === "Sales Plan" && <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Sales Plan $</Table.HeaderCell>}
              {metric === "Sales Comp" && <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>LY Sales $</Table.HeaderCell>}
              {metric === "Labor" && <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Fcst Hours</Table.HeaderCell>}
              {metric === "Markdowns" && <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Mkdn Plan %</Table.HeaderCell>}
              {metric === "Sales Plan" && <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>vP %</Table.HeaderCell>}
              {metric === "Markdowns" && <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>vP bps</Table.HeaderCell>}
              {metric === "Sales Comp" && <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>vLY %</Table.HeaderCell>}
              {metric === "Labor" && <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>vF %</Table.HeaderCell>}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {dataPagination.map((data) => {
              return (
                <Table.Row>
                  <Table.Cell>{data[dateFormat]}</Table.Cell>
                  {timeframe === 'FISCAL DAY' && <Table.Cell>{data['DAY_DESC']}</Table.Cell>}
                  {(metric === "Sales Plan" || metric === "Sales Comp") && <Table.Cell>{getDollarString(data['TY_SLS_AMT'])}</Table.Cell>}
                  {metric === "Labor" && <Table.Cell>{data['ACT_HRS']}</Table.Cell>}
                  {metric === "Markdowns" && <Table.Cell>{getPercentString(data['SMKDN_AMT'] / data['TY_SLS_AMT'])}</Table.Cell>}
                  {metric === "Sales Plan" && <Table.Cell>{getDollarString(data['SLS_PLN_AMT'])}</Table.Cell>}
                  {metric === "Sales Comp" && <Table.Cell>{getDollarString(data['LY_SLS_AMT'])}</Table.Cell>}
                  {metric === "Labor" && <Table.Cell>{data['FCST_HRS']}</Table.Cell>}
                  {metric === "Markdowns" && <Table.Cell>{getPercentString(data['SMD_PCT_PLN'])}</Table.Cell>}
                  {metric === "Sales Plan" && <Table.Cell>{data['SLS_PLN_AMT'] === 0 ? '-' : getPercentString(data['TY_SLS_AMT'] / data['SLS_PLN_AMT'] - 1)}</Table.Cell>}
                  {metric === "Sales Comp" && <Table.Cell>{data['LY_SLS_AMT'] === 0 ? '-' : getPercentString(data['TY_SLS_AMT'] / data['LY_SLS_AMT'] - 1)}</Table.Cell>}
                  {metric === "Labor" && <Table.Cell>{data['FCST_HRS'] === 0 ? '-' : getPercentString(data['ACT_HRS'] / data['FCST_HRS'] - 1)}</Table.Cell>}
                  {metric === "Markdowns" && <Table.Cell>{data['SMKDN_AMT'] !== null && data['TY_SLS_AMT'] !== null && data['SMD_PCT_PLN'] !== null ? (10000 * (data['SMKDN_AMT'] / data['TY_SLS_AMT'] - data['SMD_PCT_PLN'])).toLocaleString(undefined, {maximumFractionDigits:0}) + ' bps' : '-'}</Table.Cell>}
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      </>
      }
    </>
  )
})