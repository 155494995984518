import { Grid } from "semantic-ui-react"
import { TimeframeDropdown } from "../Common/TimeframeDropdown"
import { TimeframeDropdownMon } from "../Common/TimeframeDropdownMon"
import PropTypes from 'prop-types';
import { UnitMenu } from "../Common/UnitMenu"

export const TimeframeMenu = ({ timeframe, settimeframe, unit, setUnit, showUnits = false }) => {
    return (
        <Grid>
            <Grid.Column width={8}>
                {showUnits && (
                    <>
                        <Grid.Row>
                            Units:
                        </Grid.Row>
                        <Grid.Row>
                            <UnitMenu unit={unit} setUnit={setUnit} />
                        </Grid.Row>
                    </>
                )}
                <Grid.Row>&nbsp;</Grid.Row>
            </Grid.Column>
            <Grid.Column width={8}>
                Timeframe:
                {new Date().getDay() === 1 ?
                    <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} /> :
                    <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />}
            </Grid.Column>
        </Grid>
    )
}

TimeframeMenu.propTypes = {
    timeframe: PropTypes.string.isRequired,
    settimeframe: PropTypes.func.isRequired,
    showUnits: PropTypes.bool,
    unit: PropTypes.string,
    setUnit: PropTypes.func
}