import React, { useState, useEffect } from 'react'

import { Redirect } from "react-router-dom"


import {
  Header,
  Grid,
  Modal,
  Segment,
  Button
} from 'semantic-ui-react'



// export const Scanner = withRouter(({ fpToggle, location }) => {
export const Scanner = ({ storeNumber, setStoreNumber, storeNumberField }) => {

  const [open, setOpen] = useState(false)
  const [data, setData] = useState('')
  // const search = new URLSearchParams(location.search);
  // const strNbr = search.has('strNbr') ? search.get('strNbr') : 0;
  const [aisle, setAisle] = useState('')
  const [bay, setBay] = useState('')
  const [isBayCode, setIsBayCode] = useState(false)

  useEffect(() => {
    if (window && window.plugin && window.plugin.scannerEvent) {
      console.log("Able to find scannerEvent");

      let scannerEvent = window.plugin.scannerEvent;

      var success = (intent) => {
        console.log('scanner success');

        if (intent == null) {
          console.log('No scan intent');
          return;
        };

        console.log("intent", intent)
        let result = {
          barcode: scannerEvent.getData(intent)
        };
        console.log("data", result)
        if (!result || !result.barcode) {
          return console.error("No info", result, result.barcode);
        }
        let barcode = fnGetBarcode(result);

        setData(barcode)
        setOpen(true)
        let BAYLENGTH = 13;

        if (barcode.length === BAYLENGTH) {
          // let aisleBay = barcode.slice(barcode.length - 6);
          // let aisle = aisleBay.split('-')[0];
          // let bay = aisleBay.split('-')[1];
          // window.location.href = `/store/aisle/bay?strNbr=${strNbr}&aisle=${aisle}&bay=${aisle}-${bay}`
          setAisle(barcode.slice(barcode.length - 6).split('-')[0])
          setBay(barcode.slice(barcode.length - 6).split('-')[1])
          setStoreNumber(storeNumberField.padStart(4, '0'))
          setIsBayCode(true)
        } else {
          setIsBayCode(false)
        }
      }

      var fail = function (intent, error) {
        console.log("scanner failure");

        if (intent == null) {
          console.log('No scan intent');
          return;
        };

        let result = {
          detail: { data: null, error: error }
        };

        console.log("data", result)
        if (!result || !result.barcode) {
          return console.error("No info", result, result.barcode);
        }
        let barcode = fnGetBarcode(result);
        setData(barcode)
        setOpen(true)
      }

      if (scannerEvent) {
        console.log('scannerEvent found');
        scannerEvent.register(success, fail);

        window['scan'] = (scanString) => {
          console.log('window scan', scanString)
          setData(scanString)
          setOpen(true)
        };
      }
    }
  }, [])

  return (
    <>
      {isBayCode ? <Redirect to={`/store/aisle/bay?strNbr=${storeNumber}&aisle=${aisle}&bay=${aisle}-${bay}`} /> :
        <Modal
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
        >
          <Segment color='orange'>
            <Grid>
              <Grid.Column width={16} textAlign='center'>
                <Grid.Row>
                  <Header style={{ color: 'red' }}>Invalid Barcode</Header>
                </Grid.Row>
                <Grid.Row>&nbsp;</Grid.Row>
                <Grid.Row>
                  {data}
                </Grid.Row>
                <Grid.Row>&nbsp;</Grid.Row>
                <Grid.Row>Please scan an aisle-bay barcode.</Grid.Row>
                <Grid.Row>&nbsp;</Grid.Row>
                <Grid.Row>
                  <Button
                    onClick={() => { setOpen(false) }}
                    style={{ color: 'white', backgroundColor: '#EE7125' }}
                    content='OK'
                  />
                </Grid.Row>
                <Grid.Row>&nbsp;</Grid.Row>
              </Grid.Column>
            </Grid>
          </Segment>
        </Modal>
      }
    </>
  )
}

/**
 * Prefix for valid in store sku's
 */


/**
 * Returns the scanned data's barcode value, truncates the first 4 charactes if they match a valid SKU
 * @method      fnGetBarcode    
 * @property    {string}        barcodePrefix     first 4 characters of the scanned value
 * @property    {object}        barcode           scanned value
 * @author      Anthony Oliver & Kalai Mani
 * @example
 * let data1 = {
 *      barcode: '9807111111'
 * }
 * fnGetBarcode(data1); // returns 111111
 * 
 * let data2 = {
 *      barcode: '123456798'
 * }
 * fnGetBarcode(data2); //returns 123456798
 * 
 * 
 *let data3 = {
 *     barcode :'9089000000067-010'
 * 
 *  }
 * fnGetBarcode(data3); //returns 000000067-010
 * 
 * 
 */
const fnGetBarcode = (data) => {
  let SKU_PREFIX = '9807';
  let BAY_PREFIX = '9809';
  let BAY_SIZE = 17;

  if (!data.barcode) {
    return console.error('No barcode value is available.', data);
  }

  let barcode = data.barcode
    , barcodePrefix = barcode.toString().substring(0, 4);

  if (barcodePrefix === SKU_PREFIX) {
    return data.barcode.toString().substring(4);
  } else if (barcodePrefix === BAY_PREFIX && barcode.length === BAY_SIZE) {
    return data.barcode.toString().substring(4);
  }

  return barcode;
}
