import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { UnitMenu } from '../Common/UnitMenu';
import { DataTable } from '../Common/DataTable';
import { getData } from '../../service/DataService';
import { timeframeCode } from '../Common/timeframeCode';
import { Grid, Header, Loader } from 'semantic-ui-react';
import { useQueryParams } from '../../hooks/useQueryParams';
import { TimeframeDropdown } from '../Common/TimeframeDropdown';
import { TimeframeDropdownMon } from '../Common/TimeframeDropdownMon';
import { getPercentString, getThousandDollarString } from '../Common/TableCellUtils';

export const DeptLocDrilldown = ({ timeframe, settimeframe, fpToggle }) => {

  const [unit, setUnit] = useState('NOM');
  const [sortDirection] = useState('ascending');
  const [sortColumn, setSortColumn] = useState('AISLE');
  const { strNbr = 0, deptNbr: deptNbrInt = 0 } = useQueryParams();

  const { data, isError, isLoading } = useQuery(['querySwmLocAisleDept', { strNbr, deptNbrInt, fpToggle }], getData);

  if (isLoading) return <Loader active>Loading...</Loader>;
  if (isError) return <p>Something went wrong fetching data.</p>;

  const aisleDataFilter = data.filter((row) => {
    return (
      row &&
      row['TRNSFM_TYP_SORT_ID'] === timeframeCode[timeframe]
    )
  })

  let aisleDataSort = aisleDataFilter.sort((left, right) => {
    let result = left[sortColumn] - right[sortColumn]
    if (sortColumn === 'AISLE') {
      result = left.AISLE > right.AISLE ? 1 : -1
    }
    if (sortDirection === 'descending') {
      result *= -1
    }
    return result
  })

  const dataObj = {
    headers: [
      { name: 'Aisle ' },
      { name: 'Sales Amt ' },
      { name: unit === 'NOM' ? 'Sales Comp $ ' : 'Sales Comp % ' },
      { name: 'Unit Sales ' },
      { name: unit === 'NOM' ? 'Unit Comp # ' : 'Unit Comp % ' }
    ],
    data: data.map(row => {
      const rowData = [
        {
          stringValue: row['AISLE'],
          link: {
            to: `/store/deptLoc/aisle`,
            params: { strNbr: strNbr, deptNbr: deptNbrInt, aisle: row['AISLE'] }
          }
        },
        {
          orderBy: row['SLS_AMT'],
          stringValue: getThousandDollarString(row['SLS_AMT'], 1),
          customStyle: { color: row['SLS_AMT'] < 0 ? 'red' : 'black' },
        },
        {
          orderBy: unit === 'NOM' ? row['SLS_COMP_NOM'] : row['SLS_COMP_PCT'],
          dollarValue: unit === 'NOM' ? getThousandDollarString(row['SLS_COMP_NOM'], 1) : null,
          numValue: unit === 'PCT' ? getPercentString(row['SLS_COMP_PCT']) : null,
          compareStyle: true,
        },
        {
          orderBy: row['UNT_SLS'],
          numValue: row['UNT_SLS'].toLocaleString(undefined, { maximumFractionDigits: 0 }),
          customStyle: { color: row['UNT_SLS'] < 0 ? 'red' : 'black' },
        },
        {
          orderBy: unit === 'NOM' ? row['UNT_COMP_NOM'] : row['UNT_COMP_PCT'],
          numValue: unit === 'NOM' 
            ? row['UNT_COMP_NOM'].toLocaleString(undefined, { maximumFractionDigits: 0 }) 
            : getPercentString(row['UNT_COMP_PCT']),
          compareStyle: true,
        },
      ];
      return rowData;
    }),
    sortable: true,
    pagination: true,
    sortableDefault: 1, // SLS_AMT column index
  };

  return (
    <>
      <Grid>
        <Grid.Column width={8}>
          <Grid.Row><Link style={{ color: '#EE7125' }} to={`/store/overview?strNbr=${strNbr}`} data-test='b2o-link'>Back to Overview</Link></Grid.Row>
          <Grid.Row>&nbsp;</Grid.Row>
          <Grid.Row>Units:</Grid.Row>
          <Grid.Row>
            <UnitMenu unit={unit} setUnit={setUnit} />
          </Grid.Row>

        </Grid.Column>
        <Grid.Column width={8}>
          <Grid.Row>&nbsp;</Grid.Row>
          <Grid.Row>&nbsp;</Grid.Row>
          <Grid.Row>Timeframe:</Grid.Row>
          <Grid.Row>
            {new Date().getDay() === 1 ?
              <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} /> :
              <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />}
          </Grid.Row>
          <Grid.Row>&nbsp;</Grid.Row>
        </Grid.Column>
      </Grid>


      <Grid>
        <Grid.Column width={16} textAlign='center' style={{ marginBottom: '30px' }}>
          <Header textAlign='center'>Aisles - {deptNbrInt}-{aisleDataSort[0].DEPT_NM}</Header>
          <DataTable data={dataObj} />
        </Grid.Column>
      </Grid>
    </>
  )
};