import React, { useState, useEffect } from "react";
import { Header, Loader, Grid, Icon, Modal, Segment, Table } from 'semantic-ui-react';
import { SideKickPurgeToggle } from "./SideKickPurgeToggle";
import { useQuery } from 'react-query';
import { withRouter, Link } from 'react-router-dom/cjs/react-router-dom.min';
import { getData } from '../../service/DataService';
import { getPercentString } from "../Common/TableCellUtils";
import { TimeframeDropdownLimited } from '../Common/TimeframeDropdownLimited'
import { TimeframeDropdownMonLimited } from '../Common/TimeframeDropdownMonLimited'
export const Goal = 0.98
export const deptNbr = 38
export const deptNm = 'Packout Team Tasks Breakdown'

export const SideKickPurge = withRouter(({ timeframe, settimeframe, level, fpToggle, locNbr, location, ...props}) => {

    const [purgeOrBay, setPurgeOrBay] = useState('PURGE')
    const [purgeOrBayDept, setPurgeOrBayDept] = useState('PURGE')
    const [open, setOpen] = useState(false)
    const [sortColumn, setSortColumn] = useState("DEPT")
    const [sortDirection, setSortDirection] = useState("asecending")
    const [sortColumnPurge, setSortColumnPurge] = useState("DEPT")
    const [sortDirectionPurge, setSortDirectionPurge] = useState("asecending")
    
    useEffect(() => {
        if (timeframe !== 'WTD' && timeframe !== 'LW') {
          settimeframe('WTD')
        }
      }, [timeframe])
      
    let currentDay = new Date().getDay();
    let displayTimeframe = timeframe;

    if (currentDay === 1) { // 1 represents Monday
    if (timeframe === 'WTD') {
        displayTimeframe = 'LW';
    } else if (timeframe === 'LW') {
        displayTimeframe = 'LLW';
    }
    }

    const OSASideKickPurgeQuery = useQuery([`query/queryOSASidekickSkuBay/${level}`, { strNbr:locNbr, fpToggle }], getData)

    const SidekickSkuBayDateDtlQuery = useQuery([`queryOSASidekickSkuBayDateDtlBreakDown`, { strNbr: locNbr, fpToggle }], getData)

    if (OSASideKickPurgeQuery.isLoading || SidekickSkuBayDateDtlQuery.isLoading) {
        return (<Loader active>Loading</Loader>)
    }
  
    if (
    (!OSASideKickPurgeQuery.data || OSASideKickPurgeQuery.data.length === 0) 
    || (!SidekickSkuBayDateDtlQuery.data || SidekickSkuBayDateDtlQuery.data.length === 0) 
    ){
        return (<Header textAlign='center'>Invalid Store Number</Header>)
    }
  
    const OSASideKickPurgeData = OSASideKickPurgeQuery.data.filter(({DEPT}) => DEPT === '38')

    const SidekickSkuBayDateDtlData = SidekickSkuBayDateDtlQuery.data.filter(({DEPT_NBR}) => DEPT_NBR === 38)
    
    let OSASideKickPurgeDataFilterPurge = OSASideKickPurgeData.filter((row) => {
        return row['TASK_TYPE'] === 'PURGE' 
    })

    let OSASideKickPurgeDataFilterBay = OSASideKickPurgeData.filter((row) => {
        return row['TASK_TYPE'] === 'BAY' 
    })

    const weekDetail = SidekickSkuBayDateDtlData
    .filter(({WTD_FLG, LW_FLG}) => timeframe === 'WTD' ?  WTD_FLG === 1 : LW_FLG === 1)
    
    const dailyData = weekDetail
    .reduce((acc, row) => {
      if (row.COMPLETED_DT !== null) {
        const [year, month, day] = row.COMPLETED_DT.split('-').map(Number);
        const date = new Date(year, month - 1, day);
        const dayOfWeek = date.getDay()
        acc[dayOfWeek].dirCom += row.TASK_TYPE === 'PURGE' ? row.COMPLETED_TASK_COUNT : 0
        acc[dayOfWeek].comBay += row.TASK_TYPE === 'BAY' ? row.COMPLETED_TASK_COUNT : 0
      }

      const [year, month, day] = row.CREATED_DT.split('-').map(Number);
      const date = new Date(year, month - 1, day);
      const dayOfWeek = date.getDay()
      acc[dayOfWeek].dirBay += row.TASK_TYPE === 'BAY' ? row.TOTAL_TASK_COUNT : 0
      return acc
    }, [
      {dirCom: 0, dirBay: 0, comBay: 0}, {dirCom: 0, dirBay: 0, comBay: 0}, {dirCom: 0, dirBay: 0, comBay: 0}, {dirCom: 0, dirBay: 0, comBay: 0},
      {dirCom: 0, dirBay: 0, comBay: 0}, {dirCom: 0, dirBay: 0, comBay: 0}, {dirCom: 0, dirBay: 0, comBay: 0}
    ])
    
    const TaskCompPurge = OSASideKickPurgeDataFilterPurge.reduce((acc, curr) => {
        acc[timeframe + '_COMPLETED_TASK_COUNT'] += curr[timeframe + '_COMPLETED_TASK_COUNT']
        acc[timeframe + '_TOTAL_TASK_COUNT'] += curr[timeframe + '_TOTAL_TASK_COUNT']
        acc[timeframe + '_COMPLETED_POINTS'] += curr[timeframe + '_COMPLETED_POINTS']
        acc[timeframe + '_TOTAL_POINTS'] += curr[timeframe + '_TOTAL_POINTS']
        acc.RMNGTASKS = acc[timeframe + '_TOTAL_TASK_COUNT'] - acc[timeframe + '_COMPLETED_TASK_COUNT']
        acc.TASKSCOMPPCT = acc[timeframe + '_COMPLETED_TASK_COUNT'] / acc[timeframe + '_TOTAL_TASK_COUNT']
        acc.EXERT = acc[timeframe + '_COMPLETED_POINTS'] / acc[timeframe + '_TOTAL_POINTS']
        return acc
    }, { [timeframe + '_COMPLETED_TASK_COUNT']:0, [timeframe + '_TOTAL_TASK_COUNT']: 0, [timeframe + '_COMPLETED_POINTS']: 0, [timeframe + '_TOTAL_POINTS']: 0, RMNGTASKS: 0, TASKSCOMPPCT: 0, EXERT: 0 })

    const TaskCompBay = OSASideKickPurgeDataFilterBay.reduce((acc, curr) => {
        acc[timeframe + '_COMPLETED_TASK_COUNT'] += curr[timeframe + '_COMPLETED_TASK_COUNT']
        acc[timeframe + '_TOTAL_TASK_COUNT'] += curr[timeframe + '_TOTAL_TASK_COUNT']
        acc.RMNGTASKS = acc[timeframe + '_TOTAL_TASK_COUNT'] - acc[timeframe + '_COMPLETED_TASK_COUNT']
        acc.TASKSCOMPPCT = acc[timeframe + '_COMPLETED_TASK_COUNT'] / acc[timeframe + '_TOTAL_TASK_COUNT']
        return acc
    }, { [timeframe + '_COMPLETED_TASK_COUNT']:0, [timeframe + '_TOTAL_TASK_COUNT']: 0, RMNGTASKS: 0, TASKSCOMPPCT: 0 })

    const PackDownPurge = OSASideKickPurgeDataFilterPurge.reduce((acc, curr) => {
        let item = acc.find(item => item.DEPT === curr.DEPT);
        if (item) {
            item.STR_NBR = curr.STR_NBR
            item.DEPT = curr.DEPT
            item.DEPT_NM = curr.DEPT_NM
            item[timeframe + '_COMPLETED_TASK_COUNT'] += curr[timeframe + '_COMPLETED_TASK_COUNT']
            item[timeframe + '_TOTAL_TASK_COUNT'] += curr[timeframe + '_TOTAL_TASK_COUNT']
            item.RMNG = acc[timeframe + '_TOTAL_TASK_COUNT'] - acc[timeframe + '_COMPLETED_TASK_COUNT']
        } else {
            acc.push({
                STR_NBR: curr.STR_NBR,
                DEPT: curr.DEPT,
                DEPT_NM: curr.DEPT_NM,
                [timeframe + '_COMPLETED_TASK_COUNT']: curr[timeframe + '_COMPLETED_TASK_COUNT'],
                [timeframe + '_TOTAL_TASK_COUNT']: curr[timeframe + '_TOTAL_TASK_COUNT'],
                RMNG: curr[timeframe + '_TOTAL_TASK_COUNT'] - curr[timeframe + '_COMPLETED_TASK_COUNT']
            })
        }
        return acc
    },[])

    let PackDownPurgeSorted = PackDownPurge.sort((left, right) => {
        let result = left[sortColumnPurge] - right[sortColumnPurge]
        if (sortDirectionPurge === 'descending') {
            result *= -1
        }
        return result
    })

    const PackDownBay = OSASideKickPurgeDataFilterBay.reduce((acc, curr) => {
        let item = acc.find(item => item.DEPT === curr.DEPT);
        if (item) {
            item.STR_NBR = curr.STR_NBR
            item.DEPT = curr.DEPT
            item.DEPT_NM = curr.DEPT_NM
            item[timeframe + '_COMPLETED_TASK_COUNT'] += curr[timeframe + '_COMPLETED_TASK_COUNT']
            item[timeframe + '_TOTAL_TASK_COUNT'] += curr[timeframe + '_TOTAL_TASK_COUNT']
            item.BayTaskComp = acc[timeframe + '_TOTAL_TASK_COUNT'] !== 0 ? acc[timeframe + '_COMPLETED_TASK_COUNT'] / acc[timeframe + '_TOTAL_TASK_COUNT'] : '-'
        } else {
            acc.push({
                STR_NBR: curr.STR_NBR,
                DEPT: curr.DEPT,
                DEPT_NM: curr.DEPT_NM,
                [timeframe + '_COMPLETED_TASK_COUNT']: curr[timeframe + '_COMPLETED_TASK_COUNT'],
                [timeframe + '_TOTAL_TASK_COUNT']: curr[timeframe + '_TOTAL_TASK_COUNT'],
                BayTaskComp: curr[timeframe + '_TOTAL_TASK_COUNT'] !== 0 ? curr[timeframe + '_COMPLETED_TASK_COUNT'] / curr[timeframe + '_TOTAL_TASK_COUNT'] : '-'
            })
        }
        return acc
    },[])

    let PackDownBaySorted = PackDownBay.sort((left, right) => {
        let result = left[sortColumn] - right[sortColumn]
        if (sortColumn === 'BayTaskComp') {
            if (isNaN(left[timeframe + '_COMPLETED_TASK_COUNT'] / left[timeframe + '_TOTAL_TASK_COUNT'])) { return 1; }
            if (isNaN(right[timeframe + '_COMPLETED_TASK_COUNT'] / right[timeframe + '_TOTAL_TASK_COUNT'])) { return -1; }
            result = left[timeframe + '_COMPLETED_TASK_COUNT'] / left[timeframe + '_TOTAL_TASK_COUNT'] > right[timeframe + '_COMPLETED_TASK_COUNT'] / right[timeframe + '_TOTAL_TASK_COUNT'] ? 1 : -1
          }
        if (sortDirection === 'descending') {
            result *= -1
        }
        return result
    })
  
    return (
        <>
        <Segment>
        <Grid>
            <Grid.Column width={8}>
                <Grid.Row>
                <Link style={{ color: '#EE7125' }} to={`/${level}/overview?strNbr=${locNbr}`} data-test='b2o-link'>Overview</Link>
                {' / '}
                <Link style={{ color: '#EE7125' }} to={`/osa?strNbr=${locNbr}`} data-test='b2osa-link'>OSA</Link>
                {` / Purge`}
                </Grid.Row>
                <Grid.Row>&nbsp;</Grid.Row>
            </Grid.Column>
            <Grid.Column width={7} />
            <Grid.Column width={7}>
                        Timeframe: 
              {new Date().getDay() === 1 ?
                <TimeframeDropdownMonLimited timeframe={timeframe} settimeframe={settimeframe} /> :
                <TimeframeDropdownLimited timeframe={timeframe} settimeframe={settimeframe} />}
            </Grid.Column>
        </Grid>
        <Header textAlign="center">Sidekick Purge Overview</Header>
        <div style={{textAlign:"center"}} >
        <p style={{ display: 'inline-block'}}>Execution Rate: <span style={{ color: TaskCompPurge.EXERT > Goal ? 'green' : 'red'}}>{getPercentString(TaskCompPurge.EXERT)}</span></p>
        <p style={{ display: 'inline-block' }}>/ Goal {getPercentString(Goal)} {<Icon name='info circle' color='blue' onClick={()=> { setOpen(true)}} />}</p>   
        </div>     
        <p style={{ textAlign:"center" }}>{deptNbr}-{deptNm}</p>

        <Modal closeIcon open={open} onClose={() => { setOpen(false) }}>
            <Segment>
                <Header textAlign='center'>What does it mean?</Header>
                <p>Execution Rate:</p>
                <p>Points earned from Directed tasks, such as Purge and Bay Packdown, contribute to the Execution Rate.</p>
                &nbsp;
                <p>Directed Tasks</p>
                <ul>
                    <li>Purge packdown tasks are valued at 50 points each.</li>
                    <li>Bay packdown tasks accrue points from 7 to 20.</li>
                </ul>
                <p style={{ color: '#3366CC', textDecoration: 'underline', textAlign: 'right' }} onClick={() => { setOpen(false) }}>OK</p>
                &nbsp;
            </Segment>
        </Modal>

        <Header textAlign="center">Directed Tasks - Purge</Header>
        <Table textAlign='center' unstackable celled sortable size='small'>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Tasks Comp #</Table.HeaderCell>
                    <Table.HeaderCell>Remaining Tasks #</Table.HeaderCell>
                    <Table.HeaderCell>Tasks Comp %</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <Table.Row>
                    <Table.Cell>{TaskCompPurge[timeframe + '_COMPLETED_TASK_COUNT']}</Table.Cell>
                    <Table.Cell>{TaskCompPurge.RMNGTASKS}</Table.Cell>
                    <Table.Cell>{getPercentString(TaskCompPurge.TASKSCOMPPCT)}</Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>

        <Header textAlign="center">Directed Tasks - Bay</Header>
        <Table textAlign='center' unstackable celled sortable size='small'>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Tasks Comp #</Table.HeaderCell>
                    <Table.HeaderCell>Tasks Sent #</Table.HeaderCell>
                    <Table.HeaderCell>Tasks Comp %</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <Table.Row>
                    <Table.Cell>{TaskCompBay[timeframe + '_COMPLETED_TASK_COUNT']}</Table.Cell>
                    <Table.Cell>{TaskCompBay[timeframe + '_TOTAL_TASK_COUNT']}</Table.Cell>
                    <Table.Cell>{getPercentString(TaskCompBay.TASKSCOMPPCT)}</Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>

        <Header textAlign='center'>Tasks breakdown by {displayTimeframe}</Header>
        <p style={{ textAlign:"center" }}>Tasks</p>
        <SideKickPurgeToggle purgeOrBay={purgeOrBay} setPurgeOrBay={setPurgeOrBay}/>
        <Table textAlign='center' unstackable celled sortable size='small'>
            <Table.Header>
                <Table.Row>
                    {purgeOrBay==="BAY" && <Table.HeaderCell></Table.HeaderCell>}
                    <Table.HeaderCell>M</Table.HeaderCell>
                    <Table.HeaderCell>T</Table.HeaderCell>
                    <Table.HeaderCell>W</Table.HeaderCell>
                    <Table.HeaderCell>T</Table.HeaderCell>
                    <Table.HeaderCell>F</Table.HeaderCell>
                    <Table.HeaderCell>S</Table.HeaderCell>
                    <Table.HeaderCell>S</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {purgeOrBay==="PURGE" && (
                <Table.Row>
                    <Table.Cell>{dailyData[1].dirCom ? dailyData[1].dirCom : '-'}</Table.Cell>
                    <Table.Cell>{dailyData[2].dirCom ? dailyData[2].dirCom : '-'}</Table.Cell>
                    <Table.Cell>{dailyData[3].dirCom ? dailyData[3].dirCom : '-'}</Table.Cell>
                    <Table.Cell>{dailyData[4].dirCom ? dailyData[4].dirCom : '-'}</Table.Cell>
                    <Table.Cell>{dailyData[5].dirCom ? dailyData[5].dirCom : '-'}</Table.Cell>
                    <Table.Cell>{dailyData[6].dirCom ? dailyData[6].dirCom : '-'}</Table.Cell>
                    <Table.Cell>{dailyData[0].dirCom ? dailyData[0].dirCom : '-'}</Table.Cell>
                </Table.Row>)}

                {purgeOrBay==="BAY" && <Table.Row>
                    <Table.Cell>DIR</Table.Cell>
                    <Table.Cell>{dailyData[1].dirBay ? dailyData[1].dirBay : '-'}</Table.Cell>
                    <Table.Cell>{dailyData[2].dirBay ? dailyData[2].dirBay : '-'}</Table.Cell>
                    <Table.Cell>{dailyData[3].dirBay ? dailyData[3].dirBay : '-'}</Table.Cell>
                    <Table.Cell>{dailyData[4].dirBay ? dailyData[4].dirBay : '-'}</Table.Cell>
                    <Table.Cell>{dailyData[5].dirBay ? dailyData[5].dirBay : '-'}</Table.Cell>
                    <Table.Cell>{dailyData[6].dirBay ? dailyData[6].dirBay : '-'}</Table.Cell>
                    <Table.Cell>{dailyData[0].dirBay ? dailyData[0].dirBay : '-'}</Table.Cell>
                </Table.Row>}

                {purgeOrBay==="BAY" && <Table.Row>
                    <Table.Cell>COM</Table.Cell>
                    <Table.Cell>{dailyData[1].comBay ? dailyData[1].comBay : '-'}</Table.Cell>
                    <Table.Cell>{dailyData[2].comBay ? dailyData[2].comBay : '-'}</Table.Cell>
                    <Table.Cell>{dailyData[3].comBay ? dailyData[3].comBay : '-'}</Table.Cell>
                    <Table.Cell>{dailyData[4].comBay ? dailyData[4].comBay : '-'}</Table.Cell>
                    <Table.Cell>{dailyData[4].comBay ? dailyData[5].comBay : '-'}</Table.Cell>
                    <Table.Cell>{dailyData[5].comBay ? dailyData[6].comBay : '-'}</Table.Cell>
                    <Table.Cell>{dailyData[0].comBay ? dailyData[0].comBay : '-'}</Table.Cell>
                </Table.Row>}
            </Table.Body>
        </Table>
        {purgeOrBay==="PURGE" &&<p style={{ textAlign:"center" }}>Directed tasks completed by day</p>}

        {purgeOrBay==="BAY" &&<p style={{ textAlign:"center" }}>DIR: Directed tasks sent by Sidekick by day</p>}
        {purgeOrBay==="BAY" &&<p style={{ textAlign:"center" }}>COM: Completed tasks by day</p>}

        <Header textAlign='center'>Purge Packdown by Departments</Header>
        <SideKickPurgeToggle  purgeOrBay={purgeOrBayDept} setPurgeOrBay={setPurgeOrBayDept}/>
        <Table textAlign='center' unstackable celled sortable size='small'>
            <Table.Header>
                <Table.Row>
                {purgeOrBayDept === "PURGE" &&<Table.HeaderCell
                    style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                    sorted={sortColumnPurge === 'DEPT' ? sortDirectionPurge : null}
                    onClick={() => {
                        if (sortColumnPurge !== 'DEPT') {
                            setSortColumnPurge('DEPT')
                            setSortDirectionPurge('ascending')
                        } else {
                            sortDirectionPurge === 'ascending' ? setSortDirectionPurge('descending') : setSortDirectionPurge('ascending')
                        }}}>Dept {sortColumnPurge !== 'DEPT' && <Icon fitted name='sort' />}</Table.HeaderCell>}
                    {purgeOrBayDept === "PURGE" &&<Table.HeaderCell
                        style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                        sorted={sortColumnPurge === timeframe + '_COMPLETED_TASK_COUNT' ? sortDirectionPurge : null}
                        onClick={() => {
                            if (sortColumnPurge !== timeframe + '_COMPLETED_TASK_COUNT') {
                                setSortColumnPurge(timeframe + '_COMPLETED_TASK_COUNT')
                                setSortDirectionPurge('ascending')
                            } else {
                                sortDirectionPurge === 'ascending' ? setSortDirectionPurge('descending') : setSortDirectionPurge('ascending')
                            }}}>Comp Tasks {sortColumnPurge !== timeframe + '_COMPLETED_TASK_COUNT' && <Icon fitted name='sort' />}</Table.HeaderCell>}
                    {purgeOrBayDept === "PURGE" &&<Table.HeaderCell
                        style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                        sorted={sortColumnPurge === 'RMNG' ? sortDirectionPurge : null}
                        onClick={() => {
                            if (sortColumnPurge !== 'RMNG') {
                                setSortColumnPurge('RMNG')
                                setSortDirectionPurge('ascending')
                            } else {
                                sortDirectionPurge === 'ascending' ? setSortDirectionPurge('descending') : setSortDirectionPurge('ascending')
                            }}}>Remaining Tasks {sortColumn !== 'RMNG' && <Icon fitted name='sort' />}</Table.HeaderCell>}

                    {purgeOrBayDept === "BAY" &&<Table.HeaderCell
                        style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                        sorted={sortColumn === 'DEPT' ? sortDirection : null}
                        onClick={() => {
                            if (sortColumn !== 'DEPT') {
                                setSortColumn('DEPT')
                                setSortDirection('ascending')
                            } else {
                                sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                            }}}>Dept {sortColumn !== 'DEPT' && <Icon fitted name='sort' />}</Table.HeaderCell>}
                    {purgeOrBayDept === "BAY" &&<Table.HeaderCell
                        style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                        sorted={sortColumn === timeframe + '_COMPLETED_TASK_COUNT' ? sortDirection : null}
                        onClick={() => {
                            if (sortColumn !== timeframe + '_COMPLETED_TASK_COUNT') {
                                setSortColumn(timeframe + '_COMPLETED_TASK_COUNT')
                                setSortDirection('ascending')
                            } else {
                                sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                            }}}>Bay Tasks Comp # {sortColumn !== timeframe + '_COMPLETED_TASK_COUNT' && <Icon fitted name='sort' />}</Table.HeaderCell>}
                    {purgeOrBayDept === "BAY" &&<Table.HeaderCell
                        style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                        sorted={sortColumn === timeframe + '_TOTAL_TASK_COUNT' ? sortDirection : null}
                        onClick={() => {
                            if (sortColumn !== timeframe + '_TOTAL_TASK_COUNT') {
                                setSortColumn(timeframe + '_TOTAL_TASK_COUNT')
                                setSortDirection('ascending')
                            } else {
                                sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                            }}}>Bay Tasks Sent # {sortColumn !== timeframe + '_TOTAL_TASK_COUNT' && <Icon fitted name='sort' />}</Table.HeaderCell>}
                    {purgeOrBayDept === "BAY" &&<Table.HeaderCell
                        style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                        sorted={sortColumn === 'BayTaskComp' ? sortDirection : null}
                        onClick={() => {
                            if (sortColumn !== 'BayTaskComp') {
                                setSortColumn('BayTaskComp')
                                setSortDirection('ascending')
                            } else {
                                sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                            }}}>Bay Tasks Comp % {sortColumn !== 'BayTaskComp' && <Icon fitted name='sort' />}</Table.HeaderCell>}
                </Table.Row>
            </Table.Header>
            <Table.Body>
                    {purgeOrBayDept === "PURGE" && PackDownPurgeSorted.map((data) => {
                        return (
                        <Table.Row>
                        {/* TODO - replace when SIDEKICK_SKU_BAY_DATE_DTL is fixed */}{/* <Table.Cell><Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/osa/Purge/deptPurge?strNbr=${locNbr}&deptNbr=${data.DEPT}`}>{data.DEPT} - {data.DEPT_NM}</Link></Table.Cell> */}
                        {/* TODO - replace when SIDEKICK_SKU_BAY_DATE_DTL is fixed */}<Table.Cell>{data.DEPT} - {data.DEPT_NM}</Table.Cell>
                        <Table.Cell>{data[timeframe + '_COMPLETED_TASK_COUNT']}</Table.Cell>
                        <Table.Cell>{data.RMNG}</Table.Cell>
                        </Table.Row>
                    )})}

                    {purgeOrBayDept === "BAY" && PackDownBaySorted.map((data) => {
                        return (
                        <Table.Row>  
                        {/* TODO - replace when SIDEKICK_SKU_BAY_DATE_DTL is fixed */}{/* <Table.Cell><Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/osa/Purge/deptBay?strNbr=${locNbr}&deptNbr=${data.DEPT}`}>{data.DEPT} - {data.DEPT_NM}</Link></Table.Cell> */}
                        {/* TODO - replace when SIDEKICK_SKU_BAY_DATE_DTL is fixed */}<Table.Cell>{data.DEPT} - {data.DEPT_NM}</Table.Cell>
                        <Table.Cell>{data[timeframe + '_COMPLETED_TASK_COUNT'] ? data[timeframe + '_COMPLETED_TASK_COUNT'] : '-'}</Table.Cell>
                        <Table.Cell>{data[timeframe + '_TOTAL_TASK_COUNT'] ? data[timeframe + '_TOTAL_TASK_COUNT'] : '-'}</Table.Cell>
                        <Table.Cell>{getPercentString(data.BayTaskComp)}</Table.Cell>
                        </Table.Row>
                    )})}
            </Table.Body>
        </Table>
        &nbsp;
        <a href={`https://myview-cold.apps.homedepot.com/reports/osa?storeNum="${locNbr}"`}>
        <Icon className="external alternate icon" />VIEW IN MY VIEW
        </a>
        </Segment>
        </>
    )
})
