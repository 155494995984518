import { Header, Loader, Tab } from 'semantic-ui-react';
import useTabsMenu from '../../../hooks/useTabsMenu';
import { useRegionOverviewContext } from '../../../hooks/useRegionOverviewContext';
import { RegionOverviewTabs } from './RegionOverviewTabs';
import { withOverviewHeader } from '../../HOC/withHeader';

const RegionBody = withOverviewHeader(RegionOverviewTabs, 'Region');
const DistrictBody = withOverviewHeader(RegionOverviewTabs, 'District');
const StoresBody = withOverviewHeader(RegionOverviewTabs, 'Stores');

// TODO: Replace component with your needed imported componen e.g. <MyComponent />;
const tabsMenu = [
  {
    active: true,
    menuItem: 'Region',
    component: <RegionBody />
  },
  {
    menuItem: 'District',
    component: <DistrictBody />
  },
  {
    menuItem: 'Stores',
    component: <StoresBody />
  }
];

export const RegionOverviewMenu = () => {
  const { activeTab, handleTabChange, panes, tabMenuProps } = useTabsMenu(tabsMenu);

  const { isError, isRegionOverviewTabsLoading } = useRegionOverviewContext();
  if (isError) return <h1>Something went wrong</h1>;
  if (isRegionOverviewTabsLoading) return <Loader active>Loading</Loader>;
  if (false) {
    //TODO: replace value with correct validation field
    return <Header textAlign='center'>Invalid or Unauthorized District</Header>;
  }

  return (
    <Tab
      panes={panes}
      menu={tabMenuProps}
      activeIndex={activeTab}
      onTabChange={handleTabChange}
      style={{ marginBottom: '1em' }}
    />
  );
};
