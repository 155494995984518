import React, { useState, useEffect } from 'react'
import { Loader, Header, Segment, Grid, Dropdown, Icon, Modal, Button } from 'semantic-ui-react'
import { withRouter, Link } from 'react-router-dom'
import { TimeframeDropdown } from '../Common/TimeframeDropdown'
import { TimeframeDropdownMon } from '../Common/TimeframeDropdownMon'
import { DataTable } from '../Common/DataTable'

import { useQuery } from 'react-query'
import {
  getData
} from '../../service/DataService'

export const LTSA_SHELF_AVAILABILITY_GOAL = 0.78

export const LtsaDetail = withRouter(({ timeframe, settimeframe, fpToggle, location }) => {

  const search = new URLSearchParams(location.search)
  const strNbr = search.has('strNbr') ? search.get('strNbr') : 0
  
  const [metric, setMetric] = useState('LTSA Score')

  const [commentsOpen, setCommentsOpen] = useState(false)
  const [comments, setComments] = useState('')

  const ltsaDetailQuery = useQuery(['querySwmLtsaDtl', {strNbr, fpToggle}], getData)

  if(ltsaDetailQuery.isLoading) {
    return <Loader active>Loading...</Loader>
  }

  let dataFilter = ltsaDetailQuery.data.filter((row) => {
    return row && row[timeframe + '_FLG']
  })

  const bold = { fontWeight: 'bold' }

  const sm = dataFilter.reduce((sum, obj) => {
    sum.ltsa[obj['CUSTOMER_TYPE']] += obj['LTSA_SCR_CNT']
    sum.ltsa[obj['CUSTOMER_TYPE'] + '_TOTAL'] += obj['LTSA_SVY_CNT']
    sum.ltsa['total'] += obj['LTSA_SVY_CNT']

    sum.shelf[obj['CUSTOMER_TYPE']] += obj['SHLF_AVL_CNT']
    sum.shelf[obj['CUSTOMER_TYPE'] + '_TOTAL'] += obj['SHLF_SVY_CNT']
    sum.shelf['total'] += obj['SHLF_SVY_CNT']

    sum.cashier[obj['CUSTOMER_TYPE']] += obj['CSHR_FRND_CNT']
    sum.cashier[obj['CUSTOMER_TYPE'] + '_TOTAL'] += obj['CSHR_SVY_CNT']
    sum.cashier['total'] += obj['CSHR_SVY_CNT']

    sum.checkout[obj['CUSTOMER_TYPE']] += obj['CHCK_OUT_CNT']
    sum.checkout[obj['CUSTOMER_TYPE'] + '_TOTAL'] += obj['CHCK_SVY_CNT']
    sum.checkout['total'] += obj['CHCK_SVY_CNT']

    sum.get[obj['CUSTOMER_TYPE']] += obj['GET_SCR_CNT']
    sum.get[obj['CUSTOMER_TYPE'] + '_TOTAL'] += obj['GET_SVY_CNT']
    sum.get['total'] += obj['GET_SVY_CNT']

    sum.greet[obj['CUSTOMER_TYPE']] += obj['GRT_SCR_CNT']
    sum.greet[obj['CUSTOMER_TYPE'] + '_TOTAL'] += obj['GRT_SVY_CNT']
    sum.greet['total'] += obj['GRT_SVY_CNT']

    sum.engage[obj['CUSTOMER_TYPE']] += obj['ENG_SCR_CNT']
    sum.engage[obj['CUSTOMER_TYPE'] + '_TOTAL'] += obj['ENG_SVY_CNT']
    sum.engage['total'] += obj['ENG_SVY_CNT']

    sum.thank[obj['CUSTOMER_TYPE']] += obj['THK_SCR_CNT']
    sum.thank[obj['CUSTOMER_TYPE'] + '_TOTAL'] += obj['THK_SVY_CNT']
    sum.thank['total'] += obj['THK_SVY_CNT']

    return sum
  }, {
    ltsa: { CONSUMER: 0, CONSUMER_TOTAL: 0, PRO: 0, PRO_TOTAL: 0, total: 0 },
    shelf: { CONSUMER: 0, CONSUMER_TOTAL: 0, PRO: 0, PRO_TOTAL: 0, total: 0 },
    cashier: { CONSUMER: 0, CONSUMER_TOTAL: 0, PRO: 0, PRO_TOTAL: 0, total: 0 },
    checkout: { CONSUMER: 0, CONSUMER_TOTAL: 0, PRO: 0, PRO_TOTAL: 0, total: 0 },
    get: { CONSUMER: 0, CONSUMER_TOTAL: 0, PRO: 0, PRO_TOTAL: 0, total: 0 },
    greet: { CONSUMER: 0, CONSUMER_TOTAL: 0, PRO: 0, PRO_TOTAL: 0, total: 0 },
    engage: { CONSUMER: 0, CONSUMER_TOTAL: 0, PRO: 0, PRO_TOTAL: 0, total: 0 },
    thank: { CONSUMER: 0, CONSUMER_TOTAL: 0, PRO: 0, PRO_TOTAL: 0, total: 0 }
  })

  const summaryData = {
    headers: [
      {name: ''}, {name: 'Consumer'}, {name: 'Pro'}, {name: 'Total'}
    ],
    data: [
      [ 
        {stringValue: 'LTSA', customStyle: bold}, 
        {pctValue: sm.ltsa.CONSUMER / sm.ltsa.CONSUMER_TOTAL, decimals: 1}, 
        {pctValue: sm.ltsa.PRO / sm.ltsa.PRO_TOTAL, decimals: 1}, 
        {pctValue: (sm.ltsa.CONSUMER + sm.ltsa.PRO) / sm.ltsa.total, decimals: 1}, 
      ],
      [ 
        {stringValue: 'Shelf Availability', customStyle: bold}, 
        {pctValue: sm.shelf.CONSUMER / sm.shelf.CONSUMER_TOTAL, compareValue: LTSA_SHELF_AVAILABILITY_GOAL, decimals: 1}, 
        {pctValue: sm.shelf.PRO / sm.shelf.PRO_TOTAL, compareValue: LTSA_SHELF_AVAILABILITY_GOAL, decimals: 1}, 
        {pctValue: (sm.shelf.CONSUMER + sm.shelf.PRO) / sm.shelf.total, compareValue: LTSA_SHELF_AVAILABILITY_GOAL, decimals: 1}, 
      ],
      [
        {stringValue: 'Cashier Friendliness', customStyle: bold},
        {pctValue: sm.cashier.CONSUMER / sm.cashier.CONSUMER_TOTAL, decimals: 1}, 
        {pctValue: sm.cashier.PRO / sm.cashier.PRO_TOTAL, decimals: 1}, 
        {pctValue: (sm.cashier.CONSUMER + sm.cashier.PRO) / sm.cashier.total, decimals: 1}, 
      ],
      [
        {stringValue: 'Time To Checkout Score', customStyle: bold},
        {pctValue: sm.checkout.CONSUMER / sm.checkout.CONSUMER_TOTAL, decimals: 1}, 
        {pctValue: sm.checkout.PRO / sm.checkout.PRO_TOTAL, decimals: 1}, 
        {pctValue: (sm.checkout.CONSUMER + sm.checkout.PRO) / sm.checkout.total, decimals: 1}, 
      ],
      [
        {stringValue: 'GET', customStyle: bold},
        {pctValue: sm.get.CONSUMER / sm.get.CONSUMER_TOTAL, decimals: 1}, 
        {pctValue: sm.get.PRO / sm.get.PRO_TOTAL, decimals: 1}, 
        {pctValue: (sm.get.CONSUMER + sm.get.PRO) / sm.get.total, decimals: 1}, 
      ],
      [
        {stringValue: 'Greet', customStyle: bold},
        {pctValue: sm.greet.CONSUMER / sm.greet.CONSUMER_TOTAL, decimals: 1}, 
        {pctValue: sm.greet.PRO / sm.greet.PRO_TOTAL, decimals: 1}, 
        {pctValue: (sm.greet.CONSUMER + sm.greet.PRO) / sm.greet.total, decimals: 1}, 
      ],
      [
        {stringValue: 'Engage', customStyle: bold},
        {pctValue: sm.engage.CONSUMER / sm.engage.CONSUMER_TOTAL, decimals: 1}, 
        {pctValue: sm.engage.PRO / sm.engage.PRO_TOTAL, decimals: 1}, 
        {pctValue: (sm.engage.CONSUMER + sm.engage.PRO) / sm.engage.total, decimals: 1}, 
      ],
      [
        {stringValue: 'Thank', customStyle: bold},
        {pctValue: sm.thank.CONSUMER / sm.thank.CONSUMER_TOTAL, decimals: 1}, 
        {pctValue: sm.thank.PRO / sm.thank.PRO_TOTAL, decimals: 1}, 
        {pctValue: (sm.thank.CONSUMER + sm.thank.PRO) / sm.thank.total, decimals: 1}, 
      ]
    ]
  }

  let headers = [
    {name: 'Survey Date'},
    {name: 'Register Loc'}, {name: 'Customer Type'}, {name: metric}
  ]
  if (metric === 'LTSA Score') {
    headers.splice(1, 0, {name: 'Comments'})
  }
  const ltsaDtlData = {
    sortable: true,
    sortableDefault: 0,
    pagination: true,
    headers,
    data: dataFilter.map((data) => {
      let value = {stringValue: ''}

      switch (metric) {
        case 'LTSA Score': value = {stringValue: data['LTSA_SCORE_DISPLAY']}; break
        case 'Shelf Availability': value = {stringValue: data['SHLF_AVAL_SCORE_DISPLAY']}; break
        case 'Cashier Friendliness': value = {stringValue: data['CSHR_FRND_SCORE_DISPLAY']}; break
        case 'Time To Checkout': value = {stringValue: data['CHCK_OUT_SCORE_DISPLAY']}; break
        case 'GET': value = {pctValue: data['GET_SCR_CNT'] / data['GET_SVY_CNT']}; break
        case 'Greet': value = {stringValue: data['GRT_SCORE_DISPLAY']}; break
        case 'Engage': value = {stringValue: data['ENG_SCORE_DISPLAY']}; break
        case 'Thank': value = {stringValue: data['THK_SCORE_DISPLAY']}; break
      }
      let d = [
        {stringValue: data['RSPN_DT'].toString().slice(0, 10)},
        {stringValue: data['REGISTER_LOCATION']},
        {stringValue: data['CUSTOMER_TYPE']},
        value
      ]
      if (metric === 'LTSA Score') {
        d.splice(1, 0, {
          stringValue: data['COMMENTS'] && (<>Click to view <Icon fitted style={{ color: 'black', textDecoration: 'none' }} name='comment outline' /></>),
          customStyle: { color: '#EE7125', textDecoration: 'underline', cursor: data['COMMENTS'] && 'pointer'},
          onClick: () => {
            setCommentsOpen(true)
            setComments(data['COMMENTS'])
          }
        })
      }

      return d
    })
  }

  return (
      <Segment fluid>
        <Grid>
          <Grid.Row>
            <Grid.Column width={8} textAlign='center'>
              <Grid.Row>&nbsp;</Grid.Row>
              <Link style={{ color: '#EE7125' }} to={`/store/overview?strNbr=${strNbr}`} data-test='b2o-link'>Back to Overview</Link>
            </Grid.Column>
            <Grid.Column width={8}>
              Timeframe:
            {new Date().getDay() === 1 ?
                <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} /> :
                <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />}
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Header textAlign='center'>VOC Summary - Store #{strNbr}</Header>
        <DataTable data={summaryData} />
        &nbsp;
        <Grid>
          <Grid.Row>
            <Grid.Column width={8}>
              <Grid.Row>&nbsp;</Grid.Row>
              <Grid.Row><Header textAlign='center'>LTSA Details</Header></Grid.Row>
            </Grid.Column>
            <Grid.Column width={8}>
              Select Metric:
              <Dropdown
                fluid
                compact
                selection
                defaultValue='LTSA Score'
                options={[
                  { key: 'LTSA Score', text: 'LTSA Score', value: 'LTSA Score' },
                  { key: 'Shelf Availability', text: 'Shelf Availability', value: 'Shelf Availability' },
                  { key: 'Cashier Friendliness', text: 'Cashier Friendliness', value: 'Cashier Friendliness' },
                  { key: 'Time To Checkout', text: 'Time To Checkout', value: 'Time To Checkout' },
                  { key: 'GET', text: 'GET', value: 'GET' },
                  { key: 'Greet', text: 'Greet', value: 'Greet' },
                  { key: 'Engage', text: 'Engage', value: 'Engage' },
                  { key: 'Thank', text: 'Thank', value: 'Thank' },
                ]}
                onChange={(_, { value }) => { setMetric(value) }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <DataTable data={ltsaDtlData} />
        <Modal
          closeIcon
          open={commentsOpen}
          onClose={() => { setCommentsOpen(false) }}
        >
          <Segment color='orange'>
            <Grid textAlign='center'>
              <Grid.Column >
                <Grid.Row>
                  <Header textAlign='center' style={{ color: 'red' }}>Customer Comments</Header>
                </Grid.Row>
                <Grid.Row><p textAlign='center'>{comments}</p></Grid.Row>
                <Grid.Row>&nbsp;</Grid.Row>
                <Grid.Row>
                  <Button
                    onClick={() => { setCommentsOpen(false) }}
                    style={{ color: 'white', backgroundColor: '#EE7125' }}
                    content='Close'
                  />
                </Grid.Row>
                <Grid.Row>&nbsp;</Grid.Row>
              </Grid.Column>
            </Grid>
          </Segment>
        </Modal>
      </Segment>
  )
})