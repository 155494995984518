import { Menu } from 'semantic-ui-react';

export const MenuItems = ({ activeItem, dataTest, items, setItem }) => {
  return (
    <Menu compact data-test={dataTest}>
      {items.map(item => (
        <Menu.Item active={activeItem === item} content={item} key={item} name={item} onClick={() => setItem(item)} />
      ))}
    </Menu>
  );
};
