import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { DataTable } from '../../Common/DataTable';
import { getData } from '../../../service/DataService';
import { Dropdown, Grid, Header, Loader } from 'semantic-ui-react';
import { getLtsaPropsByMetric, ltsaMenuOptions } from '../../../constants/ltsa';

export const RegionLtsa = ({ fpToggle, level, locNbr, timeframe }) => {
  const [metric, setMetric] = useState('Ltsa');
  const { data = [], isError, isLoading } = useQuery([`query/ltsaSummaryPlusOne/${level}`, { locNbr, fpToggle }], getData);

  const filterByCustomerType = (data, customerType) => {
    return data.filter(item => item && item['CUSTOMER_TYPE'] === customerType);
  };

  const props = useMemo(() => getLtsaPropsByMetric(metric), [metric]);
  const proCustomerData = useMemo(() => filterByCustomerType(data, 'PRO'), [data]);
  const consumerCustomerData = useMemo(() => filterByCustomerType(data, 'CONSUMER'), [data]);

  const combinedCustomerData = useMemo(
    () =>
      consumerCustomerData.map(consumer => {
        const pro = proCustomerData.find(pro => pro['STR_NBR'] === consumer['STR_NBR']);
        return {
          pro: pro || {},
          consumer: consumer,
          DST_NBR: consumer['DST_NBR']
        };
      }),
    [consumerCustomerData, proCustomerData]
  );

  const dataObj = useMemo(
    () => ({
      sortable: true,
      headers: [{ name: 'District #' }, { name: 'Consumer' }, { name: 'Pro' }, { name: 'Total' }],
      data: combinedCustomerData.map(({ DST_NBR, consumer, pro }) => {
        return [
          { stringValue: DST_NBR, link: { to: `/district/ltsa`, params: { dstNbr: DST_NBR } } },
          { pctValue: consumer[`${timeframe}${props[0]}`] / consumer[`${timeframe}${props[1]}`] },
          { pctValue: pro[`${timeframe}${props[0]}`] / pro[`${timeframe}${props[1]}`] },
          {
            pctValue:
              (consumer[`${timeframe}${props[0]}`] + pro[`${timeframe}${props[0]}`]) /
              (consumer[`${timeframe}${props[1]}`] + pro[`${timeframe}${props[1]}`])
          }
        ];
      })
    }),
    [combinedCustomerData, timeframe, props]
  );

  if (isLoading) return <Loader active>Loading...</Loader>;
  if (isError) return <p>Something went wrong.</p>;

  return (
    <>
      <Grid>
        <Grid.Row style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
          <Grid.Column width={8}>
            <Header textAlign='center'>LTSA By Store</Header>
          </Grid.Column>

          <Grid.Column width={8}>
            Select Metric:
            <Dropdown
              fluid
              compact
              selection
              defaultValue='Ltsa'
              options={ltsaMenuOptions}
              onChange={(_, { value }) => setMetric(value)}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <DataTable data={dataObj} />
    </>
  );
};
