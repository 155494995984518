import React from 'react'
// import {
//   Dropdown,
// } from 'semantic-ui-react'

// TODO: Remove this file once we only use TimeframeDropdown.jsx in other components
export const TimeframeDropdownMon = ({ timeframe, settimeframe }) => {

  var mondayTf2Tf = {
    'LW': 'WTD',
    'LLW': 'LW',
    'LM': 'LM',
    'MTD': 'MTD',
    'QTD': 'QTD',
    'HTD': 'HTD',
    'YTD': 'YTD',
    'L12': 'L12'
  }
  
  var tf2MondayTf = {
    'WTD': 'LW',
    'LW': 'LLW',
    'LM': 'LM',
    'MTD': 'MTD',
    'QTD': 'QTD',
    'HTD': 'HTD',
    'YTD': 'YTD',
    'L12': 'L12'
  }

  return (
    // <Dropdown
    //   defaultValue={tf2MondayTf[timeframe]}
    //   fluid
    //   compact
    //   scrolling
    //   selection
    //   options={[{ key: 'LW', text: 'LW', value: 'LW' },
    //   { key: 'LLW', text: 'LLW', value: 'LLW' },
    //   { key: 'LM', text: 'LM', value: 'LM' },
    //   { key: 'MTLW', text: 'MTLW', value: 'MTLW' },
    //   { key: 'QTLW', text: 'QTLW', value: 'QTLW' },
    //   { key: 'HTLW', text: 'HTLW', value: 'HTLW' },
    //   { key: 'YTLW', text: 'YTLW', value: 'YTLW' },
    //   { key: 'L12', text: 'L12', value: 'L12' },
    //   ]}
    //   onChange={(_, { value }) => {
    //     settimeframe(mondayTf2Tf[value])
    //   }
    //   } />

      <div>
      <select
        name="timeframe"
        onChange={e => {
          settimeframe(mondayTf2Tf[e.target.value])
        }}
        value={tf2MondayTf[timeframe]}
        style={{ padding: 10 }}
      >
        <option value="LW" selected>LW</option>
        <option value="LLW">LLW</option>
        <option value="LM">LM</option>
        <option value="MTD">MTD</option>
        <option value="QTD">QTD</option>
        <option value="HTD">HTD</option>
        <option value="YTD">YTD</option>
        <option value="L12">L12</option>
      </select>
    </div>
  )
}