import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import { UnitMenu } from '../Common/UnitMenu';
import { DataTable } from '../Common/DataTable';
import { getData } from '../../service/DataService';
import { timeframeCode } from '../Common/timeframeCode';
import { Header, Loader, Grid } from 'semantic-ui-react';
import { useQueryParams } from '../../hooks/useQueryParams';
import { TimeframeDropdown } from '../Common/TimeframeDropdown';
import { TimeframeDropdownMon } from '../Common/TimeframeDropdownMon';
import { getPercentString, getThousandDollarString } from '../Common/TableCellUtils';

export const BaysByStore = ({ timeframe, settimeframe, fpToggle }) => {

  const { strNbr = 0 } = useQueryParams();
  const [unit, setUnit] = useState('NOM');
  const [sortDirection] = useState("descending");
  const { data, isLoading, isError } = useQuery(['querySwmLocStrBay', { strNbr, fpToggle }], getData);

  if (isLoading) return <Loader active>Loading...</Loader>;
  if (isError) return <p>Something went wrong fetching data.</p>;

  const filterDataByTimeframe = (data, timeframe) => {
    const timeframeId = timeframeCode[timeframe];
    return data.filter(row => row && row['TRNSFM_TYP_SORT_ID'] === timeframeId);
  };

  const bayDataFilter = filterDataByTimeframe(data, timeframe);

  const dataObj = {
    headers: [
      { name: 'Bay ' },
      { name: 'Sales Amt ' },
      { name: unit === 'NOM' ? 'Sales Comp $ ' : 'Sales Comp % ' },
      { name: 'Unit Sales ' },
      { name: unit === 'NOM' ? 'Unit Comp # ' : 'Unit Comp % ' }
    ],
    data: bayDataFilter.map(row => {
      const rowData = [
        {
          stringValue: row['BAY'],
          link: {
            to: `/store/baysbystore/bay`,
            params: { strNbr: strNbr, bay: row['BAY'] }
          }
        },
        {
          orderBy: row['SLS_AMT'],
          stringValue: getThousandDollarString(row['SLS_AMT'], 1),
          customStyle: { color: row['SLS_AMT'] < 0 ? 'red' : 'black' },
        },
        {
          dollarValue: unit === 'NOM' ? getThousandDollarString(row['SLS_COMP_NOM'], 1) : null,
          numValue: unit === 'PCT' ? getPercentString(row['SLS_COMP_PCT']) : null,
          compareStyle: true,
        },
        {
          numValue: row['UNT_SLS'].toLocaleString(undefined, { maximumFractionDigits: 0 }),
          customStyle: { color: row['UNT_SLS'] < 0 ? 'red' : 'black' },
        },
        {
          numValue: unit === 'NOM' 
            ? row['UNT_COMP_NOM'].toLocaleString(undefined, { maximumFractionDigits: 0 }) 
            : getPercentString(row['UNT_COMP_PCT']),
          compareStyle: true,
        },
      ];
      return rowData;
    }),
    sortable: true,
    pagination: true,
    sortableDefault: 1, // SLS_AMT column index
  };  

  return (
    <>
      <Header textAlign='center'>Store {strNbr}</Header>

      <Grid>
        <Grid.Column width={8}>
          <Grid.Row><Link style={{ color: '#EE7125' }} to={`/store/overview?strNbr=${strNbr}`} data-test='b2o-link'>Back to Overview</Link></Grid.Row>
          <Grid.Row>&nbsp;</Grid.Row>
          <Grid.Row>Units:</Grid.Row>
          <Grid.Row>
            <UnitMenu unit={unit} setUnit={setUnit} />
          </Grid.Row>

        </Grid.Column>
        <Grid.Column width={8}>
          <Grid.Row>&nbsp;</Grid.Row>
          <Grid.Row>&nbsp;</Grid.Row>
          <Grid.Row>Timeframe:</Grid.Row>
          <Grid.Row>
            {new Date().getDay() === 1 ?
              <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} /> :
              <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />}
          </Grid.Row>
        </Grid.Column>
      </Grid>

      <Grid>
        <Grid.Row>
          <Grid.Column width={16} textAlign='center'>
            <Header textAlign='center' style={{ marginTop: '20px' }}>{sortDirection === 'descending' ? 'Top' : 'Bottom'} Bays</Header> 
            <DataTable data={dataObj} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  )
};