import React from 'react';
import { useQuery } from 'react-query';
import { DataTable } from '../Common/DataTable';
import { getData } from '../../service/DataService';
import { ACCESS_LEVELS } from '../Common/AccessChecks';
import { Grid, Header, Loader } from 'semantic-ui-react';
import { TimeframeDropdown } from '../Common/TimeframeDropdown';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { TimeframeDropdownMon } from '../Common/TimeframeDropdownMon';
import { getParamByLevel, getLastTimeframe } from '../../utils/timeFrameUtils';

// The goal percentages will always be the same, so we can hardcode them here
export const sidekick_exec_skubay_goal = 0.98;
export const sidekick_exec_purge_goal = 0.98;
export const overhead_imaging_goal = 0.85;
export const ohm_no_loc_tags_goal = 0.03;
export const urgent_task_exec_goal = 1;
export const oh_auto_reject_rate_goal = 0.03;

export const OSALanding = ({
  timeframe,
  settimeframe,
  level,
  fpToggle,
  locNbr,
  userMgtLvlId,
  userIsCorporate,
  ...props
}) => {
  const { data, isError, isLoading } = useQuery([`query/queryOSADriverSummary/${level}`, { locNbr, fpToggle }], getData);

  if (isError) return <p>Something went wrong.</p>;
  if (isLoading) return <Loader active>Loading</Loader>;
  if (!data || data.length === 0) return <Header textAlign='center'>Invalid Store Number</Header>;

  const osaSummaryData = data[0];
  const urlParam = getParamByLevel(level);
  const lastTimeFrame = getLastTimeframe(timeframe);

  // Only Department Supervisors and above are allowed to see the drilldowns, so limit the links to them. And corporate
  const showDrilldown = userMgtLvlId <= ACCESS_LEVELS.DEPT_SUP || userIsCorporate;

  const osaData = {
    headers: [{}, { name: 'Actual' }, { name: 'Goal' }, { name: lastTimeFrame ? `v${lastTimeFrame} bps` : '-' }],
    data: [
      [
        {
          stringValue: 'Sidekick Exec - SKU/BAY',
          link: showDrilldown && {
            to: `/osa/sidekickskubay/${level}/`,
            params: {
              [urlParam]: locNbr
            }
          }
        },
        {
          pctValue:
            osaSummaryData[timeframe + '_AB_POINTS_COMPLETED'] / osaSummaryData[timeframe + '_AB_WEEKLY_POINTS_GOAL'],
          compareValue: sidekick_exec_skubay_goal
        },
        {
          pctValue: sidekick_exec_skubay_goal
        },
        {
          numValue: lastTimeFrame
            ? osaSummaryData[timeframe + '_AB_POINTS_COMPLETED'] - osaSummaryData[lastTimeFrame + '_AB_POINTS_COMPLETED']
            : '-',
          postfix: ' bps',
          compareStyle: true
        }
      ],
      [
        {
          stringValue: 'Sidekick Exec - Purge',
          link: showDrilldown && {
            to: `/osa/Purge/${level}/`,
            params: {
              [urlParam]: locNbr
            }
          }
        },
        {
          pctValue:
            osaSummaryData[timeframe + '_D38_POINTS_COMPLETED'] / osaSummaryData[timeframe + '_D38_WEEKLY_POINTS_GOAL'],
          compareValue: sidekick_exec_purge_goal
        },
        {
          pctValue: sidekick_exec_purge_goal
        },
        {
          numValue: lastTimeFrame
            ? osaSummaryData[timeframe + '_D38_POINTS_COMPLETED'] - osaSummaryData[lastTimeFrame + '_D38_POINTS_COMPLETED']
            : '-',
          postfix: ' bps',
          compareStyle: true
        }
      ],
      [
        {
          stringValue: 'Overhead Imaging %',
          link: showDrilldown && {
            to: `/osa/${level}/imaging/`,
            params: {
              [urlParam]: locNbr
            }
          }
        },
        {
          pctValue: osaSummaryData[timeframe + '_IMAGES_TAKEN'] / osaSummaryData[timeframe + '_IMAGES_EXPECTED'],
          compareValue: overhead_imaging_goal
        },
        {
          pctValue: overhead_imaging_goal
        },
        {
          numValue: lastTimeFrame
            ? osaSummaryData[timeframe + '_IMAGES_TAKEN'] - osaSummaryData[lastTimeFrame + '_IMAGES_TAKEN']
            : '-',
          postfix: ' bps',
          compareStyle: true
        }
      ],
      [
        {
          stringValue: 'OHM No Loc Tags %',
          link: showDrilldown && {
            to: `/osa/ohmnoloc${level === 'store' ? '' : '/' + level}`,
            params: {
              [urlParam]: locNbr
            }
          }
        },
        {
          pctValue: osaSummaryData[timeframe + '_NO_LOC_PALLET_COUNT'] / osaSummaryData[timeframe + '_TOTAL_PALLET_COUNT'],
          compareValue: ohm_no_loc_tags_goal,
          compareStyleInvert: true
        },
        {
          pctValue: ohm_no_loc_tags_goal,
          prefix: '<'
        },
        { stringValue: '-' }
      ],
      [
        { stringValue: 'Urgent Task Exec.' },
        {
          pctValue: osaSummaryData[timeframe + '_COMP_TASK_CNT_24_HR'] / osaSummaryData[timeframe + '_TOTAL_TASK_CNT_24_HR'],
          compareValue: urgent_task_exec_goal
        },
        {
          pctValue: urgent_task_exec_goal
        },
        { stringValue: '-' }
      ],
      [
        { stringValue: 'OH Auto Reject Rate' },
        {
          pctValue: osaSummaryData[timeframe + '_OH_AUTO_RJT_CNT'] / osaSummaryData[timeframe + '_OH_NEED_APPRV_CNT'],
          compareValue: oh_auto_reject_rate_goal,
          compareStyleInvert: true
        },
        {
          pctValue: oh_auto_reject_rate_goal,
          prefix: '<'
        },
        { stringValue: '-' }
      ]
    ]
  };

  return (
    <>
      <Grid>
        <Grid.Column width={8}>
          <Grid.Row>
            <Link style={{ color: '#EE7125' }} to={`/${level}/overview?${urlParam}=${locNbr}`} data-test='b2o-link'>
              Overview
            </Link>
            {' / OSA'}
          </Grid.Row>
          <Grid.Row>&nbsp;</Grid.Row>
        </Grid.Column>

        <Grid.Column width={8}>
          Timeframe:
          {new Date().getDay() === 1 ? (
            <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} />
          ) : (
            <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
          )}
        </Grid.Column>
      </Grid>

      <Header textAlign='center'>On-Shelf Availability Metrics</Header>
      <DataTable data={osaData} />
    </>
  );
};
