import React, { useEffect } from 'react';
import { withRouter, Link } from "react-router-dom";
import { useQuery } from 'react-query';
import { Loader, Header } from 'semantic-ui-react';
import { getData } from '../../service/DataService';
import { DataTable } from '../Common/DataTable';
import { Grid } from 'semantic-ui-react';
import { Table } from 'semantic-ui-react';

export const ManagerAddedBaysDetails = withRouter(({ timeframe, settimeframe, level, fpToggle, location, locNbr, ...props }) => {
  const search = new URLSearchParams(location.search)
  const bay = search.has('bay') ? search.get('bay') : ''
  const managerAddedBaysDetailsQuery = useQuery([`queryOSASidekickSkuBayDateDtlBreakDown`, { strNbr: locNbr, fpToggle }], getData);

  if (managerAddedBaysDetailsQuery.isLoading) {
    return (<Loader active>Loading</Loader>);
  }

  if (!managerAddedBaysDetailsQuery.data || managerAddedBaysDetailsQuery.data.length === 0) {
    return (<Header textAlign='center'>Invalid Store Number</Header>);
  }

  const managerAddedBaysDetailsData = managerAddedBaysDetailsQuery.data[0];
  const completionDate = managerAddedBaysDetailsData?.CREATED_DT;
  const managerAddedBayData = managerAddedBaysDetailsQuery.data;
  const managerDataFilterBay = managerAddedBayData.filter((row) => {
    return row['TASK_ORIGIN'] === 'MANAGER'
    && row['AISLE_BAY'] === bay
    && (
      (row.WTD_FLG === 1 && timeframe === 'WTD')
      || (row.LW_FLG === 1 && timeframe === 'LW')
    )

});
const assoc = managerDataFilterBay.length > 0 ? managerDataFilterBay[0].assoc : 'N/A';

  const bayDetailsData = {
    headers: [{ name: 'SKU' }, { name: 'SKU DESC' }, { name: 'OH Qty' }],
    data: managerDataFilterBay
    .map(sku => [
      { stringValue: sku.SKU_NBR || "-", },
      { stringValue: sku.SKU_DESC || "-", },
      { numValue: sku.OH_QTY || 0,  },
    ]),
  };
  console.log("bay details", bayDetailsData)


  return (
    <>
      <Grid>
        <Grid.Column width={8}>
          <Grid.Row>
            <Link style={{ color: '#EE7125' }} to={`/${level}/overview?strNbr=${locNbr}`} data-test='b2o-link'>Overview</Link>
            {' / '}
            <Link style={{ color: '#EE7125' }} to={`/osa?strNbr=${locNbr}`} data-test='b2osa-link'>OSA</Link>
            {' / '}
            <Link style={{ color: '#EE7125' }} to={`/osa/sidekickskubay/store?strNbr=${locNbr}`} data-test='b2osa-link'>SKU/Bay</Link>
            {' / '}
            <Link style={{ color: '#EE7125' }} to={`/osa/manageraddedbays?strNbr=${locNbr}`} data-test='b2osa-link'>Mgr Added Bays</Link>
            {` / ${bay}`}
          </Grid.Row>
        </Grid.Column>
      </Grid>
      <Header textAlign='center'>Manager Added Bays {bay}</Header>
      <Header as='h4' textAlign='center'>Completed By: {assoc}</Header>
      <Header as='h4' textAlign='center'>Completion Date: {completionDate || '--/--/----'}</Header>
     
 <DataTable data={bayDetailsData}/>
    </>
  );
});
