import { useQuery } from 'react-query';
import { RegionLtsa } from './RegionLtsa';
import { DataTable } from '../../Common/DataTable';
import { getData } from '../../../service/DataService';
import { Loader, Header, Segment, Grid } from 'semantic-ui-react';
import { TimeframeDropdown } from '../../Common/TimeframeDropdown';
import { TimeframeDropdownMon } from '../../Common/TimeframeDropdownMon';

export const RegionVocSummary = ({ timeframe, settimeframe, fpToggle, level, locNbr }) => {
  const { data = [], isError, isLoading } = useQuery([`query/ltsaSummary/${level}`, { locNbr, fpToggle }], getData);

  if (isLoading) {
    return <Loader active>Loading...</Loader>;
  }
  if (isError) {
    return <p>Something went wrong.</p>;
  }

  const consumerSummary = data.filter(({ CUSTOMER_TYPE }) => CUSTOMER_TYPE === 'CONSUMER')[0];
  const proSummary = data.filter(({ CUSTOMER_TYPE }) => CUSTOMER_TYPE === 'PRO')[0];

  const bold = { fontWeight: 'bold' };
  const summaryObj = {
    headers: [{ name: '' }, { name: 'Consumer' }, { name: 'Pro' }, { name: 'Total' }],
    data: [
      [
        { stringValue: 'LTSA', customStyle: bold },
        { pctValue: consumerSummary[`${timeframe}_LTSA_SCR_CNT`] / consumerSummary[`${timeframe}_LTSA_SVY_CNT`] },
        { pctValue: proSummary[`${timeframe}_LTSA_SCR_CNT`] / proSummary[`${timeframe}_LTSA_SVY_CNT`] },
        {
          pctValue:
            (consumerSummary[`${timeframe}_LTSA_SCR_CNT`] + proSummary[`${timeframe}_LTSA_SCR_CNT`]) /
            (consumerSummary[`${timeframe}_LTSA_SVY_CNT`] + proSummary[`${timeframe}_LTSA_SVY_CNT`])
        }
      ],
      [
        { stringValue: 'Shelf Availability', customStyle: bold },
        { pctValue: consumerSummary[`${timeframe}_SHLF_AVL_CNT`] / consumerSummary[`${timeframe}_SHLF_SVY_CNT`] },
        { pctValue: proSummary[`${timeframe}_SHLF_AVL_CNT`] / proSummary[`${timeframe}_SHLF_SVY_CNT`] },
        {
          pctValue:
            (consumerSummary[`${timeframe}_SHLF_AVL_CNT`] + proSummary[`${timeframe}_SHLF_AVL_CNT`]) /
            (consumerSummary[`${timeframe}_SHLF_SVY_CNT`] + proSummary[`${timeframe}_SHLF_SVY_CNT`])
        }
      ],
      [
        { stringValue: 'Cashier Friendliness', customStyle: bold },
        { pctValue: consumerSummary[`${timeframe}_CSHR_FRND_CNT`] / consumerSummary[`${timeframe}_CSHR_SVY_CNT`] },
        { pctValue: proSummary[`${timeframe}_CSHR_FRND_CNT`] / proSummary[`${timeframe}_CSHR_SVY_CNT`] },
        {
          pctValue:
            (consumerSummary[`${timeframe}_CSHR_FRND_CNT`] + proSummary[`${timeframe}_CSHR_FRND_CNT`]) /
            (consumerSummary[`${timeframe}_CSHR_SVY_CNT`] + proSummary[`${timeframe}_CSHR_SVY_CNT`])
        }
      ],
      [
        { stringValue: 'Time To Checkout Score', customStyle: bold },
        { pctValue: consumerSummary[`${timeframe}_CHCK_OUT_CNT`] / consumerSummary[`${timeframe}_CHCK_SVY_CNT`] },
        { pctValue: proSummary[`${timeframe}_CHCK_OUT_CNT`] / proSummary[`${timeframe}_CHCK_SVY_CNT`] },
        {
          pctValue:
            (consumerSummary[`${timeframe}_CHCK_OUT_CNT`] + proSummary[`${timeframe}_CHCK_OUT_CNT`]) /
            (consumerSummary[`${timeframe}_CHCK_SVY_CNT`] + proSummary[`${timeframe}_CHCK_SVY_CNT`])
        }
      ],
      [
        { stringValue: 'GET', customStyle: bold },
        { pctValue: consumerSummary[`${timeframe}_GET_SCR_CNT`] / consumerSummary[`${timeframe}_GET_SVY_CNT`] },
        { pctValue: proSummary[`${timeframe}_GET_SCR_CNT`] / proSummary[`${timeframe}_GET_SVY_CNT`] },
        {
          pctValue:
            (consumerSummary[`${timeframe}_GET_SCR_CNT`] + proSummary[`${timeframe}_GET_SCR_CNT`]) /
            (consumerSummary[`${timeframe}_GET_SVY_CNT`] + proSummary[`${timeframe}_GET_SVY_CNT`])
        }
      ],
      [
        { stringValue: 'Greet', customStyle: bold },
        { pctValue: consumerSummary[`${timeframe}_GRT_SCR_CNT`] / consumerSummary[`${timeframe}_ENG_SVY_CNT`] },
        { pctValue: proSummary[`${timeframe}_GRT_SCR_CNT`] / proSummary[`${timeframe}_ENG_SVY_CNT`] },
        {
          pctValue:
            (consumerSummary[`${timeframe}_GRT_SCR_CNT`] + proSummary[`${timeframe}_GRT_SCR_CNT`]) /
            (consumerSummary[`${timeframe}_ENG_SVY_CNT`] + proSummary[`${timeframe}_ENG_SVY_CNT`])
        }
      ],
      [
        { stringValue: 'Engage', customStyle: bold },
        { pctValue: consumerSummary[`${timeframe}_ENG_SCR_CNT`] / consumerSummary[`${timeframe}_ENG_SVY_CNT`] },
        { pctValue: proSummary[`${timeframe}_ENG_SCR_CNT`] / proSummary[`${timeframe}_ENG_SVY_CNT`] },
        {
          pctValue:
            (consumerSummary[`${timeframe}_ENG_SCR_CNT`] + proSummary[`${timeframe}_ENG_SCR_CNT`]) /
            (consumerSummary[`${timeframe}_ENG_SVY_CNT`] + proSummary[`${timeframe}_ENG_SVY_CNT`])
        }
      ],
      [
        { stringValue: 'Thank', customStyle: bold },
        { pctValue: consumerSummary[`${timeframe}_THK_SCR_CNT`] / consumerSummary[`${timeframe}_THK_SVY_CNT`] },
        { pctValue: proSummary[`${timeframe}_THK_SCR_CNT`] / proSummary[`${timeframe}_THK_SVY_CNT`] },
        {
          pctValue:
            (consumerSummary[`${timeframe}_THK_SCR_CNT`] + proSummary[`${timeframe}_THK_SCR_CNT`]) /
            (consumerSummary[`${timeframe}_THK_SVY_CNT`] + proSummary[`${timeframe}_THK_SVY_CNT`])
        }
      ]
    ]
  };

  return (
    <Segment fluid>
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            Timeframe:
            {new Date().getDay() === 1 ? (
              <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} />
            ) : (
              <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Header textAlign='center'>VOC Summary - Region #{locNbr}</Header>
      <DataTable data={summaryObj} />

      <RegionLtsa fpToggle={fpToggle} level={level} locNbr={locNbr} timeframe={timeframe} />
    </Segment>
  );
};
