import React from "react";
import { Menu } from "semantic-ui-react";

export const SideKickPurgeToggle = ({ purgeOrBay, setPurgeOrBay }) => {
  return (
    <Menu compact size="small">
      <Menu.Item
        name="PURGE"
        active={purgeOrBay === "PURGE"}
        content="PURGE"
        onClick={() => {
          setPurgeOrBay("PURGE");
        }}
      />
      <Menu.Item
        name="BAY"
        active={purgeOrBay === "BAY"}
        content="BAY"
        onClick={() => {
          setPurgeOrBay("BAY");
        }}
      />
    </Menu>
  );
};
