import { Header } from "semantic-ui-react"
import { DataTable } from "../Common/DataTable"

export const VocDetailsComponent = ({ dataFilter, level, levelNumber, levelName }) => {
  const rowProps = ['LTPA', 'Product Condition', 'Order Accuracy', 'Reasonable Wait Time']
  const propsByRow = ['SATISFACTION_SCORE', 'PRODUCT_CONDITION_RATING', 'ORDER_FULFILLMENT_ACCURACY', 'WAIT_TIME_5']
  const dataObjVocDetails = {
    sortable: false,
    headers: [
      { name: '' },
      { name: 'BOSS' },
      { name: 'BOPIS' },
      { name: 'MIXED' },
      { name: 'TOTAL' },
    ],
    data: rowProps.map((row, indexRow) => [
      {
        stringValue: row
      },
      {
        pctValue: dataFilter.length > 0 ? dataFilter.filter(row => row['ORDER_TYPE'] === 'BOSS')[0][propsByRow[indexRow]] : '-'
      },
      {
        pctValue: dataFilter.length > 0 ? dataFilter.filter(row => row['ORDER_TYPE'] === 'BOPIS')[0][propsByRow[indexRow]] : '-'
      },
      {
        pctValue: dataFilter.length > 0 ? dataFilter.filter(row => row['ORDER_TYPE'] === 'MIXED')[0][propsByRow[indexRow]] : '-'
      },
      {
        pctValue: dataFilter.length > 0 ? dataFilter.filter(row => row['ORDER_TYPE'] === 'TOTAL')[0][propsByRow[indexRow]] : '-'
      }
    ])
  }
  return (
    <>
      <Header textAlign='center' style={{ textTransform: 'capitalize' }}>VOC Overview - {level} {levelNumber}, {levelName}</Header>
      <DataTable data={dataObjVocDetails} />
    </>
  )
}