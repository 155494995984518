import { useState } from 'react';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { useQuery } from 'react-query';

import { getData } from '../../../service/DataService';
import { getRoundToDecimalString } from '../../Common/TableCellUtils';

import { Grid, Header, Loader, Segment } from 'semantic-ui-react';
import { Breadcrumb } from '../../Common/Breadcrumb';
import { DataTable } from '../../Common/DataTable';
import { TimeframeDropdown } from '../../Common/TimeframeDropdown';
import { UnitMenu } from '../../Common/UnitMenu';

const CLASSIFICATION_ENUM = { CXM: 0, NRM: 1, Total: 2 };

export const LaborDetailDeptManager = ({ fpToggle, settimeframe, timeframe }) => {
  const [unit, setUnit] = useState('NOM');
  const { deptNbr: deptNbrInt = 0, rgnNbr = 0 } = useQueryParams();
  const {
    data: laborDptManagerData = [],
    isError,
    isLoading
  } = useQuery(['queryLaborDtlByMgnDeptRgn', { deptNbrInt, locNbr: rgnNbr, fpToggle }], getData);

  if (isError) return <p>Something went wrong</p>;
  if (isLoading) return <Loader active>Loading...</Loader>;

  const breadcrumbItems = [
    { to: `/region/overview?rgnNbr=${rgnNbr}`, text: 'Back to Overview' },
    { to: `/region/labor?rgnNbr=${rgnNbr}`, text: 'Labor Overview' }
  ];
  const isUnitNom = unit === 'NOM';
  const isTimeframeWeekToDate = timeframe === 'WTD';
  const isMonday = new Date().getDay() === 1;

  const laborDptManagerDataFiltered = laborDptManagerData.filter(row => row[`${timeframe}_FLG`] === 1);
  const updatedResultMgrOverView = laborDptManagerDataFiltered.reduce((acc, curr) => {
    const classification = curr.CLASSIFICATION === 'Customer Experience Manager' ? 'CXM' : 'NRM';
    const item = acc.find(item => item.CLASSIFICATION === classification);

    if (item) {
      item.ACTUAL_HOURS += curr.ACTUAL_HOURS;
      item.OVERTIME_HOURS += curr.OVERTIME_HOURS;
      item.SCHEDULED_HOURS += curr.SCHEDULED_HOURS;
      item.AvS = item.ACTUAL_HOURS - item.SCHEDULED_HOURS;
      item.AvSPCT = (item.ACTUAL_HOURS - item.SCHEDULED_HOURS) / item.SCHEDULED_HOURS;
      item.PROJECTED_OVERTIME_HOURS += curr.PROJECTED_OVERTIME_HOURS;
    } else {
      acc.push({
        CLASSIFICATION: classification,
        ACTUAL_HOURS: curr.ACTUAL_HOURS,
        OVERTIME_HOURS: curr.OVERTIME_HOURS,
        SCHEDULED_HOURS: curr.SCHEDULED_HOURS,
        AvS: curr.ACTUAL_HOURS - curr.SCHEDULED_HOURS,
        AvSPCT: (curr.ACTUAL_HOURS - curr.SCHEDULED_HOURS) / curr.SCHEDULED_HOURS,
        PROJECTED_OVERTIME_HOURS: curr.PROJECTED_OVERTIME_HOURS
      });
    }

    const totalItem = acc.find(item => item.CLASSIFICATION === 'Total');
    if (totalItem) {
      totalItem.ACTUAL_HOURS += curr.ACTUAL_HOURS;
      totalItem.OVERTIME_HOURS += curr.OVERTIME_HOURS;
      totalItem.SCHEDULED_HOURS += curr.SCHEDULED_HOURS;
      totalItem.AvS = totalItem.ACTUAL_HOURS - totalItem.SCHEDULED_HOURS;
      totalItem.AvSPCT = (totalItem.ACTUAL_HOURS - totalItem.SCHEDULED_HOURS) / totalItem.SCHEDULED_HOURS;
      totalItem.PROJECTED_OVERTIME_HOURS += curr.PROJECTED_OVERTIME_HOURS;
    } else {
      acc.push({
        CLASSIFICATION: 'Total',
        ACTUAL_HOURS: curr.ACTUAL_HOURS,
        OVERTIME_HOURS: curr.OVERTIME_HOURS,
        SCHEDULED_HOURS: curr.SCHEDULED_HOURS,
        AvS: curr.ACTUAL_HOURS - curr.SCHEDULED_HOURS,
        AvSPCT: (curr.ACTUAL_HOURS - curr.SCHEDULED_HOURS) / curr.SCHEDULED_HOURS,
        PROJECTED_OVERTIME_HOURS: curr.PROJECTED_OVERTIME_HOURS
      });
    }

    return acc;
  }, []);

  const laborDptManagerDataSorted = updatedResultMgrOverView.sort((left, right) =>
    CLASSIFICATION_ENUM[left['CLASSIFICATION']] > CLASSIFICATION_ENUM[right['CLASSIFICATION']] ? 1 : -1
  );

  const dataObj = {
    data: laborDptManagerDataSorted.map(row => {
      const OTHours = isTimeframeWeekToDate && !isMonday ? row.PROJECTED_OVERTIME_HOURS : row.OVERTIME_HOURS;

      return [
        { stringValue: row.CLASSIFICATION },
        { stringValue: getRoundToDecimalString(row.ACTUAL_HOURS || 0) },
        { stringValue: getRoundToDecimalString(OTHours || 0, 2) },
        {
          decimals: !isUnitNom ? 2 : undefined,
          pctValue: !isUnitNom ? row.AvSPCT : undefined,
          stringValue: isUnitNom ? getRoundToDecimalString(row.AvS || 0, 2) : undefined
        }
      ];
    }),
    headers: [
      { name: '' },
      { name: 'Hours' },
      { name: `${isTimeframeWeekToDate && !isMonday ? 'PROJ ' : ''}O/T` },
      { name: `AvS ${isUnitNom ? '' : '%'}` }
    ]
  };
  const { DEPT_NM } = laborDptManagerData[0] || {};

  return (
    <Segment>
      <Grid columns={2}>
        <Grid.Row style={{ paddingBottom: 0 }}>
          <Grid.Column>
            <Breadcrumb items={breadcrumbItems} />
          </Grid.Column>

          <Grid.Column>
            <span>Timeframe:</span>
            <TimeframeDropdown settimeframe={settimeframe} timeframe={timeframe} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ paddingBottom: 0, paddingTop: '1rem' }}>
          <Grid.Column>
            <Grid.Row>
              <span>Units:</span>
            </Grid.Row>

            <UnitMenu unit={unit} setUnit={setUnit} />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Header textAlign='center'>Labor Overview - {DEPT_NM} </Header>

      <DataTable data={dataObj} />
    </Segment>
  );
};
