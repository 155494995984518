import { Header } from "semantic-ui-react"
import { DataTable } from "../Common/DataTable"
import { getCalloutStyle, getThousandDollarString } from "../Common/TableCellUtils"

export const DailyViewComponent = ({ dataQuery }) => {

  const dataObjDailyView = {
    headers: [
      { name: 'Day' },
      {
        name: 'Daily Sales',
        element: <>Daily < br />Sales $ (k)</>
      },
      { name: '$ vPlan (k)' },
      {
        name: '% to Daily Plan',
        element: <>% to < br />Daily Plan</>
      },
      { name: 'Comp %' },
      {
        name: 'Planned Comp %',
        element: <>Planned <br />Comp %</>
      },
    ],
    data: dataQuery.map(row => [
      {
        stringValue: row['ABBR_DAY_NM']
      },
      {
        dollarValue: getThousandDollarString(row['TY_SLS_AMT']),
      },
      {
        dollarValue: getThousandDollarString(row['DOL_TO_PLN'], 1),
        customStyle: getCalloutStyle(row['DOL_TO_PLN']),
        decimals: 1
      },
      {
        pctValue: row['PCT_TO_PLN'],
        customStyle: getCalloutStyle(row['PCT_TO_PLN']),
        decimals: 1
      },
      {
        pctValue: row['COMP_PCT'],
        customStyle: getCalloutStyle(row['COMP_PCT']),
        decimals: 1
      },
      {
        pctValue: row['PLANNED_COMP_PCT'],
        customStyle: getCalloutStyle(row['PLANNED_COMP_PCT']),
        decimals: 1
      },
    ])
  }

  return (
    <>
      <Header textAlign='center'>Daily View</Header>
      <DataTable data={dataObjDailyView} />
    </>
  )
}