import { Tab } from 'semantic-ui-react'
import { SalesDeparmentsTab } from './SalesDeparmentsTab'
import { SalesClassesTab } from './SalesClassesTab'
import { TabMenu } from '../../Common/TabMenu'
import { SalesSubClassesTab } from './SalesSubClassesTab'

export const SalesTab = () => {

  const tabs = [
    {
      menuItem: 'Dept',
      render: () => <SalesDeparmentsTab />
    },
    {
      menuItem: 'Classes',
      render: () => <SalesClassesTab />,
    },
    {
      menuItem: 'Subclasses',
      render: () => <SalesSubClassesTab />,
    },
  ]

  return (
    <Tab.Pane>
      <TabMenu panes={tabs} />
    </Tab.Pane>
  )
}
