import React, { useState, useEffect } from 'react'
import { Header, Loader, Grid, Table, Icon, Pagination } from 'semantic-ui-react'
import { withRouter, Link } from 'react-router-dom'

import { useQuery } from 'react-query'
import { getData } from '../../service/DataService'
import { getThousandDollarString } from '../Common/TableCellUtils'


export const InvSubclassDrilldown = withRouter(({ level, fpToggle, location }) => {

  var getLevelKey = {
    'store' : 'strNbr',
    'district' : 'dstNbr'
  }

  const [sortColumn, setSortColumn] = useState("SKU_NBR")
  const [sortDirection, setSortDirection] = useState("ascending")
  const [pageNum, setPageNum] = useState(1)
  let rowsPerPage = 50

  const search = new URLSearchParams(location.search)
  const dstNbr = search.has('dstNbr') ? search.get('dstNbr') : 0
  const strNbr = search.has('strNbr') ? search.get('strNbr') : 0
  const orgNbr = search.has('strNbr') ? search.get('strNbr') : search.has('dstNbr') ? search.get('dstNbr') : 0
  const deptNbrInt = search.has('deptNbr') ? search.get('deptNbr') : 0
  const classNbr = search.has('classNbr') ? search.get('classNbr') : 0
  const subClassNbr = search.has('subClassNbr') ? search.get('subClassNbr') : 0

  const subClassInvQuery = level === 'store' ? useQuery(['queryInvSclsByStrCls', { strNbr, classNbr, fpToggle }], getData) :
                          level === 'district' ? useQuery(['queryInvSclsByDstCls', { dstNbr, classNbr, fpToggle }], getData) : null

  const skuInvQuery = level === 'store' ? useQuery(['queryInvSkuByStrScls', { strNbr, subClassNbr, fpToggle }], getData) :
                      level === 'district' ? useQuery(['queryInvSkuByDstScls', { dstNbr, subClassNbr, fpToggle }], getData) : null

  useEffect(() => {
    setPageNum(1)
  }, [sortColumn, sortDirection])

  const subClassInventory = subClassInvQuery.data
  const skuInventory = skuInvQuery.data

  if (subClassInvQuery.isLoading || skuInvQuery.isLoading) {
    return (<Loader active>Loading...</Loader>)
  }

  // Load, extract and sort class numbers
  let allSubClassSort = subClassInventory.sort((left, right) => {
    let result = left.SUB_CLASS_NM > right.SUB_CLASS_NM ? 1 : -1
    return result
  })

  let subClassNbrArr = []
  allSubClassSort.map((data) => {
    return subClassNbrArr.push(data['EXT_SUB_CLASS_NBR'].toString())
  })

  let subClassNmArr = []
  allSubClassSort.map((data) => {
    return subClassNmArr.push(data['SUB_CLASS_NM'])
  })

  let subClassNm = subClassNmArr[subClassNbrArr.indexOf(subClassNbr)]

  let skuInventorySort = skuInventory.sort((left, right) => {
    let result = left[sortColumn] - right[sortColumn]
    if (sortColumn === 'SKU_NBR') {
      result = left.SKU_NBR > right.SKU_NBR ? 1 : -1
    }
    if (sortColumn === 'SKU_DESC') {
      result = left.SKU_DESC > right.SKU_DESC ? 1 : -1
    }
    if (sortDirection === 'descending') {
      result *= -1
    }
    return result
  })

  let dataPagination = skuInventorySort.slice(rowsPerPage * (pageNum - 1), parseInt(Math.min(rowsPerPage * pageNum, skuInventorySort.length)))

  return (
    <>
      <Header textAlign='center'>{subClassNm}</Header>
      {/* Buttons for adjacent classes */}
      <Grid>
        <Grid.Row fluid>
          <Grid.Column textAlign='left' width={8}>
            <Link
              to={`/${level}/inventory/dept/class/subclass?${getLevelKey[level]}=${orgNbr}&deptNbr=${deptNbrInt}&classNbr=${classNbr}&subClassNbr=${subClassNbrArr[(subClassNbrArr.indexOf(subClassNbr) + subClassNbrArr.length - 1) % subClassNbrArr.length]}`}
              style={{ color: '#EE7125', textDecoration: 'underline' }}>
              {subClassNmArr[(subClassNbrArr.indexOf(subClassNbr) + subClassNbrArr.length - 1) % subClassNbrArr.length]}
            </Link>

          </Grid.Column>
          <Grid.Column textAlign='right' width={8}>
            <Link
              to={`/${level}/inventory/dept/class/subclass?${getLevelKey[level]}=${orgNbr}&deptNbr=${deptNbrInt}&classNbr=${classNbr}&subClassNbr=${subClassNbrArr[(subClassNbrArr.indexOf(subClassNbr) + 1) % subClassNbrArr.length]}`}
              style={{ color: '#EE7125', textDecoration: 'underline' }}>
              {subClassNmArr[(subClassNbrArr.indexOf(subClassNbr) + 1) % subClassNbrArr.length]}
            </Link>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Grid>
        <Grid.Row>
          <Grid.Column width={8} textAlign='left'>
            <Grid.Row>&nbsp;</Grid.Row>
            <Grid.Row><Link style={{ color: '#EE7125' }} to={`/${level}/inventory/dept/class?${getLevelKey[level]}=${orgNbr}&deptNbr=${deptNbrInt}&classNbr=${classNbr}`} data-test='b2o-link'>Back to Class</Link></Grid.Row>

          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid>
        {skuInventorySort.length > rowsPerPage && <Grid.Row>
              <Grid.Column width={16} textAlign='center'>
                <Pagination
                  size='mini'
                  defaultActivePage={1}
                  totalPages={Math.floor((skuInventorySort.length - 1) / rowsPerPage) + 1}
                  activePage={pageNum}
                  boundaryRange={0}
                  siblingRange={1}
                  onPageChange={(e, { activePage }) => {
                    setPageNum(activePage)
                  }}
                />
              </Grid.Column>
            </Grid.Row>}
      </Grid>
      <Header textAlign='center'>Inventory Detail</Header>
      <Table textAlign='center' unstackable celled sortable size='small' style={{ fontSize: 12 }}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    sorted={sortColumn === 'SKU_NBR' ? sortDirection : null}
                    onClick={() => {
                      if (sortColumn !== 'SKU_NBR') {
                        setSortColumn('SKU_NBR')
                        setSortDirection('ascending')
                      } else {
                        sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                      }
                    }}>
                    SKU # <Icon fitted name='sort' />
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={sortColumn === 'SKU_DESC' ? sortDirection : null}
                    onClick={() => {
                      if (sortColumn !== 'SKU_DESC') {
                        setSortColumn('SKU_DESC')
                        setSortDirection('ascending')
                      } else {
                        sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                      }
                    }}>
                    SKU Desc <Icon fitted name='sort' />
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={sortColumn === 'INV_AMT' ? sortDirection : null}
                    onClick={() => {
                      if (sortColumn !== 'INV_AMT') {
                        setSortColumn('INV_AMT')
                        setSortDirection('ascending')
                      } else {
                        sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                      }
                    }}>
                    Inv $<br/><Icon fitted name='sort' />
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={sortColumn === 'ASW' ? sortDirection : null}
                    onClick={() => {
                      if (sortColumn !== 'ASW') {
                        setSortColumn('ASW')
                        setSortDirection('ascending')
                      } else {
                        sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                      }
                    }}>
                    ASW <br/><Icon fitted name='sort' />
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={sortColumn === 'WOS' ? sortDirection : null}
                    onClick={() => {
                      if (sortColumn !== 'WOS') {
                        setSortColumn('WOS')
                        setSortDirection('ascending')
                      } else {
                        sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                      }
                    }}>
                    WOS <br/><Icon fitted name='sort' />
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={sortColumn === 'ICE_INV_AMT' ? sortDirection : null}
                    onClick={() => {
                      if (sortColumn !== 'ICE_INV_AMT') {
                        setSortColumn('ICE_INV_AMT')
                        setSortDirection('ascending')
                      } else {
                        sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                      }
                    }}>
                    ICE $ <br/><Icon fitted name='sort' />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {dataPagination.map((data) => {
                  return (
                    <Table.Row>
                      <Table.Cell>
                        {data['SKU_NBR']}
                      </Table.Cell>
                      <Table.Cell>
                        {data['SKU_DESC']}
                      </Table.Cell>
                      <Table.Cell singleLine>
                        {getThousandDollarString(data['INV_AMT'])}
                      </Table.Cell>
                      <Table.Cell singleLine>
                        {getThousandDollarString(data['ASW'])}
                      </Table.Cell>
                      <Table.Cell singleLine>
                        {data['WOS'].toLocaleString(undefined, { maximumFractionDigits: 1})}
                      </Table.Cell>
                      <Table.Cell singleLine>
                        {getThousandDollarString(data['ICE_INV_AMT'])}
                      </Table.Cell>
                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table>

    </>
  )

})