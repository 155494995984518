import PropTypes from 'prop-types';

import { RegionOverviewMenu } from './RegionOverviewMenu';
// import { RegionOverviewTabs } from './Region/RegionOverviewTabs';

import RegionOverviewContextProvider from '../../../context/RegionOverviewContext';

export const RegionOverview = ({ fpToggle, timeframe, settimeframe }) => {
  return (
    <RegionOverviewContextProvider fpToggle={fpToggle} timeframe={timeframe} settimeframe={settimeframe}>
      <RegionOverviewMenu />
      {/* <RegionOverviewTabs /> // TODO: Waiting for Ryan comments, to re-enabled this view  */}
    </RegionOverviewContextProvider>
  );
};

RegionOverview.propTypes = {
  timeframe: PropTypes.string.isRequired,
  settimeframe: PropTypes.func.isRequired
};
