import React, {useState, useEffect} from 'react'
import {
    Grid,
    Header,
    Loader,
    Menu,
    Divider,
  } from 'semantic-ui-react'
import { withRouter, Link } from "react-router-dom/cjs/react-router-dom.min";
import { useQuery } from 'react-query'
import { DataTable } from '../Common/DataTable'
import {
    getData
  } from '../../service/DataService'

import { SKU_BAY_GOAL } from './SideKickSkuBay';

import { TimeframeDropdownLimited } from '../Common/TimeframeDropdownLimited'
import { TimeframeDropdownMonLimited } from '../Common/TimeframeDropdownMonLimited'

export const SideKickSkuBayDst = withRouter(({ timeframe, settimeframe, level, fpToggle, locNbr, location }) => {
  
  const search = new URLSearchParams(location.search)
  const active = search.has('active') ? search.get('active') : "DIR TASKS"

  const [drilldownTab, setdrilldownTab] = useState(active)
  const [drilldownTaskTypeTab, setdrilldownTaskTypeTab] = useState("TOTAL")

  useEffect(() => {
    if (timeframe !== 'WTD' && timeframe !== 'LW') {
      settimeframe('WTD')
    }
  }, [timeframe])
 
  const osaSidekickSkuBayQuery = useQuery([`query/queryOSASidekickSkuBayDst/${level}`, { strNbr:locNbr, fpToggle }], getData)
  const osaSummaryQuery = useQuery([`query/queryOSADriverSummaryPlusOne/${level}`, { locNbr, fpToggle }], getData)

  const lastTimeframeMap = {
    'WTD': 'LW',
    'LW': null, // We don't have comparisons for last week or month, so they'll be null
    'LM': null,
    'MTD': 'LM',
    'QTD': 'LQ',
    'HTD': 'LH',
    'YTD': 'YTD_LY',
    'L12': 'L12_LY',
  }
  const ltf = lastTimeframeMap[timeframe]

  
  if (osaSidekickSkuBayQuery.isLoading || osaSummaryQuery.isLoading) { 
    return (<Loader active>Loading</Loader>)
  }

  if (
      (!osaSidekickSkuBayQuery.data || osaSidekickSkuBayQuery.data.length === 0) 
      || (!osaSummaryQuery.data || osaSummaryQuery.data.length === 0)
    ) {
    return (<Header textAlign='center'>Invalid Store Number</Header>)
  }

  const osaSidekickSkuBayData =  osaSidekickSkuBayQuery.data.filter(({DEPT}) => DEPT !== '38')
  const osaSummaryData = osaSummaryQuery.data

  const directedTaskSummary = osaSidekickSkuBayData
  .reduce((acc, row) => {
    if (acc[row.STR_NBR] === undefined) {
      acc[row.STR_NBR] = {
        ttlComp: 0,
        ttlTasks: 0,
        ttlCompLTF: 0,
        ttlTasksLTF: 0,
        skuComp: 0,
        skuSent: 0,
        bayComp: 0,
        baySent: 0,
        skuBaySent: 0,
        skuBayComp: 0,
        mgrSent: 0,
        mgrComp: 0,
        logSkus: 0,
        logSkusLTF: 0,
      }
    }
    acc[row.STR_NBR].ttlComp += row[`${timeframe}_COMPLETED_TASK_COUNT`]
    acc[row.STR_NBR].ttlTasks += row[`${timeframe}_TOTAL_TASK_COUNT`]
    if (ltf) {
      acc[row.STR_NBR].ttlCompLTF += row[`${ltf}_COMPLETED_TASK_COUNT`]
      acc[row.STR_NBR].ttlTasksLTF += row[`${ltf}_TOTAL_TASK_COUNT`]
    }

    if (row.TASK_ORIGIN === 'SIDEKICK') {
      acc[row.STR_NBR].skuBayComp += row[`${timeframe}_COMPLETED_TASK_COUNT`]
      acc[row.STR_NBR].skuBaySent += row[`${timeframe}_TOTAL_TASK_COUNT`]
      if (row.TASK_TYPE === 'SKU') {
        acc[row.STR_NBR].skuComp += row[`${timeframe}_COMPLETED_TASK_COUNT`]
        acc[row.STR_NBR].skuSent += row[`${timeframe}_TOTAL_TASK_COUNT`]
      } else if (row.TASK_TYPE === 'BAY') {
        acc[row.STR_NBR].bayComp += row[`${timeframe}_COMPLETED_TASK_COUNT`]
        acc[row.STR_NBR].baySent += row[`${timeframe}_TOTAL_TASK_COUNT`]
      }
    }
    if (row.TASK_ORIGIN === 'MANAGER') {
      acc[row.STR_NBR].mgrSent += row[`${timeframe}_TOTAL_TASK_COUNT`]
      acc[row.STR_NBR].mgrComp += row[`${timeframe}_COMPLETED_TASK_COUNT`]
    }
    if (row.TASK_TYPE === 'LOGGED') {
      acc[row.STR_NBR].logSkus += row[`${timeframe}_SKUS_PACKED_DOWN`]
      if (ltf) {
        acc[row.STR_NBR].logSkusLTF += row[`${ltf}_SKUS_PACKED_DOWN`]
      }
    }

    return acc
  }, {})

  const overviewData = {
    sortable: true,
    headers: [
      {name: 'Store'}, {name: 'SKU/Bay Execution Score'}, {name: 'Goal'}, {name: ltf ? `v${ltf} bps` : '-'},
    ],
    data: Object.entries(directedTaskSummary).map(([store, data]) => {
      const overview = osaSummaryData.find(r => r.STR_NBR.toString() === store)
      return [
        {
          stringValue: store.padStart(4, '0'),
          link: {
            to: '/osa/sidekickskubay/store',
            params: {
              strNbr: store,
            }
          }
        },
        {
          pctValue: overview[timeframe + '_AB_POINTS_COMPLETED'] / overview[timeframe + '_AB_WEEKLY_POINTS_GOAL'],
          compareValue: SKU_BAY_GOAL,
        },
        {pctValue: SKU_BAY_GOAL},
        ltf ? 
        {
          numValue:  Math.round((
            (data.ttlComp / data.ttlTasks) - 
            (data.ttlCompLTF / data.ttlTasksLTF)
          ) * 10000), 
          compareStyle: true, 
          postfix: ' bps'
        } : {stringValue: '-'},
      ]
    })
  }

  // Default to DIR TASKS TOTAL
  let dataPrefix = 'skuBay'
  let headers = [{name: 'Store'}, {name: 'Tasks Comp #'}, {name: 'Tasks Sent #'}, {name: 'Tasks Comp %'}]
  switch (drilldownTab) {
    case 'DIR TASKS':
      if (drilldownTaskTypeTab === 'SKU') {
        dataPrefix = 'sku'
        headers = [{name: 'Store'}, {name: 'SKU Tasks Comp #'}, {name: 'SKU Tasks Sent #'}, {name: 'SKU Tasks Comp %'}]
      } else if (drilldownTaskTypeTab === 'BAY') {
        dataPrefix = 'bay'
        headers = [{name: 'Store'}, {name: 'Bay Tasks Comp #'}, {name: 'Bay Tasks Sent #'}, {name: 'Bay Tasks Comp %'}]
      }
      break;
    case 'MGR ADD':
      dataPrefix = 'mgr'
      headers = [{name: 'Store'}, {name: 'Ttl Tasks Comp #'}, {name: 'Ttl Tasks Added #'}, {name: 'Ttl Tasks Comp %'}]
      break;
  }

  const tasksData = {
    sortable: true,
    headers: headers,
    data: Object.entries(directedTaskSummary).map(([store, data]) => {
      return [
        {
          stringValue: store.padStart(4, '0'),
          link: {
            to: '/osa/sidekickskubay/store',
            params: {
              strNbr: store,
              active: drilldownTab,
            }
          }
        },
        {numValue: data[`${dataPrefix}Comp`]},
        {numValue: data[`${dataPrefix}Sent`]},
        {pctValue: data[`${dataPrefix}Comp`] / data[`${dataPrefix}Sent`]},
      ]
    })
  }
  const loggedData = {
    sortable: true,
    headers: [{name: 'Store'}, {name: 'Logged SKUs #'}, {name: ltf ? `v${ltf} SKU#` : '-'}],
    data: Object.entries(directedTaskSummary).map(([store, data]) => {
      return [
        {
          stringValue: store.padStart(4, '0'),
          link: {
            to: '/osa/sidekickskubay/store',
            params: {
              strNbr: store,
              active: drilldownTab,
            }
          }
        },
        {numValue: data.logSkus},
        {numValue: data.logSkus - data.logSkusLTF, compareStyle: true},
      ]
    })
  }

  const bottomData = drilldownTab === 'LOGGED WK' ? loggedData : tasksData
 
  return (
    <>
    <Grid>
      <Grid.Column width={8}>
        <Grid.Row>
          <Link style={{ color: '#EE7125' }} to={`/${level}/overview?dstNbr=${locNbr}`} data-test='b2o-link'>Overview</Link>
          {' / '}
          <Link style={{ color: '#EE7125' }} to={`/osa?dstNbr=${locNbr}`} data-test='b2osa-link'>OSA</Link>
          {` / SKU/Bay`}
        </Grid.Row>
        <Grid.Row>&nbsp;</Grid.Row>
          </Grid.Column>
          <Grid.Column width={8}>
                        Timeframe: 
              {new Date().getDay() === 1 ?
                <TimeframeDropdownMonLimited timeframe={timeframe} settimeframe={settimeframe} /> :
                <TimeframeDropdownLimited timeframe={timeframe} settimeframe={settimeframe} />}
      </Grid.Column>
    </Grid>

    <Header textAlign='center'>Sidekick Execution - SKU/Bay</Header>
    <DataTable data={overviewData} />

    <Divider />

    <Header textAlign='center'>Tasks Completion</Header>
    
    <Menu compact>
      <Menu.Item
        name='DIR TASKS'
        active={drilldownTab === 'DIR TASKS'}
        content='DIR TASKS'
        onClick={() => {
          setdrilldownTab('DIR TASKS')
        }}
      />
      <Menu.Item
        name='MGR ADD'
        active={drilldownTab === 'MGR ADD'}
        content='MGR ADD'
        onClick={() => {
          setdrilldownTab('MGR ADD')
        }}
      />
      <Menu.Item
        name='LOGGED WK'
        active={drilldownTab === 'LOGGED WK'}
        content='LOGGED WK'
        onClick={() => {
          setdrilldownTab('LOGGED WK')
        }}
      />
    </Menu>
    
    {
        drilldownTab === 'DIR TASKS' &&
        <><br /><br /> Task Type <br />
        <Menu compact>
            <Menu.Item
            name='TOTAL'
            active={drilldownTaskTypeTab === 'TOTAL'}
            content='TOTAL'
            onClick={() => {
                setdrilldownTaskTypeTab('TOTAL')
            }}
            />
            <Menu.Item
            name='SKU'
            active={drilldownTaskTypeTab === 'SKU'}
            content='SKU'
            onClick={() => {
                setdrilldownTaskTypeTab('SKU')
            }}
            />
            <Menu.Item
            name='BAY'
            active={drilldownTaskTypeTab === 'BAY'}
            content='BAY'
            onClick={() => {
                setdrilldownTaskTypeTab('BAY')
            }}
            />
        </Menu></>
    }
    <DataTable data={bottomData} />
    </>
)
})
