import React, { useState, useEffect } from 'react'
import { Header, Segment, Button, Modal, Grid, Table, Loader, Menu, Popup, Icon, Tab, Pagination } from 'semantic-ui-react'
import { withRouter, Link } from 'react-router-dom'
import { useQuery } from 'react-query'
import { getData } from '../../service/DataService'
import { getCompareStyle, getPercentString, getAdjustableDollarString, getDollarString } from '../Common/TableCellUtils'
import { TimeframeDropdownShrink } from '../Common/TimeframeDropdownShrink'
import { UnitMenu } from '../Common/UnitMenu'

export const DstShrink = withRouter(({ timeframe, settimeframe, fpToggle, location }) => {

  const search = new URLSearchParams(location.search)
  const dstNbr = search.has('dstNbr') ? search.get('dstNbr') : 0
  const [sortColumn, setSortColumn] = useState('STR_NBR')
  const [sortDirection, setSortDirection] = useState('ascending')
  const [overviewMenu, setOverviewMenu] = useState('District')
  const [storeSumUnits, setStoreSumUnits] = useState("NOM")
  const [deptSumUnits, setDeptSumUnits] = useState("NOM")
  const [sortColumnDept, setSortColumnDept] = useState("DEPT_NBR")
  const [sortDirectionDept, setSortDirectionDept] = useState("ascending")
  const [classSumUnits, setClassSumUnits] = useState("NOM")
  const [sortColumnClass, setSortColumnClass] = useState("DEPT_NBR")
  const [sortDirectionClass, setSortDirectionClass] = useState("ascending")
  const [subCSumUnits, setSubCSumUnits] = useState("NOM")
  const [sortColumnSubclass, setSortColumnSubclass] = useState("DEPT_NBR")
  const [sortDirectionSubclass, setSortDirectionSubclass] = useState("ascending")
  const [skuSumUnits, setSkuSumUnits] = useState("NOM")
  const [sortColumnSku, setSortColumnSku] = useState("SHRINK_AMT_vLY_RANK_DST")
  const [sortDirectionSku, setSortDirectionSku] = useState("ascending")
  const [open, setOpen] = useState(false)
  const [modalData, setModalData] = useState([])
  const [pageNum, setPageNum] = useState(1)
  const [deptNbr, setDeptNbr] = useState("All")
  const [pageNumScls, setPageNumScls] = useState(1)

  let rowsPerPage = 20;

  const shrinkMetricsQuery = useQuery(['queryShrinkMetricsByDst', { dstNbr, fpToggle }], getData)
  const shrinkMetricsScoresQuery = useQuery(['queryShrinkMetricsScoresByDst', { dstNbr, fpToggle }], getData)
  const accRcvQuery = useQuery(['queryACCRCVByDst', { dstNbr, fpToggle }], getData)
  const shrinkActualsDstSumQuery = useQuery(['queryShrinkActualsByDst', { dstNbr, fpToggle }], getData)
  const shrinkActualsDeptQuery = useQuery(['queryShrinkActualsDeptByDst', { dstNbr, fpToggle }], getData)
  const shrinkActualsClassQuery = useQuery(['queryShrinkActualsClsByDst', { dstNbr, fpToggle }], getData)
  const shrinkActualsSubclassQuery = useQuery(['queryShrinkActualsSclsByDst', { dstNbr, fpToggle }], getData)
  const shrinkActualsSkuQuery = useQuery(['queryShrinkActualsTop40SkusByDst', { dstNbr, fpToggle }], getData)

  useEffect(() => {
    setPageNum(1)
  }, [timeframe, sortColumnClass, sortDirectionClass, deptNbr])

  useEffect(() => {
    setPageNumScls(1)
  }, [timeframe, sortColumnSubclass, sortDirectionSubclass, deptNbr])

  useEffect(() => {
    if (skuSumUnits === 'NOM') {
      setSortColumnSku('SHRINK_AMT_vLY_RANK_DST')
    } else {
      setSortColumnSku('SHRINK_RATE_vLY_RANK_DST')
    }
    setSortDirectionSku('ascending')
  }, [skuSumUnits])

  if (shrinkMetricsQuery.isLoading || shrinkMetricsScoresQuery.isLoading || accRcvQuery.isLoading ||
    shrinkActualsDstSumQuery.isLoading || shrinkActualsDeptQuery.isLoading || shrinkActualsClassQuery.isLoading ||
    shrinkActualsSubclassQuery.isLoading || shrinkActualsSkuQuery.isLoading) {
    return (<Loader active>Loading...</Loader>)
  }

  if (!shrinkMetricsQuery.data[0] || !accRcvQuery.data[0] || !shrinkActualsDstSumQuery.data[0] || !shrinkActualsDeptQuery.data || shrinkActualsSkuQuery.isLoading ||
    !shrinkActualsClassQuery.data || !shrinkActualsSubclassQuery.data) {
    return (<Header textAlign='center'>Invalid Store Number</Header>)
  }

  const shrinkMetricsData = shrinkMetricsQuery.data[0]
  const scoresData = shrinkMetricsScoresQuery.data
  const accRcvData = accRcvQuery.data
  const storeSumData = shrinkActualsDstSumQuery.data[0]
  const deptSumData = shrinkActualsDeptQuery.data
  const classSumData = shrinkActualsClassQuery.data
  const subclassSumData = shrinkActualsSubclassQuery.data;
  const skuSumData = shrinkActualsSkuQuery.data;

  let depts = []
  deptSumData.map((data) => {
    return depts.includes(data['DEPT_NBR']) ? depts : depts.push(data['DEPT_NBR'])
  })
  depts.sort((a, b) => a - b) //Depts array sorting in the ascending order

  let dstScoresDataFiltered = scoresData.filter((row) => {
    return row && row['TIME_FRAME'] === timeframe && row['METRIC_LEVEL'] === 'DST'
  })

  let strScoresDataFiltered = scoresData.filter((row) => {
    return row && row['TIME_FRAME'] === timeframe && row['METRIC_LEVEL'] === 'STR'
  })

  let deptSumDataFilter = deptSumData.filter((row) => {
    return row
  })

  let classSumDataFilter = classSumData.filter((row) => {
    return row && (deptNbr === 'All' || row['DEPT_NBR'].toString() === deptNbr)
  })

  let subclassSumDataFilter = subclassSumData.filter((row) => {
    return row && (deptNbr === 'All' || row['DEPT_NBR'].toString() === deptNbr)
  })

  let skuSumDataFilter = skuSumData.filter((row) => {
    if (skuSumUnits === 'NOM') {
      return row && row['SHRINK_AMT_vLY_RANK_DST'] <= 40
    } else {
      return row && row['SHRINK_RATE_vLY_RANK_DST'] <= 40
    }
  })

  let strScoresDataSorted = strScoresDataFiltered.sort((left, right) => {
    let result = left[sortColumn] - right[sortColumn]
    if (sortColumn === 'VLY') {
      result = (left.SHRINK_EXEC_SCORE - left.LY_SHRINK_EXEC_SCORE) - (right.SHRINK_EXEC_SCORE - right.LY_SHRINK_EXEC_SCORE)
    }
    if (sortDirection === 'descending') {
      result *= -1
    }
    return result
  })

  let deptSumrySorted = deptSumDataFilter.sort((left, right) => {
    let result = left[sortColumnDept] - right[sortColumnDept]
    if (sortColumnDept === 'DEPT_NBR') {
      result = left.DEPT_NBR > right.DEPT_NBR ? 1 : -1
    }
    if (sortColumnDept === 'TOTAL_SHRINK_AMT') {
      result = left.TOTAL_SHRINK_AMT > right.TOTAL_SHRINK_AMT ? 1 : -1
    }
    if (sortColumnDept === 'TOTAL_SALES_AMT') {
      if (isNaN(left.TOTAL_SHRINK_AMT / left.TOTAL_SALES_AMT)) { return 1; }
      if (isNaN(right.TOTAL_SHRINK_AMT / right.TOTAL_SALES_AMT)) { return -1; }
      result =
        left.TOTAL_SHRINK_AMT / left.TOTAL_SALES_AMT >
          right.TOTAL_SHRINK_AMT / right.TOTAL_SALES_AMT
          ? 1
          : -1;
    }
    if (sortColumnDept === 'LY_TOTAL_SHRINK_AMT') {
      result = left.TOTAL_SHRINK_AMT - left.LY_TOTAL_SHRINK_AMT > right.TOTAL_SHRINK_AMT - right.LY_TOTAL_SHRINK_AMT ? 1 : -1
    }
    if (sortColumnDept === 'LY_TOTAL_SALES_AMT') {
      const l = left.TOTAL_SHRINK_AMT / left.TOTAL_SALES_AMT - left.LY_TOTAL_SHRINK_AMT / left.LY_TOTAL_SALES_AMT;
      const r = right.TOTAL_SHRINK_AMT / right.TOTAL_SALES_AMT - right.LY_TOTAL_SHRINK_AMT / right.LY_TOTAL_SALES_AMT;
      if (isNaN(l)) { return 1; }
      if (isNaN(r)) { return -1; }
      result = l > r ? 1 : -1;
    }
    if (sortColumnDept === 'OPS_SHRINK_AMT') {
      result = left.OPS_SHRINK_AMT > right.OPS_SHRINK_AMT ? 1 : -1
    }
    if (sortColumnDept === 'MAL_SHRINK_AMT') {
      if (isNaN(left.OPS_SHRINK_AMT / left.TOTAL_SALES_AMT)) { return 1; }
      if (isNaN(right.OPS_SHRINK_AMT / right.TOTAL_SALES_AMT)) { return -1; }
      result =
        left.OPS_SHRINK_AMT / left.TOTAL_SALES_AMT >
          right.OPS_SHRINK_AMT / right.TOTAL_SALES_AMT
          ? 1
          : -1;
    }
    if (sortDirectionDept === 'descending') {
      result *= -1
    }
    return result
  })

  let classSumrySorted = classSumDataFilter.sort((left, right) => {
    let result = left[sortColumnClass] - right[sortColumnClass]
    if (sortColumnClass === 'EXT_CLASS_NBR') {
      result = left.EXT_CLASS_NBR > right.EXT_CLASS_NBR ? 1 : -1
    }
    if (sortColumnClass === 'TOTAL_SHRINK_AMT') {
      result = left.TOTAL_SHRINK_AMT > right.TOTAL_SHRINK_AMT ? 1 : -1
    }
    if (sortColumnClass === 'TOTAL_SALES_AMT') {
      if (isNaN(left.TOTAL_SHRINK_AMT / left.TOTAL_SALES_AMT)) { return 1; }
      if (isNaN(right.TOTAL_SHRINK_AMT / right.TOTAL_SALES_AMT)) { return -1; }
      result =
        left.TOTAL_SHRINK_AMT / left.TOTAL_SALES_AMT >
          right.TOTAL_SHRINK_AMT / right.TOTAL_SALES_AMT
          ? 1
          : -1;
    }
    if (sortColumnClass === 'LY_TOTAL_SHRINK_AMT') {
      if (isNaN(left.LY_TOTAL_SHRINK_AMT / left.LY_TOTAL_SALES_AMT)) { return 1; }
      if (isNaN(right.LY_TOTAL_SHRINK_AMT / right.LY_TOTAL_SALES_AMT)) { return -1; }
      result = left.LY_TOTAL_SHRINK_AMT / left.LY_TOTAL_SALES_AMT > right.LY_TOTAL_SHRINK_AMT / right.LY_TOTAL_SALES_AMT ? 1 : -1
    }
    if (sortColumnClass === 'LY_TOTAL_SALES_AMT') {
      const l = left.TOTAL_SHRINK_AMT / left.TOTAL_SALES_AMT - left.LY_TOTAL_SHRINK_AMT / left.LY_TOTAL_SALES_AMT;
      const r = right.TOTAL_SHRINK_AMT / right.TOTAL_SALES_AMT - right.LY_TOTAL_SHRINK_AMT / right.LY_TOTAL_SALES_AMT;
      if (isNaN(l)) { return 1; }
      if (isNaN(r)) { return -1; }
      result = l > r ? 1 : -1;
    }
    if (sortColumnClass === 'OPS_SHRINK_AMT') {
      result = left.OPS_SHRINK_AMT > right.OPS_SHRINK_AMT ? 1 : -1
    }
    if (sortColumnClass === 'OPS_SALES_AMT') {
      if (isNaN(left.OPS_SHRINK_AMT / left.TOTAL_SALES_AMT)) { return 1; }
      if (isNaN(right.OPS_SHRINK_AMT / right.TOTAL_SALES_AMT)) { return -1; }
      result =
        left.OPS_SHRINK_AMT / left.TOTAL_SALES_AMT >
          right.OPS_SHRINK_AMT / right.TOTAL_SALES_AMT
          ? 1
          : -1;
    }
    if (sortDirectionClass === 'descending') {
      result *= -1
    }
    return result
  })

  let subclassSumDataSorted = subclassSumDataFilter.sort((left, right) => {
    let result = left[sortColumnSubclass] - right[sortColumnSubclass]
    if (sortColumnSubclass === 'EXT_SUB_CLASS_NBR') {
      result = left.EXT_SUB_CLASS_NBR > right.EXT_SUB_CLASS_NBR ? 1 : -1
    }
    if (sortColumnSubclass === 'TOTAL_SHRINK_AMT') {
      result = left.TOTAL_SHRINK_AMT > right.TOTAL_SHRINK_AMT ? 1 : -1
    }
    if (sortColumnSubclass === 'TOTAL_SALES_AMT') {
      if (isNaN(left.TOTAL_SHRINK_AMT / left.TOTAL_SALES_AMT)) { return 1; }
      if (isNaN(right.TOTAL_SHRINK_AMT / right.TOTAL_SALES_AMT)) { return -1; }
      result = left.TOTAL_SHRINK_AMT / left.TOTAL_SALES_AMT > right.TOTAL_SHRINK_AMT / right.TOTAL_SALES_AMT ? 1 : -1
    }
    if (sortColumnSubclass === 'LY_TOTAL_SHRINK_AMT') {
      result = left.TOTAL_SHRINK_AMT - left.LY_TOTAL_SHRINK_AMT > right.TOTAL_SHRINK_AMT - right.LY_TOTAL_SHRINK_AMT ? 1 : -1
    }
    if (sortColumnSubclass === 'LY_TOTAL_SALES_AMT') {
      const l = left.TOTAL_SHRINK_AMT / left.TOTAL_SALES_AMT - left.LY_TOTAL_SHRINK_AMT / left.LY_TOTAL_SALES_AMT;
      const r = right.TOTAL_SHRINK_AMT / right.TOTAL_SALES_AMT - right.LY_TOTAL_SHRINK_AMT / right.LY_TOTAL_SALES_AMT;
      if (isNaN(l)) { return 1; }
      if (isNaN(r)) { return -1; }
      result = l > r ? 1 : -1;
    }
    if (sortColumnSubclass === 'OPS_SHRINK_AMT') {
      result = left.OPS_SHRINK_AMT > right.OPS_SHRINK_AMT ? 1 : -1
    }
    if (sortColumnSubclass === 'OPS_SALES_AMT') {
      if (isNaN(left.OPS_SHRINK_AMT / left.TOTAL_SALES_AMT)) { return 1; }
      if (isNaN(right.OPS_SHRINK_AMT / right.TOTAL_SALES_AMT)) { return -1; }
      result = left.OPS_SHRINK_AMT / left.TOTAL_SALES_AMT > right.OPS_SHRINK_AMT / right.TOTAL_SALES_AMT ? 1 : -1
    }
    if (sortDirectionSubclass === 'descending') {
      result *= -1
    }
    return result
  })
  let skuSumrySorted = skuSumDataFilter.sort((left, right) => {
    let result = left[sortColumnSku] - right[sortColumnSku]
    if (sortColumnSku === 'SHRINK_RATE_vLY_RANK_STR') {
      result = left.SHRINK_RATE_vLY_RANK_STR > right.SHRINK_RATE_vLY_RANK_STR ? 1 : -1
    }
    if (sortColumnSku === 'SKU_NBR') {
      result = left.SKU_NBR > right.SKU_NBR ? 1 : -1
    }
    if (sortColumnSku === 'DEPT_NBR') {
      result = left.DEPT_NBR > right.DEPT_NBR ? 1 : -1
    }
    if (sortColumnSku === 'TOTAL_SHRINK_AMT') {
      result = left.TOTAL_SHRINK_AMT > right.TOTAL_SHRINK_AMT ? 1 : -1
    }
    if (sortColumnSku === 'SHRINK_RATE') {
      result = left.SHRINK_RATE > right.SHRINK_RATE ? 1 : -1
    }
    if (sortColumnSku === 'SHRINK_AMT_vLY') {
      result = left.SHRINK_AMT_vLY > right.SHRINK_AMT_vLY ? 1 : -1
    }
    if (sortColumnSku === 'SHRINK_RATE_vLY') {
      result = left.SHRINK_RATE_vLY > right.SHRINK_RATE_vLY ? 1 : -1
    }
    if (sortColumnSku === 'OPS_SHRINK_AMT') {
      result = left.OPS_SHRINK_AMT > right.OPS_SHRINK_AMT ? 1 : -1
    }
    if (sortColumnSku === 'OPS_SHRINK_PER') {
      if (isNaN(left.OPS_SHRINK_AMT / left.TOTAL_SALES_AMT)) { return 1; }
      if (isNaN(right.OPS_SHRINK_AMT / right.TOTAL_SALES_AMT)) { return -1; }
      result = left.OPS_SHRINK_AMT / left.TOTAL_SALES_AMT > right.OPS_SHRINK_AMT / right.TOTAL_SALES_AMT ? 1 : -1
    }
    if (sortDirectionSku === 'descending') {
      result *= -1
    }
    return result
  })

  let dstAccRcvDataFilter = accRcvData.filter(row => {
    return row && row['METRIC_LEVEL'] === 'DST'
  })[0]

  let dataPaginationClass = classSumrySorted.slice(rowsPerPage * (pageNum - 1), parseInt(Math.min(rowsPerPage * pageNum, classSumrySorted.length)))
  let dataPaginationSubclass = subclassSumDataSorted.slice(rowsPerPage * (pageNumScls - 1), parseInt(Math.min(rowsPerPage * pageNumScls, subclassSumDataSorted.length)))

  const panes = [
    {
      menuItem: 'DRIVERS',
      render: () => <>
        <Grid>
          <Grid.Column width={8}>
            <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/district/overview?dstNbr=${dstNbr}`} data-test='b2o-link'>Back to Overview</Link>
          </Grid.Column>
          <Grid.Column width={8} textAlign='right'>
            <Link
              to={{ pathname: `https://portal.homedepot.com/sites/myapron_en_us/SitePages/Asset-Protection.aspx` }}
              style={{ color: '#EE7125', textDecoration: 'underline' }}
              target="_blank"
            >Shrink Resources</Link>
          </Grid.Column>
        </Grid>

        <Header textAlign='center'>Shrink Metrics - District {dstNbr}</Header>
        <Grid>
          <Grid.Row>
            <Grid.Column width={8} textAlign='left'>
            </Grid.Column>
            <Grid.Column width={8} textAlign='left'>
              <TimeframeDropdownShrink timeframe={timeframe} settimeframe={settimeframe} />
            </Grid.Column>

          </Grid.Row>
        </Grid>

        <Grid>
          <Grid.Column>
            <Menu size='small' widths={2}>
              <Menu.Item
                name='District'
                active={overviewMenu === 'District'}
                content='District'
                onClick={() => {
                  setOverviewMenu('District')
                }}
              />
              <Menu.Item
                name='Stores'
                active={overviewMenu === 'Stores'}
                content='Stores'
                onClick={() => {
                  setOverviewMenu('Stores')
                }}
              />
            </Menu>
          </Grid.Column>
        </Grid>

        {overviewMenu === 'District' && <Header textAlign='center'>Shrink Exec Score by Timeframe</Header>}
        {overviewMenu === 'District' && <Table textAlign='center' unstackable celled sortable size='small' style={{ fontSize: 12 }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Timeframe</Table.HeaderCell>
              <Table.HeaderCell>Total Score</Table.HeaderCell>
              <Table.HeaderCell>vLY <Popup
                on='click'
                position='bottom left'
                content='For the total Operational Shrink Execution Score, always try to perform better year-over-year.'
                trigger={<Icon name='info circle' color='blue' />}
              />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>{new Date().getDay() === 1 ? 'LW' : 'WTD'}</Table.Cell>
              <Table.Cell>{getPercentString(scoresData.filter((row) => { return row && row['TIME_FRAME'] === 'WTD' && row['METRIC_LEVEL'] === 'DST' })[0].SHRINK_EXEC_SCORE)}</Table.Cell>
              <Table.Cell style={getCompareStyle(scoresData.length > 0 ? scoresData.filter((row) => { return row && row['TIME_FRAME'] === 'WTD' && row['METRIC_LEVEL'] === 'DST' })[0].SHRINK_EXEC_SCORE - scoresData.filter((row) => { return row && row['TIME_FRAME'] === 'WTD' && row['METRIC_LEVEL'] === 'DST' })[0].LY_SHRINK_EXEC_SCORE : 0)}>
                {getPercentString(scoresData.filter((row) => { return row && row['TIME_FRAME'] === 'WTD' && row['METRIC_LEVEL'] === 'DST' })[0].SHRINK_EXEC_SCORE - scoresData.filter((row) => { return row && row['TIME_FRAME'] === 'WTD' && row['METRIC_LEVEL'] === 'DST' })[0].LY_SHRINK_EXEC_SCORE)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>{new Date().getDay() === 1 ? 'LLW' : 'LW'}</Table.Cell>
              <Table.Cell>{getPercentString(scoresData.filter((row) => { return row && row['TIME_FRAME'] === 'LW' && row['METRIC_LEVEL'] === 'DST' })[0].SHRINK_EXEC_SCORE)}</Table.Cell>
              <Table.Cell style={getCompareStyle(scoresData.length > 0 ? scoresData.filter((row) => { return row && row['TIME_FRAME'] === 'LW' && row['METRIC_LEVEL'] === 'DST' })[0].SHRINK_EXEC_SCORE - scoresData.filter((row) => { return row && row['TIME_FRAME'] === 'LW' && row['METRIC_LEVEL'] === 'DST' })[0].LY_SHRINK_EXEC_SCORE : 0)}>
                {getPercentString(scoresData.filter((row) => { return row && row['TIME_FRAME'] === 'LW' && row['METRIC_LEVEL'] === 'DST' })[0].SHRINK_EXEC_SCORE - scoresData.filter((row) => { return row && row['TIME_FRAME'] === 'LW' && row['METRIC_LEVEL'] === 'DST' })[0].LY_SHRINK_EXEC_SCORE)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>LM</Table.Cell>
              <Table.Cell>{getPercentString(scoresData.filter((row) => { return row && row['TIME_FRAME'] === 'LM' && row['METRIC_LEVEL'] === 'DST' })[0].SHRINK_EXEC_SCORE)}</Table.Cell>
              <Table.Cell style={getCompareStyle(scoresData.length > 0 ? scoresData.filter((row) => { return row && row['TIME_FRAME'] === 'LM' && row['METRIC_LEVEL'] === 'DST' })[0].SHRINK_EXEC_SCORE - scoresData.filter((row) => { return row && row['TIME_FRAME'] === 'LM' && row['METRIC_LEVEL'] === 'DST' })[0].LY_SHRINK_EXEC_SCORE : 0)}>
                {getPercentString(scoresData.filter((row) => { return row && row['TIME_FRAME'] === 'LM' && row['METRIC_LEVEL'] === 'DST' })[0].SHRINK_EXEC_SCORE - scoresData.filter((row) => { return row && row['TIME_FRAME'] === 'LM' && row['METRIC_LEVEL'] === 'DST' })[0].LY_SHRINK_EXEC_SCORE)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>MTD</Table.Cell>
              <Table.Cell>{getPercentString(scoresData.filter((row) => { return row && row['TIME_FRAME'] === 'MTD' && row['METRIC_LEVEL'] === 'DST' })[0].SHRINK_EXEC_SCORE)}</Table.Cell>
              <Table.Cell style={getCompareStyle(scoresData.length > 0 ? scoresData.filter((row) => { return row && row['TIME_FRAME'] === 'MTD' && row['METRIC_LEVEL'] === 'DST' })[0].SHRINK_EXEC_SCORE - scoresData.filter((row) => { return row && row['TIME_FRAME'] === 'MTD' && row['METRIC_LEVEL'] === 'DST' })[0].LY_SHRINK_EXEC_SCORE : 0)}>
                {getPercentString(scoresData.filter((row) => { return row && row['TIME_FRAME'] === 'MTD' && row['METRIC_LEVEL'] === 'DST' })[0].SHRINK_EXEC_SCORE - scoresData.filter((row) => { return row && row['TIME_FRAME'] === 'MTD' && row['METRIC_LEVEL'] === 'DST' })[0].LY_SHRINK_EXEC_SCORE)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>QTD</Table.Cell>
              <Table.Cell>{getPercentString(scoresData.filter((row) => { return row && row['TIME_FRAME'] === 'QTD' && row['METRIC_LEVEL'] === 'DST' })[0].SHRINK_EXEC_SCORE)}</Table.Cell>
              <Table.Cell style={getCompareStyle(scoresData.length > 0 ? scoresData.filter((row) => { return row && row['TIME_FRAME'] === 'QTD' && row['METRIC_LEVEL'] === 'DST' })[0].SHRINK_EXEC_SCORE - scoresData.filter((row) => { return row && row['TIME_FRAME'] === 'QTD' && row['METRIC_LEVEL'] === 'DST' })[0].LY_SHRINK_EXEC_SCORE : 0)}>
                {getPercentString(scoresData.filter((row) => { return row && row['TIME_FRAME'] === 'QTD' && row['METRIC_LEVEL'] === 'DST' })[0].SHRINK_EXEC_SCORE - scoresData.filter((row) => { return row && row['TIME_FRAME'] === 'QTD' && row['METRIC_LEVEL'] === 'DST' })[0].LY_SHRINK_EXEC_SCORE)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>HTD</Table.Cell>
              <Table.Cell>{getPercentString(scoresData.filter((row) => { return row && row['TIME_FRAME'] === 'HTD' && row['METRIC_LEVEL'] === 'DST' })[0].SHRINK_EXEC_SCORE)}</Table.Cell>
              <Table.Cell style={getCompareStyle(scoresData.length > 0 ? scoresData.filter((row) => { return row && row['TIME_FRAME'] === 'HTD' && row['METRIC_LEVEL'] === 'DST' })[0].SHRINK_EXEC_SCORE - scoresData.filter((row) => { return row && row['TIME_FRAME'] === 'HTD' && row['METRIC_LEVEL'] === 'DST' })[0].LY_SHRINK_EXEC_SCORE : 0)}>
                {getPercentString(scoresData.filter((row) => { return row && row['TIME_FRAME'] === 'HTD' && row['METRIC_LEVEL'] === 'DST' })[0].SHRINK_EXEC_SCORE - scoresData.filter((row) => { return row && row['TIME_FRAME'] === 'HTD' && row['METRIC_LEVEL'] === 'DST' })[0].LY_SHRINK_EXEC_SCORE)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>YTD</Table.Cell>
              <Table.Cell>{getPercentString(scoresData.filter((row) => { return row && row['TIME_FRAME'] === 'YTD' && row['METRIC_LEVEL'] === 'DST' })[0].SHRINK_EXEC_SCORE)}</Table.Cell>
              <Table.Cell style={getCompareStyle(scoresData.length > 0 ? scoresData.filter((row) => { return row && row['TIME_FRAME'] === 'YTD' && row['METRIC_LEVEL'] === 'DST' })[0].SHRINK_EXEC_SCORE - scoresData.filter((row) => { return row && row['TIME_FRAME'] === 'YTD' && row['METRIC_LEVEL'] === 'DST' })[0].LY_SHRINK_EXEC_SCORE : 0)}>
                {getPercentString(scoresData.filter((row) => { return row && row['TIME_FRAME'] === 'YTD' && row['METRIC_LEVEL'] === 'DST' })[0].SHRINK_EXEC_SCORE - scoresData.filter((row) => { return row && row['TIME_FRAME'] === 'YTD' && row['METRIC_LEVEL'] === 'DST' })[0].LY_SHRINK_EXEC_SCORE)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>L12</Table.Cell>
              <Table.Cell>{getPercentString(scoresData.filter((row) => { return row && row['TIME_FRAME'] === 'R12M' && row['METRIC_LEVEL'] === 'DST' })[0].SHRINK_EXEC_SCORE)}</Table.Cell>
              <Table.Cell style={getCompareStyle(scoresData.length > 0 ? scoresData.filter((row) => { return row && row['TIME_FRAME'] === 'R12M' && row['METRIC_LEVEL'] === 'DST' })[0].SHRINK_EXEC_SCORE - scoresData.filter((row) => { return row && row['TIME_FRAME'] === 'R12M' && row['METRIC_LEVEL'] === 'DST' })[0].LY_SHRINK_EXEC_SCORE : 0)}>
                {getPercentString(scoresData.filter((row) => { return row && row['TIME_FRAME'] === 'R12M' && row['METRIC_LEVEL'] === 'DST' })[0].SHRINK_EXEC_SCORE - scoresData.filter((row) => { return row && row['TIME_FRAME'] === 'R12M' && row['METRIC_LEVEL'] === 'DST' })[0].LY_SHRINK_EXEC_SCORE)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Since Last Inv</Table.Cell>
              <Table.Cell>{getPercentString(scoresData.filter((row) => { return row && row['TIME_FRAME'] === 'LAST_INV' && row['METRIC_LEVEL'] === 'DST' })[0].SHRINK_EXEC_SCORE)}</Table.Cell>
              <Table.Cell style={getCompareStyle(scoresData.length > 0 ? scoresData.filter((row) => { return row && row['TIME_FRAME'] === 'LAST_INV' && row['METRIC_LEVEL'] === 'DST' })[0].SHRINK_EXEC_SCORE - scoresData.filter((row) => { return row && row['TIME_FRAME'] === 'LAST_INV' && row['METRIC_LEVEL'] === 'DST' })[0].LY_SHRINK_EXEC_SCORE : 0)}>
                {getPercentString(scoresData.filter((row) => { return row && row['TIME_FRAME'] === 'LAST_INV' && row['METRIC_LEVEL'] === 'DST' })[0].SHRINK_EXEC_SCORE - scoresData.filter((row) => { return row && row['TIME_FRAME'] === 'LAST_INV' && row['METRIC_LEVEL'] === 'DST' })[0].LY_SHRINK_EXEC_SCORE)}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>}

        {overviewMenu === 'Stores' && <Header textAlign='center'>Shrink Exec Score By Store</Header>}
        {overviewMenu === 'Stores' && <Table textAlign='center' unstackable celled sortable size='small' style={{ fontSize: 12 }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                sorted={sortColumn === 'STR_NBR' ? sortDirection : null}
                onClick={() => {
                  if (sortColumn !== 'STR_NBR') {
                    setSortColumn('STR_NBR')
                    setSortDirection('ascending')
                  } else {
                    sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                  }
                }}>Store #</Table.HeaderCell>
              <Table.HeaderCell
                sorted={sortColumn === 'SHRINK_EXEC_SCORE' ? sortDirection : null}
                onClick={() => {
                  if (sortColumn !== 'SHRINK_EXEC_SCORE') {
                    setSortColumn('SHRINK_EXEC_SCORE')
                    setSortDirection('ascending')
                  } else {
                    sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                  }
                }}>Total Score</Table.HeaderCell>
              <Table.HeaderCell
                sorted={sortColumn === 'VLY' ? sortDirection : null}
                onClick={() => {
                  if (sortColumn !== 'VLY') {
                    setSortColumn('VLY')
                    setSortDirection('ascending')
                  } else {
                    sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                  }
                }}>vLY <Popup
                  on='click'
                  position='bottom left'
                  content='For the total Operational Shrink Execution Score, always try to perform better year-over-year.'
                  trigger={<Icon name='info circle' color='blue' />}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {strScoresDataSorted.map((data) => {
              return (
                <Table.Row>
                  <Table.Cell>
                    <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/store/shrink?strNbr=${data['STR_NBR']}`}>{data['STR_NBR']}</Link>
                  </Table.Cell>
                  <Table.Cell>{getPercentString(data['SHRINK_EXEC_SCORE'])}</Table.Cell>
                  <Table.Cell style={getCompareStyle(data['SHRINK_EXEC_SCORE'] - data['LY_SHRINK_EXEC_SCORE'])}>{getPercentString(data['SHRINK_EXEC_SCORE'] - data['LY_SHRINK_EXEC_SCORE'])}</Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>}

        <Header textAlign='center'>Focus Metrics</Header>
        <Table textAlign='center' unstackable celled sortable size='small' style={{ fontSize: 12 }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Metric</Table.HeaderCell>
              <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Value</Table.HeaderCell>
              <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>vLY <Popup
                on='click'
                position='bottom center'
                content='If the metric is meeting the goal, but worse than last year, there is still an opportunity for shrink exposure. Always try to meet the goal first, then strive for improvement year-over-year.'
                trigger={<Icon name='info circle' color='blue' />}
              />
              </Table.HeaderCell>
              <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Goal</Table.HeaderCell>
              <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>% of Stores Passing</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <Table.Row>
              <Table.Cell><Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/district/shrink/invalidscans?dstNbr=${dstNbr}`} data-test='b2o-link'>Invalid Scans</Link></Table.Cell>
              <Table.Cell style={getCompareStyle(dstScoresDataFiltered[0].INV_SCN_GOAL - shrinkMetricsData[timeframe + '_INVAL_SCN_NUM'] / shrinkMetricsData[timeframe + '_CORE_SLS'])}>
                {getPercentString(shrinkMetricsData[timeframe + '_INVAL_SCN_NUM'] / shrinkMetricsData[timeframe + '_CORE_SLS'], 2)}
              </Table.Cell>
              <Table.Cell style={getCompareStyle(shrinkMetricsData[timeframe + '_LY_INVAL_SCN_NUM'] / shrinkMetricsData[timeframe + '_LY_CORE_SLS'] - shrinkMetricsData[timeframe + '_INVAL_SCN_NUM'] / shrinkMetricsData[timeframe + '_CORE_SLS'])}>
                {getPercentString(shrinkMetricsData[timeframe + '_INVAL_SCN_NUM'] / shrinkMetricsData[timeframe + '_CORE_SLS'] - shrinkMetricsData[timeframe + '_LY_INVAL_SCN_NUM'] / shrinkMetricsData[timeframe + '_LY_CORE_SLS'], 2)}
              </Table.Cell>
              <Table.Cell>≤ {getPercentString(dstScoresDataFiltered[0].INV_SCN_GOAL, 2)}</Table.Cell>
              <Table.Cell>{getPercentString(dstScoresDataFiltered[0].INVALID_SCAN_SCORE)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell><Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/district/shrink/rtvcredit?dstNbr=${dstNbr}`} data-test='b2o-link'>RTV Credits</Link></Table.Cell>
              <Table.Cell style={getCompareStyle(shrinkMetricsData[timeframe + '_RTV_CREDIT_NUM'] / shrinkMetricsData[timeframe + '_RTV_CREDIT_DEN'] - dstScoresDataFiltered[0].RTV_CREDIT_GOAL)}>
                {getPercentString(shrinkMetricsData[timeframe + '_RTV_CREDIT_NUM'] / shrinkMetricsData[timeframe + '_RTV_CREDIT_DEN'], 2)}
              </Table.Cell>
              <Table.Cell style={getCompareStyle(shrinkMetricsData[timeframe + '_RTV_CREDIT_NUM'] / shrinkMetricsData[timeframe + '_RTV_CREDIT_DEN'] - shrinkMetricsData[timeframe + '_LY_RTV_CREDIT_NUM'] / shrinkMetricsData[timeframe + '_LY_RTV_CREDIT_DEN'])}>
                {getPercentString(shrinkMetricsData[timeframe + '_RTV_CREDIT_NUM'] / shrinkMetricsData[timeframe + '_RTV_CREDIT_DEN'] - shrinkMetricsData[timeframe + '_LY_RTV_CREDIT_NUM'] / shrinkMetricsData[timeframe + '_LY_RTV_CREDIT_DEN'], 2)}
              </Table.Cell>
              <Table.Cell>≥ {getPercentString(dstScoresDataFiltered[0].RTV_CREDIT_GOAL, 2)}</Table.Cell>
              <Table.Cell>{getPercentString(dstScoresDataFiltered[0].RTV_CREDIT_SCORE)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell><Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/district/shrink/cullmd?dstNbr=${dstNbr}`} data-test='b2o-link'>Cull Markdowns</Link></Table.Cell>
              <Table.Cell style={getCompareStyle(shrinkMetricsData[timeframe + '_CULL_MD_NUM'] / shrinkMetricsData[timeframe + '_CULL_MD_DEN'] - dstScoresDataFiltered[0].CULL_MD_GOAL)}>
                {getPercentString(shrinkMetricsData[timeframe + '_CULL_MD_NUM'] / shrinkMetricsData[timeframe + '_CULL_MD_DEN'], 2)}
              </Table.Cell>
              <Table.Cell style={getCompareStyle(shrinkMetricsData[timeframe + '_CULL_MD_NUM'] / shrinkMetricsData[timeframe + '_CULL_MD_DEN'] - shrinkMetricsData[timeframe + '_LY_CULL_MD_NUM'] / shrinkMetricsData[timeframe + '_LY_CULL_MD_DEN'])}>
                {getPercentString(shrinkMetricsData[timeframe + '_CULL_MD_NUM'] / shrinkMetricsData[timeframe + '_CULL_MD_DEN'] - shrinkMetricsData[timeframe + '_LY_CULL_MD_NUM'] / shrinkMetricsData[timeframe + '_LY_CULL_MD_DEN'], 2)}
              </Table.Cell>
              <Table.Cell>≥ {getPercentString(dstScoresDataFiltered[0].CULL_MD_GOAL, 2)}</Table.Cell>
              <Table.Cell>{getPercentString(dstScoresDataFiltered[0].CULL_MD_SCORE)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell><Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/district/shrink/clearancenh?dstNbr=${dstNbr}`} data-test='b2o-link'>Clearance No-Homes</Link></Table.Cell>
              <Table.Cell style={getCompareStyle(dstScoresDataFiltered[0].CLRNH_GOAL - shrinkMetricsData[timeframe + '_CLRNH_NUM'] / shrinkMetricsData[timeframe + '_CLRNH_DEN'])}>
                {getPercentString(shrinkMetricsData[timeframe + '_CLRNH_NUM'] / shrinkMetricsData[timeframe + '_CLRNH_DEN'], 2)}
              </Table.Cell>
              <Table.Cell style={getCompareStyle(shrinkMetricsData[timeframe + '_LY_CLRNH_NUM'] / shrinkMetricsData[timeframe + '_LY_CLRNH_DEN'] - shrinkMetricsData[timeframe + '_CLRNH_NUM'] / shrinkMetricsData[timeframe + '_CLRNH_DEN'])}>
                {getPercentString(shrinkMetricsData[timeframe + '_CLRNH_NUM'] / shrinkMetricsData[timeframe + '_CLRNH_DEN'] - shrinkMetricsData[timeframe + '_LY_CLRNH_NUM'] / shrinkMetricsData[timeframe + '_LY_CLRNH_DEN'], 2)}
              </Table.Cell>
              <Table.Cell>≤ {getPercentString(dstScoresDataFiltered[0].CLRNH_GOAL, 2)}</Table.Cell>
              <Table.Cell>{getPercentString(dstScoresDataFiltered[0].CLEAR_NH_SCORE)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell><Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/district/shrink/damagedmd?dstNbr=${dstNbr}`} data-test='b2o-link'>Damaged Markdowns</Link></Table.Cell>
              <Table.Cell style={getCompareStyle(shrinkMetricsData[timeframe + '_DAMAGED_MD_NUM'] / shrinkMetricsData[timeframe + '_CORE_SLS'] - dstScoresDataFiltered[0].DAMAGED_MD_GOAL)}>
                {getPercentString(shrinkMetricsData[timeframe + '_DAMAGED_MD_NUM'] / shrinkMetricsData[timeframe + '_CORE_SLS'], 2)}
              </Table.Cell>
              <Table.Cell style={getCompareStyle(shrinkMetricsData[timeframe + '_DAMAGED_MD_NUM'] / shrinkMetricsData[timeframe + '_CORE_SLS'] - shrinkMetricsData[timeframe + '_LY_DAMAGED_MD_NUM'] / shrinkMetricsData[timeframe + '_LY_CORE_SLS'])}>
                {getPercentString(shrinkMetricsData[timeframe + '_DAMAGED_MD_NUM'] / shrinkMetricsData[timeframe + '_CORE_SLS'] - shrinkMetricsData[timeframe + '_LY_DAMAGED_MD_NUM'] / shrinkMetricsData[timeframe + '_LY_CORE_SLS'], 2)}
              </Table.Cell>
              <Table.Cell>≥ {getPercentString(dstScoresDataFiltered[0].DAMAGED_MD_GOAL, 2)}</Table.Cell>
              <Table.Cell>{getPercentString(dstScoresDataFiltered[0].DAMAGED_MD_SCORE)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell><Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/district/shrink/agedwc?dstNbr=${dstNbr}`} data-test='b2o-link'>Aged Will Calls</Link></Table.Cell>
              <Table.Cell style={getCompareStyle(dstScoresDataFiltered[0].AGED_WC_GOAL - shrinkMetricsData[timeframe + '_AGED_WC_NUM'] / shrinkMetricsData[timeframe + '_AGED_WC_DEN'])}>
                {getPercentString(shrinkMetricsData[timeframe + '_AGED_WC_NUM'] / shrinkMetricsData[timeframe + '_AGED_WC_DEN'], 2)}
              </Table.Cell>
              <Table.Cell style={getCompareStyle(shrinkMetricsData[timeframe + '_LY_AGED_WC_NUM'] / shrinkMetricsData[timeframe + '_LY_AGED_WC_DEN'] - shrinkMetricsData[timeframe + '_AGED_WC_NUM'] / shrinkMetricsData[timeframe + '_AGED_WC_DEN'])}>
                {getPercentString(shrinkMetricsData[timeframe + '_AGED_WC_NUM'] / shrinkMetricsData[timeframe + '_AGED_WC_DEN'] - shrinkMetricsData[timeframe + '_LY_AGED_WC_NUM'] / shrinkMetricsData[timeframe + '_LY_AGED_WC_DEN'], 2)}
              </Table.Cell>
              <Table.Cell>≤ {getPercentString(dstScoresDataFiltered[0].AGED_WC_GOAL, 2)}</Table.Cell>
              <Table.Cell>{getPercentString(dstScoresDataFiltered[0].AGED_WC_SCORE)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell><b>Total Score</b></Table.Cell>
              <Table.Cell>-</Table.Cell>
              <Table.Cell>-</Table.Cell>
              <Table.Cell>-</Table.Cell>
              <Table.Cell><b>{getPercentString(dstScoresDataFiltered[0].SHRINK_EXEC_SCORE)}</b></Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        <Header textAlign='center'>Monitor Metrics</Header>
        <Table textAlign='center' unstackable celled sortable size='small' style={{ fontSize: 12 }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Metric</Table.HeaderCell>
              <Table.HeaderCell>Value</Table.HeaderCell>
              <Table.HeaderCell>vLY <Popup
                on='click'
                position='bottom left'
                content='Although there are no goals for the Monitor Metrics, it is important to continually strive for improvement compared to last year.'
                trigger={<Icon name='info circle' color='blue' />}
              />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <Table.Row>
              <Table.Cell><Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/district/shrink/rtvdeletedtag?dstNbr=${dstNbr}`} data-test='b2o-link'>RTV Deleted Tags</Link></Table.Cell>
              <Table.Cell>
                {getPercentString(shrinkMetricsData[timeframe + '_RTV_DEL_NUM'] / shrinkMetricsData[timeframe + '_RTV_DEL_DEN'], 2)}
              </Table.Cell>
              <Table.Cell style={getCompareStyle(shrinkMetricsData[timeframe + '_LY_RTV_DEL_NUM'] / shrinkMetricsData[timeframe + '_LY_RTV_DEL_DEN'] - shrinkMetricsData[timeframe + '_RTV_DEL_NUM'] / shrinkMetricsData[timeframe + '_RTV_DEL_DEN'])}>
                {getPercentString(shrinkMetricsData[timeframe + '_RTV_DEL_NUM'] / shrinkMetricsData[timeframe + '_RTV_DEL_DEN'] - shrinkMetricsData[timeframe + '_LY_RTV_DEL_NUM'] / shrinkMetricsData[timeframe + '_LY_RTV_DEL_DEN'], 2)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell><Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/district/shrink/reducedtag?dstNbr=${dstNbr}`} data-test='b2o-link'>Reduced Tags</Link></Table.Cell>
              <Table.Cell>
                {getPercentString(shrinkMetricsData[timeframe + '_REDUCED_TAG_NUM'] / shrinkMetricsData[timeframe + '_CORE_SLS'], 2)}
              </Table.Cell>
              <Table.Cell style={getCompareStyle(shrinkMetricsData[timeframe + '_LY_REDUCED_TAG_NUM'] / shrinkMetricsData[timeframe + '_LY_CORE_SLS'] - shrinkMetricsData[timeframe + '_REDUCED_TAG_NUM'] / shrinkMetricsData[timeframe + '_CORE_SLS'])}>
                {getPercentString(shrinkMetricsData[timeframe + '_REDUCED_TAG_NUM'] / shrinkMetricsData[timeframe + '_CORE_SLS'] - shrinkMetricsData[timeframe + '_LY_REDUCED_TAG_NUM'] / shrinkMetricsData[timeframe + '_LY_CORE_SLS'], 2)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell><Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/district/shrink/bosscancel?dstNbr=${dstNbr}`} data-test='b2o-link'>BOSS RTV %</Link></Table.Cell>
              <Table.Cell>
                {getPercentString(shrinkMetricsData[timeframe + '_BOSS_CNCL_NUM'] / shrinkMetricsData[timeframe + '_BOSS_CNCL_DEN'], 2)}
              </Table.Cell>
              <Table.Cell style={getCompareStyle(shrinkMetricsData[timeframe + '_BOSS_CNCL_NUM'] / shrinkMetricsData[timeframe + '_BOSS_CNCL_DEN'] - shrinkMetricsData[timeframe + '_LY_BOSS_CNCL_NUM'] / shrinkMetricsData[timeframe + '_LY_BOSS_CNCL_DEN'])}>
                {getPercentString(shrinkMetricsData[timeframe + '_BOSS_CNCL_NUM'] / shrinkMetricsData[timeframe + '_BOSS_CNCL_DEN'] - shrinkMetricsData[timeframe + '_LY_BOSS_CNCL_NUM'] / shrinkMetricsData[timeframe + '_LY_BOSS_CNCL_DEN'], 2)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell><Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/district/shrink/ohemptpkg?dstNbr=${dstNbr}`} data-test='b2o-link'>On Hand Adjustments & Empty Packages</Link></Table.Cell>
              <Table.Cell>
                {getPercentString(shrinkMetricsData[timeframe + '_OH_EMPT_PKG_NUM'] / shrinkMetricsData[timeframe + '_OH_EMPT_PKG_DEN'], 2)}
              </Table.Cell>
              <Table.Cell style={getCompareStyle(shrinkMetricsData[timeframe + '_LY_OH_EMPT_PKG_NUM'] / shrinkMetricsData[timeframe + '_LY_OH_EMPT_PKG_DEN'] - shrinkMetricsData[timeframe + '_OH_EMPT_PKG_NUM'] / shrinkMetricsData[timeframe + '_OH_EMPT_PKG_DEN'])}>
                {getPercentString(shrinkMetricsData[timeframe + '_OH_EMPT_PKG_NUM'] / shrinkMetricsData[timeframe + '_OH_EMPT_PKG_DEN'] - shrinkMetricsData[timeframe + '_LY_OH_EMPT_PKG_NUM'] / shrinkMetricsData[timeframe + '_LY_OH_EMPT_PKG_DEN'], 2)}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        <Header textAlign='center'>Accuracy & Receiving</Header>
        <Table textAlign='center' unstackable celled sortable size='small' style={{ fontSize: 12 }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Metric</Table.HeaderCell>
              <Table.HeaderCell>Value</Table.HeaderCell>
              <Table.HeaderCell>Goal</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell><Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/district/ohautorej?dstNbr=${dstNbr}`} >OH-Auto Rej Rate</Link></Table.Cell>
              <Table.Cell style={{ color: dstAccRcvDataFilter[timeframe.replace('R12M', 'L12') + '_OH_AUTO_RJT_CNT'] / dstAccRcvDataFilter[timeframe.replace('R12M', 'L12') + '_OH_NEED_APPRV_CNT'] > 0.03 ? 'red' : 'green' }}>{getPercentString(dstAccRcvDataFilter[timeframe.replace('R12M', 'L12') + '_OH_AUTO_RJT_CNT'] > 0 ? dstAccRcvDataFilter[timeframe.replace('R12M', 'L12') + '_OH_AUTO_RJT_CNT'] / dstAccRcvDataFilter[timeframe.replace('R12M', 'L12') + '_OH_NEED_APPRV_CNT'] : 0)}</Table.Cell>
              <Table.Cell>&lt;3%</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell><Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/district/autokr?dstNbr=${dstNbr}`} >Auto Key-recs</Link></Table.Cell>
              <Table.Cell style={{ color: dstAccRcvDataFilter[timeframe.replace('R12M', 'L12') + '_AUTO_KR_COUNT'] > 0 ? 'red' : 'green' }}>{dstAccRcvDataFilter[timeframe.replace('R12M', 'L12').replace('R12M', 'L12') + '_AUTO_KR_COUNT'] > 0 ? dstAccRcvDataFilter[timeframe.replace('R12M', 'L12') + '_AUTO_KR_COUNT'] : 0}</Table.Cell>
              <Table.Cell>0</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell><Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/district/unmatchedxfers?dstNbr=${dstNbr}`}>Unmatched Transfers</Link></Table.Cell>
              <Table.Cell style={{ color: dstAccRcvDataFilter[timeframe.replace('R12M', 'L12') + '_UNMATCHED_XFER_CNT'] > 0 ? 'red' : 'green' }}>{dstAccRcvDataFilter[timeframe.replace('R12M', 'L12') + '_UNMATCHED_XFER_CNT'] > 0 ? dstAccRcvDataFilter[timeframe.replace('R12M', 'L12') + '_UNMATCHED_XFER_CNT'] : 0}</Table.Cell>
              <Table.Cell>0</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </>
      ,
    },
    {
      menuItem: 'SUMMARY',
      render: () => <>
        {/*    ___  _    _       _    _     ___                                
            |   \(_)__| |_ _ _(_)__| |_  / __|_  _ _ __  _ __  __ _ _ _ _  _ 
            | |) | (_-<  _| '_| / _|  _| \__ \ || | '  \| '  \/ _` | '_| || |
            |___/|_/__/\__|_| |_\__|\__| |___/\_,_|_|_|_|_|_|_\__,_|_|  \_, |
                                                                        |__/   
    */}
        <Header textAlign='center'>District Summary</Header>
        <Grid.Row>Units:</Grid.Row>
        <Menu compact size='small'>
          <UnitMenu unit={storeSumUnits} setUnit={setStoreSumUnits} />
        </Menu>
        <Table textAlign='center' unstackable celled size='small'>
          <Table.Header>
            <Table.Row>
              {storeSumUnits === 'NOM' && <Table.HeaderCell>Shrink $</Table.HeaderCell>}
              {storeSumUnits === 'PCT' && <Table.HeaderCell>Shrink %</Table.HeaderCell>}
              <Table.HeaderCell>vGoal</Table.HeaderCell>
              <Table.HeaderCell>vLY</Table.HeaderCell>
              {storeSumUnits === 'NOM' && <Table.HeaderCell>Ops Shrink $</Table.HeaderCell>}
              {storeSumUnits === 'PCT' && <Table.HeaderCell>Ops Shrink %</Table.HeaderCell>}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              {storeSumUnits === 'NOM' && <Table.Cell>{getAdjustableDollarString(storeSumData.TOTAL_SHRINK_AMT, 2)}</Table.Cell>}
              {storeSumUnits === 'PCT' && <Table.Cell>{getPercentString(storeSumData.TOTAL_SHRINK_AMT / storeSumData.TOTAL_SALES_AMT, 2, 2)}</Table.Cell>}
              {storeSumUnits === 'NOM' && <Table.Cell>{getAdjustableDollarString(storeSumData.TOTAL_SHRINK_AMT - (storeSumData.LI_SHRINK_AMT_GOAL / storeSumData.LI_SALES_AMT_GOAL) * storeSumData.TOTAL_SALES_AMT, 2)}</Table.Cell>}
              {storeSumUnits === 'PCT' && <Table.Cell>{getPercentString(storeSumData.TOTAL_SHRINK_AMT / storeSumData.TOTAL_SALES_AMT - storeSumData.LI_SHRINK_AMT_GOAL / storeSumData.LI_SALES_AMT_GOAL, 2, 2)}</Table.Cell>}
              {storeSumUnits === 'NOM' && <Table.Cell>{getAdjustableDollarString(storeSumData.TOTAL_SHRINK_AMT - storeSumData.LY_TOTAL_SHRINK_AMT, 2)}</Table.Cell>}
              {storeSumUnits === 'PCT' && <Table.Cell>{getPercentString(storeSumData.TOTAL_SHRINK_AMT / storeSumData.TOTAL_SALES_AMT - storeSumData.LY_TOTAL_SHRINK_AMT / storeSumData.LY_TOTAL_SALES_AMT, 2, 2)}</Table.Cell>}
              {storeSumUnits === 'NOM' && <Table.Cell>{getAdjustableDollarString(storeSumData.OPS_SHRINK_AMT, 2)}</Table.Cell>}
              {storeSumUnits === 'PCT' && <Table.Cell>{getPercentString(storeSumData.OPS_SHRINK_AMT / storeSumData.TOTAL_SALES_AMT, 2, 2)}</Table.Cell>}
            </Table.Row>
          </Table.Body>
        </Table>
        <Tab menu={{ attached: false, pointing: false, secondary: false, borderless: false, compact: true, size: 'small', widths: 4 }} panes={tabs} />
      </>
    },
  ]

  const tabs = [
    {
      menuItem: 'DEPT',
      render: () => <Tab.Pane attached={false}>
        {/* ____  ____  ____   __   ____  ____  _  _  ____  __ _  ____    ____  _  _  _  _  _  _   __   ____  _  _ 
           (    \(  __)(  _ \ / _\ (  _ \(_  _)( \/ )(  __)(  ( \(_  _)  / ___)/ )( \( \/ )( \/ ) / _\ (  _ \( \/ )
            ) D ( ) _)  ) __//    \ )   /  )(  / \/ \ ) _) /    /  )(    \___ \) \/ (/ \/ \/ \/ \/    \ )   / )  / 
           (____/(____)(__)  \_/\_/(__\_) (__) \_)(_/(____)\_)__) (__)   (____/\____/\_)(_/\_)(_/\_/\_/(__\_)(__/   */}
        <Header textAlign='center'>Department Summary</Header>
        <Grid.Row>Units:</Grid.Row>
        <Menu compact size='small'>
          <UnitMenu unit={deptSumUnits} setUnit={setDeptSumUnits} />
        </Menu>
        <Table textAlign='center' unstackable celled sortable size='small'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                sorted={sortColumnDept === 'DEPT_NBR' ? sortDirectionDept : null}
                onClick={() => {
                  if (sortColumnDept !== 'DEPT_NBR') {
                    setSortColumnDept('DEPT_NBR')
                    setSortDirectionDept('ascending')
                  } else {
                    sortDirectionDept === 'ascending' ? setSortDirectionDept('descending') : setSortDirectionDept('ascending')
                  }
                }}
              >Dept {sortColumnDept !== 'DEPT_NBR' && <Icon fitted name='sort' />}</Table.HeaderCell>
              {deptSumUnits === 'NOM' && <Table.HeaderCell
                style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                sorted={sortColumnDept === 'TOTAL_SHRINK_AMT' ? sortDirectionDept : null}
                onClick={() => {
                  if (sortColumnDept !== 'TOTAL_SHRINK_AMT') {
                    setSortColumnDept('TOTAL_SHRINK_AMT')
                    setSortDirectionDept('ascending')
                  } else {
                    sortDirectionDept === 'ascending' ? setSortDirectionDept('descending') : setSortDirectionDept('ascending')
                  }
                }}
              >Shrink $ {sortColumnDept !== 'TOTAL_SHRINK_AMT' && <Icon fitted name='sort' />}</Table.HeaderCell>}
              {deptSumUnits === 'PCT' && <Table.HeaderCell
                style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                sorted={sortColumnDept === 'TOTAL_SALES_AMT' ? sortDirectionDept : null}
                onClick={() => {
                  if (sortColumnDept !== 'TOTAL_SALES_AMT') {
                    setSortColumnDept('TOTAL_SALES_AMT')
                    setSortDirectionDept('ascending')
                  } else {
                    sortDirectionDept === 'ascending' ? setSortDirectionDept('descending') : setSortDirectionDept('ascending')
                  }
                }}
              >Shrink % {sortColumnDept !== 'TOTAL_SALES_AMT' && <Icon fitted name='sort' />}</Table.HeaderCell>}
              {deptSumUnits === 'NOM' && <Table.HeaderCell
                style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                sorted={sortColumnDept === 'LY_TOTAL_SHRINK_AMT' ? sortDirectionDept : null}
                onClick={() => {
                  if (sortColumnDept !== 'LY_TOTAL_SHRINK_AMT') {
                    setSortColumnDept('LY_TOTAL_SHRINK_AMT')
                    setSortDirectionDept('ascending')
                  } else {
                    sortDirectionDept === 'ascending' ? setSortDirectionDept('descending') : setSortDirectionDept('ascending')
                  }
                }}
              >vLY $ {sortColumnDept !== 'LY_TOTAL_SHRINK_AMT' && <Icon fitted name='sort' />}</Table.HeaderCell>}
              {deptSumUnits === 'PCT' && <Table.HeaderCell
                style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                sorted={sortColumnDept === 'LY_TOTAL_SALES_AMT' ? sortDirectionDept : null}
                onClick={() => {
                  if (sortColumnDept !== 'LY_TOTAL_SALES_AMT') {
                    setSortColumnDept('LY_TOTAL_SALES_AMT')
                    setSortDirectionDept('ascending')
                  } else {
                    sortDirectionDept === 'ascending' ? setSortDirectionDept('descending') : setSortDirectionDept('ascending')
                  }
                }}
              >vLY % {sortColumnDept !== 'LY_TOTAL_SALES_AMT' && <Icon fitted name='sort' />}</Table.HeaderCell>}
              {deptSumUnits === 'NOM' && <Table.HeaderCell
                style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                sorted={sortColumnDept === 'OPS_SHRINK_AMT' ? sortDirectionDept : null}
                onClick={() => {
                  if (sortColumnDept !== 'OPS_SHRINK_AMT') {
                    setSortColumnDept('OPS_SHRINK_AMT')
                    setSortDirectionDept('ascending')
                  } else {
                    sortDirectionDept === 'ascending' ? setSortDirectionDept('descending') : setSortDirectionDept('ascending')
                  }
                }}
              >Ops Shrink $ {sortColumnDept !== 'OPS_SHRINK_AMT' && <Icon fitted name='sort' />}</Table.HeaderCell>}
              {deptSumUnits === 'PCT' && <Table.HeaderCell
                style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                sorted={sortColumnDept === 'MAL_SHRINK_AMT' ? sortDirectionDept : null}
                onClick={() => {
                  if (sortColumnDept !== 'MAL_SHRINK_AMT') {
                    setSortColumnDept('MAL_SHRINK_AMT')
                    setSortDirectionDept('ascending')
                  } else {
                    sortDirectionDept === 'ascending' ? setSortDirectionDept('descending') : setSortDirectionDept('ascending')
                  }
                }}
              >Ops Shrink % {sortColumnDept !== 'MAL_SHRINK_AMT' && <Icon fitted name='sort' />}</Table.HeaderCell>}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {deptSumrySorted.map(data => {
              return (
                <Table.Row>
                  <Table.Cell>
                    <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/district/shrink/dept?dstNbr=${dstNbr}&deptNbr=${data['DEPT_NBR']}`}>
                      {data.DEPT_NBR} - {data.DEPT_NM.replaceAll('/', '/\u200b')}
                    </Link>
                  </Table.Cell>
                  {deptSumUnits === 'NOM' && <Table.Cell>{getDollarString(data.TOTAL_SHRINK_AMT)}</Table.Cell>}
                  {deptSumUnits === 'PCT' && <Table.Cell>{getPercentString(data.TOTAL_SHRINK_AMT / data.TOTAL_SALES_AMT, 2, 2)}</Table.Cell>}
                  {deptSumUnits === 'NOM' && <Table.Cell>{getDollarString(data.TOTAL_SHRINK_AMT - data.LY_TOTAL_SHRINK_AMT)}</Table.Cell>}
                  {deptSumUnits === 'PCT' && <Table.Cell>{getPercentString(data.TOTAL_SHRINK_AMT / data.TOTAL_SALES_AMT - data.LY_TOTAL_SHRINK_AMT / data.LY_TOTAL_SALES_AMT, 2, 2)}</Table.Cell>}
                  {deptSumUnits === 'NOM' && <Table.Cell>{getDollarString(data.OPS_SHRINK_AMT)}</Table.Cell>}
                  {deptSumUnits === 'PCT' && <Table.Cell>{getPercentString(data.OPS_SHRINK_AMT / data.TOTAL_SALES_AMT, 2, 2)}</Table.Cell>}
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      </Tab.Pane>,
    },
    {
      menuItem: 'CLASS',
      render: () => <Tab.Pane attached={false}>
        {/*    ___ _      _   ___ ___ ___ ___ 
              / __| |    /_\ / __/ __| __/ __|
             | (__| |__ / _ \\__ \__ \ _|\__ \
              \___|____/_/ \_\___/___/___|___/*/}
        <Header textAlign='center'>Classes Summary</Header>
        <Grid Column={3}>
          <Grid.Row>
            <Grid.Column width={8} >
              <Grid.Row>Units:</Grid.Row>
              <Menu compact size='small'>
                <UnitMenu unit={classSumUnits} setUnit={setClassSumUnits} />
              </Menu>
            </Grid.Column>
            <Grid.Column width={4}></Grid.Column>
            <Grid.Column textAlign='right' width={4}>
              Dept:
              <div>
                <select
                  name="depts"
                  onChange={e => {
                    setDeptNbr(e.target.value)
                  }}
                  value={deptNbr}
                  style={{ padding: 10 }}
                >
                  <option value="All" selected>All</option>
                  {depts.map((row) => {
                    return <option value={row}>{row}</option>
                  })}
                </select>
              </div>
            </Grid.Column>
          </Grid.Row>
          {classSumrySorted.length > rowsPerPage ? <Grid.Row>
            <Grid.Column width={16} textAlign='center'>
              <Pagination
                size='mini'
                defaultActivePage={1}
                totalPages={Math.floor((classSumrySorted.length - 1) / rowsPerPage) + 1}
                activePage={pageNum}
                boundaryRange={0}
                siblingRange={1}
                onPageChange={(e, { activePage }) => {
                  setPageNum(activePage)
                }}
              />
            </Grid.Column>
          </Grid.Row> : <Grid.Row></Grid.Row>}
        </Grid>
        <Table textAlign='center' unstackable celled sortable size='small'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                sorted={sortColumnClass === 'EXT_CLASS_NBR' ? sortDirectionClass : null}
                onClick={() => {
                  if (sortColumnClass !== 'EXT_CLASS_NBR') {
                    setSortColumnClass('EXT_CLASS_NBR')
                    setSortDirectionClass('ascending')
                  } else {
                    sortDirectionClass === 'ascending' ? setSortDirectionClass('descending') : setSortDirectionClass('ascending')
                  }
                }}
              >Class {sortColumnClass !== 'EXT_CLASS_NBR' && <Icon fitted name='sort' />}</Table.HeaderCell>
              {classSumUnits === 'NOM' && <Table.HeaderCell
                style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                sorted={sortColumnClass === 'TOTAL_SHRINK_AMT' ? sortDirectionClass : null}
                onClick={() => {
                  if (sortColumnClass !== 'TOTAL_SHRINK_AMT') {
                    setSortColumnClass('TOTAL_SHRINK_AMT')
                    setSortDirectionClass('ascending')
                  } else {
                    sortDirectionClass === 'ascending' ? setSortDirectionClass('descending') : setSortDirectionClass('ascending')
                  }
                }}
              >Shrink $ {sortColumnClass !== 'TOTAL_SHRINK_AMT' && <Icon fitted name='sort' />}</Table.HeaderCell>}
              {classSumUnits === 'PCT' && <Table.HeaderCell
                style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                sorted={sortColumnClass === 'TOTAL_SALES_AMT' ? sortDirectionClass : null}
                onClick={() => {
                  if (sortColumnClass !== 'TOTAL_SALES_AMT') {
                    setSortColumnClass('TOTAL_SALES_AMT')
                    setSortDirectionClass('ascending')
                  } else {
                    sortDirectionClass === 'ascending' ? setSortDirectionClass('descending') : setSortDirectionClass('ascending')
                  }
                }}
              >Shrink % {sortColumnClass !== 'TOTAL_SALES_AMT' && <Icon fitted name='sort' />}</Table.HeaderCell>}
              {classSumUnits === 'NOM' && <Table.HeaderCell
                style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                sorted={sortColumnClass === 'LY_TOTAL_SHRINK_AMT' ? sortDirectionClass : null}
                onClick={() => {
                  if (sortColumnClass !== 'LY_TOTAL_SHRINK_AMT') {
                    setSortColumnClass('LY_TOTAL_SHRINK_AMT')
                    setSortDirectionClass('ascending')
                  } else {
                    sortDirectionClass === 'ascending' ? setSortDirectionClass('descending') : setSortDirectionClass('ascending')
                  }
                }}
              >vLY $ {sortColumnClass !== 'LY_TOTAL_SHRINK_AMT' && <Icon fitted name='sort' />}</Table.HeaderCell>}
              {classSumUnits === 'PCT' && <Table.HeaderCell
                style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                sorted={sortColumnClass === 'LY_TOTAL_SALES_AMT' ? sortDirectionClass : null}
                onClick={() => {
                  if (sortColumnClass !== 'LY_TOTAL_SALES_AMT') {
                    setSortColumnClass('LY_TOTAL_SALES_AMT')
                    setSortDirectionClass('ascending')
                  } else {
                    sortDirectionClass === 'ascending' ? setSortDirectionClass('descending') : setSortDirectionClass('ascending')
                  }
                }}
              >vLY % {sortColumnClass !== 'LY_TOTAL_SALES_AMT' && <Icon fitted name='sort' />}</Table.HeaderCell>}
              {classSumUnits === 'NOM' && <Table.HeaderCell
                style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                sorted={sortColumnClass === 'OPS_SHRINK_AMT' ? sortDirectionClass : null}
                onClick={() => {
                  if (sortColumnClass !== 'OPS_SHRINK_AMT') {
                    setSortColumnClass('OPS_SHRINK_AMT')
                    setSortDirectionClass('ascending')
                  } else {
                    sortDirectionClass === 'ascending' ? setSortDirectionClass('descending') : setSortDirectionClass('ascending')
                  }
                }}
              >Ops Shrink $ {sortColumnClass !== 'OPS_SHRINK_AMT' && <Icon fitted name='sort' />}</Table.HeaderCell>}
              {classSumUnits === 'PCT' && <Table.HeaderCell
                style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                sorted={sortColumnClass === 'OPS_SALES_AMT' ? sortDirectionClass : null}
                onClick={() => {
                  if (sortColumnClass !== 'OPS_SALES_AMT') {
                    setSortColumnClass('OPS_SALES_AMT')
                    setSortDirectionClass('ascending')
                  } else {
                    sortDirectionClass === 'ascending' ? setSortDirectionClass('descending') : setSortDirectionClass('ascending')
                  }
                }}
              >Ops Shrink % {sortColumnClass !== 'OPS_SALES_AMT' && <Icon fitted name='sort' />}</Table.HeaderCell>}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {dataPaginationClass.map(data => {
              return (
                <Table.Row>
                  <Table.Cell>
                    <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/district/shrink/class?dstNbr=${dstNbr}&classNbr=${data['EXT_CLASS_NBR']}`}>
                      {data.EXT_CLASS_NBR} - {data.CLASS_DESC.replaceAll('/', '/\u200b')}
                    </Link>
                  </Table.Cell>
                  {classSumUnits === 'NOM' && <Table.Cell>{getDollarString(data.TOTAL_SHRINK_AMT)}</Table.Cell>}
                  {classSumUnits === 'PCT' && <Table.Cell>{getPercentString(data.TOTAL_SHRINK_AMT / data.TOTAL_SALES_AMT, 2, 2)}</Table.Cell>}
                  {classSumUnits === 'NOM' && <Table.Cell>{getDollarString(data.TOTAL_SHRINK_AMT - data.LY_TOTAL_SHRINK_AMT)}</Table.Cell>}
                  {classSumUnits === 'PCT' && <Table.Cell>{getPercentString(data.TOTAL_SHRINK_AMT / data.TOTAL_SALES_AMT - data.LY_TOTAL_SHRINK_AMT / data.LY_TOTAL_SALES_AMT, 2, 2)}</Table.Cell>}
                  {classSumUnits === 'NOM' && <Table.Cell>{getDollarString(data.OPS_SHRINK_AMT)}</Table.Cell>}
                  {classSumUnits === 'PCT' && <Table.Cell>{getPercentString(data.OPS_SHRINK_AMT / data.TOTAL_SALES_AMT, 2, 2)}</Table.Cell>}
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      </Tab.Pane>,
    },
    {
      menuItem: 'SUBCLASS',
      render: () => <Tab.Pane attached={false}>
        {/*  ___ _   _ ___      ___ _      _   ___ ___ 
            / __| | | | _ )___ / __| |    /_\ / __/ __|
            \__ \ |_| | _ \___| (__| |__ / _ \\__ \__ \
            |___/\___/|___/    \___|____/_/ \_\___/___/*/}
        <Header textAlign='center'>Subclasses Summary</Header>
        <Grid Column={3}>
          <Grid.Row>
            <Grid.Column width={8} >
              <Grid.Row>Units:</Grid.Row>
              <Menu compact size='small'>
                <UnitMenu unit={subCSumUnits} setUnit={setSubCSumUnits} />
              </Menu>
            </Grid.Column>
            <Grid.Column width={4}></Grid.Column>
            <Grid.Column textAlign='right' width={4}>
              Dept:
              <div>
                <select
                  name="depts"
                  onChange={e => {
                    setDeptNbr(e.target.value)
                  }}
                  value={deptNbr}
                  style={{ padding: 10 }}
                >
                  <option value="All" selected>All</option>
                  {depts.map((row) => {
                    return <option value={row}>{row}</option>
                  })}
                </select>
              </div>
            </Grid.Column>
          </Grid.Row>
          {subclassSumDataSorted.length > rowsPerPage ? <Grid.Row>
            <Grid.Column width={16} textAlign='center'>
              <Pagination
                size='mini'
                defaultActivePage={1}
                totalPages={Math.floor((subclassSumDataSorted.length - 1) / rowsPerPage) + 1}
                activePage={pageNumScls}
                boundaryRange={0}
                siblingRange={1}
                onPageChange={(e, { activePage }) => {
                  setPageNumScls(activePage)
                }}
              />
            </Grid.Column>
          </Grid.Row> : <Grid.Row></Grid.Row>}
        </Grid>
        <Table textAlign='center' unstackable celled sortable size='small'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                sorted={sortColumnSubclass === 'EXT_SUB_CLASS_NBR' ? sortDirectionSubclass : null}
                onClick={() => {
                  if (sortColumnSubclass !== 'EXT_SUB_CLASS_NBR') {
                    setSortColumnSubclass('EXT_SUB_CLASS_NBR')
                    setSortDirectionSubclass('ascending')
                  } else {
                    sortDirectionSubclass === 'ascending' ? setSortDirectionSubclass('descending') : setSortDirectionSubclass('ascending')
                  }
                }}
              >Subclasses {sortColumnSubclass !== 'EXT_SUB_CLASS_NBR' && <Icon fitted name='sort' />}</Table.HeaderCell>
              {subCSumUnits === 'NOM' && <Table.HeaderCell
                style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                sorted={sortColumnSubclass === 'TOTAL_SHRINK_AMT' ? sortDirectionSubclass : null}
                onClick={() => {
                  if (sortColumnSubclass !== 'TOTAL_SHRINK_AMT') {
                    setSortColumnSubclass('TOTAL_SHRINK_AMT')
                    setSortDirectionSubclass('ascending')
                  } else {
                    sortDirectionSubclass === 'ascending' ? setSortDirectionSubclass('descending') : setSortDirectionSubclass('ascending')
                  }
                }}
              >Shrink $ {sortColumnSubclass !== 'TOTAL_SHRINK_AMT' && <Icon fitted name='sort' />}</Table.HeaderCell>}
              {subCSumUnits === 'PCT' && <Table.HeaderCell
                style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                sorted={sortColumnSubclass === 'TOTAL_SALES_AMT' ? sortDirectionSubclass : null}
                onClick={() => {
                  if (sortColumnSubclass !== 'TOTAL_SALES_AMT') {
                    setSortColumnSubclass('TOTAL_SALES_AMT')
                    setSortDirectionSubclass('ascending')
                  } else {
                    sortDirectionSubclass === 'ascending' ? setSortDirectionSubclass('descending') : setSortDirectionSubclass('ascending')
                  }
                }}
              >Shrink % {sortColumnSubclass !== 'TOTAL_SALES_AMT' && <Icon fitted name='sort' />}</Table.HeaderCell>}
              {subCSumUnits === 'NOM' && <Table.HeaderCell
                style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                sorted={sortColumnSubclass === 'LY_TOTAL_SHRINK_AMT' ? sortDirectionSubclass : null}
                onClick={() => {
                  if (sortColumnSubclass !== 'LY_TOTAL_SHRINK_AMT') {
                    setSortColumnSubclass('LY_TOTAL_SHRINK_AMT')
                    setSortDirectionSubclass('ascending')
                  } else {
                    sortDirectionSubclass === 'ascending' ? setSortDirectionSubclass('descending') : setSortDirectionSubclass('ascending')
                  }
                }}
              >vLY $ {sortColumnSubclass !== 'LY_TOTAL_SHRINK_AMT' && <Icon fitted name='sort' />}</Table.HeaderCell>}
              {subCSumUnits === 'PCT' && <Table.HeaderCell
                style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                sorted={sortColumnSubclass === 'LY_TOTAL_SALES_AMT' ? sortDirectionSubclass : null}
                onClick={() => {
                  if (sortColumnSubclass !== 'LY_TOTAL_SALES_AMT') {
                    setSortColumnSubclass('LY_TOTAL_SALES_AMT')
                    setSortDirectionSubclass('ascending')
                  } else {
                    sortDirectionSubclass === 'ascending' ? setSortDirectionSubclass('descending') : setSortDirectionSubclass('ascending')
                  }
                }}
              >vLY % {sortColumnSubclass !== 'LY_TOTAL_SALES_AMT' && <Icon fitted name='sort' />}</Table.HeaderCell>}
              {subCSumUnits === 'NOM' && <Table.HeaderCell
                style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                sorted={sortColumnSubclass === 'OPS_SHRINK_AMT' ? sortDirectionSubclass : null}
                onClick={() => {
                  if (sortColumnSubclass !== 'OPS_SHRINK_AMT') {
                    setSortColumnSubclass('OPS_SHRINK_AMT')
                    setSortDirectionSubclass('ascending')
                  } else {
                    sortDirectionSubclass === 'ascending' ? setSortDirectionSubclass('descending') : setSortDirectionSubclass('ascending')
                  }
                }}
              >Ops Shrink $ {sortColumnSubclass !== 'OPS_SHRINK_AMT' && <Icon fitted name='sort' />}</Table.HeaderCell>}
              {subCSumUnits === 'PCT' && <Table.HeaderCell
                style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                sorted={sortColumnSubclass === 'OPS_SALES_AMT' ? sortDirectionSubclass : null}
                onClick={() => {
                  if (sortColumnSubclass !== 'OPS_SALES_AMT') {
                    setSortColumnSubclass('OPS_SALES_AMT')
                    setSortDirectionSubclass('ascending')
                  } else {
                    sortDirectionSubclass === 'ascending' ? setSortDirectionSubclass('descending') : setSortDirectionSubclass('ascending')
                  }
                }}
              >Ops Shrink % {sortColumnSubclass !== 'OPS_SALES_AMT' && <Icon fitted name='sort' />}</Table.HeaderCell>}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {dataPaginationSubclass.map(data => {
              return (
                <Table.Row>
                  <Table.Cell>
                    <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/district/shrink/subclass?dstNbr=${dstNbr}&subClassNbr=${data['EXT_SUB_CLASS_NBR']}`}>
                      {data.EXT_SUB_CLASS_NBR} - {data.SUB_CLASS_DESC.replaceAll('/', '/\u200b')}
                    </Link>
                  </Table.Cell>
                  {subCSumUnits === 'NOM' && <Table.Cell>{getDollarString(data.TOTAL_SHRINK_AMT)}</Table.Cell>}
                  {subCSumUnits === 'PCT' && <Table.Cell>{getPercentString(data.TOTAL_SHRINK_AMT / data.TOTAL_SALES_AMT, 2, 2)}</Table.Cell>}
                  {subCSumUnits === 'NOM' && <Table.Cell>{getDollarString(data.TOTAL_SHRINK_AMT - data.LY_TOTAL_SHRINK_AMT)}</Table.Cell>}
                  {subCSumUnits === 'PCT' && <Table.Cell>{getPercentString(data.TOTAL_SHRINK_AMT / data.TOTAL_SALES_AMT - data.LY_TOTAL_SHRINK_AMT / data.LY_TOTAL_SALES_AMT, 2, 2)}</Table.Cell>}
                  {subCSumUnits === 'NOM' && <Table.Cell>{getDollarString(data.OPS_SHRINK_AMT)}</Table.Cell>}
                  {subCSumUnits === 'PCT' && <Table.Cell>{getPercentString(data.OPS_SHRINK_AMT / data.TOTAL_SALES_AMT, 2, 2)}</Table.Cell>}
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      </Tab.Pane>
    },
    {
      menuItem: 'SKU',
      render: () => <Tab.Pane attached={false}>
        {/*  ____  __ _  _  _ 
            / ___)(  / )/ )( \
            \___ \ )  ( ) \/ (
            (____/(__\_)\____/*/}
        <Header textAlign='center'>Top 40 Shrink Skus</Header>
        <Grid Column={3}>
          <Grid.Row>
            <Grid.Column width={8} >
              <Grid.Row>Units:</Grid.Row>
              <Menu compact size='small'>
                <UnitMenu unit={skuSumUnits} setUnit={setSkuSumUnits} />
              </Menu>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        &nbsp;
        <p>* Top 40 SKUs are ranked by Shrink rate (vLY $ or vLY % depending on unit selection)</p>
        <Table textAlign='center' unstackable celled sortable size='small'>
          <Table.Header>
            <Table.Row>
              {skuSumUnits === 'NOM' && <Table.HeaderCell
                style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                sorted={sortColumnSku === 'SHRINK_AMT_vLY_RANK_DST' ? sortDirectionSku : null}
                onClick={() => {
                  if (sortColumnSku !== 'SHRINK_AMT_vLY_RANK_DST') {
                    setSortColumnSku('SHRINK_AMT_vLY_RANK_DST')
                    setSortDirectionSku('ascending')
                  } else {
                    sortDirectionSku === 'ascending' ? setSortDirectionSku('descending') : setSortDirectionSku('ascending')
                  }
                }}
              >Rank *{sortColumnSku !== 'SHRINK_AMT_vLY_RANK_DST' && <Icon fitted name='sort' />}</Table.HeaderCell>}
              {skuSumUnits === 'PCT' && <Table.HeaderCell
                style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                sorted={sortColumnSku === 'SHRINK_RATE_vLY_RANK_DST' ? sortDirectionSku : null}
                onClick={() => {
                  if (sortColumnSku !== 'SHRINK_RATE_vLY_RANK_DST') {
                    setSortColumnSku('SHRINK_RATE_vLY_RANK_DST')
                    setSortDirectionSku('ascending')
                  } else {
                    sortDirectionSku === 'ascending' ? setSortDirectionSku('descending') : setSortDirectionSku('ascending')
                  }
                }}
              >Rank *{sortColumnSku !== 'SHRINK_RATE_vLY_RANK_DST' && <Icon fitted name='sort' />}</Table.HeaderCell>}
              <Table.HeaderCell
                style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                sorted={sortColumnSku === 'SKU_NBR' ? sortDirectionSku : null}
                onClick={() => {
                  if (sortColumnSku !== 'SKU_NBR') {
                    setSortColumnSku('SKU_NBR')
                    setSortDirectionSku('ascending')
                  } else {
                    sortDirectionSku === 'ascending' ? setSortDirectionSku('descending') : setSortDirectionSku('ascending')
                  }
                }}
              >SKU # {sortColumnSku !== 'SKU_NBR' && <Icon fitted name='sort' />}</Table.HeaderCell>
              <Table.HeaderCell>SKU Desc</Table.HeaderCell>
              <Table.HeaderCell
                style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                sorted={sortColumnSku === 'DEPT_NBR' ? sortDirectionSku : null}
                onClick={() => {
                  if (sortColumnSku !== 'DEPT_NBR') {
                    setSortColumnSku('DEPT_NBR')
                    setSortDirectionSku('ascending')
                  } else {
                    sortDirectionSku === 'ascending' ? setSortDirectionSku('descending') : setSortDirectionSku('ascending')
                  }
                }}
              >Dept {sortColumnSku !== 'DEPT_NBR' && <Icon fitted name='sort' />}</Table.HeaderCell>
              {skuSumUnits === 'NOM' && <Table.HeaderCell
                style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                sorted={sortColumnSku === 'TOTAL_SHRINK_AMT' ? sortDirectionSku : null}
                onClick={() => {
                  if (sortColumnSku !== 'TOTAL_SHRINK_AMT') {
                    setSortColumnSku('TOTAL_SHRINK_AMT')
                    setSortDirectionSku('ascending')
                  } else {
                    sortDirectionSku === 'ascending' ? setSortDirectionSku('descending') : setSortDirectionSku('ascending')
                  }
                }}
              >Shrink $ {sortColumnSku !== 'TOTAL_SHRINK_AMT' && <Icon fitted name='sort' />}</Table.HeaderCell>}
              {skuSumUnits === 'PCT' && <Table.HeaderCell
                style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                sorted={sortColumnSku === 'SHRINK_RATE' ? sortDirectionSku : null}
                onClick={() => {
                  if (sortColumnSku !== 'SHRINK_RATE') {
                    setSortColumnSku('SHRINK_RATE')
                    setSortDirectionSku('ascending')
                  } else {
                    sortDirectionSku === 'ascending' ? setSortDirectionSku('descending') : setSortDirectionSku('ascending')
                  }
                }}
              >Shrink % {sortColumnSku !== 'SHRINK_RATE' && <Icon fitted name='sort' />}</Table.HeaderCell>}
              {skuSumUnits === 'NOM' && <Table.HeaderCell
                style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                sorted={sortColumnSku === 'SHRINK_AMT_vLY' ? sortDirectionSku : null}
                onClick={() => {
                  if (sortColumnSku !== 'SHRINK_AMT_vLY') {
                    setSortColumnSku('SHRINK_AMT_vLY')
                    setSortDirectionSku('ascending')
                  } else {
                    sortDirectionSku === 'ascending' ? setSortDirectionSku('descending') : setSortDirectionSku('ascending')
                  }
                }}
              >vLY $ {sortColumnSku !== 'SHRINK_AMT_vLY' && <Icon fitted name='sort' />}</Table.HeaderCell>}
              {skuSumUnits === 'PCT' && <Table.HeaderCell
                style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                sorted={sortColumnSku === 'SHRINK_RATE_vLY' ? sortDirectionSku : null}
                onClick={() => {
                  if (sortColumnSku !== 'SHRINK_RATE_vLY') {
                    setSortColumnSku('SHRINK_RATE_vLY')
                    setSortDirectionSku('ascending')
                  } else {
                    sortDirectionSku === 'ascending' ? setSortDirectionSku('descending') : setSortDirectionSku('ascending')
                  }
                }}
              >vLY % {sortColumnSku !== 'SHRINK_RATE_vLY' && <Icon fitted name='sort' />}</Table.HeaderCell>}
              {skuSumUnits === 'NOM' && <Table.HeaderCell
                style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                sorted={sortColumnSku === 'OPS_SHRINK_AMT' ? sortDirectionSku : null}
                onClick={() => {
                  if (sortColumnSku !== 'OPS_SHRINK_AMT') {
                    setSortColumnSku('OPS_SHRINK_AMT')
                    setSortDirectionSku('ascending')
                  } else {
                    sortDirectionSku === 'ascending' ? setSortDirectionSku('descending') : setSortDirectionSku('ascending')
                  }
                }}
              >Ops Shrink $ {sortColumnSku !== 'OPS_SHRINK_AMT' && <Icon fitted name='sort' />}</Table.HeaderCell>}
              {skuSumUnits === 'PCT' && <Table.HeaderCell
                style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                sorted={sortColumnSku === 'OPS_SHRINK_PER' ? sortDirectionSku : null}
                onClick={() => {
                  if (sortColumnSku !== 'OPS_SHRINK_PER') {
                    setSortColumnSku('OPS_SHRINK_PER')
                    setSortDirectionSku('ascending')
                  } else {
                    sortDirectionSku === 'ascending' ? setSortDirectionSku('descending') : setSortDirectionSku('ascending')
                  }
                }}
              >Ops Shrink % {sortColumnSku !== 'OPS_SHRINK_PER' && <Icon fitted name='sort' />}</Table.HeaderCell>}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {skuSumrySorted.map((data) => {
              return (
                <Table.Row>
                  {skuSumUnits === 'NOM' && <Table.Cell>{data.SHRINK_AMT_vLY_RANK_DST}</Table.Cell>}
                  {skuSumUnits === 'PCT' && <Table.Cell>{data.SHRINK_RATE_vLY_RANK_DST}</Table.Cell>}
                  <Table.Cell

                    style={{ color: '#EE7125', textDecoration: 'underline' }}
                    onClick={() => {
                      setOpen(true)
                      setModalData(data)
                    }}>
                    {data['SKU_NBR'].toString().length > 6 ? // Format SKUs, 10-digit (xxxx-xxx-xxx) versus 6-digit (xxx-xxx)
                      data['SKU_NBR'].toString().substring(0, 4) + '-' + data['SKU_NBR'].toString().substring(4, 7) + '-' + data['SKU_NBR'].toString().substring(7) :
                      data['SKU_NBR'].toString().substring(0, 3) + '-' + data['SKU_NBR'].toString().substring(3)}
                  </Table.Cell>
                  <Table.Cell>{data.SKU_DESC.replaceAll('/', '/\u200b')}</Table.Cell>
                  <Table.Cell>{data.DEPT_NBR}</Table.Cell>
                  {skuSumUnits === 'NOM' && <Table.Cell>{getDollarString(data.TOTAL_SHRINK_AMT)}</Table.Cell>}
                  {skuSumUnits === 'PCT' && <Table.Cell>{getPercentString(data.SHRINK_RATE, 2, 2)}</Table.Cell>}
                  {skuSumUnits === 'NOM' && <Table.Cell>{getDollarString(data.SHRINK_AMT_vLY)}</Table.Cell>}
                  {skuSumUnits === 'PCT' && <Table.Cell>{getPercentString(data.SHRINK_RATE_vLY, 2, 2)}</Table.Cell>}
                  {skuSumUnits === 'NOM' && <Table.Cell>{getDollarString(data.OPS_SHRINK_AMT)}</Table.Cell>}
                  {skuSumUnits === 'PCT' && <Table.Cell>{getPercentString(data.OPS_SHRINK_AMT / data.TOTAL_SALES_AMT, 2, 2)}</Table.Cell>}
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
        {open && <Modal
          closeIcon
          open={open}
          onClose={() => { setOpen(false) }}>
          <Segment color='orange'>
            <Grid>
              <Grid.Column width={16}>
                <Grid.Row>
                  <Header textAlign='center'>
                    {modalData['SKU_NBR'].toString().length > 6 ? // Format SKUs, 10-digit (xxxx-xxx-xxx) versus 6-digit (xxx-xxx)
                      modalData['SKU_NBR'].toString().substring(0, 4) + '-' + modalData['SKU_NBR'].toString().substring(4, 7) + '-' + modalData['SKU_NBR'].toString().substring(7) :
                      modalData['SKU_NBR'].toString().substring(0, 3) + '-' + modalData['SKU_NBR'].toString().substring(3)}
                  </Header>
                </Grid.Row>
                <Grid.Row>&nbsp;</Grid.Row>
                <Grid.Row><Header>Class</Header></Grid.Row>
                <Grid.Row><p textAlign='center'>{modalData['EXT_CLASS_NBR'] + '-' + modalData['CLASS_DESC']}</p></Grid.Row>
                <Grid.Row>&nbsp;</Grid.Row>
                <Grid.Row><Header>Subclass</Header></Grid.Row>
                <Grid.Row><p textAlign='center'>{modalData['EXT_SUB_CLASS_NBR'] + '-' + modalData['SUB_CLASS_DESC']}</p></Grid.Row>
                <Grid.Row>&nbsp;</Grid.Row>
                <Grid.Row><Header>SKU Description</Header></Grid.Row>
                <Grid.Row><p textAlign='center'>{modalData['SKU_DESC']}</p></Grid.Row>
              </Grid.Column>
              &nbsp;
              &nbsp;
              <Grid.Column>
                <Grid.Row>&nbsp;</Grid.Row>
                <Grid.Row>
                  <Button
                    onClick={() => { setOpen(false) }}
                    style={{ color: 'white', backgroundColor: '#EE7125' }}
                    content='Close'
                  />
                </Grid.Row>
                <Grid.Row>&nbsp;</Grid.Row>
              </Grid.Column>
            </Grid>
          </Segment>
        </Modal>}
      </Tab.Pane>
    }
  ]

  return (
    <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
  )
})