import React from 'react'
import { Container, Message, MessageContent, MessageHeader } from 'semantic-ui-react'

export const LegacyPageNotice = () => {
  return (
    <Container>
      <Message color="red"  size='massive'>
        <MessageContent>
          <MessageHeader>Store Pulse has moved to a new URL</MessageHeader>
          <a href="https://storepulse.homedepot.com/" style={{ paddingLeft: 3 }}>
            https://storepulse.homedepot.com
          </a>
          <br />
          Please update any saved links and go to this link in the future.
        </MessageContent>
      </Message>
    </Container>
  )
}