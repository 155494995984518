import { useQuery } from 'react-query';
import React, { useMemo } from 'react';
import { IntradayTable } from './IntradayTable';
import { getData } from '../../service/DataService';
import { CurrentDaySales } from './CurrentDaySales';
import { Loader, Header } from 'semantic-ui-react';
import { findBiggestObject } from '../../utils/array';
import { useQueryParams } from '../../hooks/useQueryParams';
import { INTRADAY_CONSTANTS } from '../../constants/intradayConstant';
import { IntradayDepartmentViewComponent } from './IntradayDeparmentViewComponent';

export const IntradayView = ({ fpToggle, level }) => {
  const { rgnNbr = 0, dstNbr = 0, strNbr = 0 } = useQueryParams();
  const queryParams = {
    store: { strNbr },
    district: { dstNbr },
    region: { locNbr: rgnNbr }
  };
  const { data: hourlySalesData = [], isLoading: intradayDataLoading } = useQuery(
    [`query/salesIntraday/${level}`, { locNbr: rgnNbr || dstNbr || strNbr, fpToggle }],
    getData
  );

  const latestHourData = useMemo(() => {
    if (intradayDataLoading) return {};

    return findBiggestObject(hourlySalesData, 'SLS_HR') || {};
  }, [hourlySalesData, intradayDataLoading]);

  const deptOverviewQuery = useQuery(
    [INTRADAY_CONSTANTS[level]['deptOverviewQuery'], { ...queryParams[level], fpToggle }],
    getData
  );

  if (intradayDataLoading || deptOverviewQuery.isLoading) {
    return <Loader active>Loading...</Loader>;
  }

  if (!hourlySalesData[0] || !deptOverviewQuery.data[0]) {
    return (
      <Header textAlign='center' data-testId={'invalidIntraday' + level}>
        Invalid Store Number
      </Header>
    );
  }

  return (
    <>
      <CurrentDaySales level={level} latestHourData={latestHourData} locNbr={strNbr || dstNbr || rgnNbr} />

      <IntradayTable hourlySalesData={hourlySalesData} />

      <IntradayDepartmentViewComponent
        queryData={deptOverviewQuery}
        level={level}
        levelNumber={strNbr || dstNbr || rgnNbr}
      />
    </>
  );
};
