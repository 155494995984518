import React from 'react'

export const TimeframeDropdownLimited = ({ timeframe, settimeframe }) => {
  return (
    <div>
      <select
        name="timeframe"
        onChange={e => {
          settimeframe(e.target.value)
        }}
        value={timeframe}
        style={{ padding: 10 }}
      >
        <option value="WTD" selected>WTD</option>
        <option value="LW">LW</option>
      </select>
    </div>
  )
}