import React, { useState, useEffect } from 'react'
import { Header, Loader, Dropdown, Icon, Grid, Table, Pagination, Modal, Segment, Button } from 'semantic-ui-react'
import { withRouter, Link } from 'react-router-dom'
import { TimeframeDropdown } from '../Common/TimeframeDropdown'
import { TimeframeDropdownMon } from '../Common/TimeframeDropdownMon'

import { useQuery } from 'react-query'
import {
  getData
} from '../../service/DataService'
import { UnitMenu } from '../Common/UnitMenu'
import { timeframeCode } from '../Common/timeframeCode'

export const SubclassDrilldown = withRouter(({ timeframe, settimeframe, level, fpToggle, location }) => {

  const [metric, setMetric] = useState('SLS')
  const [sortColumn, setSortColumn] = useState("SKU_NBR")
  const [sortDirection, setSortDirection] = useState("ascending")
  const [unit, setUnit] = useState("NOM")
  const [pageNum, setPageNum] = useState(1)
  let rowsPerPage = 50

  const [open, setOpen] = useState(false)
  const [modalData, setModalData] = useState([])

  const search = new URLSearchParams(location.search)
  const strNbr = search.has('strNbr') ? search.get('strNbr') : 0
  const dstNbr = search.has('dstNbr') ? search.get('dstNbr') : 0
  const orgNbr = search.has('strNbr') ? search.get('strNbr') : search.has('dstNbr') ? search.get('dstNbr') : 0
  // const deptNbrInt = search.has('deptNbr') ? search.get('deptNbr') : 0
  // const classNbr = search.has('classNbr') ? search.get('classNbr') : 0
  const subClassNbr = search.has('subClassNbr') ? search.get('subClassNbr') : 0




  // const subClassesQuery = level === 'store' ? useQuery(['querySlsSclsByStrCls', {strNbr, classNbr, fpToggle}], getData) :
  //                         level === 'district' ? useQuery(['querySlsSclsByDstCls', {dstNbr, classNbr, fpToggle}], getData) : null
  const skusQuery = level === 'store' ? useQuery(['querySlsSkuByStrScls', { strNbr, subClassNbr, fpToggle }], getData) :
    level === 'district' ? useQuery(['querySlsSkuByDstScls', { dstNbr, subClassNbr, fpToggle }], getData) : null

  useEffect(() => {
    setPageNum(1)
  }, [timeframe, sortColumn, sortDirection])

  if (
    // subClassesQuery.isLoading || 
    skusQuery.isLoading) {
    return <Loader active>Loading...</Loader>
  }

  let deptNbr = subClassNbr.toString().substring(1, 3)

  let deptNm = skusQuery.data.length > 0 ? deptNbr + '-' + skusQuery.data[0]['DEPT_NM'] : deptNbr
  
  let classNbr = subClassNbr.toString().substring(0,7)

  let classNm = skusQuery.data.length > 0 ? classNbr + '-' + skusQuery.data[0]['CLASS_DESC'] : classNbr

  let subClassNm = skusQuery.data.length > 0 ? subClassNbr + '-' + skusQuery.data[0]['SUB_CLASS_DESC'] : subClassNbr

  // Load, extract and sort class numbers
  // const allSubclasses = subClassesQuery.data.filter((row) => {
  //   return (
  //     row &&
  //     row['TRNSFM_TYP_SORT_ID'] === timeframeCode[timeframe]
  //   )
  // })

  // let allSubclassSort = allSubclasses.sort((left, right) => {
  //   let result = left.MER_SUB_CLASS_NM > right.MER_SUB_CLASS_NM ? 1 : -1
  //   return result
  // })

  // let subclassNbrArr = []
  // allSubclassSort.map((data) => {
  //   return subclassNbrArr.push(data['EXT_SUB_CLASS_NBR'].toString())
  // })

  // let subclassNmArr = []
  // allSubclassSort.map((data) => {
  //   return subclassNmArr.push(data['MER_SUB_CLASS_NM'])
  // })

  // let subclassNm = subclassNmArr[subclassNbrArr.indexOf(subClassNbr)]

  const allSkus = skusQuery.data.filter((row) => {
    return (
      row &&
      row['TRNSFM_TYP_SORT_ID'] === timeframeCode[timeframe]
    )
  })

  let allSkuSort = allSkus.sort((left, right) => {
    let result = left[sortColumn] - right[sortColumn]
    if (sortColumn === 'SKU_NBR') {
      result = left.SKU_NBR > right.SKU_NBR ? 1 : -1
    }
    if (sortColumn === 'SKU_DESC') {
      result = left.SKU_DESC > right.SKU_DESC ? 1 : -1
    }
    if (sortDirection === 'descending') {
      result *= -1
    }
    return result
  })

  // let subclassNm = allSkus[0]['EXT_SUB_CLASS_NBR'] + '-' + allSkus[0]['SUB_CLASS_DESC']

  let dataPagination = allSkuSort.slice(rowsPerPage * (pageNum - 1), parseInt(Math.min(rowsPerPage * pageNum, allSkuSort.length)))

  return (
    <>
      {/* Buttons for adjacent departments */}
      {/* <Grid>
          <Grid.Row fluid>
            <Grid.Column textAlign='left' width={8}>
              <Link
                to={`/${level}/sales/subclass?${level === 'store' ? 'strNbr' : level === 'district' ? 'dstNbr' : 'strNbr'}=${orgNbr}&deptNbr=${deptNbrInt}&classNbr=${classNbr}&subClassNbr=${subclassNbrArr[(subclassNbrArr.indexOf(subClassNbr) + subclassNbrArr.length - 1) % subclassNbrArr.length]}`}
                style={{ color: '#EE7125', textDecoration: 'underline' }}>
                {subclassNmArr[(subclassNbrArr.indexOf(subClassNbr) + subclassNbrArr.length - 1) % subclassNbrArr.length]}
              </Link>

            </Grid.Column>
            <Grid.Column textAlign='right' width={8}>
              <Link
                to={`/${level}/dept/class/subclass?${level === 'store' ? 'strNbr' : level === 'district' ? 'dstNbr' : 'strNbr'}=${orgNbr}&deptNbr=${deptNbrInt}&classNbr=${classNbr}&subClassNbr=${subclassNbrArr[(subclassNbrArr.indexOf(subClassNbr) + 1) % subclassNbrArr.length]}`}
                style={{ color: '#EE7125', textDecoration: 'underline' }}>
                {subclassNmArr[(subclassNbrArr.indexOf(subClassNbr) + 1) % subclassNbrArr.length]}
              </Link>
            </Grid.Column>
          </Grid.Row>
        </Grid> */}

      <Grid>
        <Grid.Row>
          <Grid.Column width={16} textAlign='left'>
            <Grid.Row>
              {/* <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/${level}/overview?${level === 'store' ? 'strNbr' : level === 'district' ? 'dstNbr' : 'strNbr'}=${orgNbr}`} >
                Overview
              </Link> */}
              <a
                href={`/${level}/overview?${level === 'store' ? 'strNbr' : level === 'district' ? 'dstNbr' : 'strNbr'}=${orgNbr}`}
                style={{ color: '#EE7125', textDecoration: 'underline' }}
              >Overview</a>
              {' / '}
              <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/${level}/sales/dept?${level === 'store' ? 'strNbr' : level === 'district' ? 'dstNbr' : 'strNbr'}=${orgNbr}&deptNbr=${deptNbr}`} >
                {deptNm}
              </Link>
              {' / '}
              <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/${level}/sales/class?${level === 'store' ? 'strNbr' : level === 'district' ? 'dstNbr' : 'strNbr'}=${orgNbr}&classNbr=${classNbr}`} >
                {classNm}
              </Link>
              {' / '}
              {subClassNm}
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid>
        <Grid.Column width={8}>
        <Grid.Row>
              Metric:
            </Grid.Row>
            <Grid.Row>
              <Dropdown
                defaultValue={metric}
                selection
                scrolling
                compact
                size='small'
                options={[{ key: 'SLS', text: 'Sales', value: 'SLS' },
                { key: 'UNT', text: 'Units', value: 'UNT' },
                { key: 'TXN', text: 'Trans', value: 'TXN' },
                { key: 'AVG_TCKT', text: 'Avg Ticket', value: 'AVG_TCKT' },
                ]}
                onChange={(_, { value }) => {
                  setMetric(value)
                }} />
            </Grid.Row>
        </Grid.Column>
        <Grid.Column width={8}>
        <Grid.Row>Timeframe:</Grid.Row>
            <Grid.Row>
              {new Date().getDay() === 1 ?
                <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} /> :
                <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />}
            </Grid.Row>
        </Grid.Column>
      </Grid>
      {/* <Header textAlign='center'>{subclassNm}</Header> */}
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <Grid.Row>Units:</Grid.Row>
            <UnitMenu unit={unit} setUnit={setUnit} />
          </Grid.Column>
          <Grid.Column width={8}/>
        </Grid.Row>
      </Grid>
      <Grid>
        {allSkuSort.length > rowsPerPage && <Grid.Row>
          <Grid.Column width={16} textAlign='center'>
            <Pagination
              size='mini'
              defaultActivePage={1}
              totalPages={Math.floor((allSkuSort.length - 1) / rowsPerPage) + 1}
              activePage={pageNum}
              boundaryRange={0}
              siblingRange={1}
              onPageChange={(e, { activePage }) => {
                setPageNum(activePage)
              }}
            />
          </Grid.Column>
        </Grid.Row>}
      </Grid>
      <Grid>
        <Grid.Row>
          <Grid.Column width={16} textAlign='center'>
            {
              <>
                {/* <Header textAlign='center'>All SKUs</Header> */}
                <Table textAlign='center' unstackable celled sortable size='small' style={{ fontSize: 12 }}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell
                        sorted={sortColumn === 'SKU_NBR' ? sortDirection : null}
                        style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                        onClick={() => {
                          if (sortColumn !== 'SKU_NBR') {
                            setSortColumn('SKU_NBR')
                            setSortDirection('ascending')
                          } else {
                            sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                          }
                        }}>SKU {sortColumn !== 'SKU_NBR' && <Icon fitted name='sort' />}</Table.HeaderCell>
                      <Table.HeaderCell
                        sorted={sortColumn === 'SKU_DESC' ? sortDirection : null}
                        style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                        onClick={() => {
                          if (sortColumn !== 'SKU_DESC') {
                            setSortColumn('SKU_DESC')
                            setSortDirection('ascending')
                          } else {
                            sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                          }
                        }}>SKU  Description {sortColumn !== 'SKU_DESC' && <Icon fitted name='sort' />}</Table.HeaderCell>
                      {metric === 'SLS' && <Table.HeaderCell
                        sorted={sortColumn === 'TY_SLS_AMT' ? sortDirection : null}
                        style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                        onClick={() => {
                          if (sortColumn !== 'TY_SLS_AMT') {
                            setSortColumn('TY_SLS_AMT')
                            setSortDirection('ascending')
                          } else {
                            sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                          }
                        }}>Sales Amt ($) {sortColumn !== 'TY_SLS_AMT' && <Icon fitted name='sort' />}</Table.HeaderCell>}
                      {/* {metric === 'SLS' && <Table.HeaderCell
                          sorted={sortColumn === 'LY_COMP_SLS_AMT' ? sortDirection : null}
                          onClick={() => {
                            if (sortColumn !== 'LY_COMP_SLS_AMT') {
                              setSortColumn('LY_COMP_SLS_AMT')
                              setSortDirection('ascending')
                            } else {
                              sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                            }
                          }}>Sales Amt<br/>Comp ($)<br/><Icon fitted name='sort' /></Table.HeaderCell>} */}
                      {metric === 'SLS' && unit === 'NOM' && <Table.HeaderCell
                        sorted={sortColumn === 'SLS_COMP_NOM' ? sortDirection : null}
                        style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                        onClick={() => {
                          if (sortColumn !== 'SLS_COMP_NOM') {
                            setSortColumn('SLS_COMP_NOM')
                            setSortDirection('ascending')
                          } else {
                            sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                          }
                        }}>Sales vLY ($) {sortColumn !== 'SLS_COMP_NOM' && <Icon fitted name='sort' />}</Table.HeaderCell>}
                      {metric === 'SLS' && unit === 'PCT' && <Table.HeaderCell
                        sorted={sortColumn === 'SLS_COMP_PCT' ? sortDirection : null}
                        style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                        onClick={() => {
                          if (sortColumn !== 'SLS_COMP_PCT') {
                            setSortColumn('SLS_COMP_PCT')
                            setSortDirection('ascending')
                          } else {
                            sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                          }
                        }}>Sales vLY (%) {sortColumn !== 'SLS_COMP_PCT' && <Icon fitted name='sort' />}</Table.HeaderCell>}
                      {metric === 'UNT' && <Table.HeaderCell
                        sorted={sortColumn === 'TY_UNT_SLS' ? sortDirection : null}
                        style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                        onClick={() => {
                          if (sortColumn !== 'TY_UNT_SLS') {
                            setSortColumn('TY_UNT_SLS')
                            setSortDirection('ascending')
                          } else {
                            sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                          }
                        }}>Unit Sales (#) {sortColumn !== 'TY_UNT_SLS' && <Icon fitted name='sort' />}</Table.HeaderCell>}
                      {/* {metric === 'UNT' && <Table.HeaderCell
                          sorted={sortColumn === 'LY_COMP_UNT_SLS' ? sortDirection : null}
                          onClick={() => {
                            if (sortColumn !== 'LY_COMP_UNT_SLS') {
                              setSortColumn('LY_COMP_UNT_SLS')
                              setSortDirection('ascending')
                            } else {
                              sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                            }
                          }}>Unit Sales<br/>Comp (#)<br/><Icon fitted name='sort' /></Table.HeaderCell>} */}
                      {metric === 'UNT' && unit === 'NOM' && <Table.HeaderCell
                        sorted={sortColumn === 'UNT_COMP_NOM' ? sortDirection : null}
                        style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                        onClick={() => {
                          if (sortColumn !== 'UNT_COMP_NOM') {
                            setSortColumn('UNT_COMP_NOM')
                            setSortDirection('ascending')
                          } else {
                            sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                          }
                        }}>Units vLY (#) {sortColumn !== 'UNT_COMP_NOM' && <Icon fitted name='sort' />}</Table.HeaderCell>}
                      {metric === 'UNT' && unit === 'PCT' && <Table.HeaderCell
                        sorted={sortColumn === 'UNT_COMP_PCT' ? sortDirection : null}
                        style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                        onClick={() => {
                          if (sortColumn !== 'UNT_COMP_PCT') {
                            setSortColumn('UNT_COMP_PCT')
                            setSortDirection('ascending')
                          } else {
                            sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                          }
                        }}>Units vLY (%) {sortColumn !== 'UNT_COMP_PCT' && <Icon fitted name='sort' />}</Table.HeaderCell>}
                      {metric === 'TXN' && <Table.HeaderCell
                        sorted={sortColumn === 'TY_TXN_CNT' ? sortDirection : null}
                        style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                        onClick={() => {
                          if (sortColumn !== 'TY_TXN_CNT') {
                            setSortColumn('TY_TXN_CNT')
                            setSortDirection('ascending')
                          } else {
                            sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                          }
                        }}>Trans Cnt (#) {sortColumn !== 'TY_TXN_CNT' && <Icon fitted name='sort' />}</Table.HeaderCell>}
                      {/* {metric === 'TXN' && <Table.HeaderCell
                          sorted={sortColumn === 'LY_COMP_TXN_CNT' ? sortDirection : null}
                          onClick={() => {
                            if (sortColumn !== 'LY_COMP_TXN_CNT') {
                              setSortColumn('LY_COMP_TXN_CNT')
                              setSortDirection('ascending')
                            } else {
                              sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                            }
                          }}>Trans Cnt<br/>Comp (#)<br/><Icon fitted name='sort' /></Table.HeaderCell>} */}
                      {metric === 'TXN' && unit === 'NOM' && <Table.HeaderCell
                        sorted={sortColumn === 'TXN_COMP_NOM' ? sortDirection : null}
                        style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                        onClick={() => {
                          if (sortColumn !== 'TXN_COMP_NOM') {
                            setSortColumn('TXN_COMP_NOM')
                            setSortDirection('ascending')
                          } else {
                            sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                          }
                        }}>Trans vLY (#) {sortColumn !== 'TXN_COMP_NOM' && <Icon fitted name='sort' />}</Table.HeaderCell>}
                      {metric === 'TXN' && unit === 'PCT' && <Table.HeaderCell
                        sorted={sortColumn === 'TXN_COMP_PCT' ? sortDirection : null}
                        style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                        onClick={() => {
                          if (sortColumn !== 'TXN_COMP_PCT') {
                            setSortColumn('TXN_COMP_PCT')
                            setSortDirection('ascending')
                          } else {
                            sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                          }
                        }}>Trans vLY (%) {sortColumn !== 'TXN_COMP_PCT' && <Icon fitted name='sort' />}</Table.HeaderCell>}
                      {metric === 'AVG_TCKT' && <Table.HeaderCell
                        sorted={sortColumn === 'TY_AVG_TCKT' ? sortDirection : null}
                        style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                        onClick={() => {
                          if (sortColumn !== 'TY_AVG_TCKT') {
                            setSortColumn('TY_AVG_TCKT')
                            setSortDirection('ascending')
                          } else {
                            sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                          }
                        }}>Avg Ticket ($) {sortColumn !== 'TY_AVG_TCKT' && <Icon fitted name='sort' />}</Table.HeaderCell>}
                      {/* {metric === 'AVG_TCKT' && <Table.HeaderCell
                          sorted={sortColumn === 'LY_COMP_AVG_TCKT' ? sortDirection : null}
                          onClick={() => {
                            if (sortColumn !== 'LY_COMP_AVG_TCKT') {
                              setSortColumn('LY_COMP_AVG_TCKT')
                              setSortDirection('ascending')
                            } else {
                              sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                            }
                          }}>Avg<br/>Ticket<br/>Comp ($)<br/><Icon fitted name='sort' /></Table.HeaderCell>} */}
                      {metric === 'AVG_TCKT' && unit === 'NOM' && <Table.HeaderCell
                        sorted={sortColumn === 'AVG_TCKT_COMP_NOM' ? sortDirection : null}
                        style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                        onClick={() => {
                          if (sortColumn !== 'AVG_TCKT_COMP_NOM') {
                            setSortColumn('AVG_TCKT_COMP_NOM')
                            setSortDirection('ascending')
                          } else {
                            sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                          }
                        }}>Avg Ticket vLY ($) {sortColumn !== 'AVG_TCKT_COMP_NOM' && <Icon fitted name='sort' />}</Table.HeaderCell>}
                      {metric === 'AVG_TCKT' && unit === 'PCT' && <Table.HeaderCell
                        sorted={sortColumn === 'AVG_TCKT_COMP_PCT' ? sortDirection : null}
                        style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                        onClick={() => {
                          if (sortColumn !== 'AVG_TCKT_COMP_PCT') {
                            setSortColumn('AVG_TCKT_COMP_PCT')
                            setSortDirection('ascending')
                          } else {
                            sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                          }
                        }}>Avg Ticket vLY (%) {sortColumn !== 'AVG_TCKT_COMP_PCT' && <Icon fitted name='sort' />}</Table.HeaderCell>}
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {dataPagination.map((data) => {
                      return (
                        <Table.Row>
                          <Table.Cell
                            style={{ color: '#EE7125', textDecoration: 'underline' }}
                            singleLine
                            onClick={() => {
                              setOpen(true)
                              setModalData(data)
                            }}>
                            {data['SKU_NBR'].toString().length > 6 ? // Format SKUs, 10-digit (xxxx-xxx-xxx) versus 6-digit (xxx-xxx)
                              data['SKU_NBR'].toString().substring(0, 4) + '-' + data['SKU_NBR'].toString().substring(4, 7) + '-' + data['SKU_NBR'].toString().substring(7) :
                              data['SKU_NBR'].toString().substring(0, 3) + '-' + data['SKU_NBR'].toString().substring(3)}
                          </Table.Cell>
                          <Table.Cell>{data['SKU_DESC']}</Table.Cell>
                          {/* Sales metrics */}
                          {metric === 'SLS' && <Table.Cell singleLine style={{ color: data['TY_SLS_AMT'] >= 0 ? 'black' : 'red' }}>
                            {data['TY_SLS_AMT'] != null ?
                              data['TY_SLS_AMT'] >= 0 ?
                                '$' + data['TY_SLS_AMT'].toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) :
                                '-$' + (-1 * data['TY_SLS_AMT']).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) :
                              'Loading'
                            }
                          </Table.Cell>}
                          {/* {metric === 'SLS' && <Table.Cell singleLine style={{ color: data['LY_COMP_SLS_AMT'] >= 0 ? 'black' : 'red'}}>
                              {data['LY_COMP_SLS_AMT'] != null ?
                                data['LY_COMP_SLS_AMT'] >= 0 ?
                                  '$' + data['LY_COMP_SLS_AMT'].toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) :
                                  '-$' + (-1 * data['LY_COMP_SLS_AMT']).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) :
                                'Loading'
                              }
                            </Table.Cell>} */}
                          {metric === 'SLS' && unit === 'NOM' && <Table.Cell singleLine style={{ color: data['SLS_COMP_NOM'] > 0 ? 'green' : data['SLS_COMP_NOM'] < 0 ? 'red' : 'black' }}>
                            {data['SLS_COMP_NOM'] != null ?
                              data['SLS_COMP_NOM'] >= 0 ?
                                '$' + data['SLS_COMP_NOM'].toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) :
                                '-$' + (-1 * data['SLS_COMP_NOM']).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) :
                              'Loading'
                            }
                          </Table.Cell>}
                          {metric === 'SLS' && unit === 'PCT' && <Table.Cell singleLine style={{ color: data['SLS_COMP_PCT'] > 0 ? 'green' : data['SLS_COMP_PCT'] < 0 ? 'red' : 'black' }}>
                            {data['SLS_COMP_PCT'] != null ?
                              (100 * data['SLS_COMP_PCT']).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%' :
                              'Loading'
                            }
                          </Table.Cell>}
                          {/* Units metrics */}
                          {metric === 'UNT' && <Table.Cell singleLine style={{ color: data['TY_UNT_SLS'] >= 0 ? 'black' : 'red' }}>
                            {data['TY_UNT_SLS'] != null ?
                              data['TY_UNT_SLS'].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) :
                              'Loading'
                            }
                          </Table.Cell>}
                          {/* {metric === 'UNT' && <Table.Cell singleLine style={{ color: data['LY_COMP_UNT_SLS'] >= 0 ? 'black' : 'red'}}>
                              {data['LY_COMP_UNT_SLS'] != null ?
                                data['LY_COMP_UNT_SLS'].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) :
                                'Loading'
                              }
                            </Table.Cell>} */}
                          {metric === 'UNT' && unit === 'NOM' && <Table.Cell singleLine style={{ color: data['UNT_COMP_NOM'] > 0 ? 'green' : data['UNT_COMP_NOM'] < 0 ? 'red' : 'black' }}>
                            {data['UNT_COMP_NOM'] != null ?
                              data['UNT_COMP_NOM'].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) :
                              'Loading'
                            }
                          </Table.Cell>}
                          {metric === 'UNT' && unit === 'PCT' && <Table.Cell singleLine style={{ color: data['UNT_COMP_PCT'] > 0 ? 'green' : data['UNT_COMP_PCT'] < 0 ? 'red' : 'black' }}>
                            {data['UNT_COMP_PCT'] != null ?
                              (100 * data['UNT_COMP_PCT']).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%' :
                              'Loading'
                            }
                          </Table.Cell>}
                          {/* Transactions metrics */}
                          {metric === 'TXN' && <Table.Cell singleLine style={{ color: data['TY_TXN_CNT'] >= 0 ? 'black' : 'red' }}>
                            {data['TY_TXN_CNT'] != null ?
                              data['TY_TXN_CNT'].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) :
                              'Loading'
                            }
                          </Table.Cell>}
                          {/* {metric === 'TXN' && <Table.Cell singleLine style={{ color: data['LY_COMP_TXN_CNT'] >= 0 ? 'black' : 'red'}}>
                              {data['LY_COMP_TXN_CNT'] != null ?
                                data['LY_COMP_TXN_CNT'].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) :
                                'Loading'
                              }
                            </Table.Cell>} */}
                          {metric === 'TXN' && unit === 'NOM' && <Table.Cell singleLine style={{ color: data['TXN_COMP_NOM'] > 0 ? 'green' : data['TXN_COMP_NOM'] < 0 ? 'red' : 'black' }}>
                            {data['TXN_COMP_NOM'] != null ?
                              data['TXN_COMP_NOM'].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) :
                              'Loading'
                            }
                          </Table.Cell>}
                          {metric === 'TXN' && unit === 'PCT' && <Table.Cell singleLine style={{ color: data['TXN_COMP_PCT'] > 0 ? 'green' : data['TXN_COMP_PCT'] < 0 ? 'red' : 'black' }}>
                            {data['TXN_COMP_PCT'] != null ?
                              (100 * data['TXN_COMP_PCT']).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%' :
                              'Loading'
                            }
                          </Table.Cell>}
                          {/* Average Ticket Metrics */}
                          {metric === 'AVG_TCKT' && <Table.Cell singleLine style={{ color: data['TY_AVG_TCKT'] >= 0 ? 'black' : 'red' }}>
                            {data['TY_AVG_TCKT'] != null ?
                              data['TY_AVG_TCKT'] >= 0 ?
                                '$' + data['TY_AVG_TCKT'].toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) :
                                '-$' + (-1 * data['TY_AVG_TCKT']).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) :
                              'Loading'
                            }
                          </Table.Cell>}
                          {/* {metric === 'AVG_TCKT' && <Table.Cell singleLine style={{ color: data['LY_COMP_AVG_TCKT'] >= 0 ? 'black' : 'red'}}>
                              {data['LY_COMP_AVG_TCKT'] != null ?
                                data['LY_COMP_AVG_TCKT'] >= 0 ?
                                  '$' + data['LY_COMP_AVG_TCKT'].toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) :
                                  '-$' + (-1 * data['LY_COMP_AVG_TCKT']).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) :
                                'Loading'
                              }
                            </Table.Cell>} */}
                          {metric === 'AVG_TCKT' && unit === 'NOM' && <Table.Cell singleLine style={{ color: data['AVG_TCKT_COMP_NOM'] > 0 ? 'green' : data['AVG_TCKT_COMP_NOM'] < 0 ? 'red' : 'black' }}>
                            {data['AVG_TCKT_COMP_NOM'] != null ?
                              data['AVG_TCKT_COMP_NOM'] >= 0 ?
                                '$' + data['AVG_TCKT_COMP_NOM'].toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) :
                                '-$' + (-1 * data['AVG_TCKT_COMP_NOM']).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) :
                              'Loading'
                            }
                          </Table.Cell>}
                          {metric === 'AVG_TCKT' && unit === 'PCT' && <Table.Cell singleLine style={{ color: data['AVG_TCKT_COMP_PCT'] > 0 ? 'green' : data['AVG_TCKT_COMP_PCT'] < 0 ? 'red' : 'black' }}>
                            {data['AVG_TCKT_COMP_PCT'] != null ?
                              (100 * data['AVG_TCKT_COMP_PCT']).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%' :
                              'Loading'
                            }
                          </Table.Cell>}
                        </Table.Row>
                      )
                    })}
                  </Table.Body>
                </Table>
              </>
            }
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {open && <Modal
        closeIcon
        open={open}
        onClose={() => { setOpen(false) }}
      >
        <Segment color='orange'>
          <Grid>
            <Grid.Column width={16}>
              <Grid.Row>
                <Header textAlign='center'>
                  {modalData['SKU_NBR'].toString().length > 6 ? // Format SKUs, 10-digit (xxxx-xxx-xxx) versus 6-digit (xxx-xxx)
                    modalData['SKU_NBR'].toString().substring(0, 4) + '-' + modalData['SKU_NBR'].toString().substring(4, 7) + '-' + modalData['SKU_NBR'].toString().substring(7) :
                    modalData['SKU_NBR'].toString().substring(0, 3) + '-' + modalData['SKU_NBR'].toString().substring(3)}
                </Header>
              </Grid.Row>
              <Grid.Row>&nbsp;</Grid.Row>
              <Grid.Row><Header>Class</Header></Grid.Row>
              <Grid.Row><p textAlign='center'>{modalData['EXT_CLASS_NBR'] + '-' + modalData['CLASS_DESC']}</p></Grid.Row>
              <Grid.Row>&nbsp;</Grid.Row>
              <Grid.Row><Header>Subclass</Header></Grid.Row>
              <Grid.Row><p textAlign='center'>{modalData['EXT_SUB_CLASS_NBR'] + '-' + modalData['SUB_CLASS_DESC']}</p></Grid.Row>
              <Grid.Row>&nbsp;</Grid.Row>
              <Grid.Row><Header>SKU Description</Header></Grid.Row>
              <Grid.Row><p textAlign='center'>{modalData['SKU_DESC']}</p></Grid.Row>
            </Grid.Column>
            &nbsp;
            &nbsp;

            <Grid.Column>
              <Grid.Row>&nbsp;</Grid.Row>
              <Grid.Row>
                <Button
                  onClick={() => { setOpen(false) }}
                  style={{ color: 'white', backgroundColor: '#EE7125' }}
                  content='Close'
                />
              </Grid.Row>
              <Grid.Row>&nbsp;</Grid.Row>
            </Grid.Column>
          </Grid>
        </Segment>
      </Modal>}
    </>
  )
})