import React, { useState } from 'react'
import {
  Tab,
  Grid,
  Header,
} from 'semantic-ui-react'
import { DataTable } from '../Common/DataTable'
import { topBottomFormat } from '../../utils/topBottomFormat'
import { FORMATMETRIC, LEVEL_KEYS } from '../../constants/deptDrilldown'
import { MetricTopBottomDropdown } from '../Common/MetricTopBottomDropdown'

export const TopBtmSubclassesTab = ({ topBtmSubclasses, level, orgNbr }) => {

  const [metric, setMetric] = useState('SLS_AMT')

  let topBtmSubclassFilter = topBtmSubclasses.filter((row) => {
    return (
      row &&
      row['METRIC'] === metric
    )
  })

  let topBtmSubclassSort = topBtmSubclassFilter.sort((left, right) => {
    return left.VALUE < right.VALUE ? 1 : -1
  })

  let topSubclasses = topBtmSubclassSort.slice(0, 5)
  let btmSubclasses = topBtmSubclassSort.slice(5, 10)

  const dataObjTop = {
    headers: [
      { name: 'Subclass' },
      { name: FORMATMETRIC[metric] }
    ],
    data: topSubclasses.map(row => [
      {
        stringValue: row['NAME'],
        link: {
          to: `/${level}/sales/subclass`,
          params: {
            [LEVEL_KEYS[level]]: orgNbr,
            subClassNbr: row['NAME'].slice(0, 11)
          }
        }
      },
      topBottomFormat({ metric, value: row['VALUE'], table: 'top' })
    ])
  }

  const dataObjBottom = {
    headers: [
      { name: 'Subclass' },
      { name: FORMATMETRIC[metric] }
    ],
    data: btmSubclasses.map(row => [
      {
        stringValue: row['NAME'],
        link: {
          to: `/${level}/sales/subclass`,
          params: {
            [LEVEL_KEYS[level]]: orgNbr,
            subClassNbr: row['NAME'].slice(0, 11)
          }
        }
      },
      topBottomFormat({ metric, value: row['VALUE'], table: 'bottom' })
    ]),
  }

  return (
    <Tab.Pane attached={false}>
      <MetricTopBottomDropdown
        metric={metric}
        setMetric={setMetric}
      />
      <Grid>
        <Grid.Row>
          <Grid.Column width={16} textAlign='center'>
            <Header textAlign='center'>Top Subclasses by {FORMATMETRIC[metric]}</Header>
            <DataTable data={dataObjTop} />
            <Header textAlign='center'>Bottom Subclasses by {FORMATMETRIC[metric]}</Header>
            <DataTable data={dataObjBottom} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Tab.Pane>
  )
}
