import React, { useState } from 'react';
import { Header, Loader, Grid, Icon, Modal, Segment, Table } from 'semantic-ui-react';
import { useQuery } from 'react-query';
import { withRouter, Link } from 'react-router-dom/cjs/react-router-dom.min';
import { getData } from '../../service/DataService';
import { TimeframeDropdownMon } from '../Common/TimeframeDropdownMon';
import { TimeframeDropdown } from '../Common/TimeframeDropdown';
import { DeptAssocToggle } from './DeptAssocToggle';
import { getPercentString } from '../Common/TableCellUtils'
import { ohm_no_loc_tags_goal } from './OSALanding';

export const OHMNoLocationTags = withRouter(({ timeframe, settimeframe, level, fpToggle, locNbr, ...props}) => {

    const [open, setOpen] = useState(false)
    const [DeptOrAssoc, setDeptOrAssoc] = useState("DEPARTMENT")

    const OHMNoLocationTagsQuery = useQuery([`query/osaOHMNoLocTags/${level}`, { locNbr, fpToggle }], getData)

    if (OHMNoLocationTagsQuery.isLoading) {
        return (<Loader active>Loading</Loader>)
    }

    if (!OHMNoLocationTagsQuery.data || OHMNoLocationTagsQuery.data.length === 0) {
        return (<Header textAlign='center'>Invalid Store Number</Header>)
    }

    const OHMNoLocationTagsData = OHMNoLocationTagsQuery.data

    const NoLocSum = OHMNoLocationTagsData.reduce((acc, curr) => acc + curr[timeframe + '_NO_LOC_PALLET_COUNT'] , 0)
    const TotalSum = OHMNoLocationTagsData.reduce((acc, curr) => acc + curr[timeframe + '_TOTAL_PALLET_COUNT'], 0)
    const actual = (NoLocSum/TotalSum)

    //Calculate the timeframe + _NO_LOC_PALLET_COUNT for Dept tab
    let updatedResultDept = OHMNoLocationTagsData.reduce((acc, curr) =>{
        let item = acc.find(item => item.DEPT_NBR === curr.DEPT_NBR);
        if (item) {
          item[timeframe + '_NO_LOC_PALLET_COUNT'] += curr[timeframe + '_NO_LOC_PALLET_COUNT']
          item.DEPT_NM = curr.DEPT_NM
        } else {
          acc.push({
            DEPT_NBR: curr.DEPT_NBR,
            [timeframe + '_NO_LOC_PALLET_COUNT']: curr[timeframe + '_NO_LOC_PALLET_COUNT'],
            DEPT_NM: curr.DEPT_NM,
          });
        }  
        
        item = acc.find(item => item.DEPT_NM === 'All Departments');
        if(item) {
            item[timeframe + '_NO_LOC_PALLET_COUNT'] += curr[timeframe + '_NO_LOC_PALLET_COUNT']
        } else {
            acc.push({
              DEPT_NBR: '',
              [timeframe + '_NO_LOC_PALLET_COUNT']: curr[timeframe + '_NO_LOC_PALLET_COUNT'],
              DEPT_NM: 'All Departments',
            })
          }
        return acc
      },[]);

    //Calculate the timeframe + _NO_LOC_PALLET_COUNT for assoc tab
    let updatedResultAssoc = OHMNoLocationTagsData.reduce((acc, curr) =>{
        let item = acc.find(item => item.CREATOR_NAME === curr.CREATOR_NAME);
        if (item) {
          item[timeframe + '_NO_LOC_PALLET_COUNT'] += curr[timeframe + '_NO_LOC_PALLET_COUNT']
        } else {
          acc.push({
            CREATOR_NAME: curr.CREATOR_NAME,
            [timeframe + '_NO_LOC_PALLET_COUNT']: curr[timeframe + '_NO_LOC_PALLET_COUNT'],
          });
        }
        return acc
    },[]);

    let updatedResultDeptFilter = updatedResultDept.filter((row) => {
        return row[timeframe + '_NO_LOC_PALLET_COUNT'] !== 0
    })

    let updatedResultDeptFilterSort = updatedResultDeptFilter.sort((left, right) => left.DEPT_NM === 'All Departments' ? -1 : right.DEPT_NM === 'All Departments' ? 1 : 0)
    
    let updatedResultAssocFilter = updatedResultAssoc.filter((row) => {
        return row[timeframe + '_NO_LOC_PALLET_COUNT'] >= 2 
    })

    let updatedAssocList = updatedResultAssocFilter.map((data) => {
        const [firstName, lastName] = data.CREATOR_NAME.split(" ");
        const updatedName = `${firstName.slice(0,1)}.${lastName.slice(0, 3)}`
        return { CREATOR_NAME: updatedName, [timeframe + '_NO_LOC_PALLET_COUNT']: data[timeframe + '_NO_LOC_PALLET_COUNT']}
    })

    return (
        <>
        <Segment>
        <Grid>
            <Grid.Column width={8}>
                <Grid.Row>
                <Link style={{ color: '#EE7125' }} to={`/${level}/overview?strNbr=${locNbr}`} data-test='b2o-link'>Overview</Link>
                {' / '}
                <Link style={{ color: '#EE7125' }} to={`/osa?strNbr=${locNbr}`} data-test='b2osa-link'>OSA</Link>
                {` / OHM No Loc Tags`}
                </Grid.Row>
            </Grid.Column>
        </Grid>
        <Grid>
            <Grid.Column width={8} />
            <Grid.Column width={8}>
                {new Date().getDay() === 1 ?
                <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} /> :
                <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />}
            </Grid.Column>
        </Grid>

        <Header textAlign='center'>OHM No Loc Tags % {<Icon name='info circle' color='blue' onClick={()=> { setOpen(true)}} />}</Header>

        <Modal closeIcon open={open} onClose={() => { setOpen(false) }}>
            <Segment>
                <Header textAlign='center'>Overhead Management No Location Tags</Header>
                <p>Store leaders must ensure that associates resolve no location tag issues within 7 days time frame.</p>
                &nbsp;
                <p>To learn more, please review the Overhead Management: {<a href='https://portal.homedepot.com/sites/myapron_en_us/Departments/Assets0001/ohm-how-to-manage.pdf' style={{ textDecoration: 'underline' }}>How to Manage Guide.</a>}</p>
                <p style={{ color: '#3366CC', textDecoration: 'underline', textAlign: 'right' }} onClick={() => { setOpen(false) }}>OK</p>
                &nbsp;
            </Segment>
        </Modal>

        <Table textAlign='center' unstackable celled sortable size='small'>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Actual</Table.HeaderCell>
                    <Table.HeaderCell>Goal</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                     <Table.Row>
                        <Table.Cell style={{ color: actual > ohm_no_loc_tags_goal ? 'red' : 'green'}}>{getPercentString(actual ? actual : 0 )}</Table.Cell>
                        <Table.Cell>{getPercentString(ohm_no_loc_tags_goal)}</Table.Cell>
                     </Table.Row>
            </Table.Body>
        </Table>

        <Header textAlign='center'>OHM No Loc Tags Overview</Header>
        <DeptAssocToggle DeptOrAssoc={DeptOrAssoc} setDeptOrAssoc={setDeptOrAssoc}/>
        {DeptOrAssoc === 'ASSOCIATES' && <p style={{ textAlign:'center'}}>This is the list of associates who made more than 2 no location tags.</p>}
        <Table textAlign='center' unstackable celled sortable size='small'>
            <Table.Header>
                <Table.Row>
                    {DeptOrAssoc === 'DEPARTMENT' && <Table.HeaderCell>Dept</Table.HeaderCell>}
                    {DeptOrAssoc === 'DEPARTMENT' && <Table.HeaderCell>OHM No Loc Tags</Table.HeaderCell>}

                    {DeptOrAssoc === 'ASSOCIATES' && <Table.HeaderCell>Creator</Table.HeaderCell>}
                    {DeptOrAssoc === 'ASSOCIATES' && <Table.HeaderCell>Number of No Loc Tags</Table.HeaderCell>}
                </Table.Row>
            </Table.Header>
            <Table.Body>
            {DeptOrAssoc === 'DEPARTMENT' && updatedResultDeptFilterSort.map((data) => {
                return(
                    <Table.Row>
                        {data.DEPT_NM === 'All Departments' ? <Table.Cell>{data.DEPT_NBR} {data.DEPT_NM}</Table.Cell> : <Table.Cell><Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/osa/ohmnoloc/dept?strNbr=${locNbr}&deptNbr=${data['DEPT_NBR']}`}>{data.DEPT_NBR} {data.DEPT_NM}</Link></Table.Cell>}
                        <Table.Cell style={{ color: 'red'}}>{data[timeframe + '_NO_LOC_PALLET_COUNT']}</Table.Cell>
                    </Table.Row>
            )})}
            {DeptOrAssoc === 'ASSOCIATES' && updatedAssocList.map((data) => {
                return(
                    <Table.Row>
                        {DeptOrAssoc === 'ASSOCIATES' && <Table.Cell>{data.CREATOR_NAME}</Table.Cell>}
                        {DeptOrAssoc === 'ASSOCIATES' && <Table.Cell style={{ color: 'red'}}>{data[timeframe + '_NO_LOC_PALLET_COUNT']}</Table.Cell>}
                    </Table.Row>
            )})}
            </Table.Body>
        </Table>
        </Segment>
        </>
    )
})