import React, { useState } from 'react'
import { Table, Header, Loader, Grid, Menu, Segment, Icon } from 'semantic-ui-react'
import { withRouter, Link } from 'react-router-dom'
import { TimeframeDropdown } from '../Common/TimeframeDropdown'
import { TimeframeDropdownMon } from '../Common/TimeframeDropdownMon'

import { useQuery } from 'react-query'
import {
  getData
} from '../../service/DataService'
import { getPercentString, getCompareStyle } from '../Common/TableCellUtils'

export const SafetyDetailDst = withRouter(({ timeframe, settimeframe, fpToggle, userEmplCtgryNm, location }) => {

  const search = new URLSearchParams(location.search)
  const dstNbr = search.has('dstNbr') ? search.get('dstNbr') : 0

  const incOshaLtGlbiQuery = useQuery(['querySafetyIncOshaLtGlbiByDst', { dstNbr, fpToggle }], getData)

  const [incOshaLt, setIncOshaLt] = useState('_INCIDENT_')
  const [sortColumn, setSortColumn] = useState('STR_NBR')
  const [sortDirection, setSortDirection] = useState('ascending')

  if (incOshaLtGlbiQuery.isLoading) {
    return <Loader active>Loading...</Loader>
  }

  let incOshaLtGlbiData = incOshaLtGlbiQuery.data

  // Separate DST level and STR level rows

  let incOshaLtGlbiStrFilter = incOshaLtGlbiData.filter(row => {
    return row && row['METRIC_LEVEL'] === 'STR'
  })

  let incOshaLtGlbiStrSorted = incOshaLtGlbiStrFilter.sort((left, right) => {
    let result = left[sortColumn] - right[sortColumn]
    if (sortColumn === 'vP' && incOshaLt === '_OSHA_') {
      result = (left[timeframe + '_OSHA_COUNT_WC_TOTAL'] - left[timeframe + '_WC_COUNTS_PLAN']) / left[timeframe + '_WC_COUNTS_PLAN'] -
        (right[timeframe + '_OSHA_COUNT_WC_TOTAL'] - right[timeframe + '_WC_COUNTS_PLAN']) / right[timeframe + '_WC_COUNTS_PLAN']
    }
    if (sortColumn === 'vP' && incOshaLt === '_LOST_TIME_') {
      result = (left[timeframe + '_LOST_TIME_COUNT_WC_TOTAL'] - left[timeframe + '_LT_COUNTS_PLAN']) / left[timeframe + '_LT_COUNTS_PLAN'] -
        (right[timeframe + '_LOST_TIME_COUNT_WC_TOTAL'] - right[timeframe + '_LT_COUNTS_PLAN']) / right[timeframe + '_LT_COUNTS_PLAN']
    }
    if (sortColumn === 'vLY') {
      result = (left[timeframe + incOshaLt + 'COUNT_WC_TOTAL'] - left[timeframe + '_LY' + incOshaLt + 'COUNT_WC_TOTAL']) / left[timeframe + '_LY' + incOshaLt + 'COUNT_WC_TOTAL'] -
        (right[timeframe + incOshaLt + 'COUNT_WC_TOTAL'] - right[timeframe + '_LY' + incOshaLt + 'COUNT_WC_TOTAL']) / right[timeframe + '_LY' + incOshaLt + 'COUNT_WC_TOTAL']
    }
    if (sortDirection === 'descending') {
      result *= -1
    }
    return result
  })

  let incOshaLtGlbiDstFilter = incOshaLtGlbiData.filter(row => {
    return row && row['METRIC_LEVEL'] === 'DST'
  })

  return (
    (userEmplCtgryNm.toUpperCase().includes("SALAR") || userEmplCtgryNm.toUpperCase().includes("OFFICER") || userEmplCtgryNm.toUpperCase().includes("CORP_CNTRC")) ?
      <>
        <Grid>
          <Grid.Column width={8}>
            <Grid.Row>&nbsp;</Grid.Row>
            <Grid.Row>
              <Link style={{ color: '#EE7125' }} to={`/district/overview?dstNbr=${dstNbr}`}>Back to Overview</Link>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={8} />
        </Grid>
        <Header textAlign='center'>Safety - District #{dstNbr}</Header>
        <Header textAlign='center'>Days Safe: {incOshaLtGlbiDstFilter[0].DAYS_SAFE}</Header>
        <Grid>
          <Grid.Column width={8} />
          <Grid.Column width={8}>
            <Grid.Row>Timeframe:</Grid.Row>
            <Grid.Row>
              {new Date().getDay() === 1 ?
                <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} /> :
                <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />}
            </Grid.Row>
            <Grid.Row>&nbsp;</Grid.Row>
          </Grid.Column>
        </Grid>
        <Segment>
          <Header textAlign='center'>Days Safe</Header>
          <Table textAlign='center' unstackable sortable celled size='small'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  sorted={sortColumn === 'STR_NBR' ? sortDirection : null}
                  style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                  onClick={() => {
                    if (sortColumn !== 'STR_NBR') {
                      setSortColumn('STR_NBR')
                      setSortDirection('ascending')
                    } else {
                      sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                    }
                  }}
                >Org # {sortColumn !== 'STR_NBR' && <Icon fitted name='sort' />}</Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sortColumn === 'DAYS_SAFE' ? sortDirection : null}
                  style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                  onClick={() => {
                    if (sortColumn !== 'DAYS_SAFE') {
                      setSortColumn('DAYS_SAFE')
                      setSortDirection('ascending')
                    } else {
                      sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                    }
                  }}
                >Days Safe {sortColumn !== 'DAYS_SAFE' && <Icon fitted name='sort' />}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>DST {dstNbr}</Table.Cell>
                <Table.Cell>{incOshaLtGlbiDstFilter[0].DAYS_SAFE}</Table.Cell>
              </Table.Row>
              {
                incOshaLtGlbiStrSorted.map(data => {
                  return (
                    <Table.Row>
                      <Table.Cell>
                        <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/store/safety?strNbr=${data.STR_NBR}`}>STR {data.STR_NBR}</Link>
                      </Table.Cell>
                      <Table.Cell>{data.DAYS_SAFE}</Table.Cell>
                    </Table.Row>
                  )
                })
              }
            </Table.Body>
          </Table>
        </Segment>
        <Segment>
          <Header textAlign='center'>Safety Summary</Header>
          <Menu fluid attached='top' widths={3} color='orange'>
            <Menu.Item
              name='_INCIDENT_'
              active={incOshaLt === '_INCIDENT_'}
              content='INCIDENTS'
              onClick={() => {
                setIncOshaLt('_INCIDENT_')
              }}
            />
            <Menu.Item
              name='_OSHA_'
              active={incOshaLt === '_OSHA_'}
              content='OSHA'
              onClick={() => {
                setIncOshaLt('_OSHA_')
              }}
            />
            <Menu.Item
              name='_LOST_TIME_'
              active={incOshaLt === '_LOST_TIME_'}
              content='LOST TIME'
              onClick={() => {
                setIncOshaLt('_LOST_TIME_')
              }}
            />
          </Menu>
          <Table textAlign='center' unstackable sortable celled size='small'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  sorted={sortColumn === 'STR_NBR' ? sortDirection : null}
                  style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                  onClick={() => {
                    if (sortColumn !== 'STR_NBR') {
                      setSortColumn('STR_NBR')
                      setSortDirection('ascending')
                    } else {
                      sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                    }
                  }}
                >Org # {sortColumn !== 'STR_NBR' && <Icon fitted name='sort' />}</Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sortColumn === timeframe + incOshaLt + 'COUNT_WC_TOTAL' ? sortDirection : null}
                  style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                  onClick={() => {
                    if (sortColumn !== timeframe + incOshaLt + 'COUNT_WC_TOTAL') {
                      setSortColumn(timeframe + incOshaLt + 'COUNT_WC_TOTAL')
                      setSortDirection('ascending')
                    } else {
                      sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                    }
                  }}
                >Actual {sortColumn !== timeframe + incOshaLt + 'COUNT_WC_TOTAL' && <Icon fitted name='sort' />}</Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sortColumn === 'vP' ? sortDirection : null}
                  style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                  onClick={() => {
                    if (sortColumn !== 'vP') {
                      setSortColumn('vP')
                      setSortDirection('ascending')
                    } else {
                      sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                    }
                  }}
                >vP {sortColumn !== 'vP' && <Icon fitted name='sort' />}</Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sortColumn === 'vLY' ? sortDirection : null}
                  style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                  onClick={() => {
                    if (sortColumn !== 'vLY') {
                      setSortColumn('vLY')
                      setSortDirection('ascending')
                    } else {
                      sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                    }
                  }}
                >vLY {sortColumn !== 'vLY' && <Icon fitted name='sort' />}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>DST {dstNbr}</Table.Cell>
                <Table.Cell>{incOshaLtGlbiDstFilter[0][timeframe + incOshaLt + 'COUNT_WC_TOTAL']}</Table.Cell>
                {incOshaLt === '_INCIDENT_' && <Table.Cell>-</Table.Cell>}
                {incOshaLt === '_OSHA_' && <Table.Cell style={getCompareStyle(incOshaLtGlbiDstFilter[0][timeframe + '_WC_COUNTS_PLAN'] - incOshaLtGlbiDstFilter[0][timeframe + '_OSHA_COUNT_WC_TOTAL'])}>{getPercentString((incOshaLtGlbiDstFilter[0][timeframe + '_OSHA_COUNT_WC_TOTAL'] - incOshaLtGlbiDstFilter[0][timeframe + '_WC_COUNTS_PLAN']) / incOshaLtGlbiDstFilter[0][timeframe + '_WC_COUNTS_PLAN'])}</Table.Cell>}
                {incOshaLt === '_LOST_TIME_' && <Table.Cell style={getCompareStyle(incOshaLtGlbiDstFilter[0][timeframe + '_LT_COUNTS_PLAN'] - incOshaLtGlbiDstFilter[0][timeframe + '_LOST_TIME_COUNT_WC_TOTAL'])}>{getPercentString((incOshaLtGlbiDstFilter[0][timeframe + '_LOST_TIME_COUNT_WC_TOTAL'] - incOshaLtGlbiDstFilter[0][timeframe + '_LT_COUNTS_PLAN']) / incOshaLtGlbiDstFilter[0][timeframe + '_LT_COUNTS_PLAN'])}</Table.Cell>}
                <Table.Cell style={getCompareStyle(incOshaLtGlbiDstFilter[0][timeframe + '_LY' + incOshaLt + 'COUNT_WC_TOTAL'] - incOshaLtGlbiDstFilter[0][timeframe + incOshaLt + 'COUNT_WC_TOTAL'])}>{getPercentString((incOshaLtGlbiDstFilter[0][timeframe + incOshaLt + 'COUNT_WC_TOTAL'] - incOshaLtGlbiDstFilter[0][timeframe + '_LY' + incOshaLt + 'COUNT_WC_TOTAL']) / incOshaLtGlbiDstFilter[0][timeframe + '_LY' + incOshaLt + 'COUNT_WC_TOTAL'])}</Table.Cell>
              </Table.Row>
              {incOshaLtGlbiStrSorted.map(data => {
                return (
                  <Table.Row>
                    <Table.Cell>
                      <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/store/safety?strNbr=${data.STR_NBR}`}>STR {data.STR_NBR}</Link>
                    </Table.Cell>
                    <Table.Cell>{data[timeframe + incOshaLt + 'COUNT_WC_TOTAL']}</Table.Cell>
                    {incOshaLt === '_INCIDENT_' && <Table.Cell>-</Table.Cell>}
                    {incOshaLt === '_OSHA_' && <Table.Cell style={getCompareStyle(data[timeframe + '_WC_COUNTS_PLAN'] - data[timeframe + '_OSHA_COUNT_WC_TOTAL'])}>{getPercentString((data[timeframe + '_OSHA_COUNT_WC_TOTAL'] - data[timeframe + '_WC_COUNTS_PLAN']) / data[timeframe + '_WC_COUNTS_PLAN'])}</Table.Cell>}
                    {incOshaLt === '_LOST_TIME_' && <Table.Cell style={getCompareStyle(data[timeframe + '_LT_COUNTS_PLAN'] - data[timeframe + '_LOST_TIME_COUNT_WC_TOTAL'])}>{getPercentString((data[timeframe + '_LOST_TIME_COUNT_WC_TOTAL'] - data[timeframe + '_LT_COUNTS_PLAN']) / data[timeframe + '_LT_COUNTS_PLAN'])}</Table.Cell>}
                    <Table.Cell style={getCompareStyle(data[timeframe + '_LY' + incOshaLt + 'COUNT_WC_TOTAL'] - data[timeframe + incOshaLt + 'COUNT_WC_TOTAL'])}>{getPercentString((data[timeframe + incOshaLt + 'COUNT_WC_TOTAL'] - data[timeframe + '_LY' + incOshaLt + 'COUNT_WC_TOTAL']) / data[timeframe + '_LY' + incOshaLt + 'COUNT_WC_TOTAL'])}</Table.Cell>
                  </Table.Row>
                )
              })}
            </Table.Body>
          </Table>
        </Segment>
      </> :
      <>
        <Header textAlign='center'>User does not have permission to access Safety Detail page. Please return to Overview.</Header>
      </>

  )

})