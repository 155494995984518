import { Segment, Tab } from 'semantic-ui-react';

import LaborDetailContextProvider from '../../context/LaborDetailContext';
import { LaborDetailsRgnOverview } from './Region/LaborDetailsOverview';

export const LaborDetailRgn = ({ fpToggle, settimeframe, timeframe, userEmplCtgryNm }) => {
  const panes = [
    {
      render: () => <LaborDetailsRgnOverview />
    }
  ];

  return (
    <LaborDetailContextProvider
      fpToggle={fpToggle}
      settimeframe={settimeframe}
      timeframe={timeframe}
      userEmplCtgryNm={userEmplCtgryNm}>
      <Segment>
        <Tab panes={panes} />
      </Segment>
    </LaborDetailContextProvider>
  );
};
