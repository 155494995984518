import { DataTable } from "../Common/DataTable";
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import {
  Table,
  Tab,
  Grid,
  Header,
  Dropdown,
  Button,
  Loader,
} from 'semantic-ui-react';
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { UnitMenu } from '../Common/UnitMenu'
import { withRouter } from 'react-router-dom';
import { getData } from '../../service/DataService';



export const Top40Btm40Skus = withRouter(({ timeframe, Top40Btm40Query, ...props }) => {
var timeframeCode = {
    "WTD": 3,
    "MTD": 5,
    "QTD": 7,
    "HTD": 9,
    "YTD": 11,
    "LW": 15,
    "LM": 16,
    "L12": 33
  }

  const [sortOption, setSortOption] = useState('TY_SLS_AMT');
  const [rankOrder, setRankOrder] = useState('Top');
  const [unit, setUnit] = useState("NOM")

  let dataFiltered;
    if (Top40Btm40Query.data) {
      dataFiltered = Top40Btm40Query.data.filter((row) => {
        return row && row['TRNSFM_TYP_SORT_ID'] === timeframeCode[timeframe];
      });
  }

  const sortedData = React.useMemo(() => {
    if (!dataFiltered) return [];
  
    return [...dataFiltered].sort((a, b) => {
      if (sortOption === 'TY_SLS_AMT') {
        if (b.TY_SLS_AMT !== a.TY_SLS_AMT) {
          return rankOrder === 'Top' ? b.TY_SLS_AMT - a.TY_SLS_AMT : a.TY_SLS_AMT - b.TY_SLS_AMT; // primary sort by TY_SLS_AMT
        }
        return rankOrder === 'Top' ? b.TY_UNT_SLS - a.TY_UNT_SLS : a.TY_UNT_SLS - b.TY_UNT_SLS; // secondary sort by TY_UNT_SLS
      } else if (sortOption === 'TY_UNT_SLS') {
        if (b.TY_UNT_SLS !== a.TY_UNT_SLS) {
          return rankOrder === 'Top' ? b.TY_UNT_SLS - a.TY_UNT_SLS : a.TY_UNT_SLS - b.TY_UNT_SLS; // primary sort by TY_UNT_SLS
        }
        return rankOrder === 'Top' ? b.TY_SLS_AMT - a.TY_SLS_AMT : a.TY_SLS_AMT - b.TY_SLS_AMT; // secondary sort by TY_SLS_AMT
      } else {
        return 0;
      }
    }).slice(0, 40); // limit to the first 40 items
  }, [dataFiltered, sortOption, rankOrder]);

  const sortOptions = [
    { key: 'TY_SLS_AMT', text: 'Sales', value: 'TY_SLS_AMT' },
    { key: 'TY_UNT_SLS', text: 'Units', value: 'TY_UNT_SLS' },
  ];

  const handleRankOrderToggle = () => {
    setRankOrder(prevRankOrder => (prevRankOrder === 'Top' ? 'Bottom' : 'Top'));
  };
  const handleSetUnit = (unit) => {
    if (unit === 'NOM' || unit === 'PCT') {
      setUnit(unit);
    } else {
      console.error(`Invalid unit: ${unit}. Unit can only be 'NOM' or 'PCT'.`);
    }
  };
  let name
    switch (unit + sortOption) {
        case 'NOMTY_SLS_AMT':
            name = 'Sales vLY $';
            break;
        case 'PCTTY_SLS_AMT':
            name = "Sales vLY %";
            break;
        case 'NOMTY_UNT_SLS':
            name = "Units vLY #";
            break;
        case 'PCTTY_UNT_SLS':
            name = "Units vLY %";
            break;
        default:
            console.error(`Invalid unit: ${unit}. Unit can only be 'NOM' or 'PCT'.`);
            break;
        }
  const top40Btm40Data = {
    sortable: true,
    headers: [
      {name: 'Rank'}, 
      {name: 'SKU#'}, 
      {name: 'SKU DESC'}, 
      {name: 'DEPT'}, 
      {name: sortOption === 'TY_SLS_AMT' ? 'Sales Amt $' : 'Units'},
      {name: name}
    ],
    data: sortedData
    .map((row, index) => {
        let vLY;
        let metric;
        switch (unit + sortOption) {
            case 'NOMTY_SLS_AMT':
                vLY = {dollarValue: row.SLS_COMP_NOM, compareStyle: true};
                metric = {dollarValue: row.TY_SLS_AMT, compareStyle: true};
                break;
            case 'PCTTY_SLS_AMT':
                vLY = {pctValue: row.SLS_COMP_PCT, compareStyle: true};
                metric = {dollarValue: row.TY_SLS_AMT, compareStyle: true};
                break;
            case 'NOMTY_UNT_SLS':
                vLY = {numValue: row.UNT_COMP_NOM, decimals: 0, compareStyle: true};
                metric = {numValue: row.TY_UNT_SLS, decimals: 0};
                break;
            case 'PCTTY_UNT_SLS':
                vLY = {pctValue: row.UNT_COMP_PCT, compareStyle: true};
                metric = {numValue: row.TY_UNT_SLS, decimals: 0};
                break;
            default:
                console.error(`Invalid unit: ${unit}. Unit can only be 'NOM' or 'PCT'.`);
                break;
            }

      return [
        {numValue: index + 1}, // Rank
        {numValue: row.SKU_NBR}, // SKU#
        {stringValue: row.SKU_DESC}, // SKU DESC
        {numValue: row.DEPT_NBR}, // DEPT#
        metric,
        vLY // Sales vLY %
      ]
    })
  }


  

  return (
    <Tab.Pane>
      <Grid>
        <Grid.Column>
          <Grid.Row>
            <Header as='h3' textAlign='center'>Top 40 & Bottom 40 Skus</Header>
          </Grid.Row>
          <Grid.Row>Metrics:
            <Dropdown
              placeholder='Select Sort Option'
              selection
              compact
              size='tiny'
              options={sortOptions}
              value={sortOption}
              onChange={(e, { value }) => setSortOption(value)}
              style={{ width: '150px' }}
            />
            <Button onClick={handleRankOrderToggle} style={{ marginLeft: '10px' }}>
              {rankOrder === 'Top' ? 'Show Bottom' : 'Show Top'}
            </Button>
            <Grid.Row>Units:</Grid.Row>
            <UnitMenu unit={unit} setUnit={setUnit} />
          </Grid.Row>
        </Grid.Column>
      </Grid>
     <DataTable data={top40Btm40Data} unit={unit} />
    </Tab.Pane>
  );
});
