export const INTRADAY_CONSTANTS = {
    store: {
        hourlyQuery: 'querySlsIntradayByStr',
        deptOverviewQuery: 'querySlsIntradayDeptOverviewByStr',
        METRIC_LEVEL: 'STR',
        urlParam: 'strNbr'
    },
    district: {
        hourlyQuery: 'querySlsIntradayByDst',
        deptOverviewQuery: 'querySlsIntradayDeptOverviewByDst',
        METRIC_LEVEL: 'DST',
        urlParam: 'dstNbr'
    },
    region: {
        hourlyQuery: 'querySlsIntradayByRgn',
        deptOverviewQuery: 'querySlsIntradayDeptOverviewByRgn',
        METRIC_LEVEL: 'RGN',
        urlParam: 'rgnNbr'
    }
}