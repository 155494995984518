import { useState } from 'react';

import { Grid, Header, Tab } from 'semantic-ui-react';
import { DataTable } from '../../Common/DataTable';
import { Link } from '../../Common/Link';
import { MenuItems } from '../../Common/MenuItems';
import { UnitMenu } from '../../Common/UnitMenu';
import { TimeframeDropdown } from '../../Common/TimeframeDropdown';

import { useQueryParams } from '../../../hooks/useQueryParams';
import { useLaborDetailContext } from '../../../hooks/useLaborDetailContext';

import { getRoundToDecimalString } from '../../Common/TableCellUtils';

const WEEKDAY_COMPARASIONS_TEXT = { AVF: 'AvF', AVS: 'AvS' };
const WEEKDAY_KEYS = { AVF: '_FCST_HRS', AVS: '_SCH_HRS' };

export const LaborDetailsRgnOverview = () => {
  const [days, setDays] = useState('Total');
  const [unit, setUnit] = useState('NOM');
  const [dataPerspective, setDataPerspective] = useState('AVF');
  const { rgnNbr } = useQueryParams();
  const { dataLaborOverview, isHourlyEmployee, settimeframe, timeframe } = useLaborDetailContext();

  const isMonday = new Date().getDay() === 1;
  const isDaysTotal = days === 'Total';
  const isDaysWeekend = days === 'Wkdy/Wknd';
  const isUnitPCT = unit === 'PCT';
  const isTimeframeWTD = timeframe === 'WTD';
  const isForecastActive = dataPerspective === 'AVF';

  const getOtHours = row => {
    if (!isTimeframeWTD) return getRoundToDecimalString(row[timeframe + '_OT_HRS']);
    if (isMonday) return getRoundToDecimalString(row['WTD_OT_HRS']);

    return getRoundToDecimalString(row['WTD_PROJ_OT_HRS']);
  };

  const dataTotalObj = {
    data: dataLaborOverview.map(row => {
      const isManager = row.CLASSIFICATION === 'Manager';
      const isHrlEmplAndManager = isHourlyEmployee && isManager;
      const actualLessForecastValue = row[timeframe + '_ACT_HRS'] - row[timeframe + '_FCST_HRS'];
      const actualLessScheduleValue = row[timeframe + '_ACT_HRS'] - row[timeframe + '_SCH_HRS'];

      return [
        {
          stringValue: row['CLASSIFICATION']
        },
        {
          stringValue: isHrlEmplAndManager ? '-' : getRoundToDecimalString(row[timeframe + '_ACT_HRS'])
        },
        {
          stringValue: isHrlEmplAndManager ? '-' : getOtHours(row)
        },
        {
          customStyle: isUnitPCT ? { color: row[timeframe + '_VF_PCT'] < -0.001 ? 'red' : 'green' } : {},
          decimals: isUnitPCT ? 2 : undefined,
          pctValue: isUnitPCT ? actualLessForecastValue / row[timeframe + '_FCST_HRS'] : undefined,
          stringValue: !isUnitPCT ? (isManager ? '-' : getRoundToDecimalString(actualLessForecastValue)) : undefined
        },
        {
          customStyle: isUnitPCT ? { color: row[timeframe + '_VS_PCT'] < -0.001 ? 'red' : 'green' } : {},
          decimals: isUnitPCT ? 2 : undefined,
          pctValue: isUnitPCT ? actualLessScheduleValue / row[timeframe + '_SCH_HRS'] : undefined,
          stringValue: !isUnitPCT ? (isHourlyEmployee ? '-' : getRoundToDecimalString(actualLessScheduleValue)) : undefined
        }
      ];
    }),
    headers: [
      { name: '' },
      { name: 'HRS' },
      { name: 'PROJ O/T' },
      { name: `AvF ${isUnitPCT ? '%' : ''}` },
      { name: `AvS ${isUnitPCT ? '%' : ''}` }
    ]
  };

  const dataWeekDayObj = {
    data: dataLaborOverview.map(row => {
      const isManager = row.CLASSIFICATION === 'Manager';
      const isHrlEmplAndManager = isHourlyEmployee && isManager;
      const versusCondition = isForecastActive ? isManager : isHrlEmplAndManager;

      const weekdayComparasionValue = row[timeframe + `${WEEKDAY_KEYS[dataPerspective]}_WEEKDAY`];
      const weekendComparasionValue = row[timeframe + `${WEEKDAY_KEYS[dataPerspective]}_WEEKEND`];
      const totalComparasionValue = row[timeframe + WEEKDAY_KEYS[dataPerspective]];

      const weekdayValue = row[timeframe + '_ACT_HRS_WEEKDAY'] - weekdayComparasionValue;
      const weekendValue = row[timeframe + '_ACT_HRS_WEEKEND'] - weekendComparasionValue;
      const totalValue = row[timeframe + '_ACT_HRS'] - totalComparasionValue;
      const perspectiveMetric = isForecastActive ? '_VF' : '_VS';

      return [
        {
          stringValue: row['CLASSIFICATION']
        },
        {
          customStyle: isUnitPCT
            ? { color: row[timeframe + `${perspectiveMetric}_PCT_WEEKDAY`] < -0.001 ? 'red' : 'green' }
            : {},
          decimals: isUnitPCT ? 2 : undefined,
          pctValue: isUnitPCT ? weekdayValue / weekdayComparasionValue : undefined,
          stringValue: !isUnitPCT ? (versusCondition ? '-' : getRoundToDecimalString(weekdayValue)) : undefined
        },
        {
          customStyle: isUnitPCT
            ? { color: row[timeframe + `${perspectiveMetric}_PCT_WEEKEND`] < -0.001 ? 'red' : 'green' }
            : {},
          decimals: isUnitPCT ? 2 : undefined,
          pctValue: isUnitPCT ? weekendValue / weekendComparasionValue : undefined,
          stringValue: !isUnitPCT ? (versusCondition ? '-' : getRoundToDecimalString(weekendValue)) : undefined
        },
        {
          customStyle: isUnitPCT ? { color: row[timeframe + `${perspectiveMetric}_PCT`] < -0.001 ? 'red' : 'green' } : {},
          decimals: isUnitPCT ? 2 : undefined,
          pctValue: isUnitPCT ? totalValue / totalComparasionValue : undefined,
          stringValue: !isUnitPCT ? (versusCondition ? '-' : getRoundToDecimalString(totalValue)) : undefined
        }
      ];
    }),
    headers: [
      { name: '' },
      { name: `WKDY ${WEEKDAY_COMPARASIONS_TEXT[dataPerspective]} ${isUnitPCT ? '%' : ''}` },
      { name: `WKND ${WEEKDAY_COMPARASIONS_TEXT[dataPerspective]} ${isUnitPCT ? '%' : ''}` },
      { name: `Total ${WEEKDAY_COMPARASIONS_TEXT[dataPerspective]} ${isUnitPCT ? '%' : ''}` }
    ]
  };

  return (
    <Tab.Pane attached={false}>
      <Grid columns={2}>
        <Grid.Row style={{ paddingBottom: 0 }}>
          <Grid.Column>
            <Link to={`/district/overview?dstNbr=${rgnNbr}`} data-test='back-overview' text='Back to Overview' />
          </Grid.Column>

          <Grid.Column>
            <span>Timeframe:</span>
            <TimeframeDropdown settimeframe={settimeframe} timeframe={timeframe} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ paddingBottom: 0, paddingTop: '1rem' }}>
          <Grid.Column>
            <Grid.Row>
              <span>Units:</span>
            </Grid.Row>
            <UnitMenu unit={unit} setUnit={setUnit} />
          </Grid.Column>

          <Grid.Column>
            <MenuItems activeItem={days} dataTest='totalVsWkdy' items={['Total', 'Wkdy/Wknd']} setItem={setDays} />
          </Grid.Column>
        </Grid.Row>

        {isDaysWeekend && (
          <Grid.Row style={{ paddingTop: 0 }}>
            <Grid.Column floated='right' textAlign='right'>
              <MenuItems
                activeItem={dataPerspective}
                dataTest='dataPerspective'
                items={['AVF', 'AVS']}
                setItem={setDataPerspective}
              />
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>

      <Header data-test='region-header' textAlign='center'>
        Labor Overview - Region #{rgnNbr}
      </Header>

      {isDaysTotal && <DataTable data={dataTotalObj} />}

      {isDaysWeekend && <DataTable data={dataWeekDayObj} />}
    </Tab.Pane>
  );
};
