import React, { useState } from 'react'
import { Table, Header, Loader, Grid, Dropdown, Segment } from 'semantic-ui-react'
import { withRouter, Link } from 'react-router-dom'
import { TimeframeDropdown } from '../Common/TimeframeDropdown'
import { TimeframeDropdownMon } from '../Common/TimeframeDropdownMon'

import { useQuery } from 'react-query'
import {
  getData
} from '../../service/DataService'
import { getPercentString } from '../Common/TableCellUtils'
import { useQueryParams } from '../../hooks/useQueryParams'
import { VocDetailsComponent } from './VocDetailsComponent'

const METRIC_CONSTANTS = {
  district: {
    query: 'queryOnlineVocSmryDstByDst',
    METRIC_LEVEL: 'DST',
    urlParam: 'dstNbr'
  },
  region: {
    query: 'queryOnlineVocSmryDstByRgn',
    METRIC_LEVEL: 'RGN',
    urlParam: 'rgnNbr'
  }
}

export const VocDetailDst = ({ timeframe, settimeframe, fpToggle, level, levelName }) => {

  const { dstNbr = 0, rgnNbr = 0 } = useQueryParams()
  const [metric, setMetric] = useState('LTPA')
  const queryParams = level === 'district' ? { dstNbr } : { locNbr: rgnNbr }
  const vocQuery = useQuery([METRIC_CONSTANTS[level]['query'], { ...queryParams, fpToggle }], getData)
  const onlineVocQuery = useQuery(['queryOnlineVocSmryStrByDst', { dstNbr, fpToggle }], getData)

  if (vocQuery.isLoading || onlineVocQuery.isLoading) {
    return <Loader active>Loading...</Loader>
  }

  let overviewDataFilter = vocQuery.data.filter((row) => {
    return row && row['TIME_FRAME'] === timeframe
  })

  let strDataFilterBopis = onlineVocQuery.data.filter((row) => {
    return row && row['TIME_FRAME'] === timeframe && row['ORDER_TYPE'] === 'BOPIS'
  })

  let strDataFilterBoss = onlineVocQuery.data.filter((row) => {
    return row && row['TIME_FRAME'] === timeframe && row['ORDER_TYPE'] === 'BOSS'
  })

  let strDataFilterMixed = onlineVocQuery.data.filter((row) => {
    return row && row['TIME_FRAME'] === timeframe && row['ORDER_TYPE'] === 'MIXED'
  })

  let strDataFilterTotal = onlineVocQuery.data.filter((row) => {
    return row && row['TIME_FRAME'] === timeframe && row['ORDER_TYPE'] === 'TOTAL'
  })

  let strDataTotalSorted = strDataFilterTotal.sort((left, right) => {
    return left.STR_NBR - right.STR_NBR
  })

  return (
    <Segment fluid>
      <Grid>
        <Grid.Row>
          <Grid.Column width={8} textAlign='center'>
            <Grid.Row>&nbsp;</Grid.Row>
            {
              <Link
                style={{ color: '#EE7125' }}
                to={`/${level}/overview?${METRIC_CONSTANTS[level]['urlParam']}=${dstNbr || rgnNbr}`}
                data-test='b2o-link'>
                Back to Overview
              </Link>
            }
          </Grid.Column>
          <Grid.Column width={8}>
            Timeframe:
            {new Date().getDay() === 1 ?
              <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} /> :
              <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <VocDetailsComponent
        dataFilter={overviewDataFilter}
        level={level}
        levelNumber={rgnNbr || dstNbr}
        levelName={levelName}
      />
      &nbsp;
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            Select Metric:
            <Dropdown
              fluid
              compact
              selection
              defaultValue='LTPA'
              options={[
                { key: 'LTPA', text: 'LTPA', value: 'LTPA' },
                { key: 'ProdCond', text: 'Product Condition', value: 'ProdCond' },
                { key: 'OrderAcc', text: 'Order Accuracy', value: 'OrderAcc' },
                { key: 'WaitTm', text: 'Reasonable Wait Time', value: 'WaitTm' }
              ]}
              onChange={(_, { value }) => {
                setMetric(value)
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Header textAlign='center'>Online VOC By Store</Header>

      <Table textAlign='center' unstackable celled sortable size='small' style={{ fontSize: 10 }} >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Store #</Table.HeaderCell>
            <Table.HeaderCell>BOPIS</Table.HeaderCell>
            <Table.HeaderCell>BOSS</Table.HeaderCell>
            <Table.HeaderCell>MIXED</Table.HeaderCell>
            <Table.HeaderCell>TOTAL</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {(strDataTotalSorted).map((total) => {
            return (
              <Table.Row>
                <Table.Cell><Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/store/voc?strNbr=${total['STR_NBR']}`}>{total['STR_NBR']}</Link></Table.Cell>
                {metric === 'LTPA' && <Table.Cell>
                  {strDataFilterBopis.filter((row) => { return row && row['STR_NBR'] === total['STR_NBR'] }).length > 0 ?
                    getPercentString(strDataFilterBopis.filter((row) => { return row && row['STR_NBR'] === total['STR_NBR'] })[0].SATISFACTION_SCORE) : '-'}</Table.Cell>}
                {metric === 'LTPA' && <Table.Cell>
                  {strDataFilterBoss.filter((row) => { return row && row['STR_NBR'] === total['STR_NBR'] }).length > 0 ?
                    getPercentString(strDataFilterBoss.filter((row) => { return row && row['STR_NBR'] === total['STR_NBR'] })[0].SATISFACTION_SCORE) : '-'}</Table.Cell>}
                {metric === 'LTPA' && <Table.Cell>
                  {strDataFilterMixed.filter((row) => { return row && row['STR_NBR'] === total['STR_NBR'] }).length > 0 ?
                    getPercentString(strDataFilterMixed.filter((row) => { return row && row['STR_NBR'] === total['STR_NBR'] })[0].SATISFACTION_SCORE) : '-'}</Table.Cell>}
                {metric === 'LTPA' && <Table.Cell>{getPercentString(total.SATISFACTION_SCORE)}</Table.Cell>}

                {metric === 'ProdCond' && <Table.Cell>
                  {strDataFilterBopis.filter((row) => { return row && row['STR_NBR'] === total['STR_NBR'] }).length > 0 ?
                    getPercentString(strDataFilterBopis.filter((row) => { return row && row['STR_NBR'] === total['STR_NBR'] })[0].PRODUCT_CONDITION_RATING) : '-'}</Table.Cell>}
                {metric === 'ProdCond' && <Table.Cell>
                  {strDataFilterBoss.filter((row) => { return row && row['STR_NBR'] === total['STR_NBR'] }).length > 0 ?
                    getPercentString(strDataFilterBoss.filter((row) => { return row && row['STR_NBR'] === total['STR_NBR'] })[0].PRODUCT_CONDITION_RATING) : '-'}</Table.Cell>}
                {metric === 'ProdCond' && <Table.Cell>
                  {strDataFilterMixed.filter((row) => { return row && row['STR_NBR'] === total['STR_NBR'] }).length > 0 ?
                    getPercentString(strDataFilterMixed.filter((row) => { return row && row['STR_NBR'] === total['STR_NBR'] })[0].PRODUCT_CONDITION_RATING) : '-'}</Table.Cell>}
                {metric === 'ProdCond' && <Table.Cell>{getPercentString(total.PRODUCT_CONDITION_RATING)}</Table.Cell>}

                {metric === 'OrderAcc' && <Table.Cell>
                  {strDataFilterBopis.filter((row) => { return row && row['STR_NBR'] === total['STR_NBR'] }).length > 0 ?
                    getPercentString(strDataFilterBopis.filter((row) => { return row && row['STR_NBR'] === total['STR_NBR'] })[0].ORDER_FULFILLMENT_ACCURACY) : '-'}</Table.Cell>}
                {metric === 'OrderAcc' && <Table.Cell>
                  {strDataFilterBoss.filter((row) => { return row && row['STR_NBR'] === total['STR_NBR'] }).length > 0 ?
                    getPercentString(strDataFilterBoss.filter((row) => { return row && row['STR_NBR'] === total['STR_NBR'] })[0].ORDER_FULFILLMENT_ACCURACY) : '-'}</Table.Cell>}
                {metric === 'OrderAcc' && <Table.Cell>
                  {strDataFilterMixed.filter((row) => { return row && row['STR_NBR'] === total['STR_NBR'] }).length > 0 ?
                    getPercentString(strDataFilterMixed.filter((row) => { return row && row['STR_NBR'] === total['STR_NBR'] })[0].ORDER_FULFILLMENT_ACCURACY) : '-'}</Table.Cell>}
                {metric === 'OrderAcc' && <Table.Cell>{getPercentString(total.ORDER_FULFILLMENT_ACCURACY)}</Table.Cell>}

                {metric === 'WaitTm' && <Table.Cell>
                  {strDataFilterBopis.filter((row) => { return row && row['STR_NBR'] === total['STR_NBR'] }).length > 0 ?
                    getPercentString(strDataFilterBopis.filter((row) => { return row && row['STR_NBR'] === total['STR_NBR'] })[0].WAIT_TIME_5) : '-'}</Table.Cell>}
                {metric === 'WaitTm' && <Table.Cell>
                  {strDataFilterBoss.filter((row) => { return row && row['STR_NBR'] === total['STR_NBR'] }).length > 0 ?
                    getPercentString(strDataFilterBoss.filter((row) => { return row && row['STR_NBR'] === total['STR_NBR'] })[0].WAIT_TIME_5) : '-'}</Table.Cell>}
                {metric === 'WaitTm' && <Table.Cell>
                  {strDataFilterMixed.filter((row) => { return row && row['STR_NBR'] === total['STR_NBR'] }).length > 0 ?
                    getPercentString(strDataFilterMixed.filter((row) => { return row && row['STR_NBR'] === total['STR_NBR'] })[0].WAIT_TIME_5) : '-'}</Table.Cell>}
                {metric === 'WaitTm' && <Table.Cell>{getPercentString(total.WAIT_TIME_5)}</Table.Cell>}
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    </Segment>
  )
}