import React, { useState, useEffect } from "react";
import { Header, Loader, Grid, Icon, Segment, Table } from 'semantic-ui-react';
import { withRouter, Link, Route } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getData } from '../../service/DataService';
import { getPercentString } from "../Common/TableCellUtils";
import { DataTable } from '../Common/DataTable';

import { TimeframeDropdownLimited } from '../Common/TimeframeDropdownLimited'
import { TimeframeDropdownMonLimited } from '../Common/TimeframeDropdownMonLimited'

export const ManagerAddedBays = withRouter(({ timeframe, settimeframe, level, fpToggle, locNbr, location, ...props }) => {
    const { data, isError, isLoading } = useQuery([`queryOSASidekickSkuBayDateDtlBreakDown`, { strNbr: locNbr, fpToggle }], getData);

    if (isLoading) {
        return <Loader active>Loading</Loader>;
    }
    if (isError) {
      return <p>Something went wrong.</p>;
    }
    if (!data || data.length === 0) {
        return <Header textAlign='center'>Invalid Store Number</Header>;
    }
    
    const weekDetail = data.filter(({ WTD_FLG, LW_FLG }) => timeframe === 'WTD' ? WTD_FLG === 1 : LW_FLG === 1);
    const dailyData = weekDetail.reduce((acc, row) => {
        if (row.TASK_ORIGIN !== "MANAGER") {
            return acc; // Skip tasks not added by manager
        }

        const [year, month, day] = row.CREATED_DT.split('-').map(Number);
        const date = new Date(year, month - 1, day);
        const dayOfWeek = date.getDay();
        acc[dayOfWeek].mgrBay += row.TOTAL_TASK_COUNT ;
        acc[dayOfWeek].comBay += row.COMPLETED_TASK_COUNT ;
       
        return acc;
    }, Array(7).fill().map(() =>({ mgrCom: 0, mgrBay: 0, comBay: 0 })));
    // Move index 0 (Sunday) to the end
    dailyData.push(dailyData.shift());

    
    let currentDay = new Date().getDay();
    let displayTimeframe = timeframe;
    if (currentDay === 1) { // 1 represents Monday
        if (timeframe === 'WTD') {
            displayTimeframe = 'LW';
        } else if (timeframe === 'LW') {
            displayTimeframe = 'LLW';
        }
    }

    const managerDataFilterBay = data.filter((row) => {
        return  row['TASK_ORIGIN'] === 'MANAGER'
        && (
            (row.WTD_FLG === 1 && timeframe === 'WTD')
            || (row.LW_FLG === 1 && timeframe === 'LW')
          )

    });

    const TaskCompBay = managerDataFilterBay.reduce((acc, curr) => {
        acc['COMPLETED_TASK_COUNT'] += curr['COMPLETED_TASK_COUNT'];
        acc[ 'TOTAL_TASK_COUNT'] += curr[ 'TOTAL_TASK_COUNT'];
        acc.TASKSCOMPPCT = acc[ 'TOTAL_TASK_COUNT'] !== 0 ? acc['COMPLETED_TASK_COUNT'] / acc[ 'TOTAL_TASK_COUNT'] : 0;
        return acc;
    }, { COMPLETED_TASK_COUNT: 0, TOTAL_TASK_COUNT: 0, TASKSCOMPPCT: 0 });
    const dataObjTasks = {
        headers: [
            {name: 'Ttl Tasks Comp #'}, {name: 'Ttl Tasks Added  #'}, {name: 'Ttl Tasks Comp %'}
        ],
        data: [
            [
                {numValue: TaskCompBay['COMPLETED_TASK_COUNT'] || 0},
                {numValue: TaskCompBay[ 'TOTAL_TASK_COUNT'] || 0},
                {pctValue:TaskCompBay.TASKSCOMPPCT}
            ]
        ]
    }

    let sortedData;
    if (managerDataFilterBay.length > 0) {
        // If there are tasks with TASK_ORIGIN of 'MANAGER', sort them
        sortedData = managerDataFilterBay.sort((a, b) => a.DEPT_NBR - b.DEPT_NBR);
    } else {
        // If there are no tasks with TASK_ORIGIN of 'MANAGER', sort all tasks
        sortedData = data.sort((a, b) => a.DEPT_NBR - b.DEPT_NBR);
    }

    const dataObjDaily = {
        headers: [
            {name: ''}, {name: 'M'}, {name: 'T'}, {name: 'W'}, {name: 'T'}, {name: 'F'}, {name: 'S'}, {name: 'S'}
        ],
        data: [
            [
                {stringValue: 'MGR'},
                ...dailyData.map((item) => ({
                    numValue: item.mgrBay ? item.mgrBay : undefined,
                    stringValue: item.mgrBay ? undefined : '-'
                }))
            ],
            [
                {stringValue: 'COM'},
                ...dailyData.map((item) => ({
                    numValue: item.comBay ? item.comBay : undefined,
                    stringValue: item.comBay ? undefined : '-'
                })),
            ]
        ]
    }

    const dataObjDtl = {
        headers: [
            {name: 'Bay'}, {name: 'Dept'}, {name: 'Num of Skus Packed Down'}, {name: 'Associate'}, {name: 'Comp Date'}
        ],
        data: sortedData.map(item => [
            {stringValue: item.AISLE_BAY},
            {stringValue: item.DEPT_NM},
            {numValue: item.SKUS_PACKED_DOWN},
            {stringValue: item.assoc || 'N/A'},
            {stringValue: item.CREATED_DT}
        ])
    }

    return (
        <>
            <Segment>
                <Grid>
                    <Grid.Column width={8}>
                        <Grid.Row>
                            <Link style={{ color: '#EE7125' }} to={`/${level}/overview?strNbr=${locNbr}`} data-test='b2o-link'>Overview</Link>
                            {' / '}
                            <Link style={{ color: '#EE7125' }} to={`/osa?strNbr=${locNbr}`} data-test='b2osa-link'>OSA</Link>
                            {' / '}
                            <Link style={{ color: '#EE7125' }} to={`/osa/sidekickskubay/store?strNbr=${locNbr}`} data-test='b2osa-link'>SKU/Bay</Link>
                            {` / Mgr Added Bays`}
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={7} />
                    <Grid.Column width={7}>
                        Timeframe: 
                        {new Date().getDay() === 1 ?
                            <TimeframeDropdownMonLimited timeframe={timeframe} settimeframe={settimeframe} /> :
                            <TimeframeDropdownLimited timeframe={timeframe} settimeframe={settimeframe} />}
                    </Grid.Column>
                </Grid>
                <Header textAlign="center">MGR Added Bays</Header>
                {/* Ttl Tasks Comp # */}
                <DataTable data={dataObjTasks} />
                <Header textAlign='center'>Tasks breakdown by {displayTimeframe}</Header>
                <p style={{ textAlign: "center" }}>Tasks</p>
                <DataTable data={dataObjDaily} />
                {<p style={{ textAlign: "center" }}>MGR: Manager added bays by day</p>}
                {<p style={{ textAlign: "center" }}>COM: Completed tasks by day</p>}
                {/* Render manager added bays details */}
            </Segment>
            <>
                <Header textAlign='center'>MGR Added Bays Details</Header>
                <DataTable data={dataObjDtl} />
            </>
        </>
    );
});

