export const getParamByLevel = level => {
  const paramMap = {
    district: 'dstNbr',
    region: 'rgnNbr',
    division: 'divNbr',
    default: 'strNbr'
  };
  return paramMap[level] || paramMap.default;
};

export const getLastTimeframe = timeframe => {
  const lastTimeframeMap = {
    WTD: 'LW',
    LW: null,
    LM: null,
    MTD: 'LM',
    QTD: 'LQ',
    HTD: 'LH',
    YTD: 'YTD',
    L12: 'L12'
  };
  return lastTimeframeMap[timeframe];
};
