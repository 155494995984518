import React, { useState } from 'react'
import {
  Tab,
  Grid,
  Header,
  Dropdown,
} from 'semantic-ui-react'
import { DataTable } from '../Common/DataTable'
import { FORMATMETRIC, LEVEL_KEYS } from '../../constants/deptDrilldown'
import { topBottomFormat } from '../../utils/topBottomFormat'


export const TopBtmClassesTab = ({ topBtmClasses, level, orgNbr }) => {

  const [metric, setMetric] = useState('SLS_AMT')

  let topBtmClassFilter = topBtmClasses.filter((row) => {
    return (
      row &&
      row['METRIC'] === metric
    )
  })

  let topBtmClassSort = topBtmClassFilter.sort((left, right) => {
    return left.VALUE < right.VALUE ? 1 : -1
  })

  let topClasses = topBtmClassSort.slice(0, 5)
  let btmClasses = topBtmClassSort.slice(5, 10)

  const dataObjTop = {
    headers: [
      { name: 'Class' },
      { name: FORMATMETRIC[metric] }
    ],
    data: topClasses.map(row => [
      {
        stringValue: row['NAME'],
        link: {
          to: `/${level}/sales/class`,
          params: {
            [LEVEL_KEYS[level]]: orgNbr,
            classNbr: row['NAME'].slice(0, 7)
          }
        }
      },
      topBottomFormat({ metric, value: row['VALUE'], table: 'top' })
    ])
  }


  const dataObjBottom = {
    headers: [
      { name: 'Class' },
      { name: FORMATMETRIC[metric] }
    ],
    data: btmClasses.map(row => [
      {
        stringValue: row['NAME'],
        link: {
          to: `/${level}/sales/class`,
          params: {
            [LEVEL_KEYS[level]]: orgNbr,
            classNbr: row['NAME'].slice(0, 7)
          }
        }
      },
      topBottomFormat({ metric, value: row['VALUE'], table: 'bottom' })
    ]),
  }

  return (
    <Tab.Pane attached={false}>
      <Grid>
        <Grid.Row>
          <Grid.Column textAlign='justified' width={6}>
            <Grid.Row>
              Metric:
            </Grid.Row>
            <Grid.Row>
              {/* TODO: replace dropdown with custom dropdown component */}
              <Dropdown
                compact
                defaultValue={metric}
                selection
                scrolling
                size='tiny'
                options={[{ key: 'SLS_AMT', text: 'Sales Amt', value: 'SLS_AMT' },
                { key: 'SLS_COMP_NOM', text: 'Sales Comp $', value: 'SLS_COMP_NOM' },
                { key: 'SLS_COMP_PCT', text: 'Sales Comp %', value: 'SLS_COMP_PCT' },
                { key: 'UNT_SLS', text: 'Unit Sales', value: 'UNT_SLS' },
                { key: 'UNT_COMP_NOM', text: 'Units Comp #', value: 'UNT_COMP_NOM' },
                { key: 'UNT_COMP_PCT', text: 'Units Comp %', value: 'UNT_COMP_PCT' },
                ]}
                onChange={(_, { value }) => {
                  setMetric(value)
                }} />
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid>
        <Grid.Row>
          <Grid.Column width={16} textAlign='center'>
            <Header textAlign='center'>Top Classes by {FORMATMETRIC[metric]}</Header>
            <DataTable data={dataObjTop} />
            <Header textAlign='center'>Bottom Classes by {FORMATMETRIC[metric]}</Header>
            <DataTable data={dataObjBottom} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Tab.Pane>
  )
}
