import React, { useState } from 'react'
import {
  Tab,
  Grid,
  Header,
} from 'semantic-ui-react'
import { MetricTopBottomDropdown } from '../Common/MetricTopBottomDropdown'
import { FORMATMETRIC } from '../../constants/deptDrilldown'
import { DataTable } from '../Common/DataTable'
import { topBottomFormat } from '../../utils/topBottomFormat'

export const TopBtmSkusTab = ({ topBtmSkus }) => {

  const [metric, setMetric] = useState('SLS_AMT')

  let topBtmSkuFilter = topBtmSkus.filter((row) => {
    return (row && row['METRIC'] === metric)
  })

  let topBtmSkuSort = topBtmSkuFilter.sort((left, right) => {
    return left.VALUE < right.VALUE ? 1 : -1
  })

  let topSkus = topBtmSkuSort.slice(0, 5)
  let btmSkus = topBtmSkuSort.slice(5, 10)

  const dataObjTop = {
    headers: [
      { name: 'SKU' },
      { name: FORMATMETRIC[metric] }
    ],
    data: topSkus.map(row => [
      {
        stringValue: row['NAME'],
      },
      topBottomFormat({ metric, value: row['VALUE'], table: 'top' })
    ])
  }

  const dataObjBottom = {
    headers: [
      { name: 'SKU' },
      { name: FORMATMETRIC[metric] }
    ],
    data: btmSkus.map(row => [
      {
        stringValue: row['NAME'],
      },
      topBottomFormat({ metric, value: row['VALUE'], table: 'bottom' })
    ]),
  }

  return (
    <Tab.Pane attached={false}>
      <MetricTopBottomDropdown
        metric={metric}
        setMetric={setMetric}
      />
      <Grid>
        <Grid.Row>
          <Grid.Column width={16} textAlign='center'>
                <Header textAlign='center'>Top SKUs by {FORMATMETRIC[metric]}</Header>
                <DataTable data={dataObjTop} />
                <Header textAlign='center'>Bottom SKUs by {FORMATMETRIC[metric]}</Header>
                <DataTable data={dataObjBottom} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Tab.Pane>
  )
}
