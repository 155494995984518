import React, { useState } from 'react';
import { Table, Header, Grid, Loader, Menu } from 'semantic-ui-react';
import { useQuery } from 'react-query';
import { withRouter, Link } from 'react-router-dom';
import { DstOverviewTabs } from './DstOverviewTabs';
import { UnitMenu } from '../Common/UnitMenu';
import { TimeframeDropdown } from '../Common/TimeframeDropdown';
import { TimeframeDropdownMon } from '../Common/TimeframeDropdownMon';
import { getCompareStyle, getDollarString, getPercentString } from '../Common/TableCellUtils';
// import { Outliers } from '../Support/Outliers'

import { getData } from '../../service/DataService';

import { LTPA_GOAL, GET_GOAL, LTSA_GOAL } from './Overview';


export const DstOverview = withRouter(({ timeframe, settimeframe, userEmplCtgryNm, districtName, fpToggle, location }) => {
  const [overviewMenu, setOverviewMenu] = useState('District');
  const [metric, setMetric] = useState('Sales');
  const [unit, setUnit] = useState('NOM');
  const [sortColumn, setSortColumn] = useState('STR_NBR');
  const [sortDirection, setSortDirection] = useState('ascending');
  const [sortColumnDaysSafe, setSortColumnDaysSafe] = useState('STR_NBR');
  const [sortDirectionDaysSafe, setSortDirectionDaysSafe] = useState('ascending');

  const search = new URLSearchParams(location.search);
  const dstNbr = search.has('dstNbr') ? search.get('dstNbr') : 0;

  const dstOverviewQuery = useQuery(['querySwmDstMetrics', { dstNbr, fpToggle }], getData);
  const slsDeptByDstQuery = useQuery(['querySlsDeptByDst', { dstNbr, fpToggle }], getData);
  const strMetricsByDstQuery = useQuery(['querySwmStrMetricsByDst', { dstNbr, fpToggle }], getData);
  const slsClsByDstQuery = useQuery(['querySlsClsByDst', { dstNbr, fpToggle }], getData);
  const slsSclsByDstQuery = useQuery(['querySlsSclsByDst', { dstNbr, fpToggle }], getData);
  const deptInvQuery = useQuery(['queryInvDeptByDst', { dstNbr, fpToggle }], getData);
  const daysSafeQuery = useQuery(['querySafetyIncOshaLtGlbiByDst', { dstNbr, fpToggle }], getData);
  const Top40Btm40Query = useQuery(['querySlsSkuByDst', { dstNbr, fpToggle }], getData);

  var metric2PathExtn = {
    Sales: 'overview',
    Units: 'overview',
    Transactions: 'overview',
    'Average Ticket': 'overview',
    'Units per Basket': 'overview',
    SMD: 'smd',
    Labor: 'labor',
    GET: 'ltsa',
    LTSA: 'ltsa',
    LTPA: 'voc',
    Leads: 'leads',
    Measures: 'meas',
    'HDPP - MA UA': 'overview',
    'HDPP - GM UA': 'overview'
  };

  // Mapping of metric to array of up to 5 possible column names
  var metric2SortColumn = {
    Sales: ['_SLS_AMT_ACT', '_SLS_VP', '_SLS_AMT_VP_PCT', '_SLS_AMT_VLY', '_SLS_AMT_VLY_PCT'],
    Units: ['_UNTS_ACT', '_UNTS_VLY', '_UNTS_VLY_PCT', '_UNTS_VLY', '_UNTS_VLY_PCT'],
    Transactions: ['_TRNS_ACT', '_TRNS_VLY', '_TRNS_VLY_PCT', '_TRNS_VLY', '_TRNS_VLY_PCT'],
    'Average Ticket': ['_AVG_TCKT_ACT', '_AVG_TCKT_VLY', '_AVG_TCKT_VLY_PCT', '_AVG_TCKT_VLY', '_AVG_TCKT_VLY_PCT'],
    'Units per Basket': ['_UPB', '_UPB_VLY', '_UPB_VLY_PCT', '_UPB_VLY', '_UPB_VLY_PCT'],
    SMD: ['_SMD_ACT', '_SMD_VP', '_SMD_VP', '_SMD_VLY', '_SMD_VLY'],
    Labor: ['_LBR_ACT', '_LBR_VF', '_LBR_VF_PCT', '_LBR_VF', '_LBR_VF_PCT'],
    GET: ['_GET_ACT', '_GET_ACT', '_GET_ACT', '_GET_ACT', '_GET_ACT'],
    LTSA: ['_LTSA_ACT', '_LTSA_VLY', '_LTSA_VLY', '_LTSA_VLY', '_LTSA_VLY'],
    LTPA: ['_ONLINE_SAT_ACT', '_ONLINE_SAT_VLY', '_ONLINE_SAT_VLY', '_ONLINE_SAT_VLY', '_ONLINE_SAT_VLY'],
    Leads: ['_LEADS_ACT', '_LEADS_VLY', '_LEADS_VLY_PCT', '_LEADS_VLY', '_LEADS_VLY_PCT'],
    Measures: ['_MEAS_ACT', '_MEAS_VLY', '_MEAS_VLY_PCT', '_MEAS_VLY', '_MEAS_VLY_PCT'],
    'HDPP - MA UA': ['_MA_HDPP', '_MA_HDPP_VG', '_MA_HDPP_VG', '_MA_HDPP_VLY', '_MA_HDPP_VLY'],
    'HDPP - GM UA': ['_GM_HDPP', '_GM_HDPP_VG', '_GM_HDPP_VG', '_GM_HDPP_VLY', '_GM_HDPP_VLY']
  };

  if (
    dstOverviewQuery.isLoading ||
    slsDeptByDstQuery.isLoading ||
    strMetricsByDstQuery.isLoading ||
    slsClsByDstQuery.isLoading ||
    slsSclsByDstQuery.isLoading ||
    deptInvQuery.isLoading ||
    daysSafeQuery.isLoading ||
    Top40Btm40Query.isLoading
  ) {
    return <Loader active>Loading</Loader>;
  }
  if (!dstOverviewQuery.data[0]) {
    return <Header textAlign='center'>Invalid or Unauthorized District</Header>;
  }

  const dstMetrics = dstOverviewQuery.data[0]
  const dataSlsDeptByDst = slsDeptByDstQuery.data
  const strMetricsByDst = strMetricsByDstQuery.data
  const dataSlsClsByDst = slsClsByDstQuery.data
  const dataSlsSclsByDst = slsSclsByDstQuery.data
  const deptInventory = deptInvQuery.data
  const daysSafe = daysSafeQuery.data
  const districtNumber = String(dstMetrics['DST_NBR']).padStart(4, '0');
  const dstNm = dstMetrics['DST_NM']

  let strMetricsSorted = strMetricsByDst.sort((left, right) => {
    let result = left[sortColumn] - right[sortColumn];
    if (sortColumn === timeframe + '_UPB') {
      result =
        left['TY_' + timeframe + '_UNT_SLS'] / left['TY_' + timeframe + '_TXN_CNT'] -
        right['TY_' + timeframe + '_UNT_SLS'] / right['TY_' + timeframe + '_TXN_CNT'];
    }
    if (sortColumn === timeframe + '_UPB_VLY') {
      result =
        left['TY_' + timeframe + '_COMP_UNT_SLS'] / left['TY_' + timeframe + '_COMP_TXN_CNT'] -
        left['LY_' + timeframe + '_COMP_UNT_SLS'] / left['LY_' + timeframe + '_COMP_TXN_CNT'] -
        (right['TY_' + timeframe + '_COMP_UNT_SLS'] / right['TY_' + timeframe + '_COMP_TXN_CNT'] -
          right['LY_' + timeframe + '_COMP_UNT_SLS'] / right['LY_' + timeframe + '_COMP_TXN_CNT']);
    }
    if (sortColumn === timeframe + '_UPB_VLY_PCT') {
      result =
        (left['TY_' + timeframe + '_COMP_UNT_SLS'] / left['TY_' + timeframe + '_COMP_TXN_CNT'] -
          left['LY_' + timeframe + '_COMP_UNT_SLS'] / left['LY_' + timeframe + '_COMP_TXN_CNT']) /
          (left['LY_' + timeframe + '_COMP_UNT_SLS'] / left['LY_' + timeframe + '_COMP_TXN_CNT']) -
        (right['TY_' + timeframe + '_COMP_UNT_SLS'] / right['TY_' + timeframe + '_COMP_TXN_CNT'] -
          right['LY_' + timeframe + '_COMP_UNT_SLS'] / right['LY_' + timeframe + '_COMP_TXN_CNT']) /
          right['LY_' + timeframe + '_COMP_UNT_SLS'] /
          right['LY_' + timeframe + '_COMP_TXN_CNT'];
    }
    if (sortDirection === 'descending') {
      result *= -1;
    }
    return result;
  });

  let daysSafeDstFilter = daysSafe.filter(row => {
    return row && row.METRIC_LEVEL === 'DST';
  });

  let daysSafeStrFilter = daysSafe.filter(row => {
    return row && row.METRIC_LEVEL === 'STR';
  });

  let daysSafeStrSorted = daysSafeStrFilter.sort((left, right) => {
    let result = left[sortColumnDaysSafe] - right[sortColumnDaysSafe];
    if (sortDirectionDaysSafe === 'descending') {
      result *= -1;
    }
    return result;
  });

  return (
    <>
      <Grid>
        <Grid.Column>
          <Menu size='small' widths={2}>
            <Menu.Item
              name='District'
              active={overviewMenu === 'District'}
              content='District'
              onClick={() => {
                setOverviewMenu('District');
              }}
            />
            <Menu.Item
              name='Stores'
              active={overviewMenu === 'Stores'}
              content='Stores'
              onClick={() => {
                setOverviewMenu('Stores');
              }}
            />
          </Menu>
        </Grid.Column>
      </Grid>
      <Grid>
        <Grid.Column width={8}>
          <Grid.Row>Units:</Grid.Row>
          <Grid.Row>
            <UnitMenu unit={unit} setUnit={setUnit} />
          </Grid.Row>
          <Grid.Row>&nbsp;</Grid.Row>
        </Grid.Column>
        <Grid.Column width={8}>
          Timeframe:
          {new Date().getDay() === 1 ? (
            <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} />
          ) : (
            <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
          )}
        </Grid.Column>
      </Grid>
      {overviewMenu === 'District' && (
        <>
          <Header textAlign='center'>Overview - District {districtNumber}, {dstNm}</Header>
          <div style={{ display: 'flex', justifyContent: 'center' }}>Data as of {dstMetrics['TRNSFM_DT']}</div>
          {/* <Outliers outliersData={outliersData} fpToggle={fpToggle} /> */}
          <Table textAlign='center' celled unstackable size='small'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell>Actual</Table.HeaderCell>
                {unit === 'NOM' && <Table.HeaderCell>vP/vF</Table.HeaderCell>}
                {unit === 'PCT' && <Table.HeaderCell>vP/vF %</Table.HeaderCell>}
                {unit === 'NOM' && <Table.HeaderCell>vLY</Table.HeaderCell>}
                {unit === 'PCT' && <Table.HeaderCell>vLY %</Table.HeaderCell>}
              </Table.Row>
                 
            </Table.Header>
            <Table.Body singleLine>
              <Table.Row>
                {userEmplCtgryNm.toUpperCase().includes('SALAR') ||
                userEmplCtgryNm.toUpperCase().includes('OFFICER') ||
                userEmplCtgryNm.toUpperCase().includes('CORP_CNTRC') ? (
                  <Table.Cell>
                    <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/district/safety?dstNbr=${dstNbr}`}>
                      Days Safe
                    </Link>
                  </Table.Cell>
                ) : (
                  <Table.Cell singleLine>Days Safe</Table.Cell>
                )}
                <Table.Cell>{daysSafeDstFilter[0].DAYS_SAFE}</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell singleLine>Sales</Table.Cell>
                <Table.Cell singleLine>{getDollarString(dstMetrics[timeframe + '_SLS_AMT_ACT'])}</Table.Cell>
                {unit === 'NOM' && (
                  <Table.Cell singleLine style={getCompareStyle(dstMetrics[timeframe + '_SLS_VP'])}>
                    {getDollarString(dstMetrics[timeframe + '_SLS_VP'])}
                  </Table.Cell>
                )}
                {unit === 'PCT' && (
                  <Table.Cell singleLine style={getCompareStyle(dstMetrics[timeframe + '_SLS_AMT_VP_PCT'])}>
                    {getPercentString(dstMetrics[timeframe + '_SLS_AMT_VP_PCT'])}
                  </Table.Cell>
                )}
                {unit === 'NOM' && (
                  <Table.Cell singleLine style={getCompareStyle(dstMetrics[timeframe + '_SLS_AMT_VLY'])}>
                    {getDollarString(dstMetrics[timeframe + '_SLS_AMT_VLY'])}
                  </Table.Cell>
                )}
                {unit === 'PCT' && (
                  <Table.Cell singleLine style={getCompareStyle(dstMetrics[timeframe + '_SLS_AMT_VLY_PCT'])}>
                    {getPercentString(dstMetrics[timeframe + '_SLS_AMT_VLY_PCT'], 3)}
                  </Table.Cell>
                )}
              </Table.Row>
              <Table.Row>
                <Table.Cell>Units</Table.Cell>
                <Table.Cell>
                  {dstMetrics[timeframe + '_UNTS_ACT'].toLocaleString(undefined, { maximumFractionDigits: 0 })}
                </Table.Cell>
                <Table.Cell>-</Table.Cell>
                {unit === 'NOM' && (
                  <Table.Cell style={getCompareStyle(dstMetrics[timeframe + '_UNTS_VLY'])}>
                    {dstMetrics[timeframe + '_UNTS_VLY'].toLocaleString(undefined, { maximumFractionDigits: 0 })}
                  </Table.Cell>
                )}
                {unit === 'PCT' && (
                  <Table.Cell style={getCompareStyle(dstMetrics[timeframe + '_UNTS_VLY_PCT'])}>
                    {getPercentString(dstMetrics[timeframe + '_UNTS_VLY_PCT'])}
                  </Table.Cell>
                )}
              </Table.Row>
              <Table.Row>
                <Table.Cell>Transactions</Table.Cell>
                <Table.Cell>
                  {dstMetrics[timeframe + '_TRNS_ACT'].toLocaleString(undefined, { maximumFractionDigits: 0 })}
                </Table.Cell>
                <Table.Cell>-</Table.Cell>
                {unit === 'NOM' && (
                  <Table.Cell style={getCompareStyle(dstMetrics[timeframe + '_TRNS_VLY'])}>
                    {dstMetrics[timeframe + '_TRNS_VLY'].toLocaleString(undefined, { maximumFractionDigits: 0 })}
                  </Table.Cell>
                )}
                {unit === 'PCT' && (
                  <Table.Cell style={getCompareStyle(dstMetrics[timeframe + '_TRNS_VLY_PCT'])}>
                    {getPercentString(dstMetrics[timeframe + '_TRNS_VLY_PCT'])}
                  </Table.Cell>
                )}
              </Table.Row>
              <Table.Row>
                <Table.Cell>Average Ticket</Table.Cell>
                <Table.Cell>{getDollarString(dstMetrics[timeframe + '_AVG_TCKT_ACT'], 2)}</Table.Cell>
                <Table.Cell>-</Table.Cell>
                {unit === 'NOM' && (
                  <Table.Cell style={getCompareStyle(dstMetrics[timeframe + '_AVG_TCKT_VLY'])}>
                    {getDollarString(dstMetrics[timeframe + '_AVG_TCKT_VLY'], 2)}
                  </Table.Cell>
                )}
                {unit === 'PCT' && (
                  <Table.Cell style={getCompareStyle(dstMetrics[timeframe + '_AVG_TCKT_VLY_PCT'])}>
                    {getPercentString(dstMetrics[timeframe + '_AVG_TCKT_VLY_PCT'], 3)}
                  </Table.Cell>
                )}
              </Table.Row>
              <Table.Row>
                <Table.Cell>Units per Basket</Table.Cell>
                <Table.Cell>
                  {(dstMetrics['TY_' + timeframe + '_UNT_SLS'] / dstMetrics['TY_' + timeframe + '_TXN_CNT']).toLocaleString(
                    undefined,
                    { maximumFractionDigits: 2 }
                  )}
                </Table.Cell>
                <Table.Cell>-</Table.Cell>
                {unit === 'NOM' && (
                  <Table.Cell
                    style={getCompareStyle(
                      dstMetrics['TY_' + timeframe + '_COMP_UNT_SLS'] / dstMetrics['TY_' + timeframe + '_COMP_TXN_CNT'] -
                        dstMetrics['LY_' + timeframe + '_COMP_UNT_SLS'] / dstMetrics['LY_' + timeframe + '_COMP_TXN_CNT']
                    )}>
                    {(
                      dstMetrics['TY_' + timeframe + '_COMP_UNT_SLS'] / dstMetrics['TY_' + timeframe + '_COMP_TXN_CNT'] -
                      dstMetrics['LY_' + timeframe + '_COMP_UNT_SLS'] / dstMetrics['LY_' + timeframe + '_COMP_TXN_CNT']
                    ).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                  </Table.Cell>
                )}
                {unit === 'PCT' && (
                  <Table.Cell
                    style={getCompareStyle(
                      dstMetrics['TY_' + timeframe + '_COMP_UNT_SLS'] / dstMetrics['TY_' + timeframe + '_COMP_TXN_CNT'] -
                        dstMetrics['LY_' + timeframe + '_COMP_UNT_SLS'] / dstMetrics['LY_' + timeframe + '_COMP_TXN_CNT']
                    )}>
                    {getPercentString(
                      (dstMetrics['TY_' + timeframe + '_COMP_UNT_SLS'] / dstMetrics['TY_' + timeframe + '_COMP_TXN_CNT'] -
                        dstMetrics['LY_' + timeframe + '_COMP_UNT_SLS'] / dstMetrics['LY_' + timeframe + '_COMP_TXN_CNT']) /
                        (dstMetrics['LY_' + timeframe + '_COMP_UNT_SLS'] / dstMetrics['LY_' + timeframe + '_COMP_TXN_CNT']),
                      2
                    )}
                  </Table.Cell>
                )}
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  {' '}
                  <Link
                    style={{ color: '#EE7125', textDecoration: 'underline' }}
                    to={`/osa?dstNbr=${dstNbr}`}
                    data-test='overview-osa-link'>
                    On-Shelf Availability
                  </Link>
                </Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Link
                    style={{ color: '#EE7125', textDecoration: 'underline' }}
                    to={`/district/smd?dstNbr=${dstNbr}`}
                    data-test='overview-smd-link'>
                    SMD
                  </Link>
                </Table.Cell>
                <Table.Cell>{getPercentString(dstMetrics[timeframe + '_SMD_ACT'], 2)}</Table.Cell>
                {unit === 'NOM' && (
                  <Table.Cell style={getCompareStyle(-1 * dstMetrics[timeframe + '_SMD_VP'])}>
                    {dstMetrics[timeframe + '_SMD_VP'].toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' bps'}
                  </Table.Cell>
                )}
                {unit === 'PCT' && <Table.Cell>-</Table.Cell>}
                {unit === 'NOM' && (
                  <Table.Cell style={getCompareStyle(-1 * dstMetrics[timeframe + '_SMD_VLY'])}>
                    {dstMetrics[timeframe + '_SMD_VLY'].toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' bps'}
                  </Table.Cell>
                )}
                {unit === 'PCT' && <Table.Cell>-</Table.Cell>}
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Link
                    style={{ color: '#EE7125', textDecoration: 'underline' }}
                    to={`/district/labor?dstNbr=${dstNbr}`}
                    data-test='overview-labor-link'>
                    Labor
                  </Link>
                </Table.Cell>
                <Table.Cell>
                  {dstMetrics[timeframe + '_LBR_ACT'].toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' hrs'}
                </Table.Cell>
                {unit === 'NOM' && (
                  <Table.Cell>
                    {dstMetrics[timeframe + '_LBR_VF'].toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' hrs'}
                  </Table.Cell>
                )}
                {unit === 'PCT' && (
                  <Table.Cell
                    style={{
                      color:
                        dstMetrics[timeframe + '_LBR_VF_PCT'] > 0.01 || dstMetrics[timeframe + '_LBR_VF_PCT'] < -0.01
                          ? 'red'
                          : 'green'
                    }}>
                    {getPercentString(dstMetrics[timeframe + '_LBR_VF_PCT'])}
                  </Table.Cell>
                )}
                <Table.Cell>-</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Link
                    style={{ color: '#EE7125', textDecoration: 'underline' }}
                    to={`/district/ltsa?dstNbr=${dstNbr}`}
                    data-test='overview-get-link'>
                    GET
                  </Link>
                </Table.Cell>
                <Table.Cell style={{ color: dstMetrics[timeframe + '_GET_ACT'] >= GET_GOAL ? 'green' : 'red' }}>
                  {getPercentString(dstMetrics[timeframe + '_GET_ACT'], 2)}
                </Table.Cell>
                <Table.Cell>-</Table.Cell>
                {/* GET LY metrics not available yet. Save bottom comment beginning FY2022. UPDATE: Enabled 2023-02-13 */}
                {unit === 'NOM' && (
                  <Table.Cell style={getCompareStyle(dstMetrics[timeframe + '_GET_VLY'])}>
                    {getPercentString(dstMetrics[timeframe + '_GET_VLY'], 2)}
                  </Table.Cell>
                )}
                {unit === 'PCT' && <Table.Cell>-</Table.Cell>}
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Link
                    style={{ color: '#EE7125', textDecoration: 'underline' }}
                    to={`/district/ltsa?dstNbr=${dstNbr}`}
                    data-test='overview-ltsa-link'>
                    LTSA
                  </Link>
                </Table.Cell>
                <Table.Cell style={{ color: dstMetrics[timeframe + '_LTSA_ACT'] >= LTSA_GOAL ? 'green' : 'red' }}>
                  {getPercentString(dstMetrics[timeframe + '_LTSA_ACT'], 2)}
                </Table.Cell>
                <Table.Cell>-</Table.Cell>
                {unit === 'NOM' && (
                  <Table.Cell style={getCompareStyle(dstMetrics[timeframe + '_LTSA_VLY'])}>
                    {getPercentString(dstMetrics[timeframe + '_LTSA_VLY'], 2)}
                  </Table.Cell>
                )}
                {unit === 'PCT' && <Table.Cell>-</Table.Cell>}
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Link
                    style={{ color: '#EE7125', textDecoration: 'underline' }}
                    to={`/district/voc?dstNbr=${dstNbr}`}
                    data-test='overview-onlineSAT-link'>
                    LTPA
                  </Link>
                </Table.Cell>
                <Table.Cell
                  style={{
                    color:
                      dstMetrics[timeframe + '_ONLINE_SAT_ACT'] > LTPA_GOAL
                        ? 'green'
                        : dstMetrics[timeframe + '_ONLINE_SAT_ACT'] < LTPA_GOAL
                          ? 'red'
                          : 'black'
                  }}>
                  {dstMetrics[timeframe + '_ONLINE_SAT_ACT'] != null
                    ? getPercentString(dstMetrics[timeframe + '_ONLINE_SAT_ACT'])
                    : '-'}
                </Table.Cell>
                <Table.Cell>-</Table.Cell>
                {unit === 'NOM' && (
                  <Table.Cell style={getCompareStyle(dstMetrics[timeframe + '_ONLINE_SAT_VLY'])}>
                    {dstMetrics[timeframe + '_ONLINE_SAT_VLY'] != null
                      ? dstMetrics[timeframe + '_ONLINE_SAT_VLY'].toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        }) + '%'
                      : '--'}
                  </Table.Cell>
                )}
                {unit === 'PCT' && <Table.Cell>-</Table.Cell>}
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Link
                    style={{ color: '#EE7125', textDecoration: 'underline' }}
                    to={`/district/leads?dstNbr=${dstNbr}`}
                    data-test='overview-leads-link'>
                    Leads
                  </Link>
                </Table.Cell>
                <Table.Cell>
                  {dstMetrics[timeframe + '_LEADS_ACT'].toLocaleString(undefined, { maximumFractionDigits: 0 })}
                </Table.Cell>
                <Table.Cell>-</Table.Cell>
                {unit === 'NOM' && (
                  <Table.Cell style={getCompareStyle(dstMetrics[timeframe + '_LEADS_VLY'])}>
                    {dstMetrics[timeframe + '_LEADS_VLY'].toLocaleString(undefined, { maximumFractionDigits: 0 })}
                  </Table.Cell>
                )}
                {unit === 'PCT' && (
                  <Table.Cell style={getCompareStyle(dstMetrics[timeframe + '_LEADS_VLY_PCT'])}>
                    {getPercentString(dstMetrics[timeframe + '_LEADS_VLY_PCT'])}
                  </Table.Cell>
                )}
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Link
                    style={{ color: '#EE7125', textDecoration: 'underline' }}
                    to={`/district/meas?dstNbr=${dstNbr}`}
                    data-test='overview-meas-link'>
                    Measures
                  </Link>
                </Table.Cell>
                <Table.Cell>
                  {dstMetrics[timeframe + '_MEAS_ACT'].toLocaleString(undefined, { maximumFractionDigits: 0 })}
                </Table.Cell>
                <Table.Cell>-</Table.Cell>
                {unit === 'NOM' && (
                  <Table.Cell style={getCompareStyle(dstMetrics[timeframe + '_MEAS_VLY'])}>
                    {dstMetrics[timeframe + '_MEAS_VLY'].toLocaleString(undefined, { maximumFractionDigits: 0 })}
                  </Table.Cell>
                )}
                {unit === 'PCT' && (
                  <Table.Cell style={getCompareStyle(dstMetrics[timeframe + '_MEAS_VLY_PCT'])}>
                    {getPercentString(dstMetrics[timeframe + '_MEAS_VLY_PCT'])}
                  </Table.Cell>
                )}
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  {/* <Link
                  style={{ color: '#EE7125', textDecoration: 'underline' }}
                  to={{ pathname: `https://pr-mstr-so.homedepot.com/MicroStrategy/servlet/mstrWeb?Server=MSTR-MYNUMBER-IS-20202-1&Project=PONE-SO&Port=0&evt=2048001&documentID=9C90664A48024856B23381BCFE86A029&&currentViewMedia=32&elementsPromptAnswers=E15D73A24D9970F1A0A15CBECADE46AE;E15D73A24D9970F1A0A15CBECADE46AE:${Number(dstNbr)}` }}
                  target="_blank"> */}
                  Cashier Metrics
                  {/* </Link> */}
                </Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>HDPP - MA UA</Table.Cell>
                <Table.Cell>{getPercentString(dstMetrics[timeframe + '_MA_HDPP'])}</Table.Cell>
                <Table.Cell style={getCompareStyle(dstMetrics[timeframe + '_MA_HDPP_VG'])}>
                  {getPercentString(dstMetrics[timeframe + '_MA_HDPP_VG'])}
                </Table.Cell>
                <Table.Cell style={getCompareStyle(dstMetrics[timeframe + '_MA_HDPP_VLY'])}>
                  {getPercentString(dstMetrics[timeframe + '_MA_HDPP_VLY'])}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>HDPP - GM UA</Table.Cell>
                <Table.Cell>{getPercentString(dstMetrics[timeframe + '_GM_HDPP'])}</Table.Cell>
                <Table.Cell style={getCompareStyle(dstMetrics[timeframe + '_GM_HDPP_VG'])}>
                  {getPercentString(dstMetrics[timeframe + '_GM_HDPP_VG'])}
                </Table.Cell>
                <Table.Cell style={getCompareStyle(dstMetrics[timeframe + '_GM_HDPP_VG'])}>
                  {getPercentString(dstMetrics[timeframe + '_GM_HDPP_VLY'])}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </>
      )}
      {overviewMenu === 'Stores' && (
        <>
          <Grid>
            <Grid.Column width={8} />
            <Grid.Column width={8}>
              Metric:
              <div>
                <select
                  name='metric'
                  onChange={e => {
                    setMetric(e.target.value);
                  }}
                  value={metric}
                  style={{ padding: 10 }}>
                  <option value='Sales' selected>
                    Sales
                  </option>
                  <option value='Units'>Units</option>
                  <option value='Transactions'>Transactions</option>
                  <option value='Average Ticket'>Average Ticket</option>
                  <option value='Units per Basket'>Units per Basket</option>
                  <option value='Days Safe'>Days Safe</option>
                  <option value='SMD'>SMD</option>
                  <option value='Labor'>Labor</option>
                  <option value='GET'>GET</option>
                  <option value='LTSA'>LTSA</option>
                  <option value='LTPA'>LTPA</option>
                  <option value='Leads'>Leads</option>
                  <option value='Measures'>Measures</option>
                  <option value='HDPP - MA UA'>HDPP - MA UA</option>
                  <option value='HDPP - GM UA'>HDPP - GM UA</option>
                </select>
              </div>
            </Grid.Column>
          </Grid>
          <Header textAlign='center'>
            District #{dstNbr} - {metric} By Store
          </Header>
          {metric === 'Days Safe' && (
            <Table textAlign='center' celled unstackable sortable size='small'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    sorted={sortColumnDaysSafe === 'STR_NBR' ? sortDirectionDaysSafe : null}
                    onClick={() => {
                      if (sortColumnDaysSafe !== 'STR_NBR') {
                        setSortColumnDaysSafe('STR_NBR');
                        setSortDirectionDaysSafe('ascending');
                      } else {
                        sortDirectionDaysSafe === 'ascending'
                          ? setSortDirectionDaysSafe('descending')
                          : setSortDirectionDaysSafe('ascending');
                      }
                    }}>
                    Store #
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={sortColumnDaysSafe === 'DAYS_SAFE' ? sortDirectionDaysSafe : null}
                    onClick={() => {
                      if (sortColumnDaysSafe !== 'DAYS_SAFE') {
                        setSortColumnDaysSafe('DAYS_SAFE');
                        setSortDirectionDaysSafe('ascending');
                      } else {
                        sortDirectionDaysSafe === 'ascending'
                          ? setSortDirectionDaysSafe('descending')
                          : setSortDirectionDaysSafe('ascending');
                      }
                    }}>
                    Days Safe
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {daysSafeStrSorted.map(data => {
                  return (
                    <Table.Row>
                      <Table.Cell>STR {data.STR_NBR}</Table.Cell>
                      <Table.Cell>{data.DAYS_SAFE}</Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          )}
          {metric !== 'Days Safe' && (
            <Table textAlign='center' celled unstackable sortable size='small'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    sorted={sortColumn === 'STR_NBR' ? sortDirection : null}
                    onClick={() => {
                      if (sortColumn !== 'STR_NBR') {
                        setSortColumn('STR_NBR');
                        setSortDirection('ascending');
                      } else {
                        sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending');
                      }
                    }}>
                    Store #
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={sortColumn === timeframe + metric2SortColumn[metric][0] ? sortDirection : null}
                    onClick={() => {
                      if (sortColumn !== timeframe + metric2SortColumn[metric][0]) {
                        setSortColumn(timeframe + metric2SortColumn[metric][0]);
                        setSortDirection('ascending');
                      } else {
                        sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending');
                      }
                    }}>
                    Actual
                  </Table.HeaderCell>
                  {unit === 'NOM' && (
                    <Table.HeaderCell
                      sorted={sortColumn === timeframe + metric2SortColumn[metric][1] ? sortDirection : null}
                      onClick={() => {
                        if (sortColumn !== timeframe + metric2SortColumn[metric][1]) {
                          setSortColumn(timeframe + metric2SortColumn[metric][1]);
                          setSortDirection('ascending');
                        } else {
                          sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending');
                        }
                      }}>
                      vP/vF
                    </Table.HeaderCell>
                  )}
                  {unit === 'PCT' && (
                    <Table.HeaderCell
                      sorted={sortColumn === timeframe + metric2SortColumn[metric][2] ? sortDirection : null}
                      onClick={() => {
                        if (sortColumn !== timeframe + metric2SortColumn[metric][2]) {
                          setSortColumn(timeframe + metric2SortColumn[metric][2]);
                          setSortDirection('ascending');
                        } else {
                          sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending');
                        }
                      }}>
                      vP/vF %
                    </Table.HeaderCell>
                  )}
                  {unit === 'NOM' && (
                    <Table.HeaderCell
                      sorted={sortColumn === timeframe + metric2SortColumn[metric][3] ? sortDirection : null}
                      onClick={() => {
                        if (sortColumn !== timeframe + metric2SortColumn[metric][3]) {
                          setSortColumn(timeframe + metric2SortColumn[metric][3]);
                          setSortDirection('ascending');
                        } else {
                          sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending');
                        }
                      }}>
                      vLY
                    </Table.HeaderCell>
                  )}
                  {unit === 'PCT' && (
                    <Table.HeaderCell
                      sorted={sortColumn === timeframe + metric2SortColumn[metric][4] ? sortDirection : null}
                      onClick={() => {
                        if (sortColumn !== timeframe + metric2SortColumn[metric][4]) {
                          setSortColumn(timeframe + metric2SortColumn[metric][4]);
                          setSortDirection('ascending');
                        } else {
                          sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending');
                        }
                      }}>
                      vLY %
                    </Table.HeaderCell>
                  )}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {strMetricsSorted.map(data => {
                  return (
                    <Table.Row>
                      {/* <Table.Cell>{data['STR_NBR']}</Table.Cell> */}
                      <Table.Cell>
                        <Link
                          style={{ color: '#EE7125', textDecoration: 'underline' }}
                          to={`/store/${metric2PathExtn[metric]}?strNbr=${data['STR_NBR'].toString().padStart(4, '0')}`}>
                          {'STR ' + data['STR_NBR']}
                        </Link>
                      </Table.Cell>
                      {metric === 'Sales' && (
                        <Table.Cell singleLine>{getDollarString(data[timeframe + '_SLS_AMT_ACT'])}</Table.Cell>
                      )}
                      {metric === 'Sales' && unit === 'NOM' && (
                        <Table.Cell singleLine style={getCompareStyle(data[timeframe + '_SLS_VP'])}>
                          {getDollarString(data[timeframe + '_SLS_VP'])}
                        </Table.Cell>
                      )}
                      {metric === 'Sales' && unit === 'PCT' && (
                        <Table.Cell singleLine style={getCompareStyle(data[timeframe + '_SLS_AMT_VP_PCT'])}>
                          {getPercentString(data[timeframe + '_SLS_AMT_VP_PCT'])}
                        </Table.Cell>
                      )}
                      {metric === 'Sales' && unit === 'NOM' && (
                        <Table.Cell singleLine style={getCompareStyle(data[timeframe + '_SLS_AMT_VLY'])}>
                          {getDollarString(data[timeframe + '_SLS_AMT_VLY'])}
                        </Table.Cell>
                      )}
                      {metric === 'Sales' && unit === 'PCT' && (
                        <Table.Cell singleLine style={getCompareStyle(data[timeframe + '_SLS_AMT_VLY_PCT'])}>
                          {getPercentString(data[timeframe + '_SLS_AMT_VLY_PCT'], 3)}
                        </Table.Cell>
                      )}
                      {metric === 'Units' && (
                        <Table.Cell>
                          {data[timeframe + '_UNTS_ACT'].toLocaleString(undefined, { maximumFractionDigits: 0 })}
                        </Table.Cell>
                      )}
                      {metric === 'Units' && <Table.Cell>-</Table.Cell>}
                      {metric === 'Units' && unit === 'NOM' && (
                        <Table.Cell style={getCompareStyle(data[timeframe + '_UNTS_VLY'])}>
                          {data[timeframe + '_UNTS_VLY'].toLocaleString(undefined, { maximumFractionDigits: 0 })}
                        </Table.Cell>
                      )}
                      {metric === 'Units' && unit === 'PCT' && (
                        <Table.Cell style={getCompareStyle(data[timeframe + '_UNTS_VLY_PCT'])}>
                          {getPercentString(data[timeframe + '_UNTS_VLY_PCT'])}
                        </Table.Cell>
                      )}
                      {metric === 'Transactions' && (
                        <Table.Cell>
                          {data[timeframe + '_TRNS_ACT'].toLocaleString(undefined, { maximumFractionDigits: 0 })}
                        </Table.Cell>
                      )}
                      {metric === 'Transactions' && <Table.Cell>-</Table.Cell>}
                      {metric === 'Transactions' && unit === 'NOM' && (
                        <Table.Cell style={getCompareStyle(data[timeframe + '_TRNS_VLY'])}>
                          {data[timeframe + '_TRNS_VLY'].toLocaleString(undefined, { maximumFractionDigits: 0 })}
                        </Table.Cell>
                      )}
                      {metric === 'Transactions' && unit === 'PCT' && (
                        <Table.Cell style={getCompareStyle(data[timeframe + '_TRNS_VLY_PCT'])}>
                          {getPercentString(data[timeframe + '_TRNS_VLY_PCT'])}
                        </Table.Cell>
                      )}
                      {metric === 'Average Ticket' && (
                        <Table.Cell>{getDollarString(data[timeframe + '_AVG_TCKT_ACT'], 2)}</Table.Cell>
                      )}
                      {metric === 'Average Ticket' && <Table.Cell>-</Table.Cell>}
                      {metric === 'Average Ticket' && unit === 'NOM' && (
                        <Table.Cell style={getCompareStyle(data[timeframe + '_AVG_TCKT_VLY'])}>
                          {getDollarString(data[timeframe + '_AVG_TCKT_VLY'], 2)}
                        </Table.Cell>
                      )}
                      {metric === 'Average Ticket' && unit === 'PCT' && (
                        <Table.Cell style={getCompareStyle(data[timeframe + '_AVG_TCKT_VLY_PCT'])}>
                          {getPercentString(data[timeframe + '_AVG_TCKT_VLY_PCT'], 3)}
                        </Table.Cell>
                      )}
                      {metric === 'Units per Basket' && (
                        <Table.Cell>
                          {(data['TY_' + timeframe + '_UNT_SLS'] / data['TY_' + timeframe + '_TXN_CNT']).toLocaleString(
                            undefined,
                            { maximumFractionDigits: 2 }
                          )}
                        </Table.Cell>
                      )}
                      {metric === 'Units per Basket' && <Table.Cell>-</Table.Cell>}
                      {metric === 'Units per Basket' && unit === 'NOM' && (
                        <Table.Cell
                          style={getCompareStyle(
                            data['TY_' + timeframe + '_COMP_UNT_SLS'] / data['TY_' + timeframe + '_COMP_TXN_CNT'] -
                              data['LY_' + timeframe + '_COMP_UNT_SLS'] / data['LY_' + timeframe + '_COMP_TXN_CNT']
                          )}>
                          {(
                            data['TY_' + timeframe + '_COMP_UNT_SLS'] / data['TY_' + timeframe + '_COMP_TXN_CNT'] -
                            data['LY_' + timeframe + '_COMP_UNT_SLS'] / data['LY_' + timeframe + '_COMP_TXN_CNT']
                          ).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                        </Table.Cell>
                      )}
                      {metric === 'Units per Basket' && unit === 'PCT' && (
                        <Table.Cell
                          style={getCompareStyle(
                            data['TY_' + timeframe + '_COMP_UNT_SLS'] / data['TY_' + timeframe + '_COMP_TXN_CNT'] -
                              data['LY_' + timeframe + '_COMP_UNT_SLS'] / data['LY_' + timeframe + '_COMP_TXN_CNT']
                          )}>
                          {getPercentString(
                            (data['TY_' + timeframe + '_COMP_UNT_SLS'] / data['TY_' + timeframe + '_COMP_TXN_CNT'] -
                              data['LY_' + timeframe + '_COMP_UNT_SLS'] / data['LY_' + timeframe + '_COMP_TXN_CNT']) /
                              (data['LY_' + timeframe + '_COMP_UNT_SLS'] / data['LY_' + timeframe + '_COMP_TXN_CNT']),
                            2
                          )}
                        </Table.Cell>
                      )}
                      {metric === 'SMD' && <Table.Cell>{getPercentString(data[timeframe + '_SMD_ACT'], 2)}</Table.Cell>}
                      {metric === 'SMD' && unit === 'NOM' && (
                        <Table.Cell style={getCompareStyle(-1 * data[timeframe + '_SMD_VP'])}>
                          {data[timeframe + '_SMD_VP'].toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' bps'}
                        </Table.Cell>
                      )}
                      {metric === 'SMD' && unit === 'PCT' && <Table.Cell>-</Table.Cell>}
                      {metric === 'SMD' && unit === 'NOM' && (
                        <Table.Cell style={getCompareStyle(-1 * data[timeframe + '_SMD_VLY'])}>
                          {data[timeframe + '_SMD_VLY'].toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' bps'}
                        </Table.Cell>
                      )}
                      {metric === 'SMD' && unit === 'PCT' && <Table.Cell>-</Table.Cell>}
                      {metric === 'Labor' && (
                        <Table.Cell>
                          {data[timeframe + '_LBR_ACT'].toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' hrs'}
                        </Table.Cell>
                      )}
                      {metric === 'Labor' && unit === 'NOM' && (
                        <Table.Cell>
                          {data[timeframe + '_LBR_VF'].toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' hrs'}
                        </Table.Cell>
                      )}
                      {metric === 'Labor' && unit === 'PCT' && (
                        <Table.Cell
                          style={{
                            color:
                              data[timeframe + '_LBR_VF_PCT'] > 0.01 || data[timeframe + '_LBR_VF_PCT'] < -0.01
                                ? 'red'
                                : 'green'
                          }}>
                          {getPercentString(data[timeframe + '_LBR_VF_PCT'])}
                        </Table.Cell>
                      )}
                      {metric === 'Labor' && <Table.Cell>-</Table.Cell>}
                      {metric === 'GET' && (
                        <Table.Cell style={{ color: data[timeframe + '_GET_ACT'] >= GET_GOAL ? 'green' : 'red' }}>
                          {getPercentString(data[timeframe + '_GET_ACT'], 2)}
                        </Table.Cell>
                      )}
                      {metric === 'GET' && <Table.Cell>-</Table.Cell>}
                      {metric === 'GET' && unit === 'NOM' && (
                        <Table.Cell style={getCompareStyle(data[timeframe + '_GET_VLY'])}>
                          {getPercentString(data[timeframe + '_GET_VLY'], 2)}
                        </Table.Cell>
                      )}
                      {metric === 'GET' && unit === 'PCT' && <Table.Cell>-</Table.Cell>}
                      {metric === 'LTSA' && (
                        <Table.Cell style={{ color: data[timeframe + '_LTSA_ACT'] >= LTSA_GOAL ? 'green' : 'red' }}>
                          {getPercentString(data[timeframe + '_LTSA_ACT'], 2)}
                        </Table.Cell>
                      )}
                      {metric === 'LTSA' && <Table.Cell>-</Table.Cell>}
                      {metric === 'LTSA' && unit === 'NOM' && (
                        <Table.Cell style={getCompareStyle(data[timeframe + '_LTSA_VLY'])}>
                          {getPercentString(data[timeframe + '_LTSA_VLY'], 2)}
                        </Table.Cell>
                      )}
                      {metric === 'LTSA' && unit === 'PCT' && <Table.Cell>-</Table.Cell>}
                      {metric === 'LTPA' && (
                        <Table.Cell
                          style={{
                            color:
                              data[timeframe + '_ONLINE_SAT_ACT'] > LTPA_GOAL
                                ? 'green'
                                : data[timeframe + '_ONLINE_SAT_ACT'] < LTPA_GOAL
                                  ? 'red'
                                  : 'black'
                          }}>
                          {data[timeframe + '_ONLINE_SAT_ACT'] != null
                            ? getPercentString(data[timeframe + '_ONLINE_SAT_ACT'])
                            : '-'}
                        </Table.Cell>
                      )}
                      {metric === 'LTPA' && <Table.Cell>-</Table.Cell>}
                      {metric === 'LTPA' && unit === 'NOM' && (
                        <Table.Cell style={getCompareStyle(data[timeframe + '_ONLINE_SAT_VLY'])}>
                          {data[timeframe + '_ONLINE_SAT_VLY'] != null
                            ? data[timeframe + '_ONLINE_SAT_VLY'].toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              }) + '%'
                            : '-'}
                        </Table.Cell>
                      )}
                      {metric === 'LTPA' && unit === 'PCT' && <Table.Cell>-</Table.Cell>}
                      {metric === 'Leads' && (
                        <Table.Cell>
                          {data[timeframe + '_LEADS_ACT'].toLocaleString(undefined, { maximumFractionDigits: 0 })}
                        </Table.Cell>
                      )}
                      {metric === 'Leads' && <Table.Cell>-</Table.Cell>}
                      {metric === 'Leads' && unit === 'NOM' && (
                        <Table.Cell style={getCompareStyle(data[timeframe + '_LEADS_VLY'])}>
                          {data[timeframe + '_LEADS_VLY'].toLocaleString(undefined, { maximumFractionDigits: 0 })}
                        </Table.Cell>
                      )}
                      {metric === 'Leads' && unit === 'PCT' && (
                        <Table.Cell style={getCompareStyle(data[timeframe + '_LEADS_VLY_PCT'])}>
                          {getPercentString(data[timeframe + '_LEADS_VLY_PCT'])}
                        </Table.Cell>
                      )}
                      {metric === 'Measures' && (
                        <Table.Cell>
                          {data[timeframe + '_MEAS_ACT'].toLocaleString(undefined, { maximumFractionDigits: 0 })}
                        </Table.Cell>
                      )}
                      {metric === 'Measures' && <Table.Cell>-</Table.Cell>}
                      {metric === 'Measures' && unit === 'NOM' && (
                        <Table.Cell style={getCompareStyle(data[timeframe + '_MEAS_VLY'])}>
                          {data[timeframe + '_MEAS_VLY'].toLocaleString(undefined, { maximumFractionDigits: 0 })}
                        </Table.Cell>
                      )}
                      {metric === 'Measures' && unit === 'PCT' && (
                        <Table.Cell style={getCompareStyle(data[timeframe + '_MEAS_VLY_PCT'])}>
                          {getPercentString(data[timeframe + '_MEAS_VLY_PCT'])}
                        </Table.Cell>
                      )}
                      {metric === 'HDPP - MA UA' && (
                        <Table.Cell>{getPercentString(data[timeframe + '_MA_HDPP'])}</Table.Cell>
                      )}
                      {metric === 'HDPP - MA UA' && (
                        <Table.Cell style={getCompareStyle(data[timeframe + '_MA_HDPP_VG'])}>
                          {getPercentString(data[timeframe + '_MA_HDPP_VG'])}
                        </Table.Cell>
                      )}
                      {metric === 'HDPP - MA UA' && (
                        <Table.Cell style={getCompareStyle(data[timeframe + '_MA_HDPP_VLY'])}>
                          {getPercentString(data[timeframe + '_MA_HDPP_VLY'])}
                        </Table.Cell>
                      )}
                      {metric === 'HDPP - GM UA' && (
                        <Table.Cell>{getPercentString(data[timeframe + '_GM_HDPP'])}</Table.Cell>
                      )}
                      {metric === 'HDPP - GM UA' && (
                        <Table.Cell style={getCompareStyle(data[timeframe + '_GM_HDPP_VG'])}>
                          {getPercentString(data[timeframe + '_GM_HDPP_VG'])}
                        </Table.Cell>
                      )}
                      {metric === 'HDPP - GM UA' && (
                        <Table.Cell style={getCompareStyle(data[timeframe + '_GM_HDPP_VG'])}>
                          {getPercentString(data[timeframe + '_GM_HDPP_VLY'])}
                        </Table.Cell>
                      )}
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          )}
        </>
      )}
      <DstOverviewTabs
        dataSlsDeptByDst={dataSlsDeptByDst}
        dataSlsClsByDst={dataSlsClsByDst}
        dataSlsSclsByDst={dataSlsSclsByDst}
        deptInventory={deptInventory}
        Top40Btm40Query={Top40Btm40Query}
        timeframe={timeframe}
        dstNm={dstMetrics['DST_NM']}
      />
    </>
  );
});
