import { useState } from 'react'

import { Header, Tab, Grid, Dropdown,  } from 'semantic-ui-react'
import { UnitMenu } from '../../Common/UnitMenu'
import { DataTable } from '../../Common/DataTable'

import { useQueryParams } from '../../../hooks/useQueryParams'
import { useRegionOverviewContext } from '../../../hooks/useRegionOverviewContext'
import { timeframeCode } from '../../Common/timeframeCode'

export const SalesClassesTab = () => {
  const [unit, setUnit] = useState('NOM')
  const { timeframe, slsClsByRgnData } = useRegionOverviewContext()
  const { rgnNbr } = useQueryParams()
  const isNOM = unit === 'NOM'
  const isPCT = unit === 'PCT'
  const [metric, setMetric] = useState('SLS')
  const [dept, setDept] = useState('All')
  const [rowsPerPage, setRowsPerPage] = useState(20)

  const isSLS = metric === 'SLS'
  const isUNT = metric === 'UNT'
  const isTXN = metric === 'TXN'
  const isAVG_TCKT = metric === 'AVG_TCKT'

  let dataFiltered = slsClsByRgnData.filter((row) => {
    return row && row['TRNSFM_TYP_SORT_ID'] === timeframeCode[timeframe]
      && (dept === 'All' || row['DEPT_NBR'].toString() === dept)
  })

  const dataSLSObj = {
    data: dataFiltered.map(data => ([
      {
        link: {to: '/region/sales/class',params: {rgnNbr,deptNbr: data['EXT_CLASS_NBR']}},
        stringValue: data['MER_CLASS_NM'].replaceAll('/', '/\u200b'),
      },
      {
        dollarValue: data['TY_SLS_AMT'],
        customStyle : { color: data['TY_SLS_AMT'] == null ? 'black' : data['TY_SLS_AMT'] < 0 ? 'red' : 'black' }
      },
      {
        dollarValue: isNOM ? data['SLS_VP_NOM'] == null ? '--': data['SLS_VP_NOM'] : undefined,
        pctValue: isPCT ? data['SLS_VP_NOM'] == null ? '--': data['SLS_VP_NOM'] : undefined,
        compareStyle: true,
      },
      {
        dollarValue: isNOM ? data['SLS_COMP_NOM'] == null ? '--': data['SLS_COMP_NOM'] : undefined,
        pctValue: isPCT ? data['SLS_COMP_NOM'] == null ? '--': data['SLS_COMP_NOM'] : undefined,
        compareStyle: true,
      }
    ])),
    headers: [
      { name: 'Class' },
      { name: `Sales Amt ($)` },
      { name: `Sales vP ${isPCT ? '%' : '($)'}` },
      { name: `Sales vLY ${isPCT ? '%' : '($)'}` },
    ],
    sortable: true,
    sortableDefault: 0,
    pagination: true,
  }
  
  const dataUNTObj = {
    data: dataFiltered.map(data => ([
      {
        link: {to: '/region/sales/class',params: {rgnNbr,deptNbr: data['EXT_CLASS_NBR']}},
        stringValue: data['MER_CLASS_NM'].replaceAll('/', '/\u200b'),
      },
      {
        stringValue: data['TY_UNT_SLS'] != null ? data['TY_UNT_SLS'].toLocaleString(undefined, {minimumFractionDigits: 0,maximumFractionDigits: 0}): 'Loading',
        customStyle : { color: data['TY_UNT_SLS'] == null ? 'black' : data['TY_UNT_SLS'] < 0 ? 'red' : 'black' }
      },
      {
        stringValue: isNOM ? data['UNT_COMP_NOM'] != null
        ? data['UNT_COMP_NOM'].toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          })
        : 'Loading' : undefined,
        pctValue: isPCT ? data['UNT_COMP_PCT'] == null ? '--': data['UNT_COMP_PCT'] : undefined,
        compareStyle: true,
      }
    ])),
    headers: [
      { name: 'Class' },
      { name: `Unit Sales (#)` },
      { name: `Units vLY ${isPCT ? '%' : '(#)'}` },
    ],
    sortable: true,
    sortableDefault: 0,
    pagination: true,
  }

  const dataTXNObj = {
    data: dataFiltered.map(data => ([
      {
        link: {to: '/region/sales/class',params: {rgnNbr,deptNbr: data['EXT_CLASS_NBR']}},
        stringValue: data['MER_CLASS_NM'].replaceAll('/', '/\u200b'),
      },
      {
        stringValue: data['TY_TXN_CNT'] != null ? data['TY_TXN_CNT'].toLocaleString(undefined, {minimumFractionDigits: 0,maximumFractionDigits: 0}): 'Loading',
        customStyle : { color: data['TY_TXN_CNT'] == null ? 'black' : data['TY_TXN_CNT'] < 0 ? 'red' : 'black' }
      },
      {
        stringValue: isNOM ? data['TXN_COMP_NOM'] != null
        ? data['TXN_COMP_NOM'].toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          })
        : 'Loading' : undefined,
        pctValue: isPCT ? data['TXN_COMP_PCT'] == null ? '--': data['TXN_COMP_PCT'] : undefined,
        compareStyle: true,
      }
    ])),
    headers: [
      { name: 'Class' },
      { name: `Trans Cnt (#)` },
      { name: `Trans vLY ${isPCT ? '%' : '(#)'}` },
    ],
    sortable: true,
    sortableDefault: 0,
    pagination: true,
  }

  const dataAVG_TCKTObj = {
    data: dataFiltered.map(data => ([
      {
        link: {to: '/region/sales/class',params: {rgnNbr,deptNbr: data['EXT_CLASS_NBR']}},
        stringValue: data['MER_CLASS_NM'].replaceAll('/', '/\u200b'),
      },
      {
        dollarValue: data['TY_AVG_TCKT'],
        customStyle : { color: data['TY_AVG_TCKT'] == null ? 'black' : data['TY_AVG_TCKT'] < 0 ? 'red' : 'black' }
      },
      {
        dollarValue: isNOM ? data['AVG_TCKT_COMP_NOM'] == null ? '--': data['AVG_TCKT_COMP_NOM'] : undefined,
        pctValue: isPCT ? data['AVG_TCKT_COMP_PCT'] == null ? '--': data['AVG_TCKT_COMP_PCT'] : undefined,
        compareStyle: true,
      }
    ])),
    headers: [
      { name: 'Class' },
      { name: `Avg Ticket ($)` },
      { name: `Avg Ticket vLY ${isPCT ? '%' : '($)'}` },
    ],
    sortable: true,
    sortableDefault: 0,
    pagination: true,
  }

  return (
    <Tab.Pane attached={false}>
      <Grid>
        <Grid.Row>
          <Grid.Column textAlign='justified' width={8}>
            <Grid.Row>
              Metric:
            </Grid.Row>
            <Grid.Row>
              <Dropdown
                defaultValue={metric}
                selection
                scrolling
                compact
                size='tiny'
                options={[{ key: 'SLS', text: 'Sales', value: 'SLS' },
                { key: 'UNT', text: 'Units', value: 'UNT' },
                { key: 'TXN', text: 'Trans', value: 'TXN' },
                { key: 'AVG_TCKT', text: 'Avg Ticket', value: 'AVG_TCKT' },
                ]}
                onChange={(_, { value }) => {
                  setMetric(value)
                }} />
            </Grid.Row>
            &nbsp;
            <Grid.Row>Units:</Grid.Row>
            <UnitMenu unit={unit} setUnit={setUnit} />
          </Grid.Column>
          <Grid.Column textAlign='justified' width={8}>
            <Grid.Row>
              Dept:
              <div>
                <select
                  name="dept"
                  onChange={e => {
                    setDept(e.target.value)
                  }}
                  value={dept}
                  style={{ padding: 10 }}
                >
                  <option value="All" selected>All</option>
                  <option value="21">21</option>
                  <option value="22">22</option>
                  <option value="23">23</option>
                  <option value="24">24</option>
                  <option value="25">25</option>
                  <option value="26">26</option>
                  <option value="27">27</option>
                  <option value="28">28</option>
                  <option value="29">29</option>
                  <option value="30">30</option>
                  <option value="59">59</option>
                  <option value="78">78</option>
                </select>
              </div>
            </Grid.Row>
            <Grid.Row>&nbsp;</Grid.Row>
            <Grid.Row>
              Rows per Page:
            </Grid.Row>
            <Grid.Row>
              <div>
                <select
                  name="rowsPerPage"
                  onChange={e => {
                    setRowsPerPage(e.target.value)
                  }}
                  value={rowsPerPage}
                  style={{ padding: 10 }}
                >
                  <option value="20" selected>20</option>
                  <option value="40">40</option>
                </select>
              </div>
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      
      <Header textAlign='center'>Region {rgnNbr} Classes</Header>


      {isSLS && <DataTable data = {dataSLSObj}/>}
      {isUNT && <DataTable data = {dataUNTObj}/>}
      {isTXN && <DataTable data = {dataTXNObj}/>}
      {isAVG_TCKT && <DataTable data = {dataAVG_TCKTObj}/>}
    </Tab.Pane>
  )
}
