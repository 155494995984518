import React, { useState, useEffect } from 'react'
import { Table, Header, Loader, Grid, Dropdown, Icon, Segment, Pagination, Modal, Button } from 'semantic-ui-react'
import { withRouter, Link } from 'react-router-dom'
import { TimeframeDropdown } from '../Common/TimeframeDropdown'
import { TimeframeDropdownMon } from '../Common/TimeframeDropdownMon'

import { useQuery } from 'react-query'
import {
  getData
} from '../../service/DataService'
import { getPercentString } from '../Common/TableCellUtils'

export const VocDetail = withRouter(({ timeframe, settimeframe, fpToggle, location, levelName }) => {

  const search = new URLSearchParams(location.search)
  const strNbr = search.has('strNbr') ? search.get('strNbr') : 0

  const [sortColumn, setSortColumn] = useState("RESPONSE_DATE")
  const [sortDirection, setSortDirection] = useState("ascending")
  const [orderType, setOrderType] = useState('ALL')
  const [metric, setMetric] = useState('LTPA')
  const [pageNum, setPageNum] = useState(1)
  let rowsPerPage = 50

  const [open, setOpen] = useState(false)
  const [orderNumber, setOrderNumber] = useState('')
  const [improvementComment, setImprovementComment] = useState('')
  const [emplPrseCmt, setEmplPrseCmt] = useState('')

  const vocDetailQuery = useQuery(['querySwmVocDtl', { strNbr, fpToggle }], getData)

  useEffect(() => {
    setPageNum(1)
  }, [timeframe, orderType])

  if (vocDetailQuery.isLoading) {
    return <Loader active>Loading...</Loader>
  }

  // Filter by timeframe
  let dataFilterTf = vocDetailQuery.data.filter((row) => {
    return row[timeframe + '_FLG'] && row
  })

  // Filter by order type (for use in orderType dropdown, details table)
  let dataFilterType = dataFilterTf.filter((row) => {
    if (orderType === 'ALL') {
      return row
    }
    else if (orderType === 'OTHER') {
      return (
        row &&
        row['ORDER_TYPE'] === ''
      )
    }
    else {
      return (
        row &&
        row['ORDER_TYPE'] === orderType
      )
    }
  })

  // Sorting by column
  let dataSort = dataFilterType.sort((left, right) => {
    let result = left[sortColumn] - right[sortColumn]
    if (sortColumn === 'ORDER_NUMBER') {
      result = left.ORDER_NUMBER > right.ORDER_NUMBER ? 1 : -1
    }
    if (sortColumn === 'RESPONSE_DATE') {
      result = left.RESPONSE_DATE > right.RESPONSE_DATE ? 1 : -1
    }
    if (sortColumn === 'ORDER_TYPE') {
      result = left.ORDER_TYPE > right.ORDER_TYPE ? 1 : -1
    }
    if (sortColumn === 'CUSTOMER_CONTACT_APPROVAL') {
      result = left.CUSTOMER_CONTACT_APPROVAL > right.CUSTOMER_CONTACT_APPROVAL ? 1 : -1
    }
    if (sortDirection === 'descending') {
      result *= -1
    }
    return result
  })

  // Filter by order type
  let bossFilter = dataFilterTf.filter((row) => {
    return row && row['ORDER_TYPE'] === 'BOSS'
  })

  let bopisFilter = dataFilterTf.filter((row) => {
    return row && row['ORDER_TYPE'] === 'BOPIS'
  })

  let mixedFilter = dataFilterTf.filter((row) => {
    return row && row['ORDER_TYPE'] === 'MIXED'
  })

  // Find average by order type and metric (for use in voc overview grid)
  let satisfBoss = bossFilter.length !== 0 ? bossFilter.reduce((sum, obj) => sum + (obj.SATISFACTION_SCORE === 5 ? 1 : 0), 0) / bossFilter.length : null
  let satisfBopis = bopisFilter.length !== 0 ? bopisFilter.reduce((sum, obj) => sum + (obj.SATISFACTION_SCORE === 5 ? 1 : 0), 0) / bopisFilter.length : null
  let satisfMixed = mixedFilter.length !== 0 ? mixedFilter.reduce((sum, obj) => sum + (obj.SATISFACTION_SCORE === 5 ? 1 : 0), 0) / mixedFilter.length : null
  let satisfTotal = dataFilterTf.length !== 0 ? dataFilterTf.reduce((sum, obj) => sum + (obj.SATISFACTION_SCORE === 5 ? 1 : 0), 0) / dataFilterTf.length : null
  let prodCondBoss = bossFilter.length !== 0 ? bossFilter.reduce((sum, obj) => sum + (obj.PRODUCT_CONDITION_RATING === 5 ? 1 : 0), 0) / bossFilter.length : null
  let prodCondBopis = bopisFilter.length !== 0 ? bopisFilter.reduce((sum, obj) => sum + (obj.PRODUCT_CONDITION_RATING === 5 ? 1 : 0), 0) / bopisFilter.length : null
  let prodCondMixed = mixedFilter.length !== 0 ? mixedFilter.reduce((sum, obj) => sum + (obj.PRODUCT_CONDITION_RATING === 5 ? 1 : 0), 0) / mixedFilter.length : null
  let prodCondTotal = dataFilterTf.length !== 0 ? dataFilterTf.reduce((sum, obj) => sum + (obj.PRODUCT_CONDITION_RATING === 5 ? 1 : 0), 0) / dataFilterTf.length : null
  let orderAccBoss = bossFilter.length !== 0 ? bossFilter.reduce((sum, obj) => sum + (obj.ORDER_FULFILLMENT_ACCURACY === 5 ? 1 : 0), 0) / bossFilter.length : null
  let orderAccBopis = bopisFilter.length !== 0 ? bopisFilter.reduce((sum, obj) => sum + (obj.ORDER_FULFILLMENT_ACCURACY === 5 ? 1 : 0), 0) / bopisFilter.length : null
  let orderAccMixed = mixedFilter.length !== 0 ? mixedFilter.reduce((sum, obj) => sum + (obj.ORDER_FULFILLMENT_ACCURACY === 5 ? 1 : 0), 0) / mixedFilter.length : null
  let orderAccTotal = dataFilterTf.length !== 0 ? dataFilterTf.reduce((sum, obj) => sum + (obj.ORDER_FULFILLMENT_ACCURACY === 5 ? 1 : 0), 0) / dataFilterTf.length : null
  let waitTmBoss = bossFilter.length !== 0 ? bossFilter.reduce((sum, obj) => sum + (obj.WAIT_TIME === 5 ? 1 : 0), 0) / bossFilter.length : null
  let waitTmBopis = bopisFilter.length !== 0 ? bopisFilter.reduce((sum, obj) => sum + (obj.WAIT_TIME === 5 ? 1 : 0), 0) / bopisFilter.length : null
  let waitTmMixed = mixedFilter.length !== 0 ? mixedFilter.reduce((sum, obj) => sum + (obj.WAIT_TIME === 5 ? 1 : 0), 0) / mixedFilter.length : null
  let waitTmTotal = dataFilterTf.length !== 0 ? dataFilterTf.reduce((sum, obj) => sum + (obj.WAIT_TIME === 5 ? 1 : 0), 0) / dataFilterTf.length : null

  let dataPagination = dataSort.slice(rowsPerPage * (pageNum - 1), parseInt(Math.min(rowsPerPage * pageNum, dataSort.length)))


  return (
    <Segment fluid>
      <Grid>
        <Grid.Row>
          <Grid.Column width={8} textAlign='center'>
            <Grid.Row>&nbsp;</Grid.Row>
            <Link style={{ color: '#EE7125' }} to={`/store/overview?strNbr=${strNbr}`} data-test='b2o-link'>Back to Overview</Link>
          </Grid.Column>
          <Grid.Column width={8}>
            Timeframe:
            {new Date().getDay() === 1 ?
              <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} /> :
              <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />}
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Header textAlign='center' style={{ textTransform: 'capitalize' }}>VOC Overview - Store {strNbr}, {levelName}</Header>
      <Table textAlign='center' unstackable celled sortable size='small' >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell>BOSS</Table.HeaderCell>
            <Table.HeaderCell>BOPIS</Table.HeaderCell>
            <Table.HeaderCell>MIXED</Table.HeaderCell>
            <Table.HeaderCell>TOTAL</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>LTPA</Table.Cell>
            <Table.Cell>{getPercentString(satisfBoss)}</Table.Cell>
            <Table.Cell>{getPercentString(satisfBopis)}</Table.Cell>
            <Table.Cell>{getPercentString(satisfMixed)}</Table.Cell>
            <Table.Cell>{getPercentString(satisfTotal)}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Product Condition</Table.Cell>
            <Table.Cell>{getPercentString(prodCondBoss)}</Table.Cell>
            <Table.Cell>{getPercentString(prodCondBopis)}</Table.Cell>
            <Table.Cell>{getPercentString(prodCondMixed)}</Table.Cell>
            <Table.Cell>{getPercentString(prodCondTotal)}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Order Accuracy</Table.Cell>
            <Table.Cell>{getPercentString(orderAccBoss)}</Table.Cell>
            <Table.Cell>{getPercentString(orderAccBopis)}</Table.Cell>
            <Table.Cell>{getPercentString(orderAccMixed)}</Table.Cell>
            <Table.Cell>{getPercentString(orderAccTotal)}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Reasonable Wait Time</Table.Cell>
            <Table.Cell>{getPercentString(waitTmBoss)}</Table.Cell>
            <Table.Cell>{getPercentString(waitTmBopis)}</Table.Cell>
            <Table.Cell>{getPercentString(waitTmMixed)}</Table.Cell>
            <Table.Cell>{getPercentString(waitTmTotal)}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      &nbsp;
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            Select Order Type:
            <Dropdown
              fluid
              compact
              selection
              defaultValue='ALL'
              options={[
                { key: 'ALL', text: 'ALL', value: 'ALL' },
                { key: 'BOPIS', text: 'BOPIS', value: 'BOPIS' },
                { key: 'BOSS', text: 'BOSS', value: 'BOSS' },
                { key: 'MIXED', text: 'MIXED', value: 'MIXED' },
                { key: 'OTHER', text: 'OTHER', value: 'OTHER' }
              ]}
              onChange={(_, { value }) => {
                setOrderType(value)
              }}
            />
          </Grid.Column>
          <Grid.Column width={8}>
            Select Metric:
            <Dropdown
              fluid
              compact
              selection
              defaultValue='LTPA'
              options={[
                { key: 'LTPA', text: 'LTPA', value: 'LTPA' },
                { key: 'ProdCond', text: 'Product Condition', value: 'ProdCond' },
                { key: 'OrderAcc', text: 'Order Accuracy', value: 'OrderAcc' },
                { key: 'WaitTime', text: 'Wait Time', value: 'WaitTime' }
              ]}
              onChange={(_, { value }) => {
                setMetric(value)
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Header textAlign='center'>Online VOC Details</Header>
      {dataSort.length > rowsPerPage && <Grid>
        <Grid.Row>
          <Grid.Column width={16} textAlign='center'>
            <Pagination
              size='mini'
              defaultActivePage={1}
              totalPages={Math.floor((dataSort.length - 1) / rowsPerPage) + 1}
              activePage={pageNum}
              boundaryRange={0}
              siblingRange={1}
              onPageChange={(e, { activePage }) => {
                setPageNum(activePage)
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>}
      <Table textAlign='center' unstackable celled sortable size='small' style={{ fontSize: 10 }} >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={sortColumn === 'ORDER_NUMBER' ? sortDirection : null}
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumn !== 'ORDER_NUMBER') {
                  setSortColumn('ORDER_NUMBER')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>
              Order # {sortColumn !== 'ORDER_NUMBER' && <Icon fitted name='sort' />}
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === 'RESPONSE_DATE' ? sortDirection : null}
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumn !== 'RESPONSE_DATE') {
                  setSortColumn('RESPONSE_DATE')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>
              Response Date {sortColumn !== 'RESPONSE_DATE' && <Icon fitted name='sort' />}
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === 'ORDER_TYPE' ? sortDirection : null}
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumn !== 'ORDER_TYPE') {
                  setSortColumn('ORDER_TYPE')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>
              Order Type {sortColumn !== 'ORDER_TYPE' && <Icon fitted name='sort' />}
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === 'CUSTOMER_CONTACT_APPROVAL' ? sortDirection : null}
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumn !== 'CUSTOMER_CONTACT_APPROVAL') {
                  setSortColumn('CUSTOMER_CONTACT_APPROVAL')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>
              Cust Contact Approval {sortColumn !== 'CUSTOMER_CONTACT_APPROVAL' && <Icon fitted name='sort' />}
            </Table.HeaderCell>
            {metric === 'LTPA' && <Table.HeaderCell
              sorted={sortColumn === 'SATISFACTION_SCORE' ? sortDirection : null}
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumn !== 'SATISFACTION_SCORE') {
                  setSortColumn('SATISFACTION_SCORE')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>
              LTPA {sortColumn !== 'SATISFACTION_SCORE' && <Icon fitted name='sort' />}
            </Table.HeaderCell>}
            {metric === 'ProdCond' && <Table.HeaderCell
              sorted={sortColumn === 'PRODUCT_CONDITION_RATING' ? sortDirection : null}
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumn !== 'PRODUCT_CONDITION_RATING') {
                  setSortColumn('PRODUCT_CONDITION_RATING')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>
              Product Condition {sortColumn !== 'PRODUCT_CONDITION_RATING' && <Icon fitted name='sort' />}
            </Table.HeaderCell>}
            {metric === 'OrderAcc' && <Table.HeaderCell
              sorted={sortColumn === 'ORDER_FULFILLMENT_ACCURACY' ? sortDirection : null}
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumn !== 'ORDER_FULFILLMENT_ACCURACY') {
                  setSortColumn('ORDER_FULFILLMENT_ACCURACY')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>
              Order Accuracy {sortColumn !== 'ORDER_FULFILLMENT_ACCURACY' && <Icon fitted name='sort' />}
            </Table.HeaderCell>}
            {metric === 'WaitTime' && <Table.HeaderCell
              sorted={sortColumn === 'WAIT_TIME' ? sortDirection : null}
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumn !== 'WAIT_TIME') {
                  setSortColumn('WAIT_TIME')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>
              Reasonable Wait Time {sortColumn !== 'WAIT_TIME' && <Icon fitted name='sort' />}
            </Table.HeaderCell>}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {dataPagination.map((data) => {
            return (
              <>
                <Table.Row>
                  <Table.Cell
                    style={{
                      color: data['CUSTOMER_IMPROVEMENT_COMMENTS'].length > 0 || data['EMPLOYEE_PRAISE_COMMENTS'].length > 0 ? '#EE7125' : 'black',
                      textDecoration: (data['CUSTOMER_IMPROVEMENT_COMMENTS'].length > 0 || data['EMPLOYEE_PRAISE_COMMENTS'].length > 0) && 'underline'
                    }}
                    onClick={() => {
                      if (data['CUSTOMER_IMPROVEMENT_COMMENTS'].length > 0 || data['EMPLOYEE_PRAISE_COMMENTS'].length > 0) {
                        setOpen(true)
                        setOrderNumber(data['ORDER_NUMBER'])
                        setImprovementComment(data['CUSTOMER_IMPROVEMENT_COMMENTS'])
                        setEmplPrseCmt(data['EMPLOYEE_PRAISE_COMMENTS'])
                      }
                    }
                    }
                  >
                    {data['ORDER_NUMBER']}
                    {(data['CUSTOMER_IMPROVEMENT_COMMENTS'].length > 0 || data['EMPLOYEE_PRAISE_COMMENTS'].length > 0) && <Icon fitted style={{ color: 'black', textDecoration: 'none' }} name='comment outline' />}
                  </Table.Cell>
                  <Table.Cell singleLine>
                    {data['RESPONSE_DATE'].toString().slice(0, 10)}
                  </Table.Cell>
                  <Table.Cell>
                    {data['ORDER_TYPE']}
                  </Table.Cell>
                  <Table.Cell>
                    {data['CUSTOMER_CONTACT_APPROVAL']}
                  </Table.Cell>
                  {metric === 'LTPA' && <Table.Cell>
                    {data['SATISFACTION_SCORE']}
                  </Table.Cell>}
                  {metric === 'ProdCond' && <Table.Cell>
                    {data['PRODUCT_CONDITION_RATING']}
                  </Table.Cell>}
                  {metric === 'OrderAcc' && <Table.Cell>
                    {data['ORDER_FULFILLMENT_ACCURACY']}
                  </Table.Cell>}
                  {metric === 'WaitTime' && <Table.Cell>
                    {data['WAIT_TIME']}
                  </Table.Cell>}
                </Table.Row>
                {open && <Modal
                  closeIcon
                  open={open}
                  onClose={() => { setOpen(false) }}
                >
                  <Segment color='orange'>
                    <Grid textAlign='center'>
                      <Grid.Column width={16}>
                        <Grid.Row>
                          <Header>
                            Order Number: {orderNumber}
                          </Header>
                        </Grid.Row>
                        <Grid.Row>&nbsp;</Grid.Row>
                        <Grid.Row>
                          <Header textAlign='center' style={{ color: 'red' }}>Customer Comments</Header>
                        </Grid.Row>
                        <Grid.Row><p textAlign='center'>{improvementComment}</p></Grid.Row>
                        <Grid.Row>&nbsp;</Grid.Row>
                        <Grid.Row>
                          <Header textAlign='center' style={{ color: 'red' }}>Employee Praise Comments</Header>
                        </Grid.Row>
                        <Grid.Row><p textAlign='center'>{emplPrseCmt}</p></Grid.Row>
                        <Grid.Row>&nbsp;</Grid.Row>
                        <Grid.Row>
                          <Button
                            onClick={() => { setOpen(false) }}
                            style={{ color: 'white', backgroundColor: '#EE7125' }}
                            content='Close'
                          />
                        </Grid.Row>
                        <Grid.Row>&nbsp;</Grid.Row>
                      </Grid.Column>
                    </Grid>
                  </Segment>
                </Modal>}
              </>
            )
          })}
        </Table.Body>
      </Table>
    </Segment>
  )
})