import React, { useState } from 'react'
import { Table, Header, Loader, Grid, Segment, Menu, Icon } from 'semantic-ui-react'
import { withRouter, Link } from 'react-router-dom'
import { UnitMenu } from '../Common/UnitMenu'
import { TimeframeDropdown } from '../Common/TimeframeDropdown'
import { TimeframeDropdownMon } from '../Common/TimeframeDropdownMon'

import { useQuery } from 'react-query'
import {
  getData
} from '../../service/DataService'
import { useQueryParams } from '../../hooks/useQueryParams'
import { getCompareStyle } from '../Common/TableCellUtils'
import { DataTable } from '../Common/DataTable'

const METRIC_CONSTANTS = {
  district: {
    query: 'queryMeasDtlByDst',
    METRIC_LEVEL: 'DST',
    urlParam: 'dstNbr'
  },
  region: {
    query: 'queryMeasDtlByRgn',
    METRIC_LEVEL: 'RGN',
    urlParam: 'rgnNbr'
  }
}

export const MeasDetailDst = withRouter(({ timeframe, settimeframe, level, fpToggle, location }) => {

  const { dstNbr = 0, rgnNbr = 0 } = useQueryParams()

  const [sortColumn, setSortColumn] = useState("SUMMARY_PROGRAM_NAME")
  const [sortDirection, setSortDirection] = useState("ascending")
  const [totalSortColumn, setTotalSortColumn] = useState("STR_NBR")
  const [totalSortDirection, setTotalSortDirection] = useState("ascending")
  const [unit, setUnit] = useState('NOM')
  const [allWebStore, setAllWebStore] = useState('') // All = empty string, Web = '_WEB', Store = '_STR'

  const queryParams = level === 'district' ? { dstNbr } : { locNbr: rgnNbr }
  const measDetailQuery = useQuery([METRIC_CONSTANTS[level]['query'], { ...queryParams, fpToggle }], getData)

  if (measDetailQuery.isLoading) {
    return <Loader active>Loading...</Loader>
  }

  let sumProgNmFilter = measDetailQuery.data.filter((row) => {
    return row && row['SUMMARY_PROGRAM_NAME'] !== 'TOTAL' && row['METRIC_LEVEL'] === METRIC_CONSTANTS[level]['METRIC_LEVEL']
  })

  let totalFilter = measDetailQuery.data.filter((row) => {
    return row && row['SUMMARY_PROGRAM_NAME'] === 'TOTAL'
  })

  let totalSorted = totalFilter.sort((left, right) => {
    let result = left[totalSortColumn] - right[totalSortColumn]
    if (totalSortColumn === 'STR_NBR') {
      result = left.STR_NBR > right.STR_NBR ? 1 : -1
    }
    if (totalSortDirection === 'descending') {
      result *= -1
    }
    return result
  })

  const isMonday = new Date().getDay() === 1

  const measureBySumProgram = {
    headers: [
      {
        name: 'Summary Program Name '
      },
      { name: (isMonday && timeframe === 'WTD') ? 'TW Target ' : 'Target' },
      (isMonday && timeframe === 'WTD') ? { name: 'LW Target ' } : false,
      {
        name: 'Measure '
      },
      {
        name: unit === 'PCT' ? 'VLY% ' : 'VLY '
      }
    ].filter(row => Boolean(row)),
    data: sumProgNmFilter.map(row => [
      {
        stringValue: row['SUMMARY_PROGRAM_NAME']
      },
      { numValue: row[timeframe + allWebStore + '_MEAS_TGT'] },
      (isMonday && timeframe === 'WTD') && { numValue: row['LW' + allWebStore + '_MEAS_TGT'] },
      {
        numValue: row[timeframe + allWebStore + '_MEAS']
      },
      unit === 'NOM' ? {
        numValue: row[timeframe + allWebStore + '_VLY'],
        customStyle: getCompareStyle(row[timeframe + allWebStore + '_VLY'])
      } : {
        pctValue: row[timeframe + allWebStore + '_VLY_PCT'],
        customStyle: getCompareStyle(row[timeframe + allWebStore + '_VLY_PCT']),
        decimals: 2
      },
    ].filter(row => Boolean(row))
    ),
    sortable: true,
    sortableDefault: allWebStore === '' ? 0 : undefined
  }

  const sumProNmView = <>
    <Grid>
      <Grid.Column width={8}>
        <Grid.Row>Units:</Grid.Row>
        <Grid.Row>
          <UnitMenu unit={unit} setUnit={setUnit} />
        </Grid.Row>
      </Grid.Column>
      <Grid.Column width={8} textAlign='right'>
        <Grid.Row>&nbsp;</Grid.Row>
        <Grid.Row>
          <Menu compact size='small'>
            <Menu.Item
              name='All'
              active={allWebStore === ''}
              content='All'
              onClick={() => {
                setAllWebStore('')
              }}
            />
            <Menu.Item
              name='Web'
              active={allWebStore === '_WEB'}
              content='Web'
              onClick={() => {
                setAllWebStore('_WEB')
              }}
            />
            <Menu.Item
              name='Store'
              active={allWebStore === '_STR'}
              content='Store'
              onClick={() => {
                setAllWebStore('_STR')
              }}
            />
          </Menu>
        </Grid.Row>
      </Grid.Column>
    </Grid>
    {/* Measures overview - aggregated summary programs */}
    <Header textAlign='center'>Total Measures</Header>
    <Table textAlign='center' unstackable celled sortable size='small' >
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            sorted={totalSortColumn === 'STR_NBR' ? totalSortDirection : null}
            onClick={() => {
              if (totalSortColumn !== 'STR_NBR') {
                setTotalSortColumn('STR_NBR')
                setTotalSortDirection('ascending')
              } else {
                totalSortDirection === 'ascending' ? setTotalSortDirection('descending') : setTotalSortDirection('ascending')
              }
            }}
          >Org # {totalSortColumn !== 'STR_NBR' && <Icon fitted name='sort' />}</Table.HeaderCell>
          {new Date().getDay() === 1 && timeframe === 'WTD' && <Table.HeaderCell
            sorted={totalSortColumn === 'WTD' + allWebStore + '_MEAS_TGT' ? totalSortDirection : null}
            onClick={() => {
              if (totalSortColumn !== 'WTD' + allWebStore + '_MEAS_TGT') {
                setTotalSortColumn('WTD' + allWebStore + '_MEAS_TGT')
                setTotalSortDirection('ascending')
              } else {
                totalSortDirection === 'ascending' ? setTotalSortDirection('descending') : setTotalSortDirection('ascending')
              }
            }}
          >TW Target {totalSortColumn !== 'WTD' + allWebStore + '_MEAS_TGT' && <Icon fitted name='sort' />}</Table.HeaderCell>}
          {new Date().getDay() === 1 && timeframe === 'WTD' && <Table.HeaderCell
            sorted={totalSortColumn === 'LW' + allWebStore + '_MEAS_TGT' ? totalSortDirection : null}
            onClick={() => {
              if (totalSortColumn !== 'LW' + allWebStore + '_MEAS_TGT') {
                setTotalSortColumn('LW' + allWebStore + '_MEAS_TGT')
                setTotalSortDirection('ascending')
              } else {
                totalSortDirection === 'ascending' ? setTotalSortDirection('descending') : setTotalSortDirection('ascending')
              }
            }}
          >LW Target {totalSortColumn !== 'LW' + allWebStore + '_MEAS_TGT' && <Icon fitted name='sort' />}</Table.HeaderCell>}
          {!(new Date().getDay() === 1 && timeframe === 'WTD') && <Table.HeaderCell
            sorted={totalSortColumn === timeframe + allWebStore + '_MEAS_TGT' ? totalSortDirection : null}
            onClick={() => {
              if (totalSortColumn !== timeframe + allWebStore + '_MEAS_TGT') {
                setTotalSortColumn(timeframe + allWebStore + '_MEAS_TGT')
                setTotalSortDirection('ascending')
              } else {
                totalSortDirection === 'ascending' ? setTotalSortDirection('descending') : setTotalSortDirection('ascending')
              }
            }}
          >Target {totalSortColumn !== timeframe + allWebStore + '_MEAS_TGT' && <Icon fitted name='sort' />}</Table.HeaderCell>}
          <Table.HeaderCell
            sorted={totalSortColumn === timeframe + allWebStore + '_MEAS' ? totalSortDirection : null}
            onClick={() => {
              if (totalSortColumn !== timeframe + allWebStore + '_MEAS') {
                setTotalSortColumn(timeframe + allWebStore + '_MEAS')
                setTotalSortDirection('ascending')
              } else {
                totalSortDirection === 'ascending' ? setTotalSortDirection('descending') : setTotalSortDirection('ascending')
              }
            }}
          >Measures {totalSortColumn !== timeframe + allWebStore + '_MEAS' && <Icon fitted name='sort' />}</Table.HeaderCell>
          {unit === 'NOM' && <Table.HeaderCell
            sorted={totalSortColumn === timeframe + allWebStore + '_VLY' ? totalSortDirection : null}
            onClick={() => {
              if (totalSortColumn !== timeframe + allWebStore + '_VLY') {
                setTotalSortColumn(timeframe + allWebStore + '_VLY')
                setTotalSortDirection('ascending')
              } else {
                totalSortDirection === 'ascending' ? setTotalSortDirection('descending') : setTotalSortDirection('ascending')
              }
            }}
          >VLY {totalSortColumn !== timeframe + allWebStore + '_VLY' && <Icon fitted name='sort' />}</Table.HeaderCell>}
          {unit === 'PCT' && <Table.HeaderCell
            sorted={totalSortColumn === timeframe + allWebStore + '_VLY_PCT' ? totalSortDirection : null}
            onClick={() => {
              if (totalSortColumn !== timeframe + allWebStore + '_VLY_PCT') {
                setTotalSortColumn(timeframe + allWebStore + '_VLY_PCT')
                setTotalSortDirection('ascending')
              } else {
                totalSortDirection === 'ascending' ? setTotalSortDirection('descending') : setTotalSortDirection('ascending')
              }
            }}
          >VLY (%) {totalSortColumn !== timeframe + allWebStore + '_VLY_PCT' && <Icon fitted name='sort' />}</Table.HeaderCell>}
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {totalSorted.map((data) => {
          return (
            <Table.Row>
              {<Table.Cell>
                {data['METRIC_LEVEL'] === 'DST' ? 'DST ' + data['DST_NBR'] :
                  <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/store/meas?strNbr=${data['STR_NBR']}`}>STR {data['STR_NBR']}</Link>
                }
              </Table.Cell>}
              {new Date().getDay() === 1 && timeframe === 'WTD' && <Table.Cell>{data['WTD' + allWebStore + '_MEAS_TGT']}</Table.Cell>}
              {new Date().getDay() === 1 && timeframe === 'WTD' && <Table.Cell>{data['LW' + allWebStore + '_MEAS_TGT']}</Table.Cell>}
              {!(new Date().getDay() === 1 && timeframe === 'WTD') && <Table.Cell>
                {data[timeframe + allWebStore + '_MEAS_TGT'] != null ?
                  data[timeframe + allWebStore + '_MEAS_TGT'].toLocaleString(undefined, { maximumFractionDigits: 0 }) :
                  'Loading'}
              </Table.Cell>}
              <Table.Cell>
                {data[timeframe + allWebStore + '_MEAS'] != null ?
                  data[timeframe + allWebStore + '_MEAS'].toLocaleString(undefined, { maximumFractionDigits: 0 }) :
                  'Loading'}
              </Table.Cell>
              {unit === 'NOM' && <Table.Cell style={{ color: data[timeframe + allWebStore + '_VLY'] > 0 ? 'green' : data[timeframe + allWebStore + '_VLY'] < 0 ? 'red' : 'black' }}>
                {data[timeframe + allWebStore + '_VLY'] != null ?
                  data[timeframe + allWebStore + '_VLY'].toLocaleString(undefined, { maximumFractionDigits: 0 }) :
                  'Loading'}
              </Table.Cell>}
              {unit === 'PCT' && <Table.Cell style={{ color: data[timeframe + allWebStore + '_VLY_PCT'] > 0 ? 'green' : data[timeframe + allWebStore + '_VLY_PCT'] < 0 ? 'red' : 'black' }}>
                {data[timeframe + allWebStore + '_VLY_PCT'] != null ?
                  (100 * data[timeframe + allWebStore + '_VLY_PCT']).toLocaleString(undefined, { maximumFractionDigits: 2 }) + '%' :
                  'Loading'}
              </Table.Cell>}
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
    <p>*Target is reported as the total measures generated last year through the current period. For example, YTD target is all measures generated last year through the upcoming Sunday. Actual Measure counts and vLY are reported through the previous day.</p>
    {/* Measures detail - by individual summary program */}
    <Header textAlign='center'>Measures by Summary Program</Header>
    <DataTable data={measureBySumProgram} />
  </>

  return (
    <>
      <Grid>
        <Grid.Column width={8}>
          <Grid.Row>&nbsp;</Grid.Row>
          <Grid.Row>
            <Link style={{ color: '#EE7125' }} to={`/${level}/overview?${METRIC_CONSTANTS[level]['urlParam']}=${dstNbr || rgnNbr}`} data-test='b2o-link'>Back to Overview</Link>
          </Grid.Row>
        </Grid.Column>
        <Grid.Column width={8}>
          <Grid.Row>Timeframe:</Grid.Row>
          <Grid.Row>
            {isMonday ?
              <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} /> :
              <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />}
          </Grid.Row>
          <Grid.Row>&nbsp;</Grid.Row>
        </Grid.Column>
      </Grid>
      <Segment attached='bottom'>
        {sumProNmView}
      </Segment>
    </>
  )
})