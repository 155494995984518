import React from 'react'
import {
  Table,
  Header,
  Grid,
  Loader,
} from 'semantic-ui-react'
import { useQuery } from 'react-query'
import {
  withRouter
} from "react-router-dom"
import { TimeframeDropdown } from '../Common/TimeframeDropdown'
import { TimeframeDropdownMon } from '../Common/TimeframeDropdownMon'
import { ChannelAssociate } from './ChannelAssociate'
import { getPercentString, getCompareStyle, getCalloutStyle, getBpsString, getThousandDollarString } from '../Common/TableCellUtils'

import {
  getData
} from '../../service/DataService'

export const Credit = withRouter(({ timeframe, settimeframe, fpToggle, location }) => {

  const search = new URLSearchParams(location.search)
  const strNbr = search.has('strNbr') ? search.get('strNbr') : 0
  
  const creditPerfQuery = useQuery(['queryCreditPerfStrByStr', { strNbr, fpToggle }], getData)
  const credSlsPenCalcQuery = useQuery(['queryCreditSlsPenStrByStr', { strNbr, fpToggle }], getData)
  const credAssocChnlQuery = useQuery(['queryCreditAssocChnlByStr', { strNbr, fpToggle }], getData)

  if (creditPerfQuery.isLoading || credSlsPenCalcQuery.isLoading || credAssocChnlQuery.isLoading ) {
    return (<Loader active>Loading</Loader>)
  }
  if (!creditPerfQuery.data[0] || !credSlsPenCalcQuery.data[0]) {
    return (<Header textAlign='center'>Invalid Store Number</Header>)
  }

  const creditPerfData = creditPerfQuery.data[0]
  const credSlsPenCalcData = credSlsPenCalcQuery.data[0]
  const credAssocChnlData = credAssocChnlQuery.data

  return (
    <>
      <Grid>
        <Grid.Column width={8}>
          <Grid.Row>&nbsp;</Grid.Row>
        </Grid.Column>
        <Grid.Column width={8}>
          Timeframe:
      {new Date().getDay() === 1 ?
            <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} /> :
            <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />}
        </Grid.Column>
      </Grid>

      <Header textAlign='center'>Credit - Store #{strNbr}</Header>

      {/* Applications Summary */}
      <Header textAlign='center'>Applications</Header>
      <Table textAlign='center' celled sortable unstackable size='small' style={{ fontSize: 12 }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Category</Table.HeaderCell>
            <Table.HeaderCell>Apps</Table.HeaderCell>
            <Table.HeaderCell>LY</Table.HeaderCell>
            <Table.HeaderCell>Goal</Table.HeaderCell>
            <Table.HeaderCell>% vGoal</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body singleLine>
          <Table.Row>
            <Table.Cell>Consumer</Table.Cell>
            <Table.Cell>{creditPerfData[timeframe + '_CONS_APPS']}</Table.Cell>
            <Table.Cell>{creditPerfData[timeframe + '_LY_CONS_APPS']}</Table.Cell>
            <Table.Cell>{creditPerfData[timeframe + '_CONS_APP_GOAL']}</Table.Cell>
            <Table.Cell style={{
              color: creditPerfData[timeframe + '_CONS_APPS'] > creditPerfData[timeframe + '_CONS_APP_GOAL'] ? 'green' :
                creditPerfData[timeframe + '_CONS_APPS'] < creditPerfData[timeframe + '_CONS_APP_GOAL'] ? 'red' :
                  'black'
            }}>{
                creditPerfData[timeframe + '_CONS_APPS'] != null && creditPerfData[timeframe + '_CONS_APP_GOAL'] != null ?
                  creditPerfData[timeframe + '_CONS_APP_GOAL'] === 0 ? '0%' :
                    (100 * (creditPerfData[timeframe + '_CONS_APPS'] / creditPerfData[timeframe + '_CONS_APP_GOAL'] - 1)).toLocaleString(undefined, { maximumFractionDigits: 1 }) + '%' :
                  'Loading'
              }
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Commercial</Table.Cell>
            <Table.Cell>{creditPerfData[timeframe + '_COMM_APPS']}</Table.Cell>
            <Table.Cell>{creditPerfData[timeframe + '_LY_COMM_APPS']}</Table.Cell>
            <Table.Cell>{creditPerfData[timeframe + '_COMM_APP_GOAL']}</Table.Cell>
            <Table.Cell style={{
              color: creditPerfData[timeframe + '_COMM_APPS'] > creditPerfData[timeframe + '_COMM_APP_GOAL'] ? 'green' :
                creditPerfData[timeframe + '_COMM_APPS'] < creditPerfData[timeframe + '_COMM_APP_GOAL'] ? 'red' :
                  'black'
            }}>{
                creditPerfData[timeframe + '_COMM_APPS'] != null && creditPerfData[timeframe + '_COMM_APP_GOAL'] != null ?
                  creditPerfData[timeframe + '_COMM_APP_GOAL'] === 0 ? '0%' :
                    (100 * (creditPerfData[timeframe + '_COMM_APPS'] / creditPerfData[timeframe + '_COMM_APP_GOAL'] - 1)).toLocaleString(undefined, { maximumFractionDigits: 1 }) + '%' :
                  'Loading'
              }
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Total</Table.Cell>
            <Table.Cell>{creditPerfData[timeframe + '_TOT_APPS']}</Table.Cell>
            <Table.Cell>{creditPerfData[timeframe + '_LY_TOT_APPS']}</Table.Cell>
            <Table.Cell>{creditPerfData[timeframe + '_TOT_APP_GOAL']}</Table.Cell>
            <Table.Cell style={{
              color: creditPerfData[timeframe + '_TOT_APPS'] > creditPerfData[timeframe + '_TOT_APP_GOAL'] ? 'green' :
                creditPerfData[timeframe + '_TOT_APPS'] < creditPerfData[timeframe + '_TOT_APP_GOAL'] ? 'red' :
                  'black'
            }}>{
                creditPerfData[timeframe + '_TOT_APPS'] != null && creditPerfData[timeframe + '_TOT_APP_GOAL'] != null ?
                  creditPerfData[timeframe + '_TOT_APP_GOAL'] === 0 ? '0%' :
                    (100 * (creditPerfData[timeframe + '_TOT_APPS'] / creditPerfData[timeframe + '_TOT_APP_GOAL'] - 1)).toLocaleString(undefined, { maximumFractionDigits: 1 }) + '%' :
                  'Loading'
              }
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <p>Note: If % vGoal ≥ 0%, then the goal is met.</p>

      {/* Efficiency Summary */}
      <Header textAlign='center'>Efficiency</Header>
      <Table textAlign='center' celled sortable unstackable size='small' style={{ fontSize: 12 }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>App<br/>Efficiency</Table.HeaderCell>
            <Table.HeaderCell>LY</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>{creditPerfData[timeframe + '_TXN_COUNT'] != null && creditPerfData[timeframe + '_TOT_APPS'] != null ?
              creditPerfData[timeframe + '_TOT_APPS'] === 0 ? '-' :
                (creditPerfData[timeframe + '_TXN_COUNT'] / creditPerfData[timeframe + '_TOT_APPS']).toLocaleString(undefined, { maximumFractionDigits: 0 }) :
              'Loading'
            }</Table.Cell>
            <Table.Cell>{creditPerfData[timeframe + '_LY_TXN_COUNT'] != null && creditPerfData[timeframe + '_LY_TOT_APPS'] != null ?
              creditPerfData[timeframe + '_LY_TOT_APPS'] === 0 ? '-' :
                (creditPerfData[timeframe + '_LY_TXN_COUNT'] / creditPerfData[timeframe + '_LY_TOT_APPS']).toLocaleString(undefined, { maximumFractionDigits: 0 }) :
              'Loading'
            }</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      {/* Usage Summary */}
      <Header textAlign='center'>Usage</Header>
      <Table textAlign='center' celled sortable unstackable size='small' style={{ fontSize: 12 }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Category</Table.HeaderCell>
            <Table.HeaderCell>Credit Sales</Table.HeaderCell>
            <Table.HeaderCell>Usage</Table.HeaderCell>
            <Table.HeaderCell>bps vPlan</Table.HeaderCell>
            <Table.HeaderCell>bps vLY</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Consumer</Table.Cell>
            <Table.Cell style={ getCalloutStyle(credSlsPenCalcData[timeframe+'_CONS_SLS_AMT']) }>
              { getThousandDollarString(credSlsPenCalcData[timeframe+'_CONS_SLS_AMT']) }
            </Table.Cell>
            <Table.Cell singleLine style={getCalloutStyle(credSlsPenCalcData[timeframe+'_CONS_SLS_AMT'] / credSlsPenCalcData[timeframe+'_TOT_SLS_AMT'])}>
              { getPercentString(credSlsPenCalcData[timeframe+'_CONS_SLS_AMT'] / credSlsPenCalcData[timeframe+'_TOT_SLS_AMT']) }
            </Table.Cell>
            <Table.Cell singleLine style={getCompareStyle(credSlsPenCalcData[timeframe+'_CONS_SLS_AMT'] / credSlsPenCalcData[timeframe+'_TOT_SLS_AMT'] - credSlsPenCalcData[timeframe+'_CONS_CRED_SLS_PLN'] / credSlsPenCalcData[timeframe+'_TOT_SLS_PLN'])}>
              { getBpsString(credSlsPenCalcData[timeframe+'_CONS_SLS_AMT'] / credSlsPenCalcData[timeframe+'_TOT_SLS_AMT'] - credSlsPenCalcData[timeframe+'_CONS_CRED_SLS_PLN'] / credSlsPenCalcData[timeframe+'_TOT_SLS_PLN']) }
            </Table.Cell>
            <Table.Cell singleLine style={getCompareStyle(credSlsPenCalcData[timeframe+'_CONS_SLS_AMT'] / credSlsPenCalcData[timeframe+'_TOT_SLS_AMT'] - credSlsPenCalcData[timeframe+'_LY_CONS_SLS_AMT'] / credSlsPenCalcData[timeframe+'_LY_TOT_SLS_AMT'])}>
              { getBpsString(credSlsPenCalcData[timeframe+'_CONS_SLS_AMT'] / credSlsPenCalcData[timeframe+'_TOT_SLS_AMT'] - credSlsPenCalcData[timeframe+'_LY_CONS_SLS_AMT'] / credSlsPenCalcData[timeframe+'_LY_TOT_SLS_AMT']) }
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Commercial</Table.Cell>
            <Table.Cell style={ getCalloutStyle(credSlsPenCalcData[timeframe+'_COMM_SLS_AMT']) }>
              { getThousandDollarString(credSlsPenCalcData[timeframe+'_COMM_SLS_AMT']) }
            </Table.Cell>
            <Table.Cell style={getCalloutStyle(credSlsPenCalcData[timeframe+'_COMM_SLS_AMT'] / credSlsPenCalcData[timeframe+'_TOT_SLS_AMT'])}>
              { getPercentString(credSlsPenCalcData[timeframe+'_COMM_SLS_AMT'] / credSlsPenCalcData[timeframe+'_TOT_SLS_AMT']) }
            </Table.Cell>
            <Table.Cell singleLine style={getCompareStyle(credSlsPenCalcData[timeframe+'_COMM_SLS_AMT'] / credSlsPenCalcData[timeframe+'_TOT_SLS_AMT'] - credSlsPenCalcData[timeframe+'_COMM_CRED_SLS_PLN'] / credSlsPenCalcData[timeframe+'_TOT_SLS_PLN'])}>
              { getBpsString(credSlsPenCalcData[timeframe+'_COMM_SLS_AMT'] / credSlsPenCalcData[timeframe+'_TOT_SLS_AMT'] - credSlsPenCalcData[timeframe+'_COMM_CRED_SLS_PLN'] / credSlsPenCalcData[timeframe+'_TOT_SLS_PLN']) }
            </Table.Cell>
            <Table.Cell singleLine style={getCompareStyle(credSlsPenCalcData[timeframe+'_COMM_SLS_AMT'] / credSlsPenCalcData[timeframe+'_TOT_SLS_AMT'] - credSlsPenCalcData[timeframe+'_LY_COMM_SLS_AMT'] / credSlsPenCalcData[timeframe+'_LY_TOT_SLS_AMT'])}>
              { getBpsString(credSlsPenCalcData[timeframe+'_COMM_SLS_AMT'] / credSlsPenCalcData[timeframe+'_TOT_SLS_AMT'] - credSlsPenCalcData[timeframe+'_LY_COMM_SLS_AMT'] / credSlsPenCalcData[timeframe+'_LY_TOT_SLS_AMT']) }
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Total</Table.Cell>
            <Table.Cell style={ getCalloutStyle(credSlsPenCalcData[timeframe+'_CRED_SLS_AMT']) }>
              { getThousandDollarString(credSlsPenCalcData[timeframe+'_CRED_SLS_AMT']) }
            </Table.Cell>
            <Table.Cell style={getCalloutStyle(credSlsPenCalcData[timeframe+'_CRED_SLS_AMT'] / credSlsPenCalcData[timeframe+'_TOT_SLS_AMT'])}>
              { getPercentString(credSlsPenCalcData[timeframe+'_CRED_SLS_AMT'] / credSlsPenCalcData[timeframe+'_TOT_SLS_AMT']) }
            </Table.Cell>
            <Table.Cell singleLine style={getCompareStyle(credSlsPenCalcData[timeframe+'_CRED_SLS_AMT'] / credSlsPenCalcData[timeframe+'_TOT_SLS_AMT'] - credSlsPenCalcData[timeframe+'_TOT_CRED_SLS_PLN'] / credSlsPenCalcData[timeframe+'_TOT_SLS_PLN'])}>
              { getBpsString(credSlsPenCalcData[timeframe+'_CRED_SLS_AMT'] / credSlsPenCalcData[timeframe+'_TOT_SLS_AMT'] - credSlsPenCalcData[timeframe+'_TOT_CRED_SLS_PLN'] / credSlsPenCalcData[timeframe+'_TOT_SLS_PLN']) }
            </Table.Cell>
            <Table.Cell singleLine style={getCompareStyle(credSlsPenCalcData[timeframe+'_CRED_SLS_AMT'] / credSlsPenCalcData[timeframe+'_TOT_SLS_AMT'] - credSlsPenCalcData[timeframe+'_LY_CRED_SLS_AMT'] / credSlsPenCalcData[timeframe+'_LY_TOT_SLS_AMT'])}>
              { getBpsString(credSlsPenCalcData[timeframe+'_CRED_SLS_AMT'] / credSlsPenCalcData[timeframe+'_TOT_SLS_AMT'] - credSlsPenCalcData[timeframe+'_LY_CRED_SLS_AMT'] / credSlsPenCalcData[timeframe+'_LY_TOT_SLS_AMT']) }
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      {/* Channel-Associate Tab Toggle */}
      <ChannelAssociate credAssocChnlData={credAssocChnlData} timeframe={timeframe} />

    </>
  )

})