import React, { useState } from 'react'
import {
  Table,
  Header,
  Grid,
  Loader,
} from 'semantic-ui-react'
import { useQuery } from 'react-query'
import {
  withRouter, Link
} from "react-router-dom"
import { OverviewTabs } from './OverviewTabs'
import { UnitMenu } from '../Common/UnitMenu'
import { TimeframeDropdown } from '../Common/TimeframeDropdown'
import { TimeframeDropdownMon } from '../Common/TimeframeDropdownMon'
import { getCompareStyle, getDollarString, getPercentString } from '../Common/TableCellUtils'
// import { Outliers } from '../Support/Outliers'

import {
  getData
} from '../../service/DataService'

export const LTPA_GOAL = 0.75
export const LTSA_GOAL = 0.90
export const GET_GOAL = 0.80


export const Overview = withRouter(({ timeframe, settimeframe, fpToggle, userGetsNPlus1, userIsCorporate, userEmplCtgryNm, location }) => {

  const [unit, setUnit] = useState('NOM')

  const search = new URLSearchParams(location.search)
  const strNbr = search.has('strNbr') ? search.get('strNbr') : 0

  const storeOverviewQuery = useQuery(['querySwmStore', { strNbr, fpToggle }], getData)
  const slsDeptByStrQuery = useQuery(['querySlsDeptByStr', { strNbr, fpToggle }], getData)
  const slsClsByStrQuery = useQuery(['querySlsClsByStr', { strNbr, fpToggle }], getData)
  const slsSclsByStrQuery = useQuery(['querySlsSclsByStr', { strNbr, fpToggle }], getData)
  const locAisleDtlQuery = useQuery(['querySwmLocAisleDtl', { strNbr, fpToggle }], getData)
  const deptInvQuery = useQuery(['queryInvDeptByStr', { strNbr, fpToggle }], getData)
  const daysSafeQuery = useQuery(['querySafetyIncOshaLtGlbiByStr', { strNbr, fpToggle }], getData)
  const Top40Btm40Query = useQuery(['querySlsSkuByStr', { strNbr, fpToggle }], getData);


  if (storeOverviewQuery.isLoading ||
    slsDeptByStrQuery.isLoading || slsClsByStrQuery.isLoading || slsSclsByStrQuery.isLoading ||
    locAisleDtlQuery.isLoading || deptInvQuery.isLoading || daysSafeQuery.isLoading || Top40Btm40Query.isLoading) {
    return (<Loader active>Loading</Loader>)
  }
  if (!storeOverviewQuery.data[0]) {
    return (<Header textAlign='center'>Invalid or Unauthorized Store</Header>)
  }

  const storeMetrics = storeOverviewQuery.data[0]
  const dataSlsDeptByStr = slsDeptByStrQuery.data
  const dataSlsClsByStr = slsClsByStrQuery.data
  const dataSlsSclsByStr = slsSclsByStrQuery.data
  const locAisleDtlMetrics = locAisleDtlQuery.data
  const deptInventory = deptInvQuery.data
  const daysSafe = daysSafeQuery.data[0]
  const storeNumber = String(storeMetrics['STR_NBR']).padStart(4, '0');
  // const outliersData = outliersQuery.data
  return (
    <>
      <Grid>
        <Grid.Column>
          Store #{strNbr + ' → '}
          {/* If the user has access to N+1 or is corporate, then supply the link to the district on the store page */}
          {(userGetsNPlus1 || userIsCorporate) && <Link
            style={{ color: '#EE7125', textDecoration: 'underline' }}
            to={`/district/overview?dstNbr=${storeMetrics.DST_NBR.toString().padStart(4, '0')}`}
          >District #{storeMetrics.DST_NBR.toString().padStart(4, '0')}</Link>}
        </Grid.Column>
      </Grid>

      <Grid>
        <Grid.Column width={8}>
          <Grid.Row>
            Units:
          </Grid.Row>
          <Grid.Row>
            <UnitMenu unit={unit} setUnit={setUnit} />
          </Grid.Row>
          <Grid.Row>&nbsp;</Grid.Row>
        </Grid.Column>
        <Grid.Column width={8}>
          Timeframe:
          {new Date().getDay() === 1 ?
            <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} /> :
            <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />}
        </Grid.Column>
      </Grid>
      <Header textAlign='center'>Overview - Store {storeNumber}, {storeMetrics['STR_NM']}</Header>
      <div style={{ display: 'flex', justifyContent: 'center' }}>Data as of {storeMetrics['TRNSFM_DT']}</div>
      {/* <Outliers outliersData={outliersData} fpToggle={fpToggle} /> */}
      <Table textAlign='center' celled unstackable size='small'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell>Actual</Table.HeaderCell>
            {unit === 'NOM' && <Table.HeaderCell>vP/vF</Table.HeaderCell>}
            {unit === 'PCT' && <Table.HeaderCell>vP/vF %</Table.HeaderCell>}
            {unit === 'NOM' && <Table.HeaderCell>vLY</Table.HeaderCell>}
            {unit === 'PCT' && <Table.HeaderCell>vLY %</Table.HeaderCell>}
          </Table.Row>
        </Table.Header>

        <Table.Body singleLine>
        <Table.Row>
            {daysSafeQuery.data.length > 0 && (userEmplCtgryNm.toUpperCase().includes("SALAR") || userEmplCtgryNm.toUpperCase().includes("OFFICER") || userEmplCtgryNm.toUpperCase().includes("CORP_CNTRC")) ?
              <Table.Cell><Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/store/safety?strNbr=${strNbr}`}>Days Safe</Link></Table.Cell> :
              <Table.Cell singleLine>Days Safe</Table.Cell>}
            <Table.Cell>{daysSafeQuery.data.length > 0 ? daysSafe.DAYS_SAFE : '-'}</Table.Cell>
            <Table.Cell>-</Table.Cell>
            <Table.Cell>-</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell singleLine>
              Sales
            </Table.Cell>
            <Table.Cell singleLine>
              {getDollarString(storeMetrics[timeframe + '_SLS_AMT_ACT'])}
            </Table.Cell>
            {unit === 'NOM' && <Table.Cell singleLine style={getCompareStyle(storeMetrics[timeframe + '_SLS_VP'])} >
              {getDollarString(storeMetrics[timeframe + '_SLS_VP'])}
            </Table.Cell>}
            {unit === 'PCT' && <Table.Cell singleLine style={getCompareStyle(storeMetrics[timeframe + '_SLS_AMT_VP_PCT'])}>
              {getPercentString(storeMetrics[timeframe + '_SLS_AMT_VP_PCT'])}
            </Table.Cell>}
            {unit === 'NOM' && <Table.Cell singleLine style={getCompareStyle(storeMetrics[timeframe + '_SLS_AMT_VLY'])} >
              {getDollarString(storeMetrics[timeframe + '_SLS_AMT_VLY'])}
            </Table.Cell>}
            {unit === 'PCT' && <Table.Cell singleLine style={getCompareStyle(storeMetrics[timeframe + '_SLS_AMT_VLY_PCT'])} >
              {getPercentString(storeMetrics[timeframe + '_SLS_AMT_VLY_PCT'], 3)}
            </Table.Cell>}
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              Units
            </Table.Cell>
            <Table.Cell >
              {storeMetrics[timeframe + '_UNTS_ACT'].toLocaleString(undefined, { maximumFractionDigits: 0 })}
            </Table.Cell>
            <Table.Cell>-</Table.Cell>
            {unit === 'NOM' && <Table.Cell style={getCompareStyle(storeMetrics[timeframe + '_UNTS_VLY'])} >
              {storeMetrics[timeframe + '_UNTS_VLY'].toLocaleString(undefined, { maximumFractionDigits: 0 })}
            </Table.Cell>}
            {unit === 'PCT' && <Table.Cell style={getCompareStyle(storeMetrics[timeframe + '_UNTS_VLY_PCT'])} >
              {getPercentString(storeMetrics[timeframe + '_UNTS_VLY_PCT'])}
            </Table.Cell>}
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              Transactions
            </Table.Cell>
            <Table.Cell >
              {storeMetrics[timeframe + '_TRNS_ACT'].toLocaleString(undefined, { maximumFractionDigits: 0 })}
            </Table.Cell>
            <Table.Cell>-</Table.Cell>
            {unit === 'NOM' && <Table.Cell style={getCompareStyle(storeMetrics[timeframe + '_TRNS_VLY'])} >
              {storeMetrics[timeframe + '_TRNS_VLY'].toLocaleString(undefined, { maximumFractionDigits: 0 })}
            </Table.Cell>}
            {unit === 'PCT' && <Table.Cell style={getCompareStyle(storeMetrics[timeframe + '_TRNS_VLY_PCT'])}>
              {getPercentString(storeMetrics[timeframe + '_TRNS_VLY_PCT'])}
            </Table.Cell>}
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              Average Ticket
            </Table.Cell>
            <Table.Cell >
              {getDollarString(storeMetrics[timeframe + '_AVG_TCKT_ACT'], 2)}
            </Table.Cell>
            <Table.Cell>-</Table.Cell>
            {unit === 'NOM' && <Table.Cell style={getCompareStyle(storeMetrics[timeframe + '_AVG_TCKT_VLY'])} >
              {getDollarString(storeMetrics[timeframe + '_AVG_TCKT_VLY'], 2)}
            </Table.Cell>}
            {unit === 'PCT' && <Table.Cell style={getCompareStyle(storeMetrics[timeframe + '_AVG_TCKT_VLY_PCT'])}>
              {getPercentString(storeMetrics[timeframe + '_AVG_TCKT_VLY_PCT'], 3)}
            </Table.Cell>}
          </Table.Row>
          <Table.Row>
            <Table.Cell>Units per Basket</Table.Cell>
            <Table.Cell>{(storeMetrics['TY_' + timeframe + '_UNT_SLS'] / storeMetrics['TY_' + timeframe + '_TXN_CNT']).toLocaleString(undefined, { maximumFractionDigits: 2 })}</Table.Cell>
            <Table.Cell>-</Table.Cell>
            {unit === 'NOM' && <Table.Cell style={getCompareStyle(storeMetrics['TY_' + timeframe + '_COMP_UNT_SLS'] / storeMetrics['TY_' + timeframe + '_COMP_TXN_CNT'] - storeMetrics['LY_' + timeframe + '_COMP_UNT_SLS'] / storeMetrics['LY_' + timeframe + '_COMP_TXN_CNT'])}>{(storeMetrics['TY_' + timeframe + '_COMP_UNT_SLS'] / storeMetrics['TY_' + timeframe + '_COMP_TXN_CNT'] - storeMetrics['LY_' + timeframe + '_COMP_UNT_SLS'] / storeMetrics['LY_' + timeframe + '_COMP_TXN_CNT']).toLocaleString(undefined, { maximumFractionDigits: 3 })}</Table.Cell>}
            {unit === 'PCT' && <Table.Cell style={getCompareStyle(storeMetrics['TY_' + timeframe + '_COMP_UNT_SLS'] / storeMetrics['TY_' + timeframe + '_COMP_TXN_CNT'] - storeMetrics['LY_' + timeframe + '_COMP_UNT_SLS'] / storeMetrics['LY_' + timeframe + '_COMP_TXN_CNT'])}>{getPercentString((storeMetrics['TY_' + timeframe + '_COMP_UNT_SLS'] / storeMetrics['TY_' + timeframe + '_COMP_TXN_CNT'] - storeMetrics['LY_' + timeframe + '_COMP_UNT_SLS'] / storeMetrics['LY_' + timeframe + '_COMP_TXN_CNT']) / (storeMetrics['LY_' + timeframe + '_COMP_UNT_SLS'] / storeMetrics['LY_' + timeframe + '_COMP_TXN_CNT']),2)}</Table.Cell>}
          </Table.Row>
          <Table.Row>
              <Table.Cell> <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/osa?strNbr=${strNbr}`} data-test='overview-osa-link'>
                  On-Shelf Availability
                </Link></Table.Cell>
              <Table.Cell>-</Table.Cell>
              <Table.Cell>-</Table.Cell>
              <Table.Cell>-</Table.Cell>
            </Table.Row>
          <Table.Row>
            <Table.Cell>
              <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/store/smd?strNbr=${strNbr}`} data-test='overview-smd-link'>
                SMD
              </Link>
            </Table.Cell>
            <Table.Cell >
              {getPercentString(storeMetrics[timeframe + '_SMD_ACT'], 2)}
            </Table.Cell>
            {unit === 'NOM' && <Table.Cell style={getCompareStyle(-1 * storeMetrics[timeframe + '_SMD_VP'])} >
              {storeMetrics[timeframe + '_SMD_VP'].toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' bps'}
            </Table.Cell>}
            {unit === 'PCT' && <Table.Cell>-</Table.Cell>}
            {unit === 'NOM' && <Table.Cell style={getCompareStyle(-1 * storeMetrics[timeframe + '_SMD_VLY'])} >
              {storeMetrics[timeframe + '_SMD_VLY'].toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' bps'}
            </Table.Cell>}
            {unit === 'PCT' && <Table.Cell>-</Table.Cell>}
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/store/labor?strNbr=${strNbr}`} data-test='overview-labor-link'>
                Labor
              </Link>
            </Table.Cell>
            <Table.Cell >
              {storeMetrics[timeframe + '_LBR_ACT'].toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' hrs'}
            </Table.Cell>
            {unit === 'NOM' && <Table.Cell >
              {storeMetrics[timeframe + '_LBR_VF'].toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' hrs'}
            </Table.Cell>}
            {unit === 'PCT' && <Table.Cell style={{ color: storeMetrics[timeframe + '_LBR_VF_PCT'] > 0.01 || storeMetrics[timeframe + '_LBR_VF_PCT'] < -0.01 ? 'red' : 'green' }}>
              {getPercentString(storeMetrics[timeframe + '_LBR_VF_PCT'])}
            </Table.Cell>}
            <Table.Cell>-</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/store/ltsa?strNbr=${strNbr}`} data-test='overview-get-link'>
                GET
              </Link>
            </Table.Cell>
            <Table.Cell style={{ color: storeMetrics[timeframe + '_GET_ACT'] >= GET_GOAL ? 'green' : 'red' }}>
              {getPercentString(storeMetrics[timeframe + '_GET_ACT'], 2)}
            </Table.Cell>
            <Table.Cell>-</Table.Cell>
            {/* GET LY metrics not available yet. Save bottom comment beginning FY2022 */}
            {unit === 'NOM' && <Table.Cell style={getCompareStyle(storeMetrics[timeframe + '_GET_VLY'])}>
              {getPercentString(storeMetrics[timeframe + '_GET_VLY'], 2)}
            </Table.Cell>}
            {unit === 'PCT' && <Table.Cell>-</Table.Cell>}
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/store/ltsa?strNbr=${strNbr}`} data-test='overview-ltsa-link'>
                LTSA
              </Link>
            </Table.Cell>
            <Table.Cell style={{ color: storeMetrics[timeframe + '_LTSA_ACT'] >= LTSA_GOAL ? 'green' : 'red' }}>
              {getPercentString(storeMetrics[timeframe + '_LTSA_ACT'], 2)}
            </Table.Cell>
            <Table.Cell>-</Table.Cell>
            {unit === 'NOM' && <Table.Cell style={getCompareStyle(storeMetrics[timeframe + '_LTSA_VLY'])}>
              {getPercentString(storeMetrics[timeframe + '_LTSA_VLY'], 2)}
            </Table.Cell>}
            {unit === 'PCT' && <Table.Cell>-</Table.Cell>}
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/store/voc?strNbr=${strNbr}`} data-test='overview-onlineSAT-link'>LTPA</Link>
            </Table.Cell>
            <Table.Cell style={{ color: storeMetrics[timeframe + '_ONLINE_SAT_ACT'] > LTPA_GOAL ? 'green' : storeMetrics[timeframe + '_ONLINE_SAT_ACT'] < LTPA_GOAL ? 'red' : 'black' }}>
              {storeMetrics[timeframe + '_ONLINE_SAT_ACT'] != null ?
                getPercentString(storeMetrics[timeframe + '_ONLINE_SAT_ACT']) :
                '-'}
            </Table.Cell>
            <Table.Cell>-</Table.Cell>
            {unit === 'NOM' && <Table.Cell style={getCompareStyle(storeMetrics[timeframe + '_ONLINE_SAT_VLY'])}>
              {storeMetrics[timeframe + '_ONLINE_SAT_VLY'] != null ?
                storeMetrics[timeframe + '_ONLINE_SAT_VLY'].toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%' :
                '--'}
            </Table.Cell>}
            {unit === 'PCT' && <Table.Cell>-</Table.Cell>}
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/store/leads?strNbr=${strNbr}`} data-test='overview-leads-link'>
                Leads
              </Link>
            </Table.Cell>
            <Table.Cell >
              {storeMetrics[timeframe + '_LEADS_ACT'].toLocaleString(undefined, { maximumFractionDigits: 0 })}
            </Table.Cell>
            <Table.Cell>-</Table.Cell>
            {unit === 'NOM' && <Table.Cell style={getCompareStyle(storeMetrics[timeframe + '_LEADS_VLY'])}>
              {storeMetrics[timeframe + '_LEADS_VLY'].toLocaleString(undefined, { maximumFractionDigits: 0 })}
            </Table.Cell>}
            {unit === 'PCT' && <Table.Cell style={getCompareStyle(storeMetrics[timeframe + '_LEADS_VLY_PCT'])}>
              {getPercentString(storeMetrics[timeframe + '_LEADS_VLY_PCT'])}
            </Table.Cell>}
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/store/meas?strNbr=${strNbr}`} data-test='overview-meas-link'>
                Measures
              </Link>
            </Table.Cell>
            <Table.Cell >
              {storeMetrics[timeframe + '_MEAS_ACT'].toLocaleString(undefined, { maximumFractionDigits: 0 })}
            </Table.Cell>
            <Table.Cell>-</Table.Cell>
            {unit === 'NOM' && <Table.Cell style={getCompareStyle(storeMetrics[timeframe + '_MEAS_VLY'])}>
              {storeMetrics[timeframe + '_MEAS_VLY'].toLocaleString(undefined, { maximumFractionDigits: 0 })}
            </Table.Cell>}
            {unit === 'PCT' && <Table.Cell style={getCompareStyle(storeMetrics[timeframe + '_MEAS_VLY_PCT'])}>
              {getPercentString(storeMetrics[timeframe + '_MEAS_VLY_PCT'])}
            </Table.Cell>}
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <Link
                style={{ color: '#EE7125', textDecoration: 'underline' }}
                to={{ pathname: `https://pr-mstr-so.homedepot.com/MicroStrategy/servlet/mstrWeb?Server=MSTR-MYNUMBER-IS-20202-1&Project=PONE-SO&Port=0&evt=2048001&documentID=9C90664A48024856B23381BCFE86A029&&currentViewMedia=2&elementsPromptAnswers=E15D73A24D9970F1A0A15CBECADE46AE;E15D73A24D9970F1A0A15CBECADE46AE:${Number(strNbr)}` }}
                target="_blank">
                Cashier Metrics
              </Link>
            </Table.Cell>
            <Table.Cell>-</Table.Cell>
            <Table.Cell>-</Table.Cell>
            <Table.Cell>-</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>HDPP - MA UA</Table.Cell>
            <Table.Cell>{getPercentString(storeMetrics[timeframe + '_MA_HDPP'])}</Table.Cell>
            <Table.Cell style={getCompareStyle(storeMetrics[timeframe + '_MA_HDPP_VG'])}>{getPercentString(storeMetrics[timeframe + '_MA_HDPP_VG'])}</Table.Cell>
            <Table.Cell style={getCompareStyle(storeMetrics[timeframe + '_MA_HDPP_VLY'])}>{getPercentString(storeMetrics[timeframe + '_MA_HDPP_VLY'])}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>HDPP - GM UA</Table.Cell>
            <Table.Cell>{getPercentString(storeMetrics[timeframe + '_GM_HDPP'])}</Table.Cell>
            <Table.Cell style={getCompareStyle(storeMetrics[timeframe + '_GM_HDPP_VG'])}>{getPercentString(storeMetrics[timeframe + '_GM_HDPP_VG'])}</Table.Cell>
            <Table.Cell style={getCompareStyle(storeMetrics[timeframe + '_GM_HDPP_VLY'])}>{getPercentString(storeMetrics[timeframe + '_GM_HDPP_VLY'])}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <OverviewTabs dataSlsDeptByStr={dataSlsDeptByStr} dataSlsClsByStr={dataSlsClsByStr} dataSlsSclsByStr={dataSlsSclsByStr} aisleData={locAisleDtlMetrics} deptInventory={deptInventory} Top40Btm40Query={Top40Btm40Query} timeframe={timeframe} fpToggle={fpToggle} strNm={storeMetrics['STR_NM']} />
    </>
  )
})