import { useState } from 'react'
import { Header, Loader, Grid } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import { useQuery } from 'react-query'
import { useQueryParams } from '../../hooks/useQueryParams'

import { UnitMenu } from '../Common/UnitMenu'
import { DataTable } from '../Common/DataTable'

import { getData } from '../../service/DataService'
import { getThousandDollarString } from '../Common/TableCellUtils'
import { getPrevAndNextElement } from '../../utils/array'

const LEVEL_KEYS = {
  'store' : 'strNbr',
  'district' : 'dstNbr',
  'region': 'rgnNbr',
}

const INV_QUERIES = {
  'store' : 'queryInvClsByStrDept',
  'district': 'queryInvClsByDstDept',
  'region': 'queryInvClsByRgnDept',
}

export const InvDeptDrilldown = ({ level, fpToggle }) => {
  const [unit, setUnit] = useState('NOM')
  const { deptNbr: deptNbrInt = 0, dstNbr = 0, rgnNbr = 0, strNbr = 0 } = useQueryParams()
  const orgNbr = dstNbr || strNbr || rgnNbr || 0
  const {
    data: deptOverviewData,
    isLoading: isDeptOverviewLoading,
  } = useQuery([`query/deptSales/${level}`, { locNbr: orgNbr, fpToggle }], getData)
  const {
    data: classInventory,
    isLoading: isClassInvLoading,
  } = useQuery([INV_QUERIES[level], { locNbr: orgNbr, deptNbrInt, fpToggle }], getData)

  if (isDeptOverviewLoading || isClassInvLoading) return <Loader active>Loading...</Loader>

  const deptDataSorted = deptOverviewData.sort((left, right) => {
    let result = left.DEPT_NBR - right.DEPT_NBR
    return result
  })
  const currentIndex = deptDataSorted.findIndex(dept => dept['DEPT_NBR'] === parseInt(deptNbrInt))
  const [previousDept, nextDept] = getPrevAndNextElement(deptDataSorted, currentIndex)
  const isNom = unit === 'NOM'
  const isPct = unit === 'PCT'

  const dataObj = {
    data: classInventory.map(row => ([
      {
        link: {
          to: `/${level}/inventory/dept/class`,
          params: {
            [LEVEL_KEYS[level]]: orgNbr,
            deptNbr: row['DEPT_NBR'],
            classNbr: row['EXT_CLASS_NBR'],
          },
        },
        stringValue: row['CLASS_NM']
      },
      {
        orderBy: row['INV_AMT'],
        stringValue: getThousandDollarString(row['INV_AMT']),
      },
      {
        compareStyle: true,
        orderBy: isNom ? row['INV_VP'] : row['INV_VP_PCT'],
        pctValue: isPct ? row['INV_VP_PCT'] : undefined,
        stringValue: isNom ? getThousandDollarString(row['INV_VP']) : undefined,
      },
      {
        orderBy: row['ASW'],
        stringValue: getThousandDollarString(row['ASW']),
      },
      {
        stringValue: row['WOS'].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 1 }),
        orderBy: row['WOS']
      },
      {
        orderBy: row['ICE_INV_AMT'],
        stringValue: getThousandDollarString(row['ICE_INV_AMT']),
      }
    ])),
    headers: [
      { name: 'Class ' },
      { name: 'Inv $ ' },
      { name: `vP ${isPct ? '%' : ''}` },
      { name: 'ASF ' },
      { name: 'WOS ' },
      { name: 'ICE $ ' },
    ],
    sortable: true,
    sortableDefault: 0,
  }

  return (
    <>
      <Header data-test='department-header' textAlign='center'>{deptDataSorted[currentIndex]?.MER_DEPT_NM}</Header>
      {/* Buttons for adjacent departments */}
      <Grid className="adjacent-departments">
        <Grid.Row>
          <Grid.Column textAlign='left' width={8}>
            <Link
              to={`/${level}/inventory/dept?${LEVEL_KEYS[level]}=${orgNbr}&deptNbr=${previousDept?.DEPT_NBR}`}
              style={{ color: '#EE7125', textDecoration: 'underline' }}
            >
              {previousDept?.MER_DEPT_NM}
            </Link>

          </Grid.Column>
          <Grid.Column textAlign='right' width={8}>
            <Link
              to={`/${level}/inventory/dept?${LEVEL_KEYS[level]}=${orgNbr}&deptNbr=${nextDept?.DEPT_NBR}`}
              style={{ color: '#EE7125', textDecoration: 'underline' }}
            >
              {nextDept?.MER_DEPT_NM}
            </Link>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Grid>
        <Grid.Row>
          <Grid.Column width={8} textAlign='left'>
            <Grid.Row>&nbsp;</Grid.Row>
            <Grid.Row>
              <Link style={{ color: '#EE7125' }} to={`/${level}/overview?${LEVEL_KEYS[level]}=${orgNbr}`} data-test='b2o-link'>
                Back to Overview
              </Link>
            </Grid.Row>
            <Grid.Row>&nbsp;</Grid.Row>
            <Grid.Row>Units:</Grid.Row>
            <UnitMenu unit={unit} setUnit={setUnit} />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Header textAlign='center'>Inventory Detail</Header>

      <DataTable data={dataObj} />
    </>
  )
}
