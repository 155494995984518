export const FORMATMETRIC = {
    "SLS_AMT": "Sales Amount",
    "SLS_COMP_NOM": "Sales Comp $",
    "SLS_COMP_PCT": "Sales Comp %",
    "UNT_SLS": "Unit Sales",
    "UNT_COMP_NOM": "Units Comp #",
    "UNT_COMP_PCT": "Units Comp %"
}

export const LEVEL_KEYS = {
    'store': 'strNbr',
    'district': 'dstNbr',
    'region': 'rgnNbr',
}