import React from 'react'
import {
    Grid,
    Header,
    Loader,
  } from 'semantic-ui-react'
import { withRouter, Link } from "react-router-dom/cjs/react-router-dom.min";
import { useQuery } from 'react-query'
import { TimeframeDropdown } from '../Common/TimeframeDropdown'
import { TimeframeDropdownMon } from '../Common/TimeframeDropdownMon'
import { DataTable } from '../Common/DataTable'

import {
    getData
  } from '../../service/DataService'

import {IMAGING_GOAL} from './OSAImaging'

export const OSAImagingDist = withRouter(({ timeframe, settimeframe, level, fpToggle, locNbr, ...props }) => {

  const osaImagingQuery = useQuery([`query/osaImageCapturePlusOne/${level}`, { locNbr, fpToggle }], getData)
  if (osaImagingQuery.isLoading) {
    return (<Loader active>Loading</Loader>)
  }
  if (!osaImagingQuery.data || osaImagingQuery.data.length === 0) {
    return (<Header textAlign='center'>Invalid Store Number</Header>)
  }

  const lastTimeframeMap = {
    'WTD': 'LW',
    'LW': null, // We don't have comparisons for last week or month, so they'll be null
    'LM': null,
    'MTD': 'LM',
    'QTD': 'LQ',
    'HTD': 'LH',
    'YTD': 'YTD_LY',
    'L12': 'L12_LY',
  }

  const ltf = lastTimeframeMap[timeframe]

  const storeSummary = osaImagingQuery.data.reduce((acc, row) => {
    const STR_NBR = row.STR_NBR.toString().padStart(4, '0')
    if (!acc[STR_NBR]) {
      acc[STR_NBR] = {actual: 0, goal: 0, lastActual: 0}
    }
    acc[STR_NBR].actual += row[`${timeframe}_IMAGES_TAKEN`]
    acc[STR_NBR].goal += row[`${timeframe}_IMAGES_EXPECTED`]
    if (ltf) { 
      acc[STR_NBR].lastActual += row[`${ltf}_IMAGES_TAKEN`]
      acc[STR_NBR].lastGoal += row[`${ltf}_IMAGES_EXPECTED`]
    } else {
      acc[STR_NBR].lastActual = null
      acc[STR_NBR].lastGoal = null
    }
    return acc
  }, {})

  const storeData = {
    sortable: true,
    headers: [{name: 'Store'},{name: 'Actual'},{name: 'Goal'},{name: ltf ? `v${ltf} bps` : '-'},],
    data: 
      Object.entries(storeSummary).map(([store, dat]) => {
        return [
          {
            stringValue: store,
            link: {
              to: '/osa/store/imaging/',
              params: {
                strNbr: store,
              }
            }
          },
          {pctValue: dat.actual / dat.goal, decimals: 1, compareValue: IMAGING_GOAL},
          {pctValue: IMAGING_GOAL},
          ltf ? {numValue:  ((dat.actual/dat.goal) - (dat.lastActual/dat.lastGoal)) *10000, compareStyle: true, postfix: ' bps'} : {stringValue: '-'},
        ]
      })
  }

  return (
    <>
    <Grid>
      <Grid.Column width={8}>
        <Grid.Row>
          <Link style={{ color: '#EE7125' }} to={`/${level}/overview?dstNbr=${locNbr}`} data-test='b2o-link'>Overview</Link>
          {' / '}
          <Link style={{ color: '#EE7125' }} to={`/osa?dstNbr=${locNbr}`} data-test='b2osa-link'>OSA</Link>
          {' / Imaging%'}
        </Grid.Row>
        <Grid.Row>&nbsp;</Grid.Row>
          </Grid.Column>
          <Grid.Column width={8}>
        Timeframe:
        {new Date().getDay() === 1 ?
          <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} /> :
          <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />}
      </Grid.Column>
    </Grid>
    <Header textAlign='center' >Overhead Imaging %</Header>
    <DataTable data={storeData} />
    </>
)
})