import { Tab } from 'semantic-ui-react'

export const TabMenu = ({ panes = [] }) => {
  const baseMenuStyles = {
    attached: false,
    pointing: false,
    secondary: false,
    borderless: false,
    compact: true,
    size: 'small',
    widths: panes.length
  }

  return (
    <Tab menu={baseMenuStyles} panes={panes} />
  )
}
