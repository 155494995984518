import React, { useState } from 'react'
import { withRouter, Link } from 'react-router-dom'
import {
  Table,
  Header,
  Loader,
  Icon,
  Menu,
} from 'semantic-ui-react'

import { useQuery } from 'react-query'
import {
  getData
} from '../../service/DataService'
import { getDollarString, getPercentString } from '../Common/TableCellUtils'
import { UnitsSellMenu } from './ UnitsSellMenu'

export const Events = withRouter(({ fpToggle, level, location }) => {

  const [salesOrUnits, setSalesOrUnits] = useState("Units #")
  const [completeSalesOrUnits, setCompleteSalesOrUnits] = useState("Units #")

  const search = new URLSearchParams(location.search)
  const strNbr = search.has('strNbr') ? search.get('strNbr') : 0
  const dstNbr = search.has('dstNbr') ? search.get('dstNbr') : 0
  const [sortColumn, setSortColumn] = useState('EVNT_EFF_BGN_DT')
  const [sortDirection, setSortDirection] = useState('descending')
  const [completeSortColumn, setCompleteSortColumn] = useState('EVNT_EFF_BGN_DT')
  const [completeSortDirection, setCompleteSortDirection] = useState('descending')
  const evntSumryQuery = level === 'store' ? useQuery(['queryEvntSumryByStr', { strNbr, fpToggle }], getData) :
    level === 'district' ? useQuery(['queryEvntSumryByDst', { dstNbr, fpToggle }], getData) :
      null
  if (evntSumryQuery.isLoading) {
    return <Loader active>Loading...</Loader>
  }

  if (!evntSumryQuery.data[0]) {
    return (<Header textAlign='center'>Invalid {level} number</Header>)
  }

  let activeFilter = evntSumryQuery.data.filter((row) => {
    return row && row['EVENT_STATUS'] === 'ACTIVE'
  })

  let activeSorted = activeFilter.sort((left, right) => {
    let result = left[sortColumn] - right[sortColumn]
    if (sortColumn === 'EVNT_EFF_BGN_DT') {
      result = left.EVNT_EFF_BGN_DT > right.EVNT_EFF_BGN_DT ? 1 : -1
    }
    if (sortColumn === 'EVNT_EFF_END_DT') {
      result = left.EVNT_EFF_END_DT > right.EVNT_EFF_END_DT ? 1 : -1
    }
    if (sortColumn === 'EVNT_NM') {
      result = left.EVNT_NM > right.EVNT_NM ? 1 : -1
    }
    if (sortColumn === 'TOT_ST_NUM') {
      result = left.TOT_ST_NUM / left.TOT_ST_DEN > right.TOT_ST_NUM / right.TOT_ST_DEN ? 1 : -1
    }
    if (sortColumn === 'TOT_ST_UNITS') {
      result = left.TOT_ST_NUM_UNITS / left.TOT_ST_DEN_UNITS > right.TOT_ST_NUM_UNITS / right.TOT_ST_DEN_UNITS ? 1 : -1
    }
    if (sortDirection === 'descending') {
      result *= -1
    }
    return result
  })

  let completeFilter = evntSumryQuery.data.filter((row) => {
    return row && row['EVENT_STATUS'] === 'COMPLETE'
  })


  let completeSorted = completeFilter.sort((left, right) => {
    let result = left[completeSortColumn] - right[completeSortColumn]
    if (completeSortColumn === 'EVNT_EFF_BGN_DT') {
      result = left.EVNT_EFF_BGN_DT > right.EVNT_EFF_BGN_DT ? 1 : -1
    }
    if (completeSortColumn === 'EVNT_EFF_END_DT') {
      result = left.EVNT_EFF_END_DT > right.EVNT_EFF_END_DT ? 1 : -1
    }
    if (completeSortColumn === 'EVNT_NM') {
      result = left.EVNT_NM > right.EVNT_NM ? 1 : -1
    }
    if (completeSortColumn === 'TOT_ST_NUM') {
      result = left.TOT_ST_NUM / left.TOT_ST_DEN > right.TOT_ST_NUM / right.TOT_ST_DEN ? 1 : -1
    }
    if (completeSortColumn === 'TOT_ST_UNITS') {
      result = left.TOT_ST_NUM_UNITS / left.TOT_ST_DEN_UNITS > right.TOT_ST_NUM_UNITS / right.TOT_ST_DEN_UNITS ? 1 : -1
    }
    if (completeSortDirection === 'descending') {
      result *= -1
    }
    return result
  })

  return (
    <>
      {/* In next line, capitalize first letter of level for display aesthetic */}
      <Header textAlign='center'>Events - {level[0].toUpperCase() + level.substring(1)} #{level === 'store' ? strNbr : level === 'district' ? dstNbr : strNbr}</Header>
      <Header textAlign='center'>Active Events</Header>
      <Menu compact size='small'>
        <UnitsSellMenu salesOrUnits={salesOrUnits} setSalesOrUnits={setSalesOrUnits} />
      </Menu>
      <Table textAlign='center' unstackable celled sortable size='small'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              sorted={sortColumn === 'EVNT_NM' ? sortDirection : null}
              onClick={() => {
                if (sortColumn !== 'EVNT_NM') {
                  setSortColumn('EVNT_NM')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}
            >Events {sortColumn !== 'EVNT_NM' && <Icon fitted name='sort' />}</Table.HeaderCell>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              sorted={sortColumn === 'EVNT_EFF_BGN_DT' ? sortDirection : null}
              onClick={() => {
                if (sortColumn !== 'EVNT_EFF_BGN_DT') {
                  setSortColumn('EVNT_EFF_BGN_DT')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}
            >Begin Date {sortColumn !== 'EVNT_EFF_BGN_DT' && <Icon fitted name='sort' />}</Table.HeaderCell>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              sorted={sortColumn === 'EVNT_EFF_END_DT' ? sortDirection : null}
              onClick={() => {
                if (sortColumn !== 'EVNT_EFF_END_DT') {
                  setSortColumn('EVNT_EFF_END_DT')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}
            >End Date {sortColumn !== 'EVNT_EFF_END_DT' && <Icon fitted name='sort' />}</Table.HeaderCell>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              sorted={sortColumn === 'TOT_EVNT_SLS_AMT' ? sortDirection : null}
              onClick={() => {
                if (sortColumn !== 'TOT_EVNT_SLS_AMT') {
                  setSortColumn('TOT_EVNT_SLS_AMT')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}
            >Total Sales {sortColumn !== 'TOT_EVNT_SLS_AMT' && <Icon fitted name='sort' />}</Table.HeaderCell>
            {/* {salesOrUnits === 'Units #' && <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              sorted={sortColumn === 'TOT_EVNT_SLS_UNITS' ? sortDirection : null}
              onClick={() => {
                if (sortColumn !== 'TOT_EVNT_SLS_UNITS') {
                  setSortColumn('TOT_EVNT_SLS_UNITS')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}
            >Total Units {sortColumn !== 'TOT_ST_NUM_UNITS' && <Icon fitted name='sort' />}</Table.HeaderCell>} */}
            {salesOrUnits === 'Sales $' && <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              sorted={sortColumn === 'TOT_ST_NUM' ? sortDirection : null}
              onClick={() => {
                if (sortColumn !== 'TOT_ST_NUM') {
                  setSortColumn('TOT_ST_NUM')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}
            >Sell Thru % {sortColumn !== 'TOT_ST_NUM' && <Icon fitted name='sort' />}</Table.HeaderCell>}
            {salesOrUnits === 'Units #' && <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              sorted={sortColumn === 'TOT_ST_UNITS' ? sortDirection : null}
              onClick={() => {
                if (sortColumn !== 'TOT_ST_UNITS') {
                  setSortColumn('TOT_ST_UNITS')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}
            >Unit Sell Thru % {sortColumn !== 'TOT_ST_UNITS' && <Icon fitted name='sort' />}</Table.HeaderCell>}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {activeSorted.map((data) => {
            return (
              <Table.Row>
                <Table.Cell>
                  <Link
                    to={`/${level}/events/detail?${level === 'store' ? 'strNbr' : level === 'district' ? 'dstNbr' : 'strNbr'}=${level === 'store' ? strNbr : level === 'district' ? dstNbr : strNbr}&evntId=${data['EVNT_ID']}`}
                    style={{ color: '#EE7125', textDecoration: 'underline' }}
                  >{data['EVNT_NM']}</Link>
                </Table.Cell>
                <Table.Cell>{data['EVNT_EFF_BGN_DT']}</Table.Cell>
                <Table.Cell>{data['EVNT_EFF_END_DT']}</Table.Cell>
                {<Table.Cell>{getDollarString(data['TOT_EVNT_SLS_AMT'])}</Table.Cell>}
                {salesOrUnits === 'Sales $' && <Table.Cell>{getPercentString(data['TOT_ST_NUM'] / data['TOT_ST_DEN'])}</Table.Cell>}
                {/* {salesOrUnits === 'Units #' && <Table.Cell>{data['TOT_EVNT_SLS_UNITS'].toLocaleString("en-US")}</Table.Cell>} */}
                {salesOrUnits === 'Units #' && <Table.Cell>{getPercentString(data['TOT_ST_NUM_UNITS'] / data['TOT_ST_DEN_UNITS'])}</Table.Cell>}
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
      <Header textAlign='center'>Completed Events</Header>
      <Menu compact size='small'>
        <UnitsSellMenu salesOrUnits={completeSalesOrUnits} setSalesOrUnits={setCompleteSalesOrUnits} />
      </Menu>
      <Table textAlign='center' unstackable celled sortable size='small'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              sorted={completeSortColumn === 'EVNT_NM' ? completeSortDirection : null}
              onClick={() => {
                if (completeSortColumn !== 'EVNT_NM') {
                  setCompleteSortColumn('EVNT_NM')
                  setCompleteSortDirection('ascending')
                } else {
                  completeSortDirection === 'ascending' ? setCompleteSortDirection('descending') : setCompleteSortDirection('ascending')
                }
              }}
            >Events {completeSortColumn !== 'EVNT_NM' && <Icon fitted name='sort' />}</Table.HeaderCell>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              sorted={completeSortColumn === 'EVNT_EFF_BGN_DT' ? completeSortDirection : null}
              onClick={() => {
                if (completeSortColumn !== 'EVNT_EFF_BGN_DT') {
                  setCompleteSortColumn('EVNT_EFF_BGN_DT')
                  setCompleteSortDirection('ascending')
                } else {
                  completeSortDirection === 'ascending' ? setCompleteSortDirection('descending') : setCompleteSortDirection('ascending')
                }
              }}
            >Begin Date {completeSortColumn !== 'EVNT_EFF_BGN_DT' && <Icon fitted name='sort' />}</Table.HeaderCell>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              sorted={completeSortColumn === 'EVNT_EFF_END_DT' ? completeSortDirection : null}
              onClick={() => {
                if (completeSortColumn !== 'EVNT_EFF_END_DT') {
                  setCompleteSortColumn('EVNT_EFF_END_DT')
                  setCompleteSortDirection('ascending')
                } else {
                  completeSortDirection === 'ascending' ? setCompleteSortDirection('descending') : setCompleteSortDirection('ascending')
                }
              }}
            >End Date {completeSortColumn !== 'EVNT_EFF_END_DT' && <Icon fitted name='sort' />}</Table.HeaderCell>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              sorted={completeSortColumn === 'TOT_EVNT_SLS_AMT' ? completeSortDirection : null}
              onClick={() => {
                if (completeSortColumn !== 'TOT_EVNT_SLS_AMT') {
                  setCompleteSortColumn('TOT_EVNT_SLS_AMT')
                  setCompleteSortDirection('ascending')
                } else {
                  completeSortDirection === 'ascending' ? setCompleteSortDirection('descending') : setCompleteSortDirection('ascending')
                }
              }}
            >Total Sales {completeSortColumn !== 'TOT_EVNT_SLS_AMT' && <Icon fitted name='sort' />}</Table.HeaderCell>
            {/* {completeSalesOrUnits === 'Units #' && <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              sorted={completeSortColumn === 'TOT_EVNT_SLS_UNITS' ? completeSortDirection : null}
              onClick={() => {
                if (completeSortColumn !== 'TOT_EVNT_SLS_UNITS') {
                  setCompleteSortColumn('TOT_EVNT_SLS_UNITS')
                  setCompleteSortDirection('ascending')
                } else {
                  completeSortDirection === 'ascending' ? setCompleteSortDirection('descending') : setCompleteSortDirection('ascending')
                }
              }}
            >Total Units {completeSortColumn !== 'TOT_ST_NUM_UNITS' && <Icon fitted name='sort' />}</Table.HeaderCell>} */}
            {completeSalesOrUnits === 'Sales $' && <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              sorted={completeSortColumn === 'TOT_ST_NUM' ? completeSortDirection : null}
              onClick={() => {
                if (completeSortColumn !== 'TOT_ST_NUM') {
                  setCompleteSortColumn('TOT_ST_NUM')
                  setCompleteSortDirection('ascending')
                } else {
                  completeSortDirection === 'ascending' ? setCompleteSortDirection('descending') : setCompleteSortDirection('ascending')
                }
              }}
            >Sell Thru % {completeSortColumn !== 'TOT_ST_NUM' && <Icon fitted name='sort' />}</Table.HeaderCell>}
            {completeSalesOrUnits === 'Units #' && <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              sorted={completeSortColumn === 'TOT_ST_UNITS' ? completeSortDirection : null}
              onClick={() => {
                if (completeSortColumn !== 'TOT_ST_UNITS') {
                  setCompleteSortColumn('TOT_ST_UNITS')
                  setCompleteSortDirection('ascending')
                } else {
                  completeSortDirection === 'ascending' ? setCompleteSortDirection('descending') : setCompleteSortDirection('ascending')
                }
              }}
            >Unit Sell Thru % {completeSortColumn !== 'TOT_ST_UNITS' && <Icon fitted name='sort' />}</Table.HeaderCell>}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {completeSorted.map((data) => {
            return (
              <Table.Row>
                <Table.Cell>
                  <Link
                    to={`/${level}/events/detail?${level === 'store' ? 'strNbr' : level === 'district' ? 'dstNbr' : 'strNbr'}=${level === 'store' ? strNbr : level === 'district' ? dstNbr : strNbr}&evntId=${data['EVNT_ID']}`}
                    style={{ color: '#EE7125', textDecoration: 'underline' }}
                  >{data['EVNT_NM']}</Link>
                </Table.Cell>
                <Table.Cell>{data['EVNT_EFF_BGN_DT']}</Table.Cell>
                <Table.Cell>{data['EVNT_EFF_END_DT']}</Table.Cell>
                {<Table.Cell>{getDollarString(data['TOT_EVNT_SLS_AMT'])}</Table.Cell>}
                {completeSalesOrUnits === 'Sales $' && <Table.Cell>{getPercentString(data['TOT_ST_NUM'] / data['TOT_ST_DEN'])}</Table.Cell>}
                {/* {completeSalesOrUnits === 'Units #' && <Table.Cell>{data['TOT_EVNT_SLS_UNITS']?data['TOT_EVNT_SLS_UNITS'].toLocaleString("en-US"):0}</Table.Cell>} */}
                {completeSalesOrUnits === 'Units #' && <Table.Cell>{getPercentString(data['TOT_ST_NUM_UNITS'] / data['TOT_ST_DEN_UNITS'])}</Table.Cell>}
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    </>
  )

})