import { Header } from "semantic-ui-react"

export const HeaderComponent = ({ level, levelNumber, deptNbr, rgnName }) => {
  
  return (
    <Header textAlign='center'>
      DEPT {deptNbr}
      <label style={{ textTransform: 'capitalize' }}>
        {'-' + level + '-' + levelNumber}, {' ' + String(rgnName).toLowerCase()}
      </label>

    </Header>
  )
}