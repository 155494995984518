import React, { useState } from 'react'
import {
  Table,
  Grid,
  Tab,
  Pagination,
} from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'

export const ChannelAssociate = withRouter(({ credAssocChnlData, timeframe }) => {

  const [sortColumn, setSortColumn] = useState('ASSOC_CHNL')
  const [sortDirection, setSortDirection] = useState('ascending')
  const [pageNum, setPageNum] = useState(1)
  let rowsPerPage = 25

  let assocFilter = credAssocChnlData.filter((row) => {
    return row && row['ASSOC_CHNL_IND'] === 'ASSOCIATE' && row[timeframe + '_TOT_APPS'] + row[timeframe + '_COMM_APPS'] + row[timeframe + '_CONS_APPS'] > 0
  })

  let assocSort = assocFilter.sort((left, right) => {
    let result = left[sortColumn] - right[sortColumn]
    if (sortColumn === 'ASSOC_CHNL') {
      result = left.ASSOC_CHNL > right.ASSOC_CHNL ? 1 : -1
    }
    if (sortDirection === 'descending') {
      result *= -1
    }
    return result
  })

  let assocPagination = assocSort.slice(rowsPerPage * (pageNum - 1), parseInt(Math.min(rowsPerPage * pageNum, assocSort.length)))

  let chnlFilter = credAssocChnlData.filter((row) => {
    return row && row['ASSOC_CHNL_IND'] === 'CHANNEL'
  })

  let chnlSort = chnlFilter.sort((left, right) => {
    let result = left[sortColumn] - right[sortColumn]
    if (sortColumn === 'ASSOC_CHNL') {
      result = left.ASSOC_CHNL > right.ASSOC_CHNL ? 1 : -1
    }
    if (sortDirection === 'descending') {
      result *= -1
    }
    return result
  })

  const channelView = <Tab.Pane>
    <Table textAlign='center' celled unstackable sortable size='small' style={{ fontSize: 12 }}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            sorted={sortColumn === 'ASSOC_CHNL' ? sortDirection : null}
            onClick={() => {
              if (sortColumn !== 'ASSOC_CHNL') {
                setSortColumn('ASSOC_CHNL')
                setSortDirection('ascending')
              } else {
                sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
              }
            }}>Channel</Table.HeaderCell>
          <Table.HeaderCell
            sorted={sortColumn === timeframe + '_TOT_APPS' ? sortDirection : null}
            onClick={() => {
              if (sortColumn !== timeframe + '_TOT_APPS') {
                setSortColumn(timeframe + '_TOT_APPS')
                setSortDirection('ascending')
              } else {
                sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
              }
            }}>Total</Table.HeaderCell>
          <Table.HeaderCell
            sorted={sortColumn === timeframe + '_CONS_APPS' ? sortDirection : null}
            onClick={() => {
              if (sortColumn !== timeframe + '_CONS_APPS') {
                setSortColumn(timeframe + '_CONS_APPS')
                setSortDirection('ascending')
              } else {
                sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
              }
            }}>Consumer</Table.HeaderCell>
          <Table.HeaderCell
            sorted={sortColumn === timeframe + '_COMM_APPS' ? sortDirection : null}
            onClick={() => {
              if (sortColumn !== timeframe + '_COMM_APPS') {
                setSortColumn(timeframe + '_COMM_APPS')
                setSortDirection('ascending')
              } else {
                sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
              }
            }}>Commercial</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {
          chnlSort.map((data) => {
            return (
              <Table.Row>
                <Table.Cell>{data['ASSOC_CHNL']}</Table.Cell>
                <Table.Cell>{data[timeframe + '_TOT_APPS']}</Table.Cell>
                <Table.Cell>{data[timeframe + '_CONS_APPS']}</Table.Cell>
                <Table.Cell>{data[timeframe + '_COMM_APPS']}</Table.Cell>
              </Table.Row>
            )
          })
        }
      </Table.Body>
    </Table>
  </Tab.Pane>

  const associateView = <Tab.Pane>
    {assocSort.length > rowsPerPage && <Grid>
              <Grid.Column width={16} textAlign='center'>
                <Grid.Row>&nbsp;</Grid.Row>
                <Grid.Row>
                  <Pagination
                    size='mini'
                    defaultActivePage={1}
                    totalPages={Math.floor((assocSort.length - 1) / rowsPerPage) + 1}
                    activePage={pageNum}
                    boundaryRange={0}
                    siblingRange={1}
                    onPageChange={(e, { activePage }) => {
                      setPageNum(activePage)
                    }}
                  />
                </Grid.Row>
                <Grid.Row>&nbsp;</Grid.Row>
              </Grid.Column>
            </Grid>}
    <Table textAlign='center' celled unstackable sortable size='small' style={{ fontSize: 12 }}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            sorted={sortColumn === 'ASSOC_CHNL' ? sortDirection : null}
            onClick={() => {
              if (sortColumn !== 'ASSOC_CHNL') {
                setSortColumn('ASSOC_CHNL')
                setSortDirection('ascending')
              } else {
                sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
              }
            }}>Channel</Table.HeaderCell>
          <Table.HeaderCell
            sorted={sortColumn === timeframe + '_TOT_APPS' ? sortDirection : null}
            onClick={() => {
              if (sortColumn !== timeframe + '_TOT_APPS') {
                setSortColumn(timeframe + '_TOT_APPS')
                setSortDirection('ascending')
              } else {
                sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
              }
            }}>Total</Table.HeaderCell>
          <Table.HeaderCell
            sorted={sortColumn === timeframe + '_CONS_APPS' ? sortDirection : null}
            onClick={() => {
              if (sortColumn !== timeframe + '_CONS_APPS') {
                setSortColumn(timeframe + '_CONS_APPS')
                setSortDirection('ascending')
              } else {
                sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
              }
            }}>Consumer</Table.HeaderCell>
          <Table.HeaderCell
            sorted={sortColumn === timeframe + '_COMM_APPS' ? sortDirection : null}
            onClick={() => {
              if (sortColumn !== timeframe + '_COMM_APPS') {
                setSortColumn(timeframe + '_COMM_APPS')
                setSortDirection('ascending')
              } else {
                sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
              }
            }}>Commercial</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {
          assocPagination.map((data) => {
            return (
              <Table.Row>
                <Table.Cell>{data['ASSOC_CHNL']}</Table.Cell>
                <Table.Cell>{data[timeframe + '_TOT_APPS']}</Table.Cell>
                <Table.Cell>{data[timeframe + '_CONS_APPS']}</Table.Cell>
                <Table.Cell>{data[timeframe + '_COMM_APPS']}</Table.Cell>
              </Table.Row>
            )
          })
        }
      </Table.Body>
    </Table>
  </Tab.Pane>

  // Set up renders for each of the two department view options: Sales and Comp
  const viewOption = [
    {
      menuItem: 'Channel',
      render: () => channelView
    },

    {
      menuItem: 'Associate',
      render: () => associateView,
    },
  ]

  return <Tab panes={viewOption} />
})