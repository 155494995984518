import React from 'react'
import { Button, Container, Header, Segment, Grid, Image } from 'semantic-ui-react'

export const LoginMenu = () => {

  window.location.href = `${process.env.REACT_APP_BACKEND}/identity/login?next=${encodeURIComponent(window.location.href.replaceAll('http:', 'https:'))}`

  return (
    <Container>
      <Segment>
        <Grid>
          <Grid.Row>
            <Grid.Column width={3}>
              <Image src='/hd_logo.jpg' size='mini' />
            </Grid.Column>
            <Grid.Column textAlign='center' width={10}>
              <Header textAlign='center'>Store Pulse</Header>
            </Grid.Column>
            <Grid.Column width={3}></Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column textAlign='center'>
              Please Login
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column textAlign='center'>
              <Button size='large' style={{ color: 'white', backgroundColor: '#EE7125' }} as='a' href={`${process.env.REACT_APP_BACKEND}/identity/login?next=${encodeURIComponent(window.location.href.replaceAll('http:', 'https:'))}`}>Login</Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </Container>
  )
}